import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson41/step15-1.png';
import img1xs from '../../../image/lessons/lesson41/step15-1xs.png';
import img2 from '../../../image/lessons/lesson41/step15-2.png';
import img2xs from '../../../image/lessons/lesson41/step15-2xs.png';
import img3 from '../../../image/lessons/lesson41/step15-3.png';
import img3xs from '../../../image/lessons/lesson41/step15-3xs.png';
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";


export default class step15 extends React.Component {

    state = {
        pointActive: 0,
        points: [0],
    };


    clickPoint = pointActive => {
        const {points} = this.state
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }
        this.setState({pointActive, points})

        if (points.length === 4) {
            this.props.showNextBtn();
        }
    }

    render() {
        const {points} = this.state
        return (
            <div className="step15">
                <InfoBlock
                    text={`Нажмите на активные элементы, чтобы узнать больше о росте волос у собак.`}
                />
                <TextBlock
                    text={`Рост волос – это циклический процесс, состоящий из 3-х периодов:`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className={'flex_el_number ' + (points.indexOf(1) >= 0 && 'active')} onClick={() => {this.clickPoint(1)}}>1</div>
                        <div className={'flex_el_block ' + (points.indexOf(1) >= 0 && 'active')}>
                            <div className="flex_el_img-box">
                                <picture>
                                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                                    <img src={img1} alt="" className="flex_el_img"/>
                                </picture>
                            </div>
                            <div className="flex_el_description">
                                <b>Анаген</b> – период активного роста
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className={'flex_el_number ' + (points.indexOf(2) >= 0 && 'active')} onClick={() => {this.clickPoint(2)}}>2</div>
                        <div className={'flex_el_block ' + (points.indexOf(2) >= 0 && 'active')}>
                            <div className="flex_el_img-box">
                                <picture>
                                    <source srcSet={img2xs} media="(max-width: 767px)"/>
                                    <img src={img2} alt="" className="flex_el_img"/>
                                </picture>
                            </div>
                            <div className="flex_el_description">
                                <b>Катаген</b> – переходный период
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className={'flex_el_number ' + (points.indexOf(3) >= 0 && 'active')} onClick={() => {this.clickPoint(3)}}>3</div>
                        <div className={'flex_el_block ' + (points.indexOf(3) >= 0 && 'active')}>
                            <div className="flex_el_img-box">
                                <picture>
                                    <source srcSet={img3xs} media="(max-width: 767px)"/>
                                    <img src={img3} alt="" className="flex_el_img"/>
                                </picture>
                            </div>
                            <div className="flex_el_description">
                                <b>Телоген</b> – период покоя, волосы не растут, но сохраняются в фолликулах вплоть до выпадения
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
