import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import SwipeBlock from "../../../Components/LessonAndTestComponents/SwipeBlock"
import Slider from "react-slick";
import img1 from '../../../image/lessons/lesson1/step2-1.png';
import img2 from '../../../image/lessons/lesson1/step2-2.png';
import img3 from '../../../image/lessons/lesson1/step2-3.png';
import img4 from '../../../image/lessons/lesson1/step2-4.png';
import img5 from '../../../image/lessons/lesson1/step2-5.png';
import img6 from '../../../image/lessons/lesson1/step2-6.png';
import img7 from '../../../image/lessons/lesson1/step2-7.png';
import img8 from '../../../image/lessons/lesson1/step2-8.png';
import img9 from '../../../image/lessons/lesson1/step2-9.png';



export default class step2 extends React.Component {
    state = {
        flexElActive: 0,
        showSlider: false,
        flexHidden: false,
        arrowPosition: 'left',
        flexs: [],
        slider1: [],
        slider2: []
    };

    componentDidMount() {
        /*if (window.innerWidth < 1366) {
            this.setState({
                showSlider: true
            })
        }*/
    }

    changeFlex = flexElActive => {
        const {flexs, slider1, slider2} = this.state
        if (flexs.indexOf(flexElActive) === -1) {
            flexs.push(flexElActive)
        }
        if (flexElActive === 1) {
            this.setState({arrowPosition: 'left'})
        } else {
            this.setState({arrowPosition: 'right'})
        }
        this.setState({flexElActive, flexs, flexHidden: true, showSlider: true})
        if (slider1.length === 4 && slider2.length === 3 && flexs.length === 2) {
            this.props.showNextBtn()
        }
    }

    /*openSlider = () => {
        this.setState({
            showSlider: true
        })
    }*/

    changeSlide = (next) => {
        const {flexs, slider1, slider2, flexElActive} = this.state
        if (flexElActive === 1) {
            if (slider1.indexOf(next) === -1) {
                slider1.push(next)
            }
        } else if (flexElActive ===2) {
            if (slider2.indexOf(next) === -1) {
                slider2.push(next)
            }
        }
        this.setState({
            slider1: slider1,
            slider2: slider2
        })
        if (slider1.length === 4 && slider2.length === 3 && flexs.length === 2) {
            this.props.showNextBtn();
        }
        /*if(next >= 5){
            this.setState({
                flexElActive: 2,
                arrowPosition: 'right'
            })
        }else{
            this.setState({
                flexElActive: 1,
                arrowPosition: 'left'
            })
        };
        if(next === 8){
            this.props.showNextBtn();
        }*/
    }

    closeSlider = () => {this.setState({flexHidden: false, flexElActive: 0, showSlider: false})}

    slider = {
        1: {
            1: {
                text: `Нежелание иметь хлопоты, связанные с выращиванием щенка, вполне оправдано, и приобретение молодой или взрослой собаки вполне возможно — большинство собак достаточно легко привыкают к новым владельцам, хотя и среди них встречаются «однолюбы».`,
                img: img1
            },
            2: {
                text: `Но учтите — вам предстоит иметь дело со «сложившейся личностью», у которой уже есть определенный жизненный опыт и привычки, а если вы захотите «перевоспитать» собаку, вам потребуется на это время и терпение, возможно, придется обращаться к помощи специалиста.`,
                img: img2
            },
            3: {
                text: `С другой стороны, приобретая такую собаку, вы избегаете многих проблем, связанных с выращиванием и не столкнетесь с врожденными дефектами, которые могут быть не видны у маленького щенка, но проявляются с возрастом.`,
                img: img3
            },
            4: {
                text: `Понятие «молодая собака» сейчас достаточно широко трактуется (иногда собаку считают щенком чуть ли не до трехлетнего возраста), но в основном формирование экстерьера и характера большинства собак заканчивается к окончанию периода полового созревания — в 1-1,5 года.`,
                img: img4
            },
            5: {
                text: `Имейте в виду — чем старше собака, тем сложнее ее перевоспитывать. Поэтому особенно внимательно нужно знакомиться с собакой, если вы берете ее в возрасте старше 6-7 лет. Скорее всего, вам не удастся кардинально изменить ее поведение.`,
                img: img5
            },
        },
        2: {
            1: {
                text: `Если вам принципиально важно вырастить собаку самостоятельно, то ваш выбор — щенок, и чем младше, тем лучше. Сейчас в питомниках щенков крупных и средних пород принято передавать хозяевам в возрасте не менее 45 дней (не моложе 6,5 недель), а мелких пород - не менее 60 дней (8,5 недель).`,
                img: img6
            },
            2: {
                text: `Щенок привыкает к хозяину очень быстро, в ваших руках — это «пластилин», и при должной ответственности с вашей стороны к его воспитанию из него вырастет собака, которая будет вести себя в соответствии с вашими пожеланиями.`,
                img: img7
            },
            3: {
                text: `Нужно ясно понимать, что пока щенок не подрастет, он будет часто есть, справлять нужду дома и требовать контроля. Чтобы не пострадало ваше имущество, его нужно начинать воспитывать с первого дня (а не ждать, пока он вырастет, надеясь «потом обучить»).`,
                img: img8
            },
            4: {
                text: `К сожалению, далеко не всегда удается определить по маленькому щенку, какой будет характер у взрослой собаки, не будет ли у нее проблем со здоровьем (некоторые врожденные проблемы могут проявляться с возрастом) и не будет ли у нее недостатков экстерьера (это, в основном, касается породистых).`,
                img: img9
            },
        }
    }

    render() {
        const {flexElActive, showSlider, arrowPosition, flexHidden} = this.state;
        let settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            beforeChange: (current, next) => {
                this.changeSlide(next)
            },
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        dots: true,
                        arrows: false
                    }
                }
            ]
        };
        return (
            <div className="step2">
                <InfoBlock
                    /*swipeBlock={true}
                    swipeText={
                        `Используйте свайп для перемещения по шагам.`
                    }*/
                    text={
                        `Ознакомьтесь с вариантами, нажав на ключевые элементы.
                        Используйте стрелки для перемещения по шагам.`
                    }
                    hidden={flexHidden ? ' hidden' : ''}
                />
                <SwipeBlock
                    text={`Используйте свайп для перемещения по шагам.`}
                    hidden={flexHidden ? '' : ' hidden'}
                />

                <div className={'flex ' + (flexHidden === true && 'hidden')}>
                    <div className={'flex_el ' + (flexElActive === 1 && 'active')} onClick={() => {this.changeFlex(1)}}>
                        Молодая или&nbsp;взрослая&nbsp;собака
                    </div>
                    <div className={'flex_el ' + (flexElActive === 2 && 'active')} onClick={() => {this.changeFlex(2)}}>
                        Щенок
                    </div>
                </div>
                <div className={"slider " + (showSlider ? 'show ' : '') + 'slider_'+ (arrowPosition)}>
                    <div
                        className="slider_closed"
                        onClick={() => {this.closeSlider()}}
                    />
                {Object.values(this.slider).map((item, index) => {
                    return (
                        <div key={index} className={'slider_wrp ' + (index + 1 === flexElActive ? 'active' : '')}>
                            <Slider {...settings}>
                                {Object.values(item).map(item => {
                                    return (
                                        <div
                                            key={item.img}
                                            className="slider_el"

                                        >
                                            <div className="imgMobile">
                                                <img src={item.img} alt=""/>
                                            </div>
                                            <div className="slider_flex">
                                                {item.text}
                                                <img src={item.img} alt=""/>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>
                    )
                })

                }
                </div>
            </div>
        )
    }
}
