import React from 'react'

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step1">
                <div className="head">план урока</div>

                <div className="text">
                    Вашему вниманию предлагается цикл уроков, предназначенный для широкого круга людей, интересующихся собаководством - от планирующих завести собаку до тех, у кого она уже есть.
                    <br/>
                    <br/>
                    Говорят, что собака - отражение хозяина. И в этом есть доля истины. Можно приобрести очень неплохую, по ее собственному потенциалу, собаку, можно хорошо ее содержать и кормить, правильно за ней ухаживать, но чтобы она действительно была хорошей собакой –
                    ее непременно потребуется обучать и воспитывать.
                    </div>

                <div className="lessonSteps">
                    <div className="lessonSteps_el">
                        Как должна вести себя воспитанная собака?
                    </div>
                    <div className="lessonSteps_el">
                        Как воспитывать щенка?
                    </div>
                    <div className="lessonSteps_el">
                        Этапы социализации
                    </div>
                </div>

            </div>
        )
    }
}