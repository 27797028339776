import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import img1 from '../../../image/lessons/lesson3/step12-1.png'
import img2 from '../../../image/lessons/lesson3/step12-2.png'




export default class step12 extends React.Component {
    state = {
        linkActive: 0,
        links: [],
    };

    onClickLink = linkActive => {
        const {links} = this.state;
        if (links.indexOf(linkActive) === -1) {
            links.push(linkActive)
        }
        this.setState({linkActive, links});
        if (links.length === 3) {
            this.props.showNextBtn()
        }
    }

    happy = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.339 8.85309C17.339 9.61679 16.7203 10.2358 15.9566 10.2358C15.1933 10.2358 14.5742 9.61679 14.5742 8.85309C14.5742 8.08938 15.1933 7.4707 15.9566 7.4707C16.7203 7.4707 17.339 8.08938 17.339 8.85309Z" fill="#005EAD"/>
        <path d="M9.39172 8.85309C9.39172 9.61679 8.77266 10.2358 8.00934 10.2358C7.24601 10.2358 6.62695 9.61679 6.62695 8.85309C6.62695 8.08938 7.24601 7.4707 8.00934 7.4707C8.77266 7.4707 9.39172 8.08938 9.39172 8.85309Z" fill="#005EAD"/>
        <path d="M13.0578 19.0948H11.1381C8.70674 19.0948 6.72852 17.1405 6.72852 14.7376C6.72852 14.4228 6.98373 14.168 7.29817 14.168C7.6126 14.168 7.86782 14.4228 7.86782 14.7376C7.86782 16.542 9.30414 17.9555 11.1381 17.9555H13.0578C14.8918 17.9555 16.3281 16.5655 16.3281 14.7908C16.3281 14.476 16.5833 14.2211 16.8978 14.2211C17.2126 14.2211 17.4674 14.476 17.4674 14.7908C17.4675 17.2042 15.5306 19.0948 13.0578 19.0948Z" fill="#005EAD"/>
        <path d="M12.0011 24C5.38571 24 0.00390625 18.6167 0.00390625 11.9998C0.00390625 5.38332 5.38571 0 12.0011 0C18.616 0 23.9978 5.38332 23.9978 11.9998C23.9978 18.6166 18.616 24 12.0011 24ZM12.0011 1.13973C6.01386 1.13973 1.14325 6.01151 1.14325 11.9998C1.14325 17.9885 6.0139 22.8603 12.0011 22.8603C17.9879 22.8603 22.8585 17.9885 22.8585 11.9998C22.8585 6.01147 17.9879 1.13973 12.0011 1.13973Z" fill="#005EAD"/>
    </svg>;

    noHappy = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.6361 16.8629H6.85119C6.53228 16.8629 6.27344 16.6041 6.27344 16.2852C6.27344 15.9659 6.53228 15.707 6.85119 15.707H17.6361C17.955 15.707 18.2139 15.9659 18.2139 16.2852C18.2139 16.6041 17.9551 16.8629 17.6361 16.8629Z" fill="#BD4E3B"/>
        <path d="M17.3332 8.85623C17.3332 9.61965 16.7146 10.2382 15.9516 10.2382C15.1885 10.2382 14.5703 9.61965 14.5703 8.85623C14.5703 8.0932 15.1885 7.47461 15.9516 7.47461C16.7146 7.47461 17.3332 8.0932 17.3332 8.85623Z" fill="#BD4E3B"/>
        <path d="M9.38943 8.85623C9.38943 9.61965 8.77083 10.2382 8.00819 10.2382C7.24516 10.2382 6.62695 9.61965 6.62695 8.85623C6.62695 8.0932 7.24516 7.47461 8.00819 7.47461C8.77122 7.47461 9.38943 8.0932 9.38943 8.85623Z" fill="#BD4E3B"/>
        <path d="M11.999 24C5.38362 24 0.00195312 18.6168 0.00195312 12.0002C0.00229631 5.38321 5.384 0 11.999 0C18.6141 0 23.9961 5.38321 23.9961 12.0002C23.9961 18.6167 18.6141 24 11.999 24ZM11.999 1.15593C6.02109 1.15593 1.15746 6.02072 1.15746 12.0002C1.15746 17.9797 6.02109 22.8445 11.999 22.8445C17.9774 22.8445 22.8406 17.9797 22.8406 12.0002C22.8406 6.02068 17.9774 1.15593 11.999 1.15593Z" fill="#BD4E3B"/>
    </svg>;

    table = {
        1: {
            cat: 'Гипохлорит натрия',
            parvo: 1,
            kalitsi: 1,
            trichophytosis: 1,
            giardia: 1,
            coccidia: 0,
        },
        2: {
            cat: 'Четвертичный аммоний',
            parvo: 0,
            kalitsi: 0,
            trichophytosis: 0,
            giardia: 1,
            coccidia: 0,
        },
        3: {
            cat: 'Пероксомоносульфат натрия',
            parvo: 1,
            kalitsi: 1,
            trichophytosis: 0,
            giardia: 0,
            coccidia: 0,
        },
        4: {
            cat: 'Гипохлорит кальция',
            parvo: 1,
            kalitsi: 1,
            trichophytosis: 1,
            giardia: 1,
            coccidia: 0,
        },
        5: {
            cat: 'Дихлоризоцианурат натрия',
            parvo: 1,
            kalitsi: 1,
            trichophytosis: 1,
            giardia: 1,
            coccidia: 0,
        },
        6: {
            cat: 'Форсированная перекись водорода',
            parvo: 1,
            kalitsi: 1,
            trichophytosis: 1,
            giardia: 0,
            coccidia: 0,
        },
    }

    render() {
        const {linkActive} = this.state;

        return (
            <div className="step12">
                <TextBlock
                    fontSize={16}
                    text={`Дезинфекция — это химическое или физическое воздействие, убивающее микроорганизмы (бактерии, вирусы и паразитов) на инертных поверхностях.`}
                />
                <InfoBlock
                    text={`Ознакомьтесь с определением дезинфекции, нажав на ключевые элементы.`}
                />
                <div className="blueDiv">
                    Эффективная дезинфекция =
                </div>
                <div className="flex">
                    <div className="flex_el" onClick={()=>this.onClickLink(1)}>
                        Выбор правильного <br/>
                        дезинфектанта
                    </div>
                    <div className="plus"/>
                    <div className="flex_el" onClick={()=>this.onClickLink(2)}>
                        Правильное применение <br/>
                        дезинфектанта
                    </div>
                </div>
                {linkActive === 1 &&
                <div className="infoPopap">
                    <div className="infoPopap_wrp">
                        <div className="infoPopap_close" onClick={() => this.onClickLink(0)}/>
                        <div className="infoPopap_scroll">
                            <div className="infoPopap_iconDiv">
                                <img src={img1} alt="" className="infoPopap_icon"/>
                            </div>
                            <div className="infoPopap_head">Выбор правильного дезинфектанта</div>
                            <InfoBlock
                                danger={true}
                                text={`Не существует универсального дезинфектанта! Каждое дезинфицирующее средство обладает определенным спектром действия и должно выбираться с учетом болезнетворных агентов, присутствующих в питомнике!`}
                            />
                            <div className="table">
                                <div className="hr">
                                    <div className="td">Категория</div>
                                    <div className="td">Парво</div>
                                    <div className="td">Калици</div>
                                    <div className="td">Трихо-<br/>фития</div>
                                    <div className="td">Джиар<br/>дим</div>
                                    <div className="td">Кокцидии</div>
                                </div>
                                {Object.values(this.table).map((tr) => {
                                    return (
                                        <div className="tr">
                                            <div className="td">{tr.cat}</div>
                                            <div className="td">{tr.parvo === 1 ? this.happy : this.noHappy}</div>
                                            <div className="td">{tr.kalitsi === 1 ? this.happy : this.noHappy}</div>
                                            <div
                                                className="td">{tr.trichophytosis === 1 ? this.happy : this.noHappy}</div>
                                            <div className="td">{tr.giardia === 1 ? this.happy : this.noHappy}</div>
                                            <div className="td">{tr.coccidia === 1 ? this.happy : this.noHappy}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <button className="btn btn_blue" onClick={() => this.onClickLink(2)}>Продолжить</button>

                    </div>
                </div>
                }
                {linkActive === 2 &&
                <div className="infoPopap">
                    <div className="infoPopap_wrp">
                        <div className="infoPopap_close" onClick={() => this.onClickLink(0)}/>
                        <div className="infoPopap_scroll">
                            <div className="infoPopap_iconDiv">
                                <img src={img1} alt="" className="infoPopap_icon"/>
                            </div>
                            <div className="infoPopap_head">Правила применения дезинфенктанта</div>
                            <div className="infoPopap_text">
                                Нет «хороших» и «плохих» дезинфицирующих средств, есть неправильно подобранные средства и неправильные способы их применения: несоответствующий спектр действия, неправильно приготовленный раствор, неподходящая температура и т. п.
                            </div>
                            <div className="ul">
                                <div className="li">
                                    <div className="number">1</div>
                                    <div className="text">
                                        Конкретное средство способно воздействовать  на ограниченную группу микробов – это называется спектром действия выбирайте дезинфектант с широким спектром действия.
                                    </div>
                                </div>
                                <div className="li">
                                    <div className="number">2</div>
                                    <div className="text">
                                        Важные вопросы:
                                        <ul>
                                            <li>Какой микроорганизм является нашей целью?</li>
                                            <li>Как он попал в питомник?</li>
                                            <li>В каком месте питомника он может выжить?</li>
                                            <li>Как происходит заражение животных?</li>
                                            <li>К каким средствам он устойчив, а к каким чувствителен?</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="li">
                                    <div className="number">3</div>
                                    <div className="text">
                                        Другие параметры: опасность появления коррозии, возможность распыления, продолжительность
                                        остаточного действия, токсичность для животных, наличие запаха и т. д.
                                    </div>
                                </div>
                            </div>
                            <div className="infoPopap_imgDiv">
                                <img src={img2} alt="" className="infoPopap_img"/>
                            </div>
                        </div>
                        <button className="btn btn_blue" onClick={() => this.onClickLink(0)}>Продолжить</button>
                    </div>
                </div>
                }
            </div>
        )
    }
}