import React from 'react'
import './style.sass'

export default props => {
    return (
        <div className={'SwipeBlock ' + props.hidden}>
            <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M33.5139 28.1208C33.5158 26.1778 33.5179 24.7367 33.5191 23.5595C33.5233 20.3913 33.5248 19.2047 33.5167 15.7178C33.5142 14.5976 32.6791 13.6849 31.6554 13.6849H31.6005C30.5741 13.6849 29.7422 14.6012 29.7422 15.725V17.3154C29.7422 17.6566 29.4657 17.9331 29.1245 17.9331C28.7834 17.9331 28.5068 17.6566 28.5068 17.3154V14.378C28.5068 13.2545 27.6831 12.3403 26.6567 12.3403C25.6246 12.3403 24.8006 13.2545 24.8006 14.378V17.4916C24.8006 17.8327 24.524 18.1093 24.1828 18.1093C23.8417 18.1093 23.5651 17.8327 23.5651 17.4916V13.5235C23.5651 12.3997 22.7052 11.5229 21.6791 11.5229H21.6206C20.6205 11.5229 19.7817 12.3541 19.7817 13.4381V17.9054C19.7817 18.2465 19.5051 18.5231 19.1639 18.5231C18.8228 18.5231 18.5462 18.2465 18.5462 17.9054V13.5672C18.5462 13.5525 18.5447 13.538 18.5447 13.5226C18.5447 13.4873 18.5462 13.4523 18.5462 13.4173V3.27277C18.5462 2.14925 17.7005 1.23535 16.6747 1.23535C15.6486 1.23535 14.8032 2.14895 14.8026 3.27156L14.7908 20.0942C14.7905 20.3551 14.6264 20.5877 14.3809 20.6754C14.1354 20.7632 13.8612 20.6875 13.6956 20.486L11.6872 18.0408C11.1651 17.3863 10.3942 16.9782 9.55958 16.9143C8.70742 16.8575 8.22057 16.9966 7.31978 17.7283L6.95361 18.0294L13.901 31.372C14.9932 33.47 17.0572 34.7642 19.2876 34.7642H27.3381C30.74 34.7642 33.5103 31.7891 33.5139 28.1208Z" fill="#99BFDE"/>
                <path d="M21.9436 6.11836H29.9494L28.514 7.55375C28.3542 7.70909 28.2902 7.93831 28.347 8.15367C28.4037 8.36932 28.572 8.53763 28.7876 8.59433C29.003 8.65073 29.2322 8.58679 29.3875 8.42693L31.8683 5.94644C32.1096 5.70515 32.1096 5.31395 31.8683 5.07296L29.3875 2.59247C29.1459 2.35359 28.7568 2.3548 28.5168 2.59488C28.2764 2.83527 28.2752 3.22436 28.514 3.46595L29.931 4.88294H21.9436C21.6025 4.88294 21.3259 5.15953 21.3259 5.50065C21.3259 5.84178 21.6025 6.11836 21.9436 6.11836Z" fill="#99BFDE"/>
                <path d="M3.35353 6.11836H11.2882C11.6293 6.11836 11.9059 5.84178 11.9059 5.50065C11.9059 5.15953 11.6293 4.88294 11.2882 4.88294H3.37193L4.78892 3.46595C5.02781 3.22436 5.0266 2.83527 4.78621 2.59488C4.54612 2.3548 4.15704 2.35359 3.91544 2.59247L1.43465 5.07296C1.19366 5.31426 1.19366 5.70545 1.43465 5.94644L3.91544 8.42693C4.07108 8.58468 4.2994 8.64681 4.51355 8.58981C4.7277 8.5331 4.89479 8.36571 4.9518 8.15156C5.0088 7.93741 4.94667 7.70939 4.78892 7.55345L3.35353 6.11836Z" fill="#99BFDE"/>
            </svg>
            <span dangerouslySetInnerHTML={{ __html: props.text }}/>
        </div>
    )
}
