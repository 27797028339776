import React from 'react';
import { withRouter } from 'react-router-dom';


import Main from './Navigation/main';
import Entrance from './Navigation/Entrance';
import { withCookies} from "react-cookie";

import './Page/pageStyle.sass'

import LeftSideBar from "./Components/LeftSideBar";
import Cookies from "./Components/Cookies";
import ProjectHeader from "./Components/ProjectHeader";
import Api from './Service/Api'
import GuruPanel from "./Components/GuruPanel";
import SiteFooter from "./Components/SiteFooter";

class App extends React.Component {
    state = {
        isGuest: undefined,
        showGuru: true,
        user: undefined,
        showCookiesPopup: true,
    };
    Api = new Api()


    componentDidMount() {
        if (process.env.NODE_ENV !== 'development') {
            this.Api.isGuest()
                .then(isGuest => {
                    this.setState({isGuest}, () => {
                        if (isGuest /* && this.props.history.location.pathname.indexOf('startPage') === -1*/) {
                            this.props.history.push('/startPage');
                            this.needShowCookies()
                        }else{
                            this.updateUserDate()
                            this.needShowCookies()
                        }
                    })


                })
        }else{
            this.setState({
                isGuest: true
            }, () => {
                this.updateUserDate()
            })

            // this.props.history.push('/myTraining');
            this.props.history.push('/startPage');
            this.needShowCookies()

        }
        this.unlisten = this.props.history.listen( location =>  {
            this.updateUserDate()
        });

    }
    componentWillUnmount() {
        this.unlisten();

    }

    needShowCookies = () => {
        if (this.props.cookies.get("use-cookies") === "yes") {
            this.setState({showCookiesPopup: false});
        } else {
            this.setState({showCookiesPopup: true});
        }
    };

    closeCookiesPopup = () => {
        this.props.cookies.set("use-cookies", "no");
        this.setState({ showCookiesPopup: false });
    }

    cookiesYes = () => {
        this.props.cookies.set("use-cookies", "yes");
        this.setState({ showCookiesPopup: false });
    }

    updateUserDate = () =>{
        const {isGuest} = this.state
        let route = this.props.history.location.pathname
        if(isGuest === false) {
            if (route.indexOf('/myTraining') >= 0 || route.indexOf('/myTesting') >= 0 || (route.indexOf('/') >= 0 && route.length === 1)) {
                this.Api.getUserData()
                    .then(user => {
                        this.setState({user})
                    })

            }
        }
    }

    changeType = () => {
        this.Api.isGuest()
            .then(isGuest => {
                this.setState({isGuest})
                if(isGuest /* && this.props.history.location.pathname.indexOf('startPage') === -1*/){
                    this.props.history.push('/startPage');
                }else{
                    this.props.history.push('/');
                }
            })
    }



    render() {
        const {isGuest, showCookiesPopup} = this.state
        if(isGuest === undefined) {
            return(<div/>)
        }
        let route = this.props.history.location.pathname
        let showGuru = true;
        let ProjectHeaderType = true;
        if(route.indexOf('/faq') >= 0 ||route.indexOf('/lesson') >= 0 ||route.indexOf('/test') >= 0){
            showGuru = false
        }
        if(route.indexOf('/lesson') >= 0 ||route.indexOf('/test') >= 0){
            ProjectHeaderType = false
        }
        return (
            <div>
                {isGuest ?
                    <Entrance changeType={this.changeType}/>
                    :
                        <div>
                            <LeftSideBar user={this.state.user} changeType={this.changeType}/>
                            <div className="wrp">
                                <div className="content content_withLeftSideBar">
                                    {ProjectHeaderType &&
                                    <ProjectHeader
                                        ProjectHeaderType={ProjectHeaderType}
                                    />
                                    }
                                    {showGuru &&
                                    <GuruPanel user={this.state.user} />
                                    }
                                    <div className={'content_wrp ' + (showGuru && 'content_wrpGuru')}>
                                        <Main/>
                                    </div>
                                    <SiteFooter/>
                                </div>
                            </div>
                        </div>
                }
                {/*{showCookiesPopup &&*/}
                {/*<Cookies*/}
                {/*    closeCookiesPopup={this.closeCookiesPopup}*/}
                {/*    cookiesYes={this.cookiesYes}*/}
                {/*/>*/}
                {/*}*/}

            </div>
        )
    }
}

export default withCookies(withRouter(App));

