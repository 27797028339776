import React from 'react'

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step1">
                <div className="head">план урока</div>

                <div className="text">
                    В этом уроке вы узнаете о том, что такое сбалансированный рацион и из чего он состоит. Вы изучите особенности пищевого поведения собак и поймете, как их нужно учитывать при кормлении.
                </div>

                <div className="lessonSteps">
                    <div className="lessonSteps_el">Особенности пищевого поведения собак</div>
                    <div className="lessonSteps_el">Как кормить собаку – выбираем тип кормления</div>
                    <div className="lessonSteps_el">Какие бывают виды промышленных кормов</div>
                    <div className="lessonSteps_el">Суточная норма кормления</div>
                    <div className="lessonSteps_el">Правильный выбор лакомств</div>
                    <div className="lessonSteps_el">Что такое водный баланс и как его соблюдать</div>
                </div>

            </div>
        )
    }
}
