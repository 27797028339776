import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img0 from "../../../image/lessons/lesson8/step4-0.png";
import img1 from "../../../image/lessons/lesson8/step4-1.png";
import img2 from "../../../image/lessons/lesson8/step4-2.png";
import img3 from "../../../image/lessons/lesson8/step4-3.png";
import img4 from "../../../image/lessons/lesson8/step4-4.png";
import img5 from "../../../image/lessons/lesson8/step4-5.png";
import img6 from "../../../image/lessons/lesson8/step4-6.png";
import img7 from "../../../image/lessons/lesson8/step4-7.png";
import img8 from "../../../image/lessons/lesson8/step4-8.png";
import img9 from "../../../image/lessons/lesson8/step4-9.png";
import img10 from "../../../image/lessons/lesson8/step4-10.png";
import img11 from "../../../image/lessons/lesson8/step4-11.png";
import img12 from "../../../image/lessons/lesson8/step4-12.png";
import img13 from "../../../image/lessons/lesson8/step4-13.png";
import img14 from "../../../image/lessons/lesson8/step4-14.png";


export default class step4 extends React.Component {
    state = {
        activeFlex: 0,
        flexs: [0]
    };

    changeFlex = activeFlex => {
        const {flexs} = this.state
        if (flexs.indexOf(activeFlex) === -1) {
            flexs.push(activeFlex)
        }
        this.setState({activeFlex, flexs})
        /*if (flexs.length < 22) {
            this.props.showNextBtn();
        }*/
    }

    _renderImg = () => {
        const {activeFlex} = this.state
        switch(activeFlex) {
            case 0:
                return img0;
            case 1:
                return img1;
            case 2:
                return img2;
            case 3:
                return img3;
            case 4:
                return img4;
            case 5:
                return img5;
            case 6:
                return img6;
            case 7:
                return img7;
            case 8:
                return img8;
            case 9:
                return img9;
            case 10:
                return img10;
            case 11:
                return img11;
            case 12:
                return img12;
            case 13:
                return img13;
            case 14:
                return img14;
            default:
                return img0;

        }
    }

    render() {
        const {activeFlex} = this.state;
        return (
            <div className="step4">
                <TextBlock
                    text={`Название региональной группы в России:`}
                />
                <InfoBlock
                    text={`Ознакомьтесь регионами, нажав на активные элементы.`}
                />
                <div className="img">
                    <img src={this._renderImg()} alt="" className="imgCenter"/>
                </div>
                <div className="city">
                    <div className="city_column">
                        <div className={'city_el ' + (activeFlex !== 0 && ' inactive ') + (activeFlex === 1 && 'active')} onClick={() => {this.changeFlex(1)}}>А: Дальневосточный</div>
                        <div className={'city_el ' + (activeFlex !== 0 && ' inactive ') + (activeFlex === 2 && 'active')} onClick={() => {this.changeFlex(2)}}>B: Восточно-Сибирский</div>
                        <div className={'city_el ' + (activeFlex !== 0 && ' inactive ') + (activeFlex === 3 && 'active')} onClick={() => {this.changeFlex(3)}}>С: Центрально-Сибирский</div>
                        <div className={'city_el ' + (activeFlex !== 0 && ' inactive ') + (activeFlex === 4 && 'active')} onClick={() => {this.changeFlex(4)}}>D: Западно-Сибирский</div>
                        <div className={'city_el ' + (activeFlex !== 0 && ' inactive ') + (activeFlex === 5 && 'active')} onClick={() => {this.changeFlex(5)}}>Е: Уральский</div>
                        <div className={'city_el ' + (activeFlex !== 0 && ' inactive ') + (activeFlex === 6 && 'active')} onClick={() => {this.changeFlex(6)}}>F: Волжский</div>
                        <div className={'city_el ' + (activeFlex !== 0 && ' inactive ') + (activeFlex === 7 && 'active')} onClick={() => {this.changeFlex(7)}}>G: Волго-Вятский</div>
                    </div>
                    <div className="city_column">
                        <div className={'city_el ' + (activeFlex !== 0 && ' inactive ') + (activeFlex === 8 && 'active')} onClick={() => {this.changeFlex(8)}}>I: Центрально-Черноземный</div>
                        <div className={'city_el ' + (activeFlex !== 0 && ' inactive ') + (activeFlex === 9 && 'active')} onClick={() => {this.changeFlex(9)}}>J: Северо-Кавказский</div>
                        <div className={'city_el ' + (activeFlex !== 0 && ' inactive ') + (activeFlex === 10 && 'active')} onClick={() => {this.changeFlex(10)}}>K: Центральный</div>
                        <div className={'city_el ' + (activeFlex !== 0 && ' inactive ') + (activeFlex === 11 && 'active')} onClick={() => {this.changeFlex(11)}}>L: Московский</div>
                        <div className={'city_el ' + (activeFlex !== 0 && ' inactive ') + (activeFlex === 12 && 'active')} onClick={() => {this.changeFlex(12)}}>M: Санкт-Петербургский</div>
                        <div className={'city_el ' + (activeFlex !== 0 && ' inactive ') + (activeFlex === 13 && 'active')} onClick={() => {this.changeFlex(13)}}>N: Северный</div>
                        <div className={'city_el ' + (activeFlex !== 0 && ' inactive ') + (activeFlex === 14 && 'active')} onClick={() => {this.changeFlex(14)}}>O: Северо-Западный</div>
                    </div>
                </div>
            </div>
        )
    }
}