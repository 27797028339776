import React from 'react'
import '../style.sass'

import logo from '../../../image/logo-start.png'
import iconOk from '../../../image/icon-ok.png'
import Inputs from '../../../Components/Inputs'
// import Partners from '../../../Components/LeftSideBar/Partners'
import SiteFooter from '../../../Components/SiteFooter'
import Api from '../../../Service/Api'
import {Link, withRouter} from 'react-router-dom';
import Pedigree from "../../../image/ped-start.png";
import PerfectFit from "../../../image/perf-start.png";

class Forgot extends React.Component {

    state = {
        email: '',
        emailError: false,
        emailErrorText: false,
        login: '',
        loginError: false,
        password: '',
        passwordError: false,
        passwordErrorText: false,
        emailSend: false,
    }

    Api = new Api()

    componentDidMount() {
    }

    updateState = (type, value, index) => {
        // eslint-disable-next-line
        switch (type) {
            case 'email' : {
                this.setState({
                    email: value,
                    emailError: false,
                    emailErrorText: false
                });
                break;
            }
            case 'login' : {
                this.setState({
                    login: value,
                    loginError: false
                })
                break;
            }
            case 'password' : {
                this.setState({
                    password: value,
                    passwordError: false,
                    passwordErrorText: false
                })
                break;
            }
        }
    }

    sendDate = () => {
        const {
            email,
        } = this.state
        if (!this.valid_email(email)) {
            this.setState({emailError: true})
        } else {
            this.Api.requestPasswordReset(email)
                .then((res) => {
                    if (res.status === 200) {
                        this.setState({emailSend: true})
                    } else {
                        Object.keys(res.error).map(key => {
                            // eslint-disable-next-line
                            this.state[key + 'Error'] = true;
                            // eslint-disable-next-line
                            this.state[key + 'ErrorText'] = res.error[key];
                            this.setState({errors: new Date()})
                            // if (key === 'password') {
                            //     this.setState({
                            //         passwordError: true,
                            //         passwordErrorText: res.error[key],
                            //     })
                            // }
                            return false
                        })
                    }
                })
        }

    }

    valid_email = (el) => {
        let reg = /^[a-z-._0-9]+@[a-z-_0-9]+\.[a-z0-9]{2,10}$/i;
        return reg.test(el)
    }

    render() {
        const {
            email,
            emailError,
            emailErrorText,
            emailSend
        } = this.state
        return (
            <div className="Entrance">
                <div className="wrp">
                        <form action="" className="form">
                            <div className="logo">
                                <img className="logo_subbrend" src={Pedigree} alt=""/>
                                <img className="logo_rkf" src={logo} alt=""/>
                                <img className="logo_subbrend" src={PerfectFit} alt=""/>
                            </div>
                            {!emailSend ?
                                <div>
                                    <div className="head">
                                        Для восстановления пароля, введите email на который, был&nbsp;зарегистрирован пользователь портала:
                                    </div>
                                    <Inputs
                                        name={'email'}
                                        placeholder={'E-mail'}
                                        onChange={this.updateState}
                                        type={'email'}
                                        className={emailError && 'error'}
                                        errorText={emailErrorText}
                                        required={'required'}
                                    />
                                    <div className="buttons">
                                        <button
                                            type='button'
                                            className="btn btn_white"
                                            disabled={email.length ? '' : 'disabled'}
                                            onClick={() => {
                                                this.sendDate()
                                            }}
                                        >
                                            Отправить запрос
                                        </button>
                                    </div>
                                </div>
                                :
                                <div className="email_send">
                                    <div className="email_send_icon">
                                        <img src={iconOk} alt=""/>

                                    </div>
                                    Вам успешно отправлено письмо. Проверьте пожалуйста почту.
                                    <p><Link to={'/StartPage'}>Перейти на главную</Link></p>
                                </div>
                            }
                            {/*<Partners brStyle={{display: 'none'}}/>*/}
                        </form>
                </div>
                <SiteFooter style={{backgroundColor: '#00559C'}}/>
            </div>
        )
    }

}

export default withRouter(Forgot);
