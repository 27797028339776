import React from 'react'
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";

export default class step8 extends React.Component {
    render() {
        return (
            <div className="step8">
                <InfoBlock text={`Ознакомьтесь с содержанием видео ролика.`}/>
                <VideoBlock
                    src={'https://www.youtube.com/embed/U1hscbRl-tU'}
                    title={'https://www.youtube.com/watch?v=U1hscbRl-tU'}
                />
            </div>
        )
    }
}
