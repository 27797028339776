import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson4/step14-1.png'
import img2 from '../../../image/lessons/lesson4/step14-2.png'
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";


export default class step6 extends React.Component {
    state = {
        pointActive: 0,
        points: [],
    };

    changePoint = pointActive => {
        this.setState({pointActive}, () => {
            this.checkPoint(pointActive)
        })
    }

    checkPoint = (point) => {
        const {points} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        console.log(points)
        if (points.length === 5) {
            this.props.showNextBtn()
        }
    }

    popapWrp = {
        1: {
            head: 'Для чего выводились?',
            text: `Все таксы – норные собаки, охотники. Даже самые мелкие таксы, сегодня чаще используемые в качестве декоративных собак, могут охотиться на кролика и сохраняют присущий породе дух и рабочие качества.`
        },
        2: {
            head: 'Характер',
                text: `Таксы – очень умные, но вместе с тем своенравные собаки, не чуждые здорового эгоцентризма, обаятельные и общительные. Такса – прирожденный фигляр, скрывающий под маской клоуна настоящее благородство и истинное мужество.
                        
                        Это сильное и духовно здоровое существо, способное лишь на подлинные чувства. Таксы могут проявлять сварливость по отношению к другим собакам. Иногда встречаются мнения, что таксы – собаки непослушные и плохо поддаются дрессировке.
                        
                        На самом деле, такса – собака с большим чувством собственного достоинства и настоящим бойцовским духом, она не терпит грубости и насилия.
                        
                        Сопротивляющаяся чему-то такса не сдастся никогда, поэтому воспитывать собаку нужно терпеливо, мягко, но последовательно. Тогда вы вряд ли найдете более приятного и преданного спутника.`
        },
        3: {
            head: 'Особенности внешности',
            text: `Шерсть – у длинношерстных и жесткошерстных разновидностей требует соответствующего ухода, размеры – от карликового до маленького (максимальный допустимый вес стандартной таксы – 9 кг.). Крепкие, неприхотливые собаки, как правило, долгожители.`
        },
        4: {
            head: 'Типичные представители',
            text: `В этой группе всего одна порода, три ростовых типа и три типа по шерсти (гладкая, жесткая и длинная) такс, в совокупности дающие девять разновидностей такс.`
        },
    }


    render() {
        const {pointActive, points} = this.state;
        return (
            <div className="step12">
                <TextBlock
                    text={`<span class="text_subheader">Таксы (секция 1)</span>`}
                />
                <InfoBlock
                    text={`Ознакомьтесь с каждой группой, нажав на ключевые элементы.`}
                />
                <div className="flex">
                    <img src={img1} alt="" className='mainImg'/>
                    <div className="flex_el"><span className={points.indexOf(1) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(1)}}>Для чего выводились?</span></div>
                    <div className="flex_el"><span className={points.indexOf(2) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(2)}}>Характер</span></div>
                    <div className="flex_el"><span className={points.indexOf(3) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(3)}}>Особенности внешности</span></div>
                    <div className="flex_el"><span className={points.indexOf(4) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(4)}}>Типичные представители</span></div>
                </div>

                {pointActive !== 0 &&
                <PopupInStep
                    closePopup={() => this.changePoint(0)}
                    popupImg={img2}
                    popupHead={this.popapWrp[pointActive].head}
                    popupText={this.popapWrp[pointActive].text}
                    changePoint={() => this.changePoint(pointActive < 4 ? pointActive + 1 : 0)}
                />
                }
            </div>
        )
    }
}
