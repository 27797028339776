import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from '../../../image/lessons/lesson41/step17-1.png';


export default class step17 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        points: [0],
        showPopup: false,
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    clickPoint = pointActive => {
        const {points} = this.state
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        this.setState({pointActive, points})


        if (points.length === 4) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    infoData = {
        1: {
            name: `Белки`,
            list: [
                `1/4 всего ежедневно потребляемого организмом белка утилизируется кожей и <b>используется для образования новых волос и эпидермиса</b>`,
                `Белок также <b>секретируется кожными железами</b> и помогает <b>ремоделировать дерму</b>`,
            ],
        },
        2: {
            img: `Липиды`,
            list: [
                `Необходимы для <b>образования новых клеток</b> и обеспечения <b>секреторных процессов кожи</b>`,
            ],
        },
        3: {
            img: `Витамины и минералы`,
            list: [
                `Необходимы в качестве кофакторов для <b>поддержания и улучшения обмена веществ</b>`,
            ],
        },
    }

    render() {
        const {pointActive, windowWidth, points} = this.state
        return (
            <div className="step17">
                <InfoBlock
                    text={`Нажмите на активные элементы, чтобы узнать больше о пищеварении собак`}
                />
                <TextBlock
                    text={`Рационы PEDIGREE® cодержат высокоусвояемые ингредиенты:`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div
                            className={'flex_el_name ' + (pointActive === 1 && windowWidth > 767 && 'active ') + (points.indexOf(1) >= 0 && windowWidth <= 767 && ' active')}
                            onClick={() => {this.clickPoint(1)}}
                        >
                            Белки
                        </div>
                        <div className={'flex_el_block ' + (pointActive === 1 && windowWidth > 767 && 'active ') + (points.indexOf(1) >= 0 && windowWidth <= 767 && ' active')}>
                            <ul>
                                {this.infoData[1].list.map((item, index) => {
                                    return (
                                        <li
                                            key={index}
                                            dangerouslySetInnerHTML={{ __html: item }}
                                        />
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div
                            className={'flex_el_name ' + (pointActive === 2 && windowWidth > 767 && 'active ') + (points.indexOf(2) >= 0 && windowWidth <= 767 && ' active')}
                            onClick={() => {this.clickPoint(2)}}
                        >
                            Липиды
                        </div>
                        <div className={'flex_el_block ' + (pointActive === 2 && windowWidth > 767 && 'active ') + (points.indexOf(2) >= 0 && windowWidth <= 767 && ' active')}>
                            <ul>
                                {this.infoData[2].list.map((item, index) => {
                                    return (
                                        <li
                                            key={index}
                                            dangerouslySetInnerHTML={{ __html: item }}
                                        />
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div
                            className={'flex_el_name ' + (pointActive === 3 && windowWidth > 767 && 'active ') + (points.indexOf(3) >= 0 && windowWidth <= 767 && ' active')}
                            onClick={() => {this.clickPoint(3)}}
                        >
                            Витамины и минералы
                        </div>
                        <div className={'flex_el_block ' + (pointActive === 3 && windowWidth > 767 && 'active ') + (points.indexOf(3) >= 0 && windowWidth <= 767 && ' active')}>
                            <ul>
                                {this.infoData[3].list.map((item, index) => {
                                    return (
                                        <li
                                            key={index}
                                            dangerouslySetInnerHTML={{ __html: item }}
                                        />
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={'substances ' + (pointActive > 0 && windowWidth <= 767 && 'hidden')}>
                    <img src={img1} alt="" className="substances_img"/>
                    <ul>
                        <li>Белки</li>
                        <li>Полиненасыщенные жирные кислоты</li>
                        <li>Цинк</li>
                        <li>Витамин А</li>
                        <li>Витамины группы В, в том числе биотин</li>
                    </ul>
                </div>
            </div>
        )
    }
}
