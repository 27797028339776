import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import SwipeBlock from '../../../Components/LessonAndTestComponents/SwipeBlock';
import Slider from "react-slick";
import img1 from '../../../image/lessons/lesson1/step3-1.png';
import img2 from '../../../image/lessons/lesson1/step3-2.png';
import img3 from '../../../image/lessons/lesson1/step3-3.png';

export default class step3 extends React.Component {
    state = {
    };


    changeSlide = (next) => {
        if(next === 2){
            this.props.showNextBtn();
        }
    }

    slider = {
        1: {
            head: `Шаг 1 из 3`,
            text: `Выбор в пользу <span class="text_blue"><b>беспородной собаки</b></span> – не единственный выход из положения. Необходимо учитывать, что затраты на приобретение щенка составляют лишь незначительную часть от "стоимости владения" собакой. В дальнейшем содержание и породной, и беспородной собаки обходится одинаково: кормление, ветеринарное обслуживание, занятия в школе дрессировки, амуниция и средства для ухода за собакой.`,
            text2: `Конечно, вы будете свободны от затрат на кинологические мероприятия, но это и с породистой собакой — ваш выбор, а не непременное требование.`,
            img: img1
        },
        2: {
            head: `Шаг 2 из 3`,
            text: `Сейчас есть <span class="text_blue"><b>«команды помощи породам»</b></span> или приюты для собак, которые ищут владельцев для собак тех или иных пород или беспородным собакам, в силу различных обстоятельств потерявших дом и хозяев. Можете обратиться к ним, возможно, ваша мечта уже ждет вас, осталось только найти друг друга.`,
            img: img2
        },
        3: {
            head: `Шаг 3 из 3`,
            text: `Как правило, чем крупнее собака, тем больше расходы на ее содержание. Сейчас собака с проблемами поведения может потребовать ощутимых расходов на услуги специалиста (если вы не знаете, как решить проблему самостоятельно), а животное с проблемами здоровья — на ветеринарное обслуживание и лекарства. `,
            img: img3
        },
    }

    render() {

        let settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: false,
            beforeChange: (current, next) => {
                this.changeSlide(next)
            },
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        dots: true,
                        arrows: false,
                        adaptiveHeight: true,
                    }
                },
            ]
        };
        return (
            <div className="step3">
                <TextBlock
                    text={`Сложная, но, увы, распространённая ситуация – человек очень хочет собаку, но его материальное положение вынуждает руководствоваться при выборе экономическими соображениями. Здесь нет какого-то готового рецепта (кроме как отложить приобретение животного), но при принятии решения нужно учитывать следующее:`}
                />
                <InfoBlock
                    text={
                        `Ознакомьтесь с каждым тезисом, нажав на ключевые элементы.`
                    }
                />
                <SwipeBlock
                    text={
                        `Используйте свайп для перемещения по шагам.`
                    }
                />

                <div className={"slider slider_noArrow show"}>
                    <Slider {...settings}>
                        {Object.values(this.slider).map(item => {
                            return (
                                <div
                                key={item.img}
                                    className="slider_el"

                                >
                                    <div className="imgMobile">
                                        <img src={item.img} alt=""/>
                                    </div>
                                    <div className="head">
                                        {item.head}
                                    </div>
                                    <div className="slider_flex">
                                        <TextBlock text={item.text}/>
                                        <img src={item.img} alt=""/>
                                    </div>
                                    {item.text2 &&
                                        <TextBlock text={item.text2}/>
                                    }
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
        )
    }
}
