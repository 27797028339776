import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson4/step7-1-1.png'
import img2 from '../../../image/lessons/lesson4/step7-1-2.png'
import img3 from '../../../image/lessons/lesson4/step7-2.png'
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";


export default class step6 extends React.Component {
    state = {
        pointActive: 0,
        points: [],
    };

    changePoint = pointActive => {
        this.setState({pointActive}, () => {
            this.checkPoint(pointActive)
        })
    }

    checkPoint = (point) => {
        const {points} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        console.log(points)
        if (points.length === 5) {
            this.props.showNextBtn()
        }
    }

    popapWrp = {
        1: {
            head: 'Для чего выводились?',
            text: `Особенности этих собак очень близки к тому, что было сказано об овчарках. Все породы подгруппы (не исключая «малышей») являются служебными, рабочими или, во всяком случае, пригодными для спортивной и прикладной дрессировки. 
                    
                    Очень популярны так же три породы шнауцеров (большой, средний и малый) это прекрасные рабочие и спортивные собаки. `
        },
        2: {
            head: 'Характер',
            text: `По происхождению они, в основном, скорее сторожа, многие из них считаются «собакой одного хозяина», обладают сильным характером.
                    
                    Требуют тщательной социализации, квалифицированного воспитания. Владелец должен настраиваться на длительные прогулки и занятия  с собакой.`
        },
        3: {
            head: 'Особенности внешности',
            text: `С шерстью дела обстоят следующим образом: шнауцеры – жесткошерстные собаки, их шерстный покров требует регулярного ухода, включающего стрижку и тримминг.
                    
                    Пинчеры имеют короткую (до так называемой «гладкой») шерсть, в связи с чем круглый год на улице могут жить только в теплом климате.
                    
                    В наших условиях уличное содержание возможно при наличии подогрева помещения, где живет собака зимой, или тщательно утепленной будки с мощной теплоизоляцией.
                    
                    С другой стороны, благодаря короткой шерсти эти собаки очень популярны среди горожан. Размеры – от малых  до крупных.`
        },
        4: {
            head: 'Типичные представители',
            text: `Самая молодая и, пожалуй, наиболее известная порода из их числа – доберман, долгое время считавшийся лучшей полицейской собакой в мире.`
        },
    }


    render() {
        const {pointActive, points} = this.state;
        return (
            <div className="step7">
                <TextBlock
                    text={`<span class="text_subheader">Пинчеры и шнауцеры (секция 1)</span>`}
                />
                <InfoBlock
                    text={`Ознакомьтесь с каждой группой, нажав на ключевые элементы.`}
                />
                <div className="flex">
                    <div className="flexImg">
                        <img src={img1} alt="" className=''/>
                        <img src={img2} alt="" className=''/>
                    </div>
                    <div className="flex_el"><span className={points.indexOf(1) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(1)}}>Для чего выводились?</span></div>
                    <div className="flex_el"><span className={points.indexOf(2) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(2)}}>Характер</span></div>
                    <div className="flex_el"><span className={points.indexOf(3) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(3)}}>Особенности внешности</span></div>
                    <div className="flex_el"><span className={points.indexOf(4) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(4)}}>Типичные представители</span></div>
                </div>

                {pointActive !== 0 &&
                <PopupInStep
                    closePopup={() => this.changePoint(0)}
                    popupImg={img3}
                    popupHead={this.popapWrp[pointActive].head}
                    popupText={this.popapWrp[pointActive].text}
                    changePoint={() => this.changePoint(pointActive < 4 ? pointActive + 1 : 0)}
                />
                }
            </div>
        )
    }
}
