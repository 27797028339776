import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson8/step3-1.png";
import img2 from "../../../image/lessons/lesson8/step3-2.png";
import img3 from "../../../image/lessons/lesson8/step3-3.png";
import img4 from "../../../image/lessons/lesson8/step3-4.png";
import img5 from "../../../image/lessons/lesson8/step3-5.png";
import img1_icon from "../../../image/lessons/lesson8/step3-1i.png";
import img2_icon from "../../../image/lessons/lesson8/step3-2i.png";
import img3_icon from "../../../image/lessons/lesson8/step3-3i.png";
import img4_icon from "../../../image/lessons/lesson8/step3-4i.png";


export default class step3 extends React.Component {
    state = {
        flexActive: 0,
        flex: [],
    };

    changeFlex = flexActive => {
        const {flex} = this.state
        this.setState({flexActive}, () => {})
        if (flex.indexOf(flexActive) === -1) {
            flex.push(flexActive)
        }
        this.setState({flex})
        if (flex.length === 3) {
            this.props.showNextBtn()
        }
    }


    popapWrp = {
        1: {
            img: img1_icon,
            head: 'Выставки по рангу'
        },
        2: {
            img: img2_icon,
            head: 'Выставки'
        },
        3: {
            img: img3_icon,
            head: 'Крупнейшие выставки России'
        },
        4: {
            img: img4_icon,
            head: 'Титул интерчемпион'
        },
    }


    render() {
        const {flexActive} = this.state;
        return (
            <div className="step3">
                <InfoBlock text={`Ознакомьтесь с существующими вариантами, нажав на активные элементы.`}
                />
                <div className="step_content">
                    <div className="flex">
                        <div className={'flex_el ' + (flexActive === 1 && 'active')} onClick={() => {this.changeFlex(1)}}>
                            <img src={img1} alt=""/>
                        </div>
                        {/*<div className={'flex_el ' + (flexActive === 2 && 'active')}>*/}
                        {/*    <img src={img2} alt=""/>*/}
                        {/*</div>*/}
                        <div className={'flex_el ' + (flexActive === 3 && 'active')} onClick={() => {this.changeFlex(3)}}>
                            <img src={img3} alt=""/>
                        </div>
                        {/*<div className={'flex_el ' + (flexActive === 4 && 'active')}>*/}
                        {/*    <img src={img4} alt=""/>*/}
                        {/*</div>*/}

                    </div>
                </div>

                {flexActive !== 0 &&
                    <div className={'infoPopap'}>
                    <div className="infoPopap_wrp">
                        <div className="popap_close" onClick={() => {
                            this.changeFlex(0)
                        }}/>
                        <div className="image">
                            <div className="imageBg">
                                <img src={this.popapWrp[flexActive].img} alt=""/>
                            </div>
                        </div>
                        <div className="scroll">
                            <div className="head"
                                 dangerouslySetInnerHTML={{__html: this.popapWrp[flexActive].head}}/>

                            {flexActive === 1 &&
                                <div>
                                    <TextBlock
                                        text={`ВЫСТАВКИ В СИСТЕМЕ РКФ БЫВАЮТ:
                                        - Интернациональные всех пород (ранг CACIB FCI);
                                        - Национальные всех пород (ранг САС);
                                        - Национальные отдельных групп пород (ранг САС);
                                        - Монопородные (ранг КЧК/КЧП, ПК/ПП) 
                                        
                                        `}
                                    />
                                </div>
                            }
                            {flexActive === 2 &&
                                <div>
                                    <TextBlock
                                        text={`В России ежегодно проводятся выставки`}
                                    />
                                    {/*<img src={img5} alt="" className="imgCenter"/>*/}
                                </div>
                            }
                            {flexActive === 3 &&
                                <div>
                                    <TextBlock
                                        text={
                                            `<b>«Евразия»</b> (<a href="http://rkf.org.ru/vystavochnaja-dejatelnost/eurasia/" target="_blank" rel="noopener noreferrer">http://rkf.org.ru/vystavochnaja-dejatelnost/eurasia/</a>).
                                            <b>«Россия»</b> (<a href="http://rkf.org.ru/vystavochnaja-dejatelnost/russia/" target="_blank" rel="noopener noreferrer">http://rkf.org.ru/vystavochnaja-dejatelnost/russia/</a>).
<!--                                            <b>«Мемориал А.П. Мазовера»</b> (<a href="http://rkf.org.ru/vystavochnaja-dejatelnost/mazover/" target="_blank" rel="noopener noreferrer">http://rkf.org.ru/vystavochnaja-dejatelnost/mazover/</a>).-->
<!--                                            <b>«Золотой Ошейник»</b> (<a href="http://rkf.com.ru/golden_collar/" target="_blank" rel="noopener noreferrer">http://rkf.com.ru/golden_collar/</a>).-->
                                            
                                            Это самые главные, самые грандиозные выставки России, настоящий смотр достижений заводчиков.
                                            
                                            Они собирают тысячи участников, и более того — на каждой из них можно познакомиться с новинками, приобрести их, послушать бесплатные лекции, посмотреть показательные выступления кинологов и спортивные мероприятия, познакомиться поближе с некоторыми породами собак (прежде всего отечественными, так как 
                                            в рамках этих выставок специализированные клубы имеют постоянно действующие представительства).`
                                        }
                                    />
                                </div>
                            }
                            {flexActive === 4 &&
                                <div>
                                    {/*<TextBlock*/}
                                    {/*    text={*/}
                                    {/*        `Россия — единственная страна в мире, в которой можно получить титул <b>«интерчемпион»</b> не выезжая за ее пределы!*/}

                                    {/*        Собака должна получить 4 сертификата CACIB на международных выставках у трех разных судей в трех разных странах или в <b>четырех региональных группах в России</b>.*/}
                                    {/*        */}
                                    {/*        Между получением первого и последнего сертификатов должен пройти 1 год и 1 день (не менее 366 дней); три судьи из 4-х должны быть иностранными судьями.*/}
                                    {/*        */}
                                    {/*        Для пород, которым для получения титула требуется рабочий сертификат, действуют другие правила: 2 титула CACIB, полученных у 2-х разных судей, один их которых иностранец, в 2-х разных странах <b>или региональных группах  в России</b>, с интервалом в не менее чем 366 дней + международный сертификат по рабочим качествам.`*/}
                                    {/*    }*/}
                                    {/*/>*/}
                                </div>
                            }

                            <button className="btn btn_blue" onClick={() => {
                                this.changeFlex(flexActive === 1 ? flexActive + 2 : 0 )
                            }}>Продолжить</button>
                        </div>
                    </div>
                </div>
                }

            </div>
        )
    }
}