import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson39/step4-1.png';
import img1xs from '../../../image/lessons/lesson39/step4-1xs.png';


export default class step4 extends React.Component {

    render() {
        return (
            <div className="step4">

                <TextBlock
                    text={`Кожа и шерсть выполняют в организме кошек и собак важные функции защиты и сохранения температуры тела, поэтому ее необходимо поддерживать в здоровом состоянии.`}
                />
                <div className="head">
                    Признаки здоровой кожи и шерсти:
                </div>
                <div className="flex">
                    <div className="flex_el">
                        упругость кожи
                    </div>
                    <div className="flex_el">
                        блеск шерсти
                    </div>
                    <div className="flex_el">
                        насыщенный цвет шерсти
                    </div>
                    <div className="flex_el">
                        отсутствие перхоти
                    </div>
                </div>
                <picture className="img-box">
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt="" className="img"/>
                </picture>
            </div>
        )
    }
}
