import React from 'react'

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step1">
                <div className="head">план урока</div>

                <div className="text">
                    Вашему вниманию предлагается цикл уроков, предназначенный для широкого круга людей, интересующихся собаководством – от планирующих завести собаку – до тех, у кого она уже есть.
                    <br/>
                    <br/>
                    Быть владельцем собаки – значит содержать ее и ухаживать за ней. <b>И это нужно делать ответственно и правильно.</b> Это такая «работа», которая не подразумевает выходных и от того, как вы это организуете, будет зависеть жизнь и безопасность вашей собаки и комфорт
                    и безопасность окружающих.
                    <br/>
                    <br/>
                    Вопросы содержания и ухода должны быть продуманы и взвешены до ее приобретения,
                    так как помните — «нет ничего долговечнее, чем временные решения».

                </div>

                <div className="lessonSteps">
                    <div className="lessonSteps_el">
                        Как и где содержать собаку?
                    </div>
                    <div className="lessonSteps_el">
                        Как выгуливать собаку?
                    </div>
                </div>

            </div>
        )
    }
}
