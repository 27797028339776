import React from 'react';
import './style.sass'
import photo_user from '../../image/LeftSideBar/photo_user.png'
import Api from '../../Service/Api'
import {Link} from "react-router-dom";

export default class Profile extends React.Component{
    Api = new Api();

    _renderStatus = () =>{
        const {user} = this.props;
        let rank = ''
        if(user !== undefined && user.rank){
            switch (user.rank) {
                case 0:
                    rank = 'НОВИЧОК';
                    break;
                case 1:
                    rank = 'СПЕЦИАЛИСТ';
                    break;
                case 2:
                    rank = 'ПРОФЕССИОНАЛ';
                    break;
                case 3:
                    rank = 'МАСТЕР';
                    break;
                case 4:
                    rank = 'ЗНАТОК';
                    break;
                case 5:
                    rank = 'МУДРЕЦ';
                    break;
                case 6:
                    rank = 'ГУРУ';
                    break;
                default :
                    rank = 'НОВИЧОК';
                    break
            }
        }
        return rank
    }

    render() {
        const {user} = this.props;
        return (
            <div className="profile">
                <div className="profile_name">
                    {user && user.email}
                </div>
                <div className="profile_photo">
                    <Link className="edit" to={'/profileCard'} onClick={this.props.closePopup}>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <g clipPath="url(#clip0)">
                                <path d="M0 13.9999L4.83363 13.0439L0.951699 9.14453L0 13.9999Z" fill="#005EAD"/>
                                <path
                                    d="M1.90344 8.45301L5.61006 12.1385L11.5457 6.12596L7.88913 2.44043L1.90344 8.45301Z"
                                    fill="#005EAD"/>
                                <path
                                    d="M9.45442 1.44654C9.42937 1.81132 9.52955 2.12579 9.78 2.3522L11.6333 4.21384C11.8963 4.46541 12.2093 4.57862 12.585 4.56604C12.9482 4.55346 13.2737 4.41509 13.5617 4.13836C13.8372 3.87421 13.9875 3.54717 14 3.15723C14.0126 2.7673 13.8873 2.45283 13.6244 2.20126L11.7711 0.352201C11.5331 0.113208 11.2451 0 10.907 0C10.5063 0 10.1682 0.150943 9.86765 0.440252C9.61721 0.72956 9.47946 1.0566 9.45442 1.44654Z"
                                    fill="#005EAD"/>
                            </g>
                            <defs>
                                <clipPath id="clip0">
                                    <rect width="14" height="14" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>

                    </Link>
                    <img src={(user !== undefined && user.user.photo) ? ('/uploads' + user.user.photo) : photo_user} alt="" className='profile_photo_img'/>
                </div>
                <div className="profile_status">
                    {this._renderStatus()}
                </div>
                <div className="profile_points">
                    {(user !== undefined && user.balls) ? user.balls + ' баллов' : ''}
                </div>
            </div>
        )
    }
}
