import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import img1 from '../../../image/lessons/lesson2/step4-1.png';
import img2 from '../../../image/lessons/lesson2/step4-2.png';
import img3 from '../../../image/lessons/lesson2/step4-3.png';


export default class step4 extends React.Component {
    state = {
        flexActive: 0,
        flexs: [],
        flexHidden: false,
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs, flexHidden: true})
        if (flexs.length === 3) {
            this.props.showNextBtn()
        }
    }

    closeImgDiv = () => {this.setState({flexHidden: false, flexActive: 0})}


    render() {
        const {flexActive, flexHidden} = this.state
        return (
            <div className="step4">
                <TextBlock
                    text={`Сейчас много противоречивых рекомендаций относительно допустимых нагрузок (и препятствий – барьеров, лестниц и т.п.), которые нужны щенку и сами породы очень разнообразны, но можно дать следующие:`}
                    hidden={flexHidden === true ? 'hidden' : ''}
                />
                <InfoBlock
                    text={`Ознакомьтесь с каждой характеристикой, нажав на ключевые элементы.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />

                <div className={'flex ' + (flexHidden === true && 'hidden')}>
                    <div className={'flex_el ' + (flexActive === 1 && 'active')} onClick={() => {
                        this.changeFlex(1)
                    }}>Младшая возрастная группа <p className={'flex_el_description'}>(До 4 месяцев)</p>
                    </div>
                    <div className={'flex_el ' + (flexActive === 2 && 'active')} onClick={() => {
                        this.changeFlex(2)
                    }}>Средняя возрастная группа <p className={'flex_el_description'}>(4-7 месяцев)</p>
                    </div>
                    <div className={'flex_el ' + (flexActive === 3 && 'active')} onClick={() => {
                        this.changeFlex(3)
                    }}>Старшая возрастная группа <p className={'flex_el_description'}>(С 12 месяцев)</p>
                    </div>
                </div>
                {flexActive !== 0 &&
                <div className={'imgDiv ' + (flexActive === 2 ? 'center' : flexActive === 3 ? 'right' : '')}>
                    <div
                        className="imgDiv_closed"
                        onClick={() => {this.closeImgDiv()}}
                    />
                    {flexActive === 1 &&
                    <div className="imgDiv_flex">
                        <TextBlock
                            text={`Гуляем, пока щенок весело бегает и исследует мир, проявляет активность или охотно играет. Преодолеваемые препятствия – высотой до локтя (желательно учить постепенно, в жизни это пригодится). Когда щенок показывает первые признаки усталости (он сам это покажет), идем домой.`}
                        />
                        <img src={img1} alt=""/>
                    </div>
                    }
                    {flexActive === 2 &&
                    <div className="imgDiv_flex">
                        <TextBlock
                            text={`Гуляем аналогично младшей группе, препятствия – до середины груди. Из специальных снарядов – низкий широкий бум и небольшая «щенячья» лестница (эти снаряды обычно есть на оборудованных выгулах).`}
                        />
                        <img src={img2} alt=""/>
                    </div>
                    }
                    {flexActive === 3 &&
                    <div className="imgDiv_flex">
                        <TextBlock
                            text={`Нагрузки как для взрослой собаки. При свободном выгуле собака, в общей сложности (за одну-две прогулки) и в зависимости от размера, «наматывает» от 15 до 30 и более (очень активные) километров в сутки. Это вполне нормальная для нее нагрузка. Спортивные снаряды как для взрослой собаки.`}
                        />
                        <img src={img3} alt=""/>
                    </div>
                    }
                </div>
                }
            </div>
        )
    }
}
