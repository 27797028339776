import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import img1 from '../../../image/lessons/lesson2/step7-1.png';

export default class step3 extends React.Component {
    state = {
        points: []
    };

    setPoints = point => {
        const {points} = this.state
        if(points.indexOf(point) === -1){
            points.push(point)
            this.setState({points})
        }

        if(points.length === 5){
            this.props.showNextBtn()
        }
    }




    render() {
        const {points} = this.state
        return (
            <div className="step7">
                <InfoBlock text={`Ознакомьтесь с советами, нажав на кнопки с цифрой.`}/>

                <div className="flex">
                    <div className="points">
                        <div className={'point ' + (points.indexOf(1) >= 0 && 'active')}>
                            <div className="text">
                                Прогулки со взрослой собакой в условиях современного города всегда представляют собой проблему, так как заставляют постоянно искать компромисс между необходимостью дать собаке необходимую физическую нагрузку и реалиями мегаполиса, между законодательными и этическими требованиями к выгулу животных.
                            </div>
                            <div className="number" onClick={()=>{this.setPoints(1)}}>1</div>
                        </div>
                        <div className={'point ' + (points.indexOf(2) >= 0 && 'active')}>
                            <div className="text">
                                Гулять со взрослой собакой нужно не два (как думают многие), а все-таки три раза в день. И хотя бы одна из прогулок должна быть достаточно продолжительной – не менее полутора-двух часов, поэтому взвесить свои возможности и наличие времени нужно до приобретения животного.
                            </div>
                            <div className="number" onClick={()=>{this.setPoints(2)}}>2</div>
                        </div>
                        <div className={'point ' + (points.indexOf(3) >= 0 && 'active')}>
                            <div className="text">
                                Важно подходить к выгулу собаки ответственно – за любой ущерб, который собака может принести другим людям, их имуществу или животным несет ответственность хозяин собаки.
                                Агрессивная собака в общественных местах должна быть в наморднике. Любая собака в общественных местах – на поводке.
                            </div>
                            <div className="number" onClick={()=>{this.setPoints(3)}}>3</div>
                        </div>
                        <div className={'point ' + (points.indexOf(4) >= 0 && 'active')}>
                            <div className="text">
                                Отпускать с поводка там, где это разрешено, можно собаку, поведение которой владелец может контролировать. Нельзя отпускать
                                с поводка непослушную собаку – этим вы подвергаете опасности прежде всего саму собаку. Она может убежать, потеряться, погибнуть.
                            </div>
                            <div className="number" onClick={()=>{this.setPoints(4)}}>4</div>
                        </div>
                        <div className={'point ' + (points.indexOf(5) >= 0 && 'active')}>
                            <div className="text">
                                Поэтому, чтобы гулять без поводка, сначала научите собаку послушанию (об этом поговорим в соответствующем разделе). В настоящее время Законодательство РФ требует
                                от владельца убирать за животным. Лучше пользоваться для этого крахмальными или бумажными, а не полиэтиленовыми пакетами.
                            </div>
                            <div className="number" onClick={()=>{this.setPoints(5)}}>5</div>
                        </div>
                    </div>
                    <img src={img1} alt="" className={'flex_img'}/>
                </div>
            </div>
        )
    }
}
