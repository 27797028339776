import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import img1 from '../../../image/lessons/lesson33/step11-1.png'


export default class step6 extends React.Component {
    state = {
    };

    render() {
        return (
            <div className="step11">
                <TextBlock
                    text={`После родов собакам необходимо наибольшее количество питательных веществ. Она должна есть достаточно пищи, чтобы обеспечить энергией как саму себя, так и своих быстро растущих щенков.`}
                />
                <img src={img1} alt=""/>
                <TextBlock
                    text={`В период лактации меняется режим кормления собаки. Рекомендуется давать ей еду <b>небольшими порциями, но часто,</b> либо предоставить <b>свободный доступ</b> к корму и воде. Это поможет ей получить то количество энергии, которое необходимо. Для кормящих собак также подходит по питательным свойствам <b>корм для щенков.</b>`}
                />
            </div>
        )
    }
}
