import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson7/step4-2.png";
import img2 from "../../../image/lessons/lesson7/step4-3.png";
import img3 from "../../../image/lessons/lesson7/step4-4.png";
import img4 from "../../../image/lessons/lesson7/step4-5.png";
import img5 from "../../../image/lessons/lesson7/step4-6.png";


export default class step4 extends React.Component {
    state = {
        activeFlex: 3,
        flexs: [3]
    };

    changeFlex = activeFlex => {
        const {flexs} = this.state
        if (flexs.indexOf(activeFlex) === -1) {
            flexs.push(activeFlex)
        }
        this.setState({activeFlex, flexs})
        if (flexs.length === 3) {
            this.props.showNextBtn();
        }
    }

    navList = [
        {
            "index": 1,
            "text": "3",
            "img": img3
        },
        {
            "index": 2,
            "text": "2",
            "img": img2
        },
        {
            "index": 3,
            "text": "1",
            "img": img1
        }
    ]

    contentActive = {
        3: {
            text:  `<span class="text_dark-blue"><b>Первый период социализации (от 2 до 8 недель)</b></span>

                Первое и одно из важнейших событий в жизни социального животного – это <span class="text_dark-blue"><b>импринтинг</b></span> (запечатление), в ходе которого запечатлевается образ своего вида и – это особенность собаки – <span class="text_dark-blue"><b>образ человека-партнера</b></span>.`
        },
        2: {
            text: `<span class="text_dark-blue"><b>Второй период социализации (8-20 недель)</b></span>

                Суть периода заключается в формировании индивидуальности. Щенок начинает выделять себя из мира прочих существ.

                Резко возрастают активность и самостоятельность. Щенок активно исследует окружающий мир и формирует свое отношение к событиям и явлениям.`
        },
        1: {
            text:  `<span class="text_dark-blue"><b>Третий период социализации (5-10 месяцев - вариативно у собак раннего и позднего созревания).</b></span>

                Связан с активно протекающим половым созреванием.
                
                В результате это резко осложняет для нее возможность легкой смены социальных партнеров. В этом периоде чужие собаки уже могут проявлять агрессию к молодой собаке.
                
                Суть третьего периода в том, что собака разделяет окружение по признаку «свои» и «чужие».`
        }
    }

    render() {
        const {activeFlex} = this.state;
        return (
            <div className="step4">
                <TextBlock
                    text={`У щенка в процессе роста и взросления происходит социализация <b>– формирование отношения к окружающему миру, людям и животным.</b> Этот важнейший процесс происходит в несколько этапов:`}
                />
                <InfoBlock
                    text={`Ознакомьтесь с распространёнными мифами, нажав на активные элементы.`}
                />
                <div className="step_content">
                    <div className="flex">
                        {this.navList.map(item => {
                            return (
                                <div
                                    key={item.index}
                                    className={'flex_el ' + (activeFlex === item.index && `active active--${activeFlex}`)}
                                    onClick={() => {
                                        this.changeFlex(item.index)
                                    }}
                                >
                                    <img src={item.img} alt=""/>
                                    {item.text}
                                </div>
                            )})
                        }
                        <img className="scale" src={img4} alt=""/>
                        <img className="scaleArrow" src={img5} alt=""/>
                    </div>
                    {activeFlex !== 0 &&
                        <div className={'textDiv ' + (activeFlex !== 1 ? `textDiv--${activeFlex}` : '')}>
                            {this.contentActive[activeFlex].text &&
                                <TextBlock
                                    text={this.contentActive[activeFlex].text}
                                />
                            }
                        </div>
                    }
                </div>

            </div>
        )
    }
}