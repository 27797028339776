import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson33/step21-1.png'


export default class step21 extends React.Component {


    render() {
        return (
            <div className="step21">
                <TextBlock
                    text={`Перед вами расположена рекомендуемая схема кормления щенков рационами PEDIGREE®. Изучите её для того, чтобы выбирать правильный уход за питомцем с учётом его возраста и размера.`}
                />
                <img className="img" src={img1} alt=""/>
            </div>
        )
    }
}
