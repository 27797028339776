import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson5/step39-1.png'
import img2 from '../../../image/lessons/lesson5/step39-2.png'
import img3 from '../../../image/lessons/lesson5/step39-3.png'
import img4 from '../../../image/lessons/lesson5/step39-4.png'


export default class step6 extends React.Component {
    state = {
        flexActive: 0,
        flex: [],
    };

    changeFlex = flexActive => {
        const {flex} = this.state
        this.setState({flexActive}, () => {})
        if (flex.indexOf(flexActive) === -1) {
            flex.push(flexActive)
        }
        this.setState({flex})
        if (flex.length === 5) {
            this.props.showNextBtn()
        }
    }


    render() {
        const {flexActive} = this.state;
        return (
            <div className="step39">
                <InfoBlock
                    text={`Ознакомьтесь с информацией о воде, нажав на активные элементы.`}
                />
                <div className="flex">
                    <div className={'flex_el ' + (flexActive === 1 ? 'active' : '')}
                          onClick={() => {this.changeFlex(1)}}>
                        ОПИ&shy;СА&shy;НИЕ
                    </div>
                    <div className={'flex_el ' + (flexActive === 2 ? 'active' : '')}
                          onClick={() => {this.changeFlex(2)}}>
                        ФУН&shy;К&shy;ЦИИ
                    </div>
                    <div className={'flex_el ' + (flexActive === 3 ? 'active' : '')}
                          onClick={() => {this.changeFlex(3)}}>
                        ИС&shy;ТОЧ&shy;НИ&shy;КИ
                    </div>
                    <div className={'flex_el ' + (flexActive === 4 ? 'active' : '')}
                          onClick={() => {this.changeFlex(4)}}>
                        ДЕ&shy;ФИ&shy;ЦИТ
                        И ИЗБЫТОК
                    </div>
                    <div className={'flex_el ' + (flexActive === 5 ? 'active' : '')}
                          onClick={() => {this.changeFlex(5)}}>
                        ПО&shy;ТРЕ&shy;БЛЕ&shy;НИЕ
                    </div>
                </div>

                {flexActive !== 0 &&
                    <div className={'info ' + (flexActive === 2 && " right_center ") + (flexActive === 3 && " center ") + (flexActive === 4 && " left_center ") + (flexActive === 5 && " left ")}>
                        {flexActive === 1 &&
                            <div>
                                <div className="info_head">
                                    Вода — самый жизненно необходимый элемент для организма.
                                </div>
                                <TextBlock text={`Вода является одним из основных элементов организма животного (75% при рождении и 60% в зрелом возрасте). Она является самым важным компонентом питания и играет ключевую роль во всех основных физиологических процессах.`}/>
                                <div className="flex">
                                    <TextBlock text={``}/>
                                    <img src={img1} alt="" className="flex_img"/>
                                </div>
                                <div className="infoBlock">
                                    У собак должен быть постоянный свободный доступ к питьевой воде.
                                </div>
                            </div>
                        }
                        {flexActive === 2 &&
                            <div>
                                <div className="info_head">Вода выполняет ряд жизненно важных функций в организме животных:</div>
                                <div className="flex">
                                    <ul>
                                        <li>образует среду для транспортировки компонентов питания и выведения отходов жизнедеятельности из организма;</li>
                                        <li>отвечает за регуляцию температуры тела;</li>
                                        <li>является смазкой суставных поверхностей, слизистой оболочки глаз и внутреннего уха.</li>
                                    </ul>
                                    <img src={img2} alt="" className="flex_img"/>
                                </div>
                            </div>
                        }
                        {flexActive === 3 &&
                            <div>
                                <div className="info_head">Существует три источника воды:</div>
                                <ul>
                                    <li>жидкости (например, вода в миске);</li>
                                    <li>пища (в сухих кормах содержится до 10% воды, а во влажных — около 80%);</li>
                                    <li>побочный продукт обмена веществ (производство воды внутри организма).</li>
                                </ul>
                            </div>
                        }
                        {flexActive === 4 &&
                            <div>
                                <TextBlock text={`Свободный доступ к чистой питьевой воде помогает избежать обезвоживания, признаками которого являются:`}/>
                                <div className="flex">
                                    <div className="left">
                                        <ul>
                                            <li>сухая и неэластичная кожа;</li>
                                            <li>учащенное сердцебиение;</li>
                                            <li>повышенная температура тела.</li>
                                        </ul>
                                        <TextBlock text={`Потеря организмом более 10% воды может привести к серьезным последствиям для здоровья.`}/>
                                    </div>
                                    <img src={img3} alt="" className="flex_img"/>
                                </div>
                                <div className="infoBlock">
                                    Повышенное потребление воды может быть признаком сахарного диабета или заболевания почек!
                                </div>
                            </div>
                        }
                        {flexActive === 5 &&
                            <div>
                                <TextBlock text={`Потребность животного в воде зависит от его веса:`}/>
                                <ul>
                                    <li>примерная суточная норма <b>собаки — 60 мл/кг.</b></li>
                                </ul>
                                <img src={img4} alt="" className={'imgCenter'}/>
                            </div>
                        }
                    </div>
                }



            </div>



        )
    }
}