import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson5/step5-1.png'


export default class step5 extends React.Component {
    state = {
        clicked: [],
        correct: ''
    };

    onClick = (el) => {
        const {clicked, correct} = this.state
        if(correct === '') {
            if (clicked.indexOf(el) === -1) {
                clicked.push(el)
            } else {
                clicked.splice(clicked.indexOf(el), 1)
            }
            this.setState({clicked});
        }
    }

    checkClick = () => {
        const {clicked, correct} = this.state
        if (clicked.length === 1 && clicked[0] === 1 && correct === '') {
            this.setState({correct: '1'})
        } else {
            this.setState({correct: '0'})
        }
        this.props.showNextBtn()
    }

    render() {
        const {clicked, correct} = this.state
        return (
            <div className="step5">
                <TextBlock
                    text={`Знаете ли вы, какая частота приемов пищи оптимальна для взрослых собак?`}
                />
                <InfoBlock
                    text={
                        `Выберите подходящий вариант и нажмите «Проверить».`
                    }
                />
                <div className="flex">
                    <div className={'flex_el ' + (clicked.indexOf(1) >= 0 && ' active ') + (correct !== '' && ' correct') } onClick={() => {
                        this.onClick(1)
                    }}>1-2 РАЗА В ДЕНЬ
                    </div>
                    <div className={'flex_el ' + (clicked.indexOf(2) >= 0 && ' active ') + (correct !== '' && ' inCorrect')} onClick={() => {
                        this.onClick(2)
                    }}>4-6 РАЗ В ДЕНЬ
                    </div>
                    <div className={'flex_el ' + (clicked.indexOf(3) >= 0 && ' active ') + (correct !== '' && ' inCorrect')} onClick={() => {
                        this.onClick(3)
                    }}>7-20 РАЗ В ДЕНЬ
                    </div>
                </div>
                <img src={img1} alt="" className="imgCenter"/>
                {correct !== '' ?
                    <div className={'info ' + (correct === '0' && 'error')}>
                        <div className="info_head">
                            {correct === '1' ? 'Вы правы!' : 'К сожалению, ваш ответ не верен.'}
                        </div>
                        <div className="infoText">
                            Взрослых собак надо кормить 1-2 раза в день, а вот щенков  кормят чаще: 4-6 раз в день
                        </div>
                    </div>
                    :
                    <button className="btn btn_blue" onClick={() => this.checkClick()}>
                        ПРОВЕРИТЬ
                    </button>
                }

            </div>
        )
    }
}
