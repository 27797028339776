import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson36/step17-1.png';
import img1xs from '../../../image/lessons/lesson36/step17-1xs.png';


export default class step17 extends React.Component {

    render() {
        return (
            <div className="step17">
                <TextBlock
                    text={`Ещё одним отличием кормов разных классов является <b>дневная норма кормления,</b> то есть количество корма, которое необходимо животному съесть в сутки, чтобы получить все питательные вещества.
                    
                    Как видим из пирамиды, дневная норма кормления у корма <b>эконом класса выше,</b> в то время как у корма <b>супер-премиум она ниже.</b>
                    
                    В таблице взят пример дневной нормы кормления собаки весом 25 кг сухими кормами раличных классов. Вы видите, что для того чтобы накормить собаку кормом Chappi®, ей надо 350 г корма, кормом Pedigree® — 300 г, и, наконец, корма Perfect Fit ей понадобится 280 г в день.`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt=""/>
                </picture>
                <div className="advice">
                    Таким образом, кормить животных <b>полезно и сбалансированно можно любым из классов кормов:</b> эконом, премиум или супер-премиум. <b>Важно соблюдать рекомендованную норму кормления!</b>
                </div>
            </div>
        )
    }
}
