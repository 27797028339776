import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import img1 from '../../../image/lessons/lesson5/step43-1.png'


export default class step6 extends React.Component {


    render() {
        return (
            <div className="step43">
                <TextBlock text={`Полноценный сбалансированный рацион:`}/>
                <ul>
                    <li>обеспечивает животных всеми необходимыми питательными веществами в правильных пропорциях;</li>
                    <li>удовлетворяет их энергетические потребности на конкретной стадии жизни.</li>
                </ul>
                <TextBlock
                    text={`Мясо в отдельности не является полноценным рационом.

                    Существуют различные организации, изучающие пищевые потребности кошек и собак и разрабатывающие рекомендации по их кормлению. <b>Сбалансированные готовые корма обеспечивают животное всеми необходимыми ему макро- и микронутриентами</b>, а также <b>учитывают особенности вкусового восприятия собак</b>.`}
                />
                <div className="flex">
                    <div className="left">
                        <TextBlock text={`<b>Сочетание</b> сухого и влажного корма — самый <b>оптимальный способ кормления</b>, который совмещает преимущества сухих и влажных кормов. Такой тип кормления позволяет:`}/>
                        <ul>
                            <li>контролировать вес питомца;</li>
                            <li>избегать перекармливания;</li>
                            <li>снижает риск мочекаменной болезни.</li>
                        </ul>
                    </div>
                    <img src={img1} alt=""/>
                </div>
            </div>
        )
    }
}
