import React from 'react'

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step1">
                <div className="head">план урока</div>

                <div className="text">
                    В этом уроке мы рассмотрим видеокурс из цикла «Основы дрессировки» и узнаем, каким образом останавливать собаку из движения.
                </div>

                <div className="lessonSteps">
                    <div className="lessonSteps_el">
                        1 этап
                    </div>
                    <div className="lessonSteps_el">
                        2 этап
                    </div>
                    <div className="lessonSteps_el">
                        3 этап
                    </div>
                    <div className="lessonSteps_el">
                        Тест
                    </div>
                </div>

            </div>
        )
    }
}
