import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson5/step30-1.png'
import img2 from '../../../image/lessons/lesson5/step30-2.png'
import img3 from '../../../image/lessons/lesson5/step30-3.png'


export default class step6 extends React.Component {
    state = {
        flexActive: 0,
        flex: [],
    };

    changeFlex = flexActive => {
        const {flex} = this.state
        this.setState({flexActive}, () => {})
        if (flex.indexOf(flexActive) === -1) {
            flex.push(flexActive)
        }
        this.setState({flex})
        if (flex.length === 3) {
            this.props.showNextBtn()
        }
    }

    infoWrp = {
        1: {
            text1: 'С этой целью обычно используются твердые лакомства, для разгрызания которых нужно длительное время. Питомцу интересно играть с таким лакомством, и он может провести с ним большое количество времени.',
            text2: 'часто люди дают питомцам кости или хрящи. Это небезопасно и вредно для здоровья животного!',
            text3: 'специально изготовленные лакомства, которые не только доставляют питомцу удовольствие, но и безопасны для здоровья.',
            img: img1
        },
        2: {
            text1: 'Некоторые лакомства являются функциональными, т.е. оказывают положительное влияние на здоровье животных — например, на гигиену зубов или укрепление суставов.',
            text2: 'некоторые хозяева считают, что деревянные палки полезно грызть собакам, чтобы поддерживать гигиену зубов. Это не так! Палки небезопасны для собак: они могут поранить язык, слизистые рта и пищеварительной трубки, а также вызвать прободение, и при этом не способствуют очищению зубов от налета.',
            text3: 'следует кормить специализированными лакомствами.',
            img: img2
        },
        3: {
            text1: 'Хозяева любят баловать своих питомцев и давать им лакомства в дополнение к основному рациону. Так они проявляют дополнительную заботу и становятся ближе со своим любимцем.',
            text2: 'люди используют еду со стола в качестве угощения. Так делать нельзя! Человеческая еда слишком калорийна для животных. Более того, она может оказаться токсичной и вызвать проблемы с пищеварением.',
            text3: 'покупать готовые лакомства, разработанные с учетом потребностей животных.',
            img: img3
        },
    }


    render() {
        const {flexActive} = this.state;
        return (
            <div className="step30">
                <TextBlock
                    text={`Хозяева угощают своих питомцев по разным причинам. `}
                />
                <InfoBlock
                    text={`Нажмите на кнопки, чтобы узнать подробнее о причинах угощений.`}
                />
                <div className="flex">
                    <div className={'flex_el ' + (flexActive === 1 ? 'active' : '')}
                          onClick={() => {this.changeFlex(1)}}>
                        для занятия <br/>
                        на время
                    </div>
                    <div className={'flex_el ' + (flexActive === 2 ? 'active' : '')}
                          onClick={() => {this.changeFlex(2)}}>
                        функци&shy;ональный <br/>
                        уход
                    </div>
                    <div className={'flex_el ' + (flexActive === 3 ? 'active' : '')}
                          onClick={() => {this.changeFlex(3)}}>
                        Проявление <br/>
                        заботы
                    </div>
                </div>

                {flexActive !== 0 &&
                    <div className={'info ' + (flexActive === 2 && " center ") + (flexActive === 3 && " left ")}>
                        <TextBlock
                            text={this.infoWrp[flexActive].text1}
                        />
                        <div className="flex">
                            <div className="left">
                                <TextBlock
                                    text={`<span class="textRed">Ошибка: </span>${this.infoWrp[flexActive].text2}`}
                                />
                                <TextBlock
                                    text={`<span class="textBlue">Правильное решение: </span>${this.infoWrp[flexActive].text3}`}
                                />
                            </div>
                            {/*<img src={this.infoWrp[flexActive].img} alt=""/>*/}
                        </div>

                    </div>
                }



            </div>



        )
    }
}
