import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson41/step26-1.png'
import img1xs from "../../../image/lessons/lesson41/step26-1xs.png";


export default class step5 extends React.Component {


    render() {
        return (
            <div className="step26">
                <TextBlock
                    text={`После перевода собак с домашней еды на рационы PEDIGREE® :`}
                />
                {/*<div className="info">*/}
                {/*    <div className="info_el">*/}
                {/*        <p className="info_el_icon">*/}
                {/*            <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*                <path d="M27 3L10.9863 19L3 10.9795" stroke="#BA0E0E" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>*/}
                {/*            </svg>*/}
                {/*        </p>*/}
                {/*        <p className="info_el_text">*/}
                {/*            Более выраженный блеск шерсти Насыщенный цвет шерсти*/}
                {/*        </p>*/}
                {/*    </div>*/}
                {/*    <div className="info_el">*/}
                {/*        <p className="info_el_icon">*/}
                {/*            <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*                <path d="M27 3L10.9863 19L3 10.9795" stroke="#BA0E0E" strokeWidth="6" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>*/}
                {/*            </svg>*/}
                {/*        </p>*/}
                {/*        <p className="info_el_text">*/}
                {/*            Насыщенный цвет шерсти*/}
                {/*        </p>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt="" className="imgCenter"/>
                </picture>
            </div>
        )
    }
}
