import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import DefinitionBlock from '../../../Components/LessonAndTestComponents/DefinitionBlock'
import img1 from "../../../image/lessons/lesson6/step9-1.png";


export default class step9 extends React.Component {
    state = {
    };

    render() {
        return (
            <div className="step9 step33">
                <div className="imgDiv">
                    <TextBlock
                        text={`Как вы уже знаете, инструкции по кормлению, написанные на упаковках кормов, являются только ориентиром. Хозяин должен постоянно следить за общим состоянием питомца путем оценки упитанности.`}
                    />
                    <img src={img1} alt="" className='mainImg'/>
                </div>
                <DefinitionBlock
                    text={`<span class="text_dark-blue"><b>Количественная оценка упитанности</b></span> — очень полезный технический прием, позволяющий определить кондицию тела и отследить ее изменения с течением времени.`}
                />
                <TextBlock
                    text={`Чтобы оценить упитанность животного, нужно посмотреть на него с двух ракурсов:`}
                />
                <ul>
                    <li>вид сверху;</li>
                    <li>вид сбоку в профиль.</li>
                </ul>
                <TextBlock
                    text={`Стоит обратить внимание, насколько сильно выступают грудная клетка и позвоночник и насколько легко их почувствовать наощупь. Осмотр питомца позволит сделать полезные выводы и скорректировать его питание.`}
                />
            </div>
        )
    }
}