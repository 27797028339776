import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import img1 from '../../../image/lessons/lesson5/step3-1.png';


export default class step3 extends React.Component {
    state = {
    };



    render() {
        return (
            <div className="step3">
                <TextBlock
                    text={`Собаки относятся к факультативным плотоядным. Это означает, что в дикой среде они питаются преимущественно мясом, но вместе с ним могут потреблять и растительную пищу.`}
                />
                <TextBlock
                    text={`<span class="textBlue">Пищевое поведение собак имеет следующие особенности:</span>`}
                />

                <ul>
                    <li>Поскольку волки и дикие собаки охотятся в стае, они привыкли есть добычу максимально быстро.
                        Поэтому собака может съесть за один прием примерно 1/5 от веса своего тела.
                    </li>
                    <li>Практически все жертвы волков и диких собак являются растительноядными. Поэтому волков и собак
                        можно назвать факультативными плотоядными, так как, съедая органы жертвы, они употребляют как
                        животные, так и растительные компоненты.
                    </li>
                </ul>

                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
