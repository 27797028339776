import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import List from '../../../Components/LessonAndTestComponents/List';
import ArrowBack from '../../../Components/LessonAndTestComponents/ArrowBack';

import img1 from '../../../image/lessons/lesson33/step14-1.jpg';


export default class step14 extends React.Component {
    state = {
        flexActive: 0,
        flexs: [1],
        flexHidden: false,
        ListData1: [
            `беспомощны и нуждаются в заботе матери;`,
            `большую часть времени спят или едят;`,
            `глаза и уши закрыты, но в то же время щенки чувствительны к прикосновениям и запахам;`,
            `не могут стоять и удерживать собственный вес;`,
            `плохо регулируют температуру тела;`,
            `необходимо материнское молоко для выработки незаменимых антител в организме.`
        ],
        ListData2: [
            `щенки открывают глаза и реагируют на свет и движения;`,
            `открываются ушные каналы;`,
            `щенки учатся стоять и ходить;`,
            `развивается социальное поведение (рычание, игры с другими щенками);`,
            `щенки начинают интересоваться едой, которую ест мать;`,
            `регулярный контакт с человеком положительно сказывается на будущем поведении щенков.`
        ],
        ListData3: [
            `активная социализация;`,
            `распознавание сигналов;`,
            `исследуют мир вокруг, становятся любопытными;`,
            `появляются молочные зубы;`,
            `переходят на твердую пищу.`
        ],
        ListData4: [
            `развитие органов чувств;`,
            `замена молочных зубов постоянными;`,
            `хорошие способности к дрессировке;`,
            `потребность в общении с людьми и другими животными; навыки движения полностью развиты.`
        ],
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs, flexHidden: true})
        if (flexs.length === 4) {
            this.props.showNextBtn()
        }
    }

    closeImgDiv = () => {this.setState({flexHidden: false, flexActive: 0})}


    render() {
        const {flexActive, flexHidden, ListData1, ListData2, ListData3, ListData4} = this.state
        return (
            <div className="step14">
                <TextBlock
                    text={`Развитие щенков делят на четыре стадии, каждая из которых имеет свои особенности.`}
                    hidden={flexHidden === true ? 'hidden' : ''}
                />
                <div className="wrp">
                    <div className={'flex ' + (flexHidden === true && 'hidden')}>
                        <div className={'flex_el ' + (flexActive === 1 && 'active')} onClick={() => {
                            this.changeFlex(1)
                        }}>Новорожденный период
                        </div>
                        <div className={'flex_el ' + (flexActive === 2 && 'active')} onClick={() => {
                            this.changeFlex(2)
                        }}>Переходный период
                        </div>
                        <div className={'flex_el ' + (flexActive === 3 && 'active')} onClick={() => {
                            this.changeFlex(3)
                        }}>Период социализации
                        </div>
                        <div className={'flex_el ' + (flexActive === 4 && 'active')} onClick={() => {
                            this.changeFlex(4)
                        }}>Подростковый период
                        </div>
                    </div>
                    {flexActive === 0 &&
                    <img src={img1} alt=""/>
                    }
                    {flexActive !== 0 &&
                    <div className={'imgDiv ' + (flexActive === 2 ? 'imgDiv--2' : flexActive === 3 ? 'imgDiv--3': flexActive === 4 ? 'imgDiv--4' : '')}>
                        <ArrowBack
                            returnMenu={this.closeImgDiv}
                        />
                        {flexActive === 1 &&
                        <div className="imgDiv_wrap">
                            <TextBlock
                                text={`<span class="text_dark-blue">Продолжительность:</span> 2 недели, начиная с рождения.`}
                            />
                            <TextBlock
                                text={`<span class="text_dark-blue">Особенности щенков:</span>`}
                            />
                            <List ListData={ListData1}/>
                        </div>
                        }
                        {flexActive === 2 &&
                        <div className="imgDiv_wrap">
                            <TextBlock
                                text={`<span class="text_dark-blue">Продолжительность:</span> 1-2 недели.`}
                            />
                            <TextBlock
                                text={`<span class="text_dark-blue">Особенности щенков:</span>`}
                            />
                            <List ListData={ListData2}/>
                        </div>
                        }
                        {flexActive === 3 &&
                        <div className="imgDiv_wrap">
                            <TextBlock
                                text={`<span class="text_dark-blue">Продолжительность:</span> 3-12 недель.`}
                            />
                            <TextBlock
                                text={`<span class="text_dark-blue">Особенности щенков:</span>`}
                            />
                            <List ListData={ListData3}/>
                            <TextBlock
                                text={`Этот период играет важную роль в формировании характера щенков. Многие поведенческие проблемы взрослых собак возникают из-за недостаточного взаимодействия или поощрения именно в этот период.`}
                            />
                        </div>
                        }
                        {flexActive === 4 &&
                        <div className="imgDiv_wrap">
                            <TextBlock
                                text={`<span class="text_dark-blue">Продолжительность:</span> от 12 недель и до взросления.`}
                            />
                            <TextBlock
                                text={`<span class="text_dark-blue">Особенности щенков:</span>`}
                            />
                            <List ListData={ListData4}/>
                            <TextBlock
                                text={`Продолжительность подросткового периода варьируется в зависимости от размера собаки. У маленьких пород взросление занимает около года, в то время как большие собаки растут медленнее, и этот период занимает у них от 18 месяцев до двух лет.`}
                            />
                        </div>
                        }
                    </div>
                    }
                </div>
            </div>
        )
    }
}
