import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import PopupInStep from '../../../Components/LessonAndTestComponents/PopupInStep';

import img2_1 from '../../../image/lessons/lesson2/step2-1.png'
import img2_1xs from '../../../image/lessons/lesson2/step2-1xs.png'
import img2_2 from '../../../image/lessons/lesson2/step2-2.png'
import img2_3 from '../../../image/lessons/lesson2/step2-3.png'

import img2_popap1 from '../../../image/lessons/lesson2/step2-popap1.png'
import img2_popap2 from '../../../image/lessons/lesson2/step2-popap2.png'
import img2_popap3 from '../../../image/lessons/lesson2/step2-popap3.png'
import img2_popap4 from '../../../image/lessons/lesson2/step2-popap4.png'
import img2_popap5 from '../../../image/lessons/lesson2/step2-popap5.png'
import img2_popap6 from '../../../image/lessons/lesson2/step2-popap6.png'
import img2_popap7 from '../../../image/lessons/lesson2/step2-popap7.png'
import img2_popap11 from '../../../image/lessons/lesson2/step2-popap11.png'
import img2_popap12 from '../../../image/lessons/lesson2/step2-popap12.png'
import img2_popap13 from '../../../image/lessons/lesson2/step2-popap13.png'
import img2_popap14 from '../../../image/lessons/lesson2/step2-popap14.png'
import img2_popap21 from '../../../image/lessons/lesson2/step2-popap21.png'
import img2_popap22 from '../../../image/lessons/lesson2/step2-popap22.png'
import img2_popap23 from '../../../image/lessons/lesson2/step2-popap23.png'
import img2_popap24 from '../../../image/lessons/lesson2/step2-popap24.png'
import img2_popap25 from '../../../image/lessons/lesson2/step2-popap25.png'
import img2_popap26 from '../../../image/lessons/lesson2/step2-popap26.png'
import img2_popap27 from '../../../image/lessons/lesson2/step2-popap27.png'
import img2_popap28 from '../../../image/lessons/lesson2/step2-popap28.png'
import img2_popap29 from '../../../image/lessons/lesson2/step2-popap29.png'
import img2_popap31 from '../../../image/lessons/lesson2/step2-popap31.png'
import img2_circle1 from '../../../image/lessons/lesson2/step2-circle1.png'
import img2_circle2 from '../../../image/lessons/lesson2/step2-circle2.png'


export default class step2 extends React.Component {
    state = {
        popapActive: 0,
        popapShow: false,
        flexHidden: false,
        activeFlex: 0,
        circleActive: 0,
        circles: [0],
        points0: [],
        points1: [],
        points2: [],
    };

    changeFlex = activeFlex => {
        this.setState({activeFlex, flexHidden: true})
    }

    changeCircle = circleActive => {
        const {circles} = this.state
        this.setState({circleActive})
        if (circles.indexOf(circleActive) === -1) {
            circles.push(circleActive)
        }
        if (circleActive === 1) {
            this.setState({points1: []})
        }
        if (circleActive === 2) {
            this.setState({points2: []})
        }
    }

    showPopap = popapActive => {
        const {popapShow, circleActive, points0, points1, points2, points3} = this.state;

        //Собираем просмотренные попапы в массив
        if (circleActive === 0) {
            if (points0.indexOf(popapActive) === -1) {
                points0.push(popapActive)
            }
        }
        if (circleActive === 1) {
            if (points1.indexOf(popapActive) === -1) {
                points1.push(popapActive)
            }
        }
        if (circleActive === 2) {
            if (points2.indexOf(popapActive) === -1) {
                points2.push(popapActive)
            }
        }
        if (circleActive === 3) {
            if (points3.indexOf(popapActive) === -1) {
                points3.push(popapActive)
            }
        }

        //Обновляем значения state в зависимаости от уже открытого или закрытого окна
        if (!popapShow) {
            this.setState({
                popapActive: popapActive,
                popapShow: true,
                points0,
                points1,
                points2,
                points3,
            })
        } else if (circleActive === 1 && points1.length === 4) {
            this.setState({circleActive: 0, popapShow: false})
        } else if (circleActive === 1 && popapActive >= 14) {
            this.setState({circleActive: 0, popapShow: false})
        } else if (circleActive === 2 && points2.length === 9) {
            this.setState({circleActive: 0, popapShow: false})
        } else if (circleActive === 2 && popapActive >= 29) {
            this.setState({circleActive: 0, popapShow: false})
        } else if (circleActive === 0 && points0.length === 7) {
            this.setState({popapShow: false, popapActive: 0})
        } else if (circleActive === 0 && popapActive >= 7) {
            this.setState({popapShow: false, popapActive: 0})
        } else {
            this.setState({
                popapActive: popapActive +1,
                popapShow: true,
                points0,
                points1,
                points2,
                points3,
            })
        }

        //Показ кнопки продолжить после окончания просмотра всех points
        if (points0.length === 7 && points1.length === 4 && points2.length === 9) {
            this.props.showNextBtn();
        }
    }

    closePopap = () => {
        this.setState({popapShow: false})
    }

    closeImgDiv = () => {this.setState({flexHidden: false, activeFlex: 0})}


    popapWrp = {
        0: {img: '', head: '', text: ''},
        1: {
            img: img2_popap1,
            head: `Место отдыха`,
            text: `Нужно определить щенку такое место, где он может отдыхать и его не будут беспокоить. Оно не должно быть на сквозняке или в помещении, где готовят и едят.`
        },
        2: {
            img: img2_popap2,
            head: `Логово собаки`,
            subHead: `«Клетка или «домик» — жестоко,
            я никогда не буду ставить это в своем доме»`,
            text: `Совершенно ошибочное суждение. Для животного на уровне инстинкта свойственно иметь «берлогу», «логово». Если щенка с раннего возраста приучить к клетке или домику, кормить там и разместить удобную подстилку, это позволит избежать в будущем целого ряда проблем. При этом к логову щенка нужно приучать постепенно, а не сходу и надолго запирать его.`,
            slider: {
                1: `Вы всегда сможете там изолировать собаку от гостей, которые ее боятся или просто не хотят с ней общаться.`,
                2: `Это позволит избежать погромов, которые щенок может учинить, когда остается дома один.`,
                3: `Это позволит вам в будущем путешествовать с собакой, так как перевозка собак в транспорте чаще всего означает перевозку в транспортном контейнере.`,
                4: `Для собаки, привыкшей к такого рода «логову» дома, транспортировка  не превратится в стресс, угрожающий психике и здоровью.`,
            }
        },
        3: {
            img: img2_popap3,
            head: `Не играйте с одеждой`,
            text: `Не играйте с ним предметами одежды и обуви — он потом будет их рвать, так как не научится понимать, что вам жалко, а что нет.`
        },
        4: {
            img: img2_popap4,
            head: `Один дома`,
            text: `Заранее приучайте щенка оставаться одного, даже если вы его взяли специально перед отпуском, чтобы быть с ним, пока он маленький: если собака привыкнет, что с ней постоянно кто-то находится, а потом ее резко начнут оставлять надолго, возможны проблемы с поведением (лай, вой, порча имущества).`
        },
        5: {
            img: img2_popap5,
            head: `Попрошайка`,
            text: `Не кормите собаку со стола, если не хотите вырастить попрошайку.`
        },
        6: {
            img: img2_popap6,
            head: `Правила поведения в доме`,
            text: `Когда вы дома, следите за щенком, запрещая ему шкодничать. Бить не нужно, щенки прекрасно различают интонации, иногда нужно просто отвлечь внимание. Если вы будете терпеливы и последовательны, собачка очень быстро поймет правила жизни в доме.`
        },
        7: {
            img: img2_popap7,
            head: `Взрослая собака в квартире`,
            text: `Если взята взрослая собака, то постарайтесь взять ее так, чтобы впереди у вас были выходные или отпуск, так как вы не всегда знаете, как она будет вести себя дома. Сразу твердо обозначьте, что в доме можно, а что нельзя, так как перевоспитывать будет гораздо сложнее.
            
            Если собака возрастная и с уже сложившимися «плохими привычками», то клетка или вольер на время вашего отсутствия, возможно, будет меньшим злом, чем мероприятия по перевоспитанию. Для недоверчивой к посторонним собаки продумайте постоянное место так, чтобы ее можно было безболезненно изолировать от приходящих в дом гостей.`
        },
        11: {
            img: img2_popap11,
            head: `Надёжная ограда`,
            text: `Надежная ограда, без дыр, которую собака не сможет подкопать или перепрыгнуть. В зоне особого риска по подкопам находятся калитка и ворота, под которыми обычно итак имеется перспективная для расширения щель. Несмотря на это, подкоп может быть сделан в любом месте.`
        },
        12: {
            img: img2_popap12,
            head: `Регулярные прогулки важны`,
            text: `Если вы завели не караульную собаку, а планируете выходить с ней в большой мир, вам не следует полагаться на то, что собака будет гулять исключительно на участке, не видя окружающего мира. Она там «одичает».

            Выводите ее на регулярные «экскурсии», чтобы окружающая обстановка на улицах не вызывала у нее страх или ненужное возбуждение.`
        },
        13: {
            img: img2_popap13,
            head: `Дополнительные хлопоты на участке`,
            text: `Если у вас есть ценные посадки, необходимо заблаговременно сделать вокруг них временные или постоянные ограды. Если этого не сделать, ваш ландшафтный дизайн и огород будут находиться в зоне риска! Позже, когда собака привыкнет ходить по дорожкам и определённым маршрутам, ограду можно будет убрать.

            Заранее имейте в виду, что вам предстоят дополнительные хлопоты по поддержанию чистоты на участке.`
        },
        14: {
            img: img2_popap14,
            head: `Осторожно – собака!`,
            text: `Если у вас живет недоверчивая, сторожевая собака – предупреждающая табличка обязательна.`
        },
        21: {
            img: img2_popap21,
            head: `Обогреваемое зимнее помещение`,
            text: `В средней полосе России в таких условиях хорошо себя чувствуют только собаки с шерстью средней длины или длинной. Иначе необходимо строить обогреваемое зимнее помещение.
            
            Собаки, хорошо защищенные шерстью, могут жить в находящейся в вольере будке.`
        },
        22: {
            img: img2_popap22,
            head: `Регулярные прогулки`,
            text: `Собак вольерного содержания необходимо регулярно выгуливать, так как при таком содержании они не могут получать необходимую для здоровья физическую нагрузку.`
        },
        23: {
            img: img2_popap23,
            head: `Надежная будка`,
            text: `Будка должна быть разборной или хотя бы со съемной крышей
             (для ежегодной санитарной обработки).`
        },
        24: {
            img: img2_popap24,
            head: `Прочная будка`,
            text: `Будка должна быть построена из материала с хорошими теплоизолирующими свойствами.`
        },
        25: {
            img: img2_popap25,
            head: `Просторная будка`,
            text: `Будка должна соответствовать по размеру собаке так, чтобы она там могла стоять, не поднимая головы, и развернуться. Больше будку строить не нужно – просторная будка не будет «удобнее», собака в ней будет мерзнуть, так как не сможет обогреть ее объем своим телом.`
        },
        26: {
            img: img2_popap26,
            head: `Удобный вход будки`,
            text: `В холодное время года на вход в будку вешают плотную занавеску с грузиками снизу – от ветра. Вход в вольер должен открываться внутрь и иметь надежный запор.`
        },
        27: {
            img: img2_popap27,
            head: `Место для отдыха`,
            text: `Лучший выбор подстилки для собаки - современные подстилки из синтетических материалов, легко поддающиеся мойке и дезинфекции. Также можно использовать сено и солому, их следует регулярно менять. Они комфортны для собак, но в них могут поселиться блохи и другие паразиты.`
        },
        28: {
            img: img2_popap28,
            head: `Пол вольера`,
            text: `«Пол» вольера наилучшим образом можно сделать, выбрав землю на два штыка и засыпав гравием или галькой. Но эта «подушка» требует периодической замены, поэтому чаще его делают из деревянных щитов (тоже нужно менять) или современной плитки, только ни в коем случае не скользкой.`
        },
        29: {
            img: img2_popap29,
            head: `Ограда вольера`,
            text: `Ограда: чаще всего – сетка, сваренная из металла, заглубленная не менее, чем на штык (от подкопа), площадь — от 6-8 кв.м. для средней или крупной собаки, для небольшой — пропорционально меньше.`
        },
        // 30: {
        //     img: img2_popap31,
        //     head: `Собака на привязи`,
        //     text: `Содержание собаки на привязи тоже имеет право на жизнь (хотя многие считают это «устаревшим» или «жестоким», но чем привязь принципиально «хуже» вольера сопоставимой площади? – трехметровая цепь дает собаке свободу в пределах того же пространства), при условии, что собака не будет все время сидеть
        //     на цепи, а с ней будут регулярно гулять и давать побегать хотя бы
        //     по участку. Обычно так содержат крупных сторожевых собак.`,
        //     slider: {
        //         1: 'Для привязи потребуется будка.',
        //         2: 'Длинна привязи – от 2,5-3 метров, очень длинную цепь делать не следует, в этом случае лучше сделать блок (цепь скользит по натянутой выше проволоке).',
        //         3: 'При организации привязи исключите предметы, о которые она может запутаться.',
        //     }
        // },
    }

    infoPoints0 = [1, 2, 3, 4, 5, 6, 7];
    infoPoints1 = [11, 12, 13, 14];
    infoPoints2 = [21, 22, 23, 24, 25, 26, 27, 28, 29];


    render() {
        const {popapShow, popapActive, activeFlex, flexHidden, circleActive, points0, points1, points2, circles} = this.state;
        return (
            <div className="step2">
                <InfoBlock
                    text={
                        `Ознакомьтесь с существующими вариантами, нажав на активные элементы.`
                    }
                />
                <div className={'flex ' + (flexHidden === true && 'hidden')}>
                    <div
                        className={'flex_el ' + (activeFlex === 1 && 'active')}
                        onClick={() => {
                            this.changeFlex(1)
                        }}
                    >
                        Собака будет жить в&nbsp;помещении&nbsp;(дом/квартира)
                    </div>
                    <div
                        className={'flex_el ' + (activeFlex === 2 && 'active')}
                        onClick={() => {
                            this.changeFlex(2)
                        }}
                    >
                        Собака будет жить в&nbsp;вольере
                    </div>
                </div>
                {activeFlex === 0 ?
                    null
                    :
                    activeFlex === 1 ?
                        <div className="imgDiv info">
                            <div
                                className="imgDiv_closed"
                                onClick={() => {this.closeImgDiv()}}
                            />
                            <picture>
                                <source media="(max-width: 500px)" srcSet={img2_1xs}/>
                                <img src={img2_1} alt="" className='infoImg'/>
                            </picture>
                            <div className="infoPoints">
                                {this.infoPoints0.map(item => {
                                    return (
                                        <div
                                            key={item}
                                            className={'point ' + (points0.indexOf(item) >= 0 && 'active')}
                                            onClick={() => {
                                                this.showPopap(item)
                                            }
                                            }>
                                            ?
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        :
                        activeFlex === 2 ?
                            <div className="imgDiv info right">
                                <div
                                    className={'imgDiv_closed' + (circleActive === 1 || circleActive === 2 ? 'hidden' : '')}
                                    onClick={() => {this.closeImgDiv()}}
                                />
                                {circleActive === 0 ?
                                    <div className="circles">
                                        <img
                                            src={img2_circle1}
                                            alt=""
                                            className={"circle " + (circleActive === 1 && 'active ') + (circles.indexOf(1) >= 0 && ' show')}
                                            onClick={() => {
                                                this.changeCircle(1)
                                            }}
                                        />
                                        <img
                                            src={img2_circle2}
                                            alt=""
                                            className={"circle " + (circleActive === 2 && 'active ') + (circles.indexOf(2) >= 0 && ' show')}
                                            onClick={() => {
                                                this.changeCircle(2)
                                            }}
                                        />
                                    </div>
                                    :
                                    circleActive === 1 ?
                                        <div className="infoPoints_wrp">
                                            <img src={img2_2} alt="" className='infoImg'/>
                                            <div className="infoPoints infoPoints2">
                                                {this.infoPoints1.map(item => {
                                                    return (
                                                        <div
                                                            key={item}
                                                            className={'point ' + (points1.indexOf(item) >= 0 && 'active')}
                                                            onClick={() => {
                                                                this.showPopap(item)
                                                            }}
                                                        >
                                                            ?
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        : circleActive === 2 ?
                                        <div className="infoPoints_wrp">
                                            <img src={img2_3} alt="" className='infoImg'/>
                                            <div className="infoPoints infoPoints3">
                                                {this.infoPoints2.map(item => {
                                                    return (
                                                        <div
                                                            key={item}
                                                            className={'point ' + (points2.indexOf(item) >= 0 && 'active')}
                                                            onClick={() => {
                                                                this.showPopap(item)
                                                            }}
                                                        >
                                                            ?
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            :
                            null
                }

                {popapShow &&
                <PopupInStep
                    closePopup={this.closePopap}
                    popupImg={this.popapWrp[popapActive].img}
                    popupHead={this.popapWrp[popapActive].head}
                    popupSubhead={this.popapWrp[popapActive].subHead}
                    popupText={this.popapWrp[popapActive].text}
                    slider={this.popapWrp[popapActive].slider}
                    changePoint={() => this.showPopap(popapActive)}
                />
                }

            </div>
        )
    }
}
