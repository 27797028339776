import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import List from "../../../Components/LessonAndTestComponents/List";

import img1 from '../../../image/lessons/lesson39/step10-1.png'
import img1_icon from '../../../image/lessons/lesson39/step10-1-icon.png'
import img2_1 from '../../../image/lessons/lesson39/step10-2-1.png'
import img2_2 from '../../../image/lessons/lesson39/step10-2-2.png'
import img2_icon from '../../../image/lessons/lesson39/step10-2-icon.png'
import img3 from '../../../image/lessons/lesson39/step10-3.png'
import img3_icon from '../../../image/lessons/lesson39/step10-3-icon.png'
import img4 from '../../../image/lessons/lesson39/step10-4.png'
import img4_icon from '../../../image/lessons/lesson39/step10-4-icon.png'
import img5 from '../../../image/lessons/lesson39/step10-5.png'
import img5_icon from '../../../image/lessons/lesson39/step10-5-icon.png'
import img6 from '../../../image/lessons/lesson39/step10-6.png'
import img6_icon from '../../../image/lessons/lesson39/step10-6-icon.png'
import img7 from '../../../image/lessons/lesson39/step10-7.png'
import img7_icon from '../../../image/lessons/lesson39/step10-7-icon.png'
import img8 from '../../../image/lessons/lesson39/step10-8.png'
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";


export default class step10 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        flexActive: 0,
        flexs: [1],
        showPopup: false
    };

    //Данные попапа
    imgDivData = {
        1: {
            text: `Здоровье костей особенно важно <b>в период роста.</b> Рационы для щенков способствуют правильному развитию и укреплению костей за счет содержания <span class="text_dark-blue">кальция</span>, эффективность которого клинически доказана.`,
            list: false,
            head: `Здоровые кости`,
            img2: img1,
            icon: img1_icon,
            text2: false,
            text3: `<b>Чем больше размер щенка, тем выше скорость прироста массы тела.</b> Поэтому щенки разных размеров требуют разного уровня кальция в рационе. Таким образом, не существует одного уровня кальция, который одинаково подходит для всех размерных групп собак.`
        },
        2: {
            text: `<span class="text_dark-blue">Таурин</span> поддерживает здоровье сердца и необходим, в первую очередь, кошкам, так как они не способны его синтезировать в организме самостоятельно.`,
            list: false,
            head: `Здоровое сердце`,
            img2: img2_2,
            img: img2_1,
            icon: img2_icon,
            text2: `Избыточный вес и ожирение вызывают проблемы с сердечно-сосудистой системой. Наличие в ежедневном рационе <span class="text_dark-blue">L-карнитина</span> способствует поддержанию здорового веса, так как L-карнитин активизирует процессы расщепления жиров в организме.`,
            text3: false,
        },
        3: {
            text: `Для контроля веса в корма добавляют <span class="text_dark-blue">L-карнитин</span>, который активизирует процессы расщепления жиров в организме и способствует поддержанию оптимального веса. Часто используется в кормах для стерилизованных животных.`,
            list: false,
            head: `Контроль веса`,
            img2: img3,
            icon: img3_icon,
            text2: false,
            text3: false,
        },
        4: {
            text: `<span class="text_dark-blue">Витамин А</span> и <span class="text_dark-blue">таурин</span> необходимы для зрения, в особенности для быстрой адаптации к темноте.`,
            list: false,
            head: `Здоровое зрение`,
            img2: img4,
            icon: img4_icon,
            text2: false,
            text3: `Витамин А участвует в синтезе зрительных пигментов, необходимых для восприятия цвета (йодопсина) и ночного зрения (родопсина).`,
        },
        5: {
            text: `Поддержка здоровья суставов животного в основном осуществляется за счет добавления в корм следующих элементов:`,
            list: [
                `<span class="text_dark-blue">глюкозамина и хондроитина</span> — строительные компоненты хрящевой ткани, добавление которых способствует сохранению здоровой хрящевой поверхности в суставах;`,
                `<span class="text_dark-blue">омега-3,6 жирные кислоты</span> — способны снимать локальные воспаления в суставах животного.`
            ],
            head: `Здоровые суставы`,
            img2: img5,
            icon: img5_icon,
            text2: false,
            text3: false,
        },
        6: {
            text: `<span class="text_dark-blue">Баланс минералов (Mg, Ca, P, Na, K)</span>, помогает поддержанию здоровья мочевыводящей системы и снижению риска формирования камней.
            
            Из пяти перечисленных минералов три являются структурными компонентами мочевого камня: магний, фосфор и кальций. Значит, их снижение способствует <b>профилактике отложения мочевых камней</b>.
            
            Натрий и калий, в свою очередь, <b>стимулируют потребление воды</b>, а значит, моча становится менее концентрированной. В свою очередь, относительное увеличение в корме этих минералов способствует замедлению кристаллизации магния, кальция и фосфора, и тем самым помогает профилактике мочекаменной болезни.`,
            list: false,
            head: `Здоровье мочевыделительной системы`,
            img2: img6,
            icon: img6_icon,
            text2: false,
            text3: false
        },
        7: {
            text: `Для уменьшения запаха туалета в рационы для домашних собак добавляется <span class="text_dark-blue">Экстракт Юкки Шидигера</span>, уникальный природный компонент, способствующий поддержанию здоровья ЖКТ и иммунитета.`,
            list: [
                `связывает газы, образующиеся при распаде белка в кишечнике, способствуя снижению метеоризма.`,
                `Снижает запах кала и обеспечивает формирование твердого стула.`,
                `Способствует обновлению клеток, замедляя процесс старения.`,
                `Уменьшает запах испражнений животного за счет содержания стероидного сапонина, снижающего содержание аммония в моче и устраняющего неприятный запах экскрементов животного.`
            ],
            head: `Устранение запаха туалета`,
            img2: '',
            img: img7,
            icon: img7_icon,
            text2: `Юкка Шидигера выполняет важную роль в организме:`,
            text3: false
        },
    }

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }

        this.setState({flexActive, flexs, showPopup: true})
        if (flexs.length === 7) {
            this.props.showNextBtn()
        }
    }

    nextPopup = (flexActive) => {
        const {flexs, showPopup} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }

        if (!showPopup) {
            this.setState({
                flexActive: flexActive,
                showPopup: true,
            })
        } else if (flexActive >= 7) {
            this.setState({showPopup: false, flexActive: 0})
        } else {
            this.setState({
                flexActive: flexActive +1,
                showPopup: true,
            })
        }

        if (flexs.length === 7) {
            this.props.showNextBtn()
        }
    }

    closeImgDiv = () => {this.setState({flexActive: 0})}


    render() {
        const {flexActive, windowWidth} = this.state
        return (
            <div className="step10">
                <TextBlock
                    text={`Корма несут в себе не только 4 основные функциональные преимущества, о которых мы рассказали ранее в уроке, но и другие полезные свойства, которые удовлетворяют другие потребности животных. Давайте посмотрим, какие компоненты в составе кормов также поддерживают нижеперечисленные потребности собак:`}
                />
                <InfoBlock
                    text={`Нажмите на потребность, чтобы узнать, какие компоненты в составе кормов поддерживают ее в норме.`}
                    className={' InfoBlockTablet'}
                />
                <div className="wrp">
                    <div className={'flex '}>
                        <div className={'flex_el ' + (flexActive === 1 && 'active')} onClick={() => {
                            this.changeFlex(1)
                        }}>Здоровые кости
                        </div>
                        <div className={'flex_el ' + (flexActive === 2 && 'active')} onClick={() => {
                            this.changeFlex(2)
                        }}>Здоровое сердце
                        </div>
                        <div className={'flex_el ' + (flexActive === 3 && 'active')} onClick={() => {
                            this.changeFlex(3)
                        }}>Контроль веса
                        </div>
                        <div className={'flex_el ' + (flexActive === 4 && 'active')} onClick={() => {
                            this.changeFlex(4)
                        }}>Здоровое зрение
                        </div>
                        <div className={'flex_el ' + (flexActive === 5 && 'active')} onClick={() => {
                            this.changeFlex(5)
                        }}>Здоровые суставы
                        </div>
                        <div className={'flex_el ' + (flexActive === 6 && 'active')} onClick={() => {
                            this.changeFlex(6)
                        }}>Здоровье мочевыделительной системы
                        </div>
                        <div className={'flex_el ' + (flexActive === 7 && 'active')} onClick={() => {
                            this.changeFlex(7)
                        }}>Устранение запаха туалета
                        </div>
                    </div>
                    {windowWidth >= 768 ?
                        <div className="box">
                            {flexActive === 0 &&
                            <div className="wrp_right">
                                <InfoBlock
                                    text={`Вы видите ключевые элементы, необходимые щенку. Нажмите на элемент, чтобы узнать, для чего он нужен растущему организму.`}
                                />
                                <img className="wrp_img" src={img8} alt=""/>
                            </div>
                            }
                            {flexActive !== 0 &&
                            <div className={'imgDiv'}>
                                <div className="img_box">
                                    <img className="imgDiv_img" src={this.imgDivData[flexActive].icon} alt=""/>
                                </div>
                                <div className="imgDiv_flex">
                                    <TextBlock text={this.imgDivData[flexActive].text}/>
                                    <img src={this.imgDivData[flexActive].img} alt="" className="imgDiv_flex_img"/>
                                </div>
                                {this.imgDivData[flexActive].text2 &&
                                <TextBlock text={this.imgDivData[flexActive].text2}/>
                                }
                                {this.imgDivData[flexActive].list &&
                                <List ListData={this.imgDivData[flexActive].list}/>
                                }
                                <img src={this.imgDivData[flexActive].img2} alt="" className="imgDiv_picture"/>
                                {this.imgDivData[flexActive].text3 &&
                                <TextBlock text={this.imgDivData[flexActive].text3}/>
                                }
                            </div>
                            }
                        </div>
                        :
                        <div className="box">
                            {flexActive > 0 &&
                                <PopupInStep
                                    closePopup={this.closeImgDiv}
                                    popupImg={this.imgDivData[flexActive].icon}
                                    popupHead={this.imgDivData[flexActive].head}
                                    popupText={this.imgDivData[flexActive].text}
                                    popupImg2={this.imgDivData[flexActive].img}
                                    popupText2={this.imgDivData[flexActive].text}
                                    popupImg3={this.imgDivData[flexActive].img2}
                                    ListData={this.imgDivData[flexActive].list}
                                    changePoint={() => this.nextPopup(flexActive)}
                                    popupBtnText={'Далее'}
                                />
                            }
                        </div>
                    }
                </div>
            </div>
        )
    }
}
