import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import SwipeBlock from "../../../Components/LessonAndTestComponents/SwipeBlock";
import Slider from "react-slick";

import img1 from '../../../image/lessons/lesson34/step5-1.png';
import img2 from '../../../image/lessons/lesson34/step5-2.png';
import img3 from '../../../image/lessons/lesson34/step5-3.png';
import img4 from '../../../image/lessons/lesson34/step5-4.png';
import img5 from '../../../image/lessons/lesson34/step5-5.png';
import img6 from '../../../image/lessons/lesson34/step5-6.png';


export default class step5 extends React.Component{

    changeSlide = (next) => {
        if(next === 2){
            this.props.showNextBtn();
        }
        console.log(111, next)
    }

    render() {
        let settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 2,
            adaptiveHeight: true,
            beforeChange: (current, next) => {
                this.changeSlide(next)
            },
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        dots: true,
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]
        };
        return (
            <div className="step5">
                <TextBlock
                    text={`В научно-исследовательском институте WALTHAM были сделаны важные научные открытия, некоторые из которых в корне изменили индустрию производства кормов для животных.`}
                />
                <InfoBlock
                    text={`Используйте стрелки, чтобы узнать о всех научных открытиях.`}
                />
                <SwipeBlock
                    text={`<p class="swiper-block__tablet">Используйте свайп для перемещения между элементами.</p><p class="swiper-block__mobile">Используйте свайп для ознакомления с каждой характеристикой.</p>`}
                />
                <Slider {...settings}>
                    <div className="flex_el">
                        <div className="img">
                            <img src={img3} alt=""/>
                        </div>
                        <TextBlock
                            text={`<span class="text_dark-blue">1997 ГОД</span>
В 1997 г. специалисты научно-исследовательского института Waltham определили количество цинка и линолевой кислоты необходимое для поддержания хорошего состояния кожи и шерсти собак.`}
                        />
                    </div>
                    <div className="flex_el">
                        <div className="img">
                            <img src={img4} alt=""/>
                        </div>
                        <TextBlock
                            text={`<span class="text_dark-blue">2003 ГОД</span>
Другой пример - в научно-исследовательском институте Waltham был открыт идеальный баланс макронутриентов в пищи для собак: около 60% суточной нормы калорий должно приходиться на жиры, 30% - на белки и 10% - на углеводы.`}
                        />
                    </div>
{/*                    <div className="flex_el">*/}
{/*                        <div className="img">*/}
{/*                            <img src={img3} alt=""/>*/}
{/*                        </div>*/}
{/*                        <TextBlock*/}
{/*                            text={`<span class="text_dark-blue">1997 ГОД</span>*/}
{/*Запатентован уровень цинка и линолевой кислоты, определяющей оптимальное состояние кожи и шерсти.`}*/}
{/*                        />*/}
{/*                    </div>*/}
{/*                    <div className="flex_el">*/}
{/*                        <div className="img">*/}
{/*                            <img src={img4} alt=""/>*/}
{/*                        </div>*/}
{/*                        <TextBlock*/}
{/*                            text={`<span class="text_dark-blue">2003 ГОД</span>*/}
{/*Определена роль макронутриентного профиля (оптиального сочетания белков, жиров и углеводов) в пищевом предпочтении кошек.`}*/}
{/*                        />*/}
{/*                    </div>*/}
{/*                    <div className="flex_el">*/}
{/*                        <div className="img">*/}
{/*                            <img src={img5} alt=""/>*/}
{/*                        </div>*/}
{/*                        <TextBlock*/}
{/*                            text={`<span class="text_dark-blue">2005 ГОД</span>*/}
{/*Было установлено, что у кошек нет рецепторов, распознающих сладкий вкус сахаров.`}*/}
{/*                        />*/}
{/*                    </div>*/}
                    <div className="flex_el">
                        <div className="img">
                            <img src={img6} alt=""/>
                        </div>
                        <TextBlock
                            text={`<span class="text_dark-blue">2007 ГОД</span>
Открыт ген, обуславливающий размер собак.`}
                        />
                    </div>
                </Slider>
                <p className="attention">
                    Многие из перечисленных научных открытий являются фундаментальными не только для компании Mars и ее продукции, но и для всей индустрии!
                </p>
            </div>
        )
    }
}
