import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson35/step8-1.png'

export default class step8 extends React.Component {

    render() {
        return (
            <div className="step8">
                <TextBlock
                    text={`Содержание питательных веществ в корме обеспечивается разными сырьевыми источниками.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Белки
                        </div>
                        <div className="flex_el_wrp">
                            <h3 className="header">
                                Животные белки:
                            </h3>
                            <ul>
                                <li>мясная, куриная или рыбная мука (дегидратированное, то есть высушенное мясо);</li>
                                <li>субпродукты.</li>
                            </ul>
                            <h3 className="header">Растительные белки:</h3>
                            <ul>
                                <li>изолят растительного белка</li>
                                <li>пшеничный, кукурузный, рисовый глютен (глютен — это белковая часть злаков).</li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Жиры
                        </div>
                        <div className="flex_el_wrp">
                            <h3 className="header">
                                Растительные жиры:
                            </h3>
                            <ul>
                                <li>подсолнечное масло;</li>
                                <li>оливковое масло;</li>
                                <li>льняное масло.</li>
                            </ul>
                            <h3 className="header">Животные жиры:</h3>
                            <ul>
                                <li>животный жир;</li>
                                <li>рыбий жир.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Углеводы
                        </div>
                        <div className="flex_el_wrp">
                            <ul>
                                <li>злаки (рис, кукуруза, пшеница, овсяная крупа, молотый ячмень);</li>
                                <li>клетчатка (пульпа сахарной свеклы, целлюлоза);</li>
                                <li>кукурузный крахмал;</li>
                                <li>овощи;</li>
                                <li>картофель.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <img className="img" src={img1} alt=""/>
            </div>
        )
    }
}
