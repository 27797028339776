import React from 'react'
// import ProfileCard from '../../Components/ProfileCard'
import CourseStat from '../../Components/CourseStat'
import Api from '../../Service/Api'
import './style.sass'
import PanelTest from "../../Components/PanelTest";
import PanelStudy from "../../Components/PanelStudy";
import Popup from "../../Components/Popup";
import Expert from "../../Components/Expert";
import img1 from "../../image/expert-evgenij.png";
import img3 from "../../image/expert-grigorij.png";
// import Slider from "react-slick";
import img4xs from "../../image/banner-pedigreeXs-new.png";
import img4 from "../../image/banner-pedigree-new.png";
import img5 from "../../image/banner-pedigree-text-new.png";
import img6 from "../../image/expert_waltham.png";
import img7 from "../../image/expert_waltham_img.png";
import TextBlock from "../../Components/LessonAndTestComponents/TextBlock";
import img8 from '../../image/banner-1af-left.png';
// import img8xs from '../../image/banner-1af-leftXs.png';
import img9 from '../../image/banner-1af-logo.png';
import img9xs from '../../image/banner-1af-logoXs.png';

export default class Main extends React.Component {
    Api = new Api()

    state = {
        courses: {},
        course: {},
        openTab: 0,
        showPopupExpert: false,
        popupExpertActive: 0,
    }

    componentDidMount() {
        this.Api.getCourses()
            .then((courses) => {
                this.setState({courses})
                // eslint-disable-next-line
                Object.values(courses).map((item, index) => {
                    if (!index) {
                        this.Api.getCourse(item.id)
                            .then((course) => {
                                this.setState(course)
                            });
                    }
                })
            })
    }

    getCourse = (openTab, id) => {
        this.setState({
            openTab,
            course: {}
        })
        this.Api.getCourse(id)
            .then((course) => {
                this.setState(course)
            });

    }

    changeLessonActive = lessonActive => {
        this.setState({lessonActive})
        this.props.history.push('/lesson', lessonActive)
    }

    showPopupExpert = (popupExpertActive) => {
        this.setState({
            showPopupExpert: true,
            popupExpertActive: popupExpertActive
        })
        console.log(111, 'click')
    }

    closeEndPopap = () => {
        this.setState({
            showPopupExpert: false,
        })
    }

    expertData = {
        1: {
            img: img1,
            fio: 'Евгений Цигельницкий',
            position: 'кинолог, преподаватель, специалист РКФ',
            description: 'Автор книг, публикаций, методических разработок и учебных программ в области кинологии.',
        },
        2: {
            img: img3,
            fio: 'Григорий Гречкин',
            position: 'Заместитель руководителя Департамента РКФ по дрессировке и испытаниям рабочих качеств собак, судья, инструктор РКФ.',
            description: 'Главный специалист Департамента дрессировки и испытаний рабочих качеств собак РКФ.',
        },
        3: {
            img: img3,
            fio: 'Григорий Гречкин',
            position: 'Заместитель руководителя Департамента РКФ по дрессировке и испытаниям рабочих качеств собак, судья, инструктор РКФ.',
            description: 'Главный специалист Департамента дрессировки и испытаний рабочих качеств собак РКФ.',
        },
        5: {
            img: img6,
            img2: img7,
            head: 'Научно-исследовательский центр питания домашних животных WALTHAM',
            text1: `Мировой лидер в области питания и ухода за домашними животными. <b>На протяжении 50 лет</b> – ведет передовые исследования, направленные на поддержание здоровья животных. 
            
            Центр WALTHAM <b>проводит фундаментальные исследования и обеспечивает научную поддержку Mars Petcare</b>, способствует поддержанию здоровья и благополучия собак, кошек, лошадей, птиц и рыб, а также комфортному взаимодействию с ними человека.`,
            text2: `Сотрудники центра преданы идее – <b>сделать мир домашних животных лучше</b>, и исследования WALTHAM очень важны для того, чтобы эта идея воплотилась в жизнь. 
            
            <b>В основе деятельности центра лежит концепция заботы о домашних животных</b> и приведения окружающей их обстановки в соответствие с высочайшими стандартами, что позволяет сохранить их здоровье и обеспечить благополучие. Работа с животными основана на применении поощрения, благодаря чему животные, выполняющие те или иные задания в ходе научных исследований, получают интересный позитивный опыт. 
            
            <b>WALTHAM неоднократно совершал прорывы в науке о питании</b> домашних животных, а его сотрудничество с международными исследовательскими центрами создает научную и экспериментальную основу для развития брендов Mars Petcare.`,
            link: 'https://www.waltham.com/'
        },
    }

    _renderCourses = () => {
        const {courses, course, openTab} = this.state;
        if (Object.values(courses).length) {
            return Object.values(courses).map((item, index) => {
                let pointTotal = 0
                let pointTotalTest = 0
                let lessonAttr = undefined;
                let testAttr = undefined;
                /*let settings = {
                    arrows: false,
                    dots: false,
                    infinite: false,
                    speed: 500,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    /!*beforeChange: (current, next) => {
                        this.changeSlide(next)
                    },*!/
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            }
                        }
                    ]
                };*/
                if (Object.values(course).length) {
                    lessonAttr = course.lessons;
                    testAttr = course.tests;
                    lessonAttr !== undefined && Object.values(lessonAttr).length &&
                    // eslint-disable-next-line
                    Object.values(lessonAttr).map(lesson => {
                        pointTotal = pointTotal + lesson.attributes.points
                    })
                    testAttr !== undefined && Object.values(testAttr).length &&
                    // eslint-disable-next-line
                    Object.values(testAttr).map(test => {
                        pointTotalTest = pointTotalTest + test.attributes.points
                    })
                }
                return (
                    <div
                        key={item.name}
                    >
                        <div
                            className={"course " + (openTab === index && 'active')}
                        >
                            <div
                                className="content_head top"
                                onClick={() => {
                                    this.getCourse(index, item.id)
                                }}
                            >
                                {item.name}
                            </div>
                            <div className="open">
                                {Object.values(course).length ?
                                    <div>
                                        {course.attributes.id !== 4
                                            ?
                                            <Expert
                                                preview={course.attributes.id === 5 ? '' : 'Автор курса'}
                                                expertName={course.attributes.id === 1 ? 'Евгений Цигельницкий' : course.attributes.id === 2 || course.attributes.id === 3 ? 'Григорий Гречкин' : 'Подготовлено по материалам WALTHAM'}
                                                expertInfo={course.attributes.id === 5 ? 'Нажмите на кнопку, чтобы узнать о центре больше.' : 'Нажмите на кнопку, чтобы узнать об эксперте больше.'}
                                                showPopupExpert={() => {this.showPopupExpert(course.attributes.id)}}
                                            />
                                            :
                                            undefined
                                        }
                                        <div className="wrp_CourseStat">
                                            <CourseStat
                                                courses={course}
                                                pointTotal={pointTotal}
                                                pointTotalTest={pointTotalTest}
                                            />
                                        </div>
                                        <div
                                            className="content_head"
                                        >
                                            В процессе:
                                        </div>

                                        <div className="studyPanels">
                                            {(testAttr !== undefined && Object.values(testAttr).length) ?
                                            // eslint-disable-next-line
                                            Object.values(testAttr).map(test => {
                                                if (!test.finished && test.active) {
                                                    let lesson = {};
                                                    lesson.attributes = {};
                                                    lesson.attributes.id = test.attributes.lesson_id;
                                                    lesson.MainHeader_header = test.attributes.name;
                                                    lesson.MainHeader_subheader = test.attributes.description;
                                                    lesson.plan = test.lessonPlan;
                                                    return (
                                                        <PanelTest
                                                            key={test.attributes.id}
                                                            active={test.active}
                                                            name={test.attributes.name}
                                                            text={test.attributes.description}
                                                            steps={test.steps}
                                                            userSteps={test.userSteps}
                                                            id={test.attributes.id}
                                                            balls={test.attributes.points}
                                                            finished={test.finished}
                                                            lesson={lesson}
                                                            history={this.props.history}
                                                            blue={true}
                                                        />
                                                    )
                                                }
                                                else {
                                                    return undefined
                                                }
                                            })
                                                :
                                                undefined
                                            }
                                            {(lessonAttr !== undefined && Object.values(lessonAttr).length) ?
                                            // eslint-disable-next-line
                                            Object.values(lessonAttr).map(lesson => {
                                                if (lesson.steps !== lesson.attributes.steps) {
                                                    return (
                                                        <PanelStudy
                                                            key={lesson.attributes.id}
                                                            lesson={lesson}
                                                            img={'http://rkf-study.ru' + lesson.attributes.image}
                                                            id={lesson.attributes.id}
                                                            onClick={this.changeLessonActive}
                                                            blue={true}
                                                        />
                                                    )
                                                }else{
                                                    return undefined
                                                }
                                            })
                                                :
                                                undefined
                                            }
                                        </div>
                                        {course.attributes.id === 1 &&
                                        <div className="banners">
                                            <div className="banners_wrp">
                                                <div className="banners_el pedigree">
                                                    <div className="flex">
                                                        <div className="flex_el">
                                                            <picture className="new_pic">
                                                                <source srcSet={img4xs} media="(max-width: 920px)"/>
                                                                <img src={img4} alt="" className="banners_img"/>
                                                            </picture>
                                                        </div>
                                                        <div className="flex_el new_el">
                                                            <img src={img5} alt="" className="banners_img new_img"/>
                                                            {/*<div className="flex_el_text">
                                                            </div>*/}
                                                            <a href="https://www.pedigree.ru/positive-treats" className="banner_link" target="_blank" rel="noopener noreferrer"><button className="btn btn_red btn_red_new">Узнать больше</button></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="banners_el pedigree-1af">
                                                    <div className="flex">
                                                        <div className="flex_el left">
                                                            <picture>
                                                                <source srcSet={img8} media="(max-width: 1023px)"/>
                                                                <img src={img8} alt="" className="banners_img left"/>
                                                            </picture>
                                                        </div>
                                                        <div className="flex_el">
                                                            <picture>
                                                                <source srcSet={img9xs} media="(max-width: 500px)"/>
                                                                <img src={img9} alt="" className="banners_img"/>
                                                            </picture>
                                                            <div className="flex_el_text bold">
                                                                Щенки раскрывают лучшее в детях
                                                            </div>
                                                            <div className="flex_el_text">
                                                                Пусть ребенок научится заботиться о питомце в 1 «Аф» классе.
                                                            </div>
                                                            <a href="https://1af-class.pedigree.ru/" target="_blank" rel="noopener noreferrer"><button className="btn btn_red">Начать</button></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*<Slider {...settings}>
                                                <div className="banners_el pedigree">
                                                    <div className="flex">
                                                        <div className="flex_el">
                                                            <picture>
                                                                <source srcSet={img4xs} media="(max-width: 500px)"/>
                                                                <img src={img4} alt="" className="banners_img"/>
                                                            </picture>
                                                        </div>
                                                        <div className="flex_el">
                                                            <img src={img5} alt="" className="banners_img"/>
                                                            <div className="flex_el_text">
                                                                Получите подарок для щенка у ветеринарного врача
                                                            </div>
                                                            <a href="https://www.pedigree.ru/podarok-dlya-shenka" target="_blank" rel="noopener noreferrer"><button className="btn btn_red">Получить подарок</button></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="banners_el perfectfit">
                                                    <div className="flex">
                                                        <div className="flex_el"/>
                                                        <div className="flex_el">
                                                            <svg width="159" height="24" viewBox="0 0 159 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M13.6267 3.42279C12.1955 1.31024 10.079 0.725661 7.74467 0.600682C7.1238 0.572461 6.53519 0.572461 5.9103 0.572461H0V23.5525H4.7613V14.808H6.34571C8.67999 14.808 11.0465 14.808 12.7962 12.9857C13.4637 12.2841 13.9854 11.4572 14.3314 10.5528C14.6774 9.64836 14.8408 8.68434 14.812 7.71642C14.8648 6.19431 14.442 4.69358 13.6026 3.42279H13.6267ZM8.27683 10.4136C7.75959 10.5532 7.2233 10.609 6.68839 10.5788H4.78952V4.72499H6.78112C7.28279 4.7166 7.784 4.75983 8.27683 4.854C8.9622 5.05155 10.1757 5.73288 10.1757 7.67611C10.2132 8.2785 10.0461 8.87589 9.70139 9.37134C9.3567 9.86679 8.85469 10.2312 8.27683 10.4055" fill="#64636C"/>
                                                                <path d="M16.7714 0.564258V23.5241H29.2169V18.9362H21.5327V14.0257H29.0637V9.56678H21.5327V5.01916H29.2492V0.564258H16.7714Z" fill="#64636C"/>
                                                                <path d="M48.1371 0.564258V23.5241H52.9307V13.8967H60.8971V9.40955H52.9307V4.91837H60.8971V0.564258H48.1371Z" fill="#64636C"/>
                                                                <path d="M63.0097 0.564258V23.5241H75.4592V18.9362H67.771V14.0257H75.302V9.56678H67.771V5.01916H75.4914V0.564258H63.0097Z" fill="#64636C"/>
                                                                <path d="M95.6252 0.564258V5.05141H100.322V23.5241H105.083V4.9869H109.784V0.564258H95.6252Z" fill="#64636C"/>
                                                                <path d="M117.642 0.564258V23.5241H122.435L122.431 13.8967H130.402V9.40552L122.435 9.40955V4.91837H130.402V0.564258H117.642Z" fill="#64636C"/>
                                                                <path d="M137.59 0.560449H132.796V23.5203H137.59V0.560449Z" fill="#64636C"/>
                                                                <path d="M139.795 0.564258V5.04738H144.496V23.5241H149.253V4.98287H153.954V0.564258H139.795Z" fill="#64636C"/>
                                                                <path d="M89.4566 17.3358C87.3481 16.3198 86.7232 17.4688 86.2314 17.6019C85.7395 17.7349 85.0904 17.4487 84.655 17.6019C84.2196 17.7551 83.6068 17.5051 82.74 17.6019C81.8732 17.6986 81.7926 17.356 81.2241 17.2794C80.6557 17.2028 80.2243 17.1342 79.9945 16.9165C79.7647 16.6988 79.6155 15.828 79.164 15.1426C78.7125 14.4572 77.9545 13.5542 77.9827 12.7237C78.011 11.8932 81.4741 10.6555 82.6473 10.0185C84.1148 9.21216 83.635 8.16798 84.143 7.52696C84.651 6.88594 88.4165 5.00722 90.5169 5.10801C91.9522 5.17252 92.3513 5.46279 92.7262 5.64824C93.2081 5.97374 93.6274 6.38345 93.9639 6.85772V1.56022C93.4035 1.3022 92.8149 1.04015 92.2223 0.810348C90.696 0.291447 89.0971 0.0179327 87.4852 0C86.0652 0.00504943 84.6581 0.268718 83.3326 0.778095C81.9211 1.36934 80.6755 2.29663 79.7042 3.47925C79.0833 4.16428 78.5584 4.93059 78.144 5.7571C77.2542 7.72817 76.7991 9.86766 76.8095 12.0302C76.8095 17.235 79.3293 20.2909 80.7605 21.5609C83.377 23.9032 86.5781 24 87.6706 24C89.7126 23.9712 91.7249 23.5066 93.5729 22.6373C93.1697 21.8673 93.4761 20.7707 92.7665 19.7427C92.057 18.7146 90.3476 17.747 89.4647 17.3237" fill="#64636C"/>
                                                                <path d="M41.0134 13.7639C42.3801 13.4413 42.8196 13.2761 43.4324 12.8527C45.1821 11.6916 45.9602 9.44202 45.9602 7.3577C45.975 6.0102 45.6209 4.68432 44.9361 3.52366C43.5291 1.24582 41.2271 0.701555 38.8646 0.592702C38.276 0.560449 37.7156 0.560449 37.1229 0.560449H31.2409V23.5405H35.8812V13.8647L41.7028 23.5405H47.347L41.0134 13.7639ZM37.6188 10.6112H35.9094V4.56381H37.5221C38.6751 4.60009 39.8564 4.69685 40.6667 5.67249C41.0849 6.2111 41.3042 6.8776 41.2876 7.55927C41.2917 8.12226 41.153 8.67708 40.8844 9.17191C40.1668 10.4741 38.8928 10.5709 37.6148 10.6031" fill="#64636C"/>
                                                                <path d="M156.058 0.564258V0.826311H156.284V1.7052H156.595L156.591 0.826311H156.82V0.564258H156.058Z" fill="#64636C"/>
                                                                <path d="M157.082 0.560462L156.909 1.70543H157.232L157.284 1.30227C157.284 1.20552 157.284 1.12085 157.316 1.03216C157.316 1.09263 157.353 1.15714 157.373 1.22164L157.526 1.69334H157.715L157.873 1.22164C157.893 1.15714 157.913 1.09263 157.929 1.03216C157.929 1.11682 157.953 1.20148 157.961 1.28615L158.01 1.68931H158.328L158.155 0.544336H157.816L157.699 0.923305C157.699 0.967652 157.671 1.012 157.659 1.06038C157.647 1.10876 157.659 1.12488 157.627 1.16117L157.594 1.04425L157.554 0.919273L157.441 0.544336H157.095" fill="#64636C"/>
                                                            </svg>
                                                            <Link to="#"><button className="btn btn_dark-blue">Получить подарок</button></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Slider>*/}
                                        </div>
                                        }
                                    </div>
                                    :
                                    undefined
                                }
                            </div>
                        </div>
                        {/*{index === 2 ?
                            <div className="course">
                                <div className="content_head top">ПИТАНИЕ СОБАК: УЗНАЙТЕ БОЛЬШЕ</div>
                            </div>
                        :
                        undefined}*/}
                    </div>
                )
            })
        }
    }

    render() {
        const {showPopupExpert, popupExpertActive} = this.state;
        return (
            <div className="row">
                {/*<ProfileCard/>*/}
                {/*<CourseStat*/}
                {/*courses={this.courses}*/}
                {/*pointTotal={'100'}*/}
                {/*pointTotalTest={'200'}*/}
                {/*/>*/}
                {this._renderCourses()}
                {showPopupExpert &&
                <Popup
                    closePopup={this.closeEndPopap}
                >
                    {popupExpertActive === 5
                        ?
                        <div className="expert_popup waltham">
                            <img src={this.expertData[popupExpertActive].img} alt="" className="expert_popup_img"/>
                            <div className="expert_popup_head">
                                {this.expertData[popupExpertActive].head}
                            </div>
                            <div className="expert_popup_flex">
                                <TextBlock
                                    text={this.expertData[popupExpertActive].text1}
                                />
                                <img src={this.expertData[popupExpertActive].img2} alt="" className="expert_popup_flex_img"/>
                            </div>
                            <div className="expert_popup_text">
                                <TextBlock
                                    text={this.expertData[popupExpertActive].text2}
                                />
                            </div>
                            <a href={this.expertData[popupExpertActive].link} className="expert_popup_link">{this.expertData[popupExpertActive].link}</a>
                        </div>
                        :
                        <div className="expert_popup">
                            <img src={this.expertData[popupExpertActive].img} alt="" className="expert_popup_img"/>
                            <p className="expert_popup_fio">{this.expertData[popupExpertActive].fio}</p>
                            <p className="expert_popup_position">{this.expertData[popupExpertActive].position}</p>
                            <p className="expert_popup_description">{this.expertData[popupExpertActive].description}</p>
                        </div>
                    }
                </Popup>
                }

            </div>
        )
    }
}
