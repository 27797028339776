import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";


export default class step3 extends React.Component {
    state = {
    };

    render() {
        return (
            <div className="step4">
                <InfoBlock text={`Ознакомьтесь с третьим этапом видеокурса Азбуки трюков: повороты, а затем нажмите кнопку продолжить для проверки полученных знаний.`}/>
                <VideoBlock
                    src={'https://www.youtube.com/embed/cpAq4Bs13Rk'}
                    title={'https://www.youtube.com/watch?v=cpAq4Bs13Rk'}
                />
            </div>
        )
    }
}
