import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson5/step13-1.png'


export default class step6 extends React.Component {

    render() {
        return (
            <div className="step13">
                <TextBlock
                    text={`У каждого незаменимого питательного вещества есть свой минимум и максимум потребления. Эти значения определялись путем длительных исследований, которые продолжаются до сих пор.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <div className="info">
                    Избыток питательных веществ так же вредит здоровью питомца, как их дефицит. Поэтому так важно не перекармливать животное и грамотно составлять его дневной рацион.
                </div>
            </div>
        )
    }
}