import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson4/step13-1.png'
import img2 from '../../../image/lessons/lesson4/step13-2.png'
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";


export default class step6 extends React.Component {
    state = {
        pointActive: 0,
        points: [],
    };

    changePoint = pointActive => {
        this.setState({pointActive}, () => {
            this.checkPoint(pointActive)
        })
    }

    checkPoint = (point) => {
        const {points} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        console.log(points)
        if (points.length === 5) {
            this.props.showNextBtn()
        }
    }

    popapWrp = {
        1: {
            head: 'Для чего выводились?',
            text: `Предки этих собак выводились для собачьих боев и травли, они необычайно сильные физически и выносливые. Им необходима социализация с самого раннего возраста.<br>В настоящее время данную группу пород принято относить к собакам компаньонам, агрессия у них считается пороком.`
        },
        2: {
            head: 'Характер',
            text: `При правильном воспитании из них получаются выдающиеся рабочие и спортивные собаки, а также прекрасные компаньоны, но при неправильном воспитании могут быть очень агрессивны к животным.
                    
                    Этих собак не рекомендуется заводить начинающим собаководам, они требуют опыта, много внимания, больших физических нагрузок.`
        },
        3: {
            head: 'Особенности внешности',
            text: `Среднего размера, гладкошерстные, с очень сильным характером. Долгожители, сохраняющие активность до преклонного возраста.`
        },
        4: {
            head: 'Типичные представители',
            text: `Известные представители – бультерьер, американский стаффордширский терьер и стаффордширский бультерьер.`
        },
    }


    render() {
        const {pointActive, points} = this.state;
        return (
            <div className="step11">
                <TextBlock
                    text={`<span class="text_subheader">Терьеры в типе бульдога (секция 3)</span> `}
                />
                <InfoBlock
                    text={`Ознакомьтесь с каждой группой, нажав на ключевые элементы.`}
                />
                <div className="flex">
                    <img src={img1} alt="" className='mainImg'/>
                    <div className="flex_el"><span className={points.indexOf(1) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(1)}}>Для чего выводились?</span></div>
                    <div className="flex_el"><span className={points.indexOf(2) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(2)}}>Характер</span></div>
                    <div className="flex_el"><span className={points.indexOf(3) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(3)}}>Особенности внешности</span></div>
                    <div className="flex_el"><span className={points.indexOf(4) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(4)}}>Типичные представители</span></div>
                </div>

                {pointActive !== 0 &&
                <PopupInStep
                    closePopup={() => this.changePoint(0)}
                    popupImg={img2}
                    popupHead={this.popapWrp[pointActive].head}
                    popupText={this.popapWrp[pointActive].text}
                    changePoint={() => this.changePoint(pointActive < 4 ? pointActive + 1 : 0)}
                />
                }
            </div>
        )
    }
}
