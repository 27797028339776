import React from 'react'
// import YouTube from 'react-youtube';
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";

export default class step1 extends React.Component {

    /*onStateChange = () => {
        console.log(111, 'clicked')
    }*/

    render() {
        /*const opts = {
            height: '315',
            width: '560',
            playerVars: {
                autoplay: 0,
                src: "https://www.youtube.com/embed/DWN-BuiI-w0",
                frameBorder: '0',
                allow: 'accelerometer',
            },
        }*/
        return (
            <div className="step1">
                <InfoBlock text={`Ознакомьтесь с видеокурсом “Основы дрессировки”`}/>
                {/*<div className="video">
                    <iframe title={'https://www.youtube.com/watch?v=DWN-BuiI-w0&feature=emb_logo'} width="560" height="315" src="https://www.youtube.com/embed/DWN-BuiI-w0" frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                </div>*/}
                {/*<div className="video">
                    <YouTube videoId="DWN-BuiI-w0" opts={opts} onStateChange={this.onStateChange} />
                </div>*/}
                <VideoBlock
                    src={'https://www.youtube.com/embed/v0ezqJeTLGw'}
                    title={'https://www.youtube.com/watch?v=v0ezqJeTLGw'}
                />
            </div>
        )
    }
}
