import React from 'react';
import './style.sass'
import Pedigree from '../../image/LeftSideBar/Pedigree.png'
import PerfectFit from '../../image/LeftSideBar/PerfectFit.png'
import toRKF from '../../image/LeftSideBar/to-rkf.png'


export default function Partners(props) {
    return (
        <div className={"partners " + props.className}>
            <div className="partners_text">
                Партнёр проекта <br style={(props.brStyle)}/>
                MARS Petcare
            </div>
            <div className={"partners__box"}>
                <a href="https://www.pedigree.ru/" target="_blank" rel="noopener noreferrer"><img src={Pedigree} alt=""/></a>
                <a href="https://www.perfectfit.ru/" target="_blank" rel="noopener noreferrer"><img src={PerfectFit} alt=""/></a>
                <a href="https://rkf.academy/" target="_blank" rel="noopener noreferrer"><img src={toRKF} alt=""/></a>
            </div>
        </div>
    );
}
