import React from 'react'
import img1 from "../../../image/lessons/lesson6/step13-1.png";


export default class step13 extends React.Component {
    state = {
    };

    render() {
        return (
            <div className="step13 step37">
                <div className="imgDiv">
                    <div className="ul">
                        <div className="li">
                            <div className="number">1</div>
                            <div className="text">
                                В России количество собак,
                                имеющих лишний вес, <b>больше
                                в крупных городах,</b> чем в небольших
                                населённых пунктах.
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">2</div>
                            <div className="text">
                                <b>Ожирение</b> у собак
                                ведет к серьезным проблемам
                                со здоровьем.
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">3</div>
                            <div className="text">
                                <b>Контроль калорийности</b> рациона
                                и <b>регулярная физическая
                                активность</b> — важнейшие аспекты
                                поддержания нормального веса
                                питомца.
                            </div>
                        </div>
                    </div>
                    <div className="blockImg">
                        <img src={img1} alt="" className='mainImg'/>
                    </div>
                </div>
            </div>
        )
    }
}