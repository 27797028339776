import React from 'react'
import './style.sass'

export default class Popup extends React.Component {

    componentDidMount() {
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
    }

    componentWillUnmount() {
        document.body.style.overflow = "";
    }

    stopClosePopup = (e) => {
        e.stopPropagation()
    }

    render() {
        return (
            <div className="Popup" onClick={() => {this.props.closePopup()}}>
                <div className="Popup_block" onClick={(e) => {this.stopClosePopup(e)}}>
                    <div
                        className="Popup_closed"
                        onClick={() => {this.props.closePopup()}}
                    />
                    {this.props.children}
                </div>
            </div>
        )
    }
}
