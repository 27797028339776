import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'


export default class step2 extends React.Component {
    state = {
    };

    render() {
        return (
            <div className="step2">
                <TextBlock
                    text={`В конце 2018 года вступил в силу Федеральный закон <b>"Об ответственном обращении с животными и о внесении изменений в отдельные законодательные акты Российской Федерации" от 27.12.2018 N 498-ФЗ,</b> поэтому требования к владельцам домашних собак, сформулированные в Законе, являются обязательными к выполнению владельцами собак на всей территории Российской Федерации.`}
                />
                <InfoBlock
                    text={`Ознакомьтесь с основными правилами и требованиями содержания собаки, и нажмите кнопку “Продолжить”`}
                />
                <TextBlock
                    text={`Далее вы ознакомитесь с:`}
                />
                <ul>
                    <li>Требованиями к содержанию</li>
                    <li>Требованиями к выгулу</li>
                    <li>Правилами перевозки собак</li>
                </ul>

            </div>
        )
    }
}