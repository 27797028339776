import React from 'react'

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step1">
                <div className="head">план урока</div>

                <div className="text">
                    Существует огромный мир спорта с собаками, десятки дрессировочных нормативов, для обученных собак организуются соревнования и чемпионаты вплоть до международного уровня.
                    <br/>
                    <br/>
                    Спорт с дрессированной собакой может быть как активным досугом, приятным и полезным для обеих участвующих сторон, так и тернистой и непростой дорогой к высоким достижениям.
                    По тем видам спорта с собаками, которые включены в Всероссийский реестр видов спорта, возможно получение спортивных разрядов и званий.


                </div>

                <div className="lessonSteps">
                    <div className="lessonSteps_el">
                        Как обучать молодых и взрослых собак?
                    </div>
                </div>

            </div>
        )
    }
}
