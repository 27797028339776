import React from 'react'

import img1 from '../../../image/lessons/lesson5/step9-1.png'


export default class step6 extends React.Component {


    render() {
        return (
            <div className="step9">
                <div className="flex">
                    <div className="ul">
                        <div className="li">
                            <div className="number">1</div>
                            <div className="text">
                                Собаки — <b>факультативные плотоядные</b>.
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">2</div>
                            <div className="text">
                                Так как предки собак охотились стаями и съедали <b>за раз большое количество пищи</b>, такое же пищевое поведение является характерным
                                и для домашних собак.
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">3</div>
                            <div className="text">
                                Оптимальный режим питания для собак — <b>1-2 раза в день</b>.
                            </div>
                        </div>
                    </div>
                    <img src={img1} alt=""/>
                </div>
            </div>
        )
    }
}
