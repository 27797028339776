import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson33/step25-1.jpg'
import List from "../../../Components/LessonAndTestComponents/List";


export default class step5 extends React.Component {

    ListData = [
        `почечная недостаточность;`,
        `артрит;`,
        `болезнь сердца;`,
        `появление лишнего веса;`,
        `проблемы с зубами.`
    ]


    render() {
        return (
            <div className="step25">
                <TextBlock
                    text={`С возрастом собаке требуется дополнительный уход.
                    
                    Она считается пожилой, когда достигает третьей части своей жизни. Продолжительность жизни больших собак меньше, чем маленьких, поэтому они стареют раньше. У пожилых собак появляются проблемы со здоровьем. <span class="text_dark-blue">Самые распространенные заболевания собак, связанные с пожилым возрастом:</span>`}
                />
                <div className="flex">
                    <List ListData={this.ListData}/>
                    <img className="img" src={img1} alt=""/>
                </div>
                <div className="attention">
                    <p className="attention_text">Даже здоровые пожилые собаки требуют особого внимания и заботы, а также подхода к кормлению.</p>
                </div>
            </div>
        )
    }
}
