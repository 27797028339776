import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from '../../../image/lessons/lesson5/step26-1.png'


export default class step5 extends React.Component {


    render() {
        return (
            <div className="step26">
                <TextBlock
                    text={`Рекомендуемый рацион должен тщательно рассчитываться, но, как следует из его названия, он — всего лишь рекомендация относительно количества корма, которая может потребовать коррекции применительно к индивидуальной потребности животного в энергии.`}
                />
                <InfoBlock
                    text={`На этих таблицах показан пример стандартно рекомендуемой схемы кормления для собак и кошек сухим и влажным кормом.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}