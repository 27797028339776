import React from 'react'
import './style.sass'
import photo_user from '../../image/LeftSideBar/photo_user.png'
// import {Link} from "react-router-dom";
import Inputs from "../Inputs";
import Checkbox from "../Checkbox";
import Api from '../../Service/Api'
import ImageUploader from "react-images-upload";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default class ProfileCard extends React.Component {

    state = {
        user: {},
        status: {1: 'Заводчик', 2: 'Владелец собаки', 3: 'Будущий владелец собаки', 4: 'Дрессировщик', 5: 'Хендлер', 6: 'Другое'},
        status_id: '',
        type: '',
        statusError: false,
        option_status_show: false,
        email: '',
        emailError: false,
        emailErrorText: false,
        login: '',
        loginError: false,
        password: '',
        passwordError: false,
        passwordErrorText: false,
        city: '',
        cityError: false,
        hasDog: false,
        breed: [],
        breedError: [],
        club: '',
        clubError: false,
        agree: false,
        agreeError: false,
        surname: '',
        name: '',
        // patronymic: '',
        phone: '',
        quantity: '',
        quantityError: false,
        nursery: '',
        nurseryError: false,
        federation: '',
        federationError: false,
        pictures: false,
        passed: false,
    }

    Api = new Api();

    componentDidMount() {
        const {status} = this.state;
        this.Api.getUserData()
            .then((res) => {
                this.setState({
                    user: res,
                    city: res.user.city === null ? '' : res.user.city,
                    club: res.user.club === null ? '' : res.user.club,
                    quantity: res.user.countDog === null ? '' : res.user.countDog,
                    email: res.email,
                    federation: res.user.federation === null ? '' : res.user.federation,
                    nursery: res.user.nursery === null ? '' : res.user.nursery,
                    surname: res.user.surname === null ? '' : res.user.surname,
                    name: res.user.name === null ? '' : res.user.name,
                    phone: res.user.phone === null ? '' : res.user.phone.slice(1),
                    status_id: res.user.type,
                    type: status[res.user.type],
                    breed: res.user.breeds !== undefined ? res.user.breeds : [],
                    hasDog: !!(res.user.breeds !== undefined && res.user.breeds.length),
                    passed: res.user.passed
                })
        })


    }

    onDrop = (pictureFiles, pictureDataURLs) => {
        const extension = pictureFiles[0].name.slice(-3)

        this.setState({
            pictures: pictureDataURLs
        }, () => {
            this.Api.setUserPhoto(pictureDataURLs, extension)
                .then((res) => {
                    console.log(res)
                })
        });
    }

    optionStatusShow = () => {
        this.setState({option_status_show: !this.state.option_status_show})
    }

    setStatusId = status_id => {
        this.setState({
            status_id,
            option_status_show: false,
            clubError: false,
            type: this.state.status[status_id],
            hasDog: false,
        })
    }

    changeHasDog = (text) => {
        if (text === 'Да') {
            this.setState({
                hasDog: true
            })
        } else {
            this.setState({
                hasDog: false
            })
        }
    }

    updateState = (type, value, index) => {
        // eslint-disable-next-line
        switch (type) {
            case 'email' : {
                this.setState({
                    email: value,
                    emailError: false,
                    emailErrorText: false
                });
                break;
            }
            case 'login' : {
                this.setState({
                    login: value,
                    loginError: false
                })
                break;
            }
            case 'password' : {
                this.setState({
                    password: value,
                    passwordError: false,
                    passwordErrorText: false
                })
                break;
            }
            case 'city' : {
                this.setState({
                    city: value,
                    cityError: false
                })
                break;
            }
            case 'breed' : {
                const {breed, breedError} = this.state
                if (!breed.length) {
                    breed.push(value)
                    breedError[0] = false
                } else {
                    breed[index] = value
                }
                if (breedError[0]) {
                    breedError[0] = false
                }
                this.setState({breed: breed, breedError: breedError})
                break;
            }
            case 'surname' : {
                this.setState({
                    surname: value,
                })
                break;
            }
            case 'name' : {
                this.setState({
                    name: value,
                })
                break;
            }
            case 'patronymic' : {
                this.setState({
                    patronymic: value,
                })
                break;
            }
            case 'phone' : {
                this.setState({
                    phone: value,
                })
                break;
            }
            case 'quantity' : {
                this.setState({
                    quantity: value,
                    quantityError: false
                })
                break;
            }
            case 'nursery' : {
                this.setState({
                    nursery: value,
                    nurseryError: false
                })
                break;
            }
            case 'federation' : {
                this.setState({
                    federation: value,
                    federationError: false
                })
                break;
            }
            case 'status' : {
                this.setState({
                    type: value,
                    statusError: false
                })
                break;
            }
            case 'club' : {
                this.setState({
                    club: value,
                    clubError: false
                })
                break;
            }
        }
    }

    sendDate = () => {
        const {
            email,
            city,
            breed,
            // club_id,
            club,
            // agree,
            hasDog,
            type,
            status_id,
            surname,
            name,
            // patronymic,
            phone,
            quantity,
            nursery,
            federation,
        } = this.state

        if (!this.valid_email(email)) {
            this.setState({emailError: true})
        } else if (!city.length) {
            this.setState({cityError: true})
        } else if (type === 'Владелец собаки' && !Number.isInteger(quantity) && !quantity.length) {
            this.setState({quantityError: true})
        } else if (type === 'Владелец собаки' && !this.valid_breed()) {

        } else if (type === 'Заводчик' && !nursery.length) {
            this.setState({nurseryError: true})
        } else if (type === 'Заводчик' && !club.length) {
            this.setState({clubError: true})
        } else if (type === 'Заводчик' && !federation.length) {
            this.setState({federationError: true})
        } else if (type === 'Заводчик' && !this.valid_breed()) {

        } else if (type === 'Дрессировщик' && hasDog && !Number.isInteger(quantity) && !quantity.length) {
            this.setState({quantityError: true})
        } else if (type === 'Дрессировщик' && hasDog && !this.valid_breed()) {

        } else if (type === 'Хендлер' && hasDog && !Number.isInteger(quantity) && !quantity.length) {
            this.setState({quantityError: true})
        } else if (type === 'Хендлер' && hasDog && !this.valid_breed()) {

        } else {
            this.Api.updateUserData(email, city, club, breed, surname, name, phone, status_id, quantity, nursery, federation)
                .then((res) => {
                    if (res.status === 200) {

                    }else{
                        Object.keys(res.error).map(key => {
                            // eslint-disable-next-line
                            this.state[key + 'Error'] = true;
                            // eslint-disable-next-line
                            this.state[key + 'ErrorText'] = res.error[key];
                            this.setState({errors: new Date()})
                            return false
                        })
                    }
                })
        }
    }

    valid_email = (el) => {
        let reg = /^[a-z-._0-9]+@[a-z-_0-9]+\.[a-z0-9]{2,10}$/i;
        return reg.test(el)
    }

    valid_breed = () => {
        let {breed, breedError} = this.state
        if (!breed.length) {
            breedError = [true]
        }

        if (breed[0].length) {
            breedError[0] = false
        } else {
            breedError[0] = true
        }
        // eslint-disable-next-line
        /*breed.map((item, index) => {
            if (breed[index].length) {
                breedError[index] = false
            } else {
                breedError[index] = true
            }
        })*/
        this.setState({breedError: breedError})
        if (breedError.indexOf(true) === - 1) {
            return true
        }
        return false
    }

    addBreed = () => {
        const {breed} = this.state
        if (!breed.length) {
            breed.push('', '')
        } else {
            breed.push('')
        }
        this.setState({breed: breed})
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    render() {
        const {
            user,
            status,
            status_id,
            type,
            statusError,
            email,
            emailError,
            emailErrorText,
            city,
            cityError,
            hasDog,
            breed,
            breedError,
            club,
            clubError,
            option_status_show,
            nursery,
            nurseryError,
            federation,
            federationError,
            quantity,
            quantityError,
            phone,
            surname,
            name,
            pictures,
            // patronymic
            passed,

        } = this.state

        const urlPdf = "/certificate"

        return (
            <div className="ProfileCard">
                <div className="ProfileCard_data">
                    <p className="ProfileCard_head">Личные данные</p>
                    {/*<div
                    className="edit"
                    onClick={this.changeShowEditProfileModal}
                >
                    редактировать
                    <div className="editSvg">
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <g clipPath="url(#clip0)">
                            <path d="M0 13.9999L4.83363 13.0439L0.951699 9.14453L0 13.9999Z" fill="#fff"/>
                            <path d="M1.90344 8.45301L5.61006 12.1385L11.5457 6.12596L7.88913 2.44043L1.90344 8.45301Z" fill="#fff"/>
                            <path d="M9.45442 1.44654C9.42937 1.81132 9.52955 2.12579 9.78 2.3522L11.6333 4.21384C11.8963 4.46541 12.2093 4.57862 12.585 4.56604C12.9482 4.55346 13.2737 4.41509 13.5617 4.13836C13.8372 3.87421 13.9875 3.54717 14 3.15723C14.0126 2.7673 13.8873 2.45283 13.6244 2.20126L11.7711 0.352201C11.5331 0.113208 11.2451 0 10.907 0C10.5063 0 10.1682 0.150943 9.86765 0.440252C9.61721 0.72956 9.47946 1.0566 9.45442 1.44654Z" fill="#fff"/>
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect width="14" height="14" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    </div>
                </div>*/}
                    <img src={pictures ? pictures : (('/uploads' + user.photo) || photo_user)} alt="" className="ProfileCard_img"/>
                    <ImageUploader
                        withIcon={false}
                        withPreview={false}
                        buttonText="Обновить фотографию"
                        withLabel={false}
                        onChange={this.onDrop}
                        imgExtension={[".jpg", ".png"]}
                        maxFileSize={5242880}
                    />
                    <div className="wrp">
                        <form action="" className="form">
                            <Inputs
                                name={'email'}
                                placeholder={'E-mail'}
                                value={email}
                                onChange={this.updateState}
                                type={'email'}
                                className={emailError && 'error'}
                                errorText={emailErrorText}
                                disabled={'disabled'}
                            />
                            <div className="flex">
                                <Inputs
                                    name={'surname'}
                                    placeholder={'Фамилия'}
                                    type={'text'}
                                    value={surname}
                                    onChange={this.updateState}
                                />
                                <Inputs
                                    name={'name'}
                                    placeholder={'Имя'}
                                    type={'text'}
                                    value={name}
                                    onChange={this.updateState}
                                />
                                {/*<Inputs
                                name={'patronymic'}
                                placeholder={'Отчество'}
                                type={'text'}
                                value={patronymic}
                                onChange={this.updateState}
                            />*/}
                            </div>
                            <div className="flex">
                                <Inputs
                                    name={'city'}
                                    placeholder={'Город'}
                                    value={city}
                                    onChange={this.updateState}
                                    type={'text'}
                                    className={cityError && 'error'}
                                    required={'required'}
                                />
                                <Inputs
                                    name={'phone'}
                                    placeholder={'Телефон'}
                                    value={phone}
                                    type={'tel'}
                                    onChange={this.updateState}
                                    mask={'+7 (999) 999-99-99'}
                                />
                            </div>
                            <div>
                                <Inputs
                                    name={'status'}
                                    placeholder={'Укажите Ваш статус'}
                                    type={'text'}
                                    onChange={this.updateState}
                                    onClick={this.optionStatusShow}
                                    className={statusError && 'error'}
                                    value={status_id && status[status_id]}
                                    options={status}
                                    option_active={status_id}
                                    option_show={option_status_show}
                                    option_click={this.setStatusId}
                                />
                                {type === 'Заводчик' &&
                                <Inputs
                                    name={'nursery'}
                                    placeholder={'Питомник / заводская приставка'}
                                    value={nursery}
                                    type={'text'}
                                    onChange={this.updateState}
                                    className={nurseryError && 'error'}
                                    required={'required'}
                                />
                                }
                                {type === 'Заводчик' &&
                                <Inputs
                                    name={'club'}
                                    placeholder={'Клуб'}
                                    value={club}
                                    type={'text'}
                                    onChange={this.updateState}
                                    className={clubError && 'error'}
                                    required={'required'}
                                />
                                }
                                {type === 'Заводчик' &&
                                <Inputs
                                    name={'federation'}
                                    placeholder={'Федерация'}
                                    value={federation}
                                    type={'text'}
                                    onChange={this.updateState}
                                    className={federationError && 'error'}
                                    required={'required'}
                                />
                                }
                                {type === 'Дрессировщик' || type === 'Хендлер' ?
                                    <div className="checkboxs">
                                        <div className="checkboxsLabel">
                                            У вас есть собака?
                                        </div>
                                        <div className={'switch ' + (hasDog ? 'switch_right' : '')}>
                                            {hasDog &&
                                            <span className="switch_yes">Да</span>
                                            }
                                            {!hasDog &&
                                            <span className="switch_no">Нет</span>
                                            }

                                            <Checkbox
                                                active={hasDog}
                                                text={'Да'}
                                                onClick={this.changeHasDog}
                                            />
                                            <Checkbox
                                                active={!hasDog}
                                                text={'Нет'}
                                                onClick={this.changeHasDog}
                                            />
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                {type === 'Владелец собаки' || hasDog === true ?
                                    <Inputs
                                        name={'quantity'}
                                        placeholder={'Сколько у Вас собак?'}
                                        value={quantity}
                                        type={'number'}
                                        onChange={this.updateState}
                                        className={quantityError && 'error'}
                                        required={'required'}
                                    />
                                    :
                                    null
                                }
                                {type === 'Владелец собаки' || type === 'Заводчик' || hasDog === true ?
                                    <div className="inputsPlusList">
                                        {breed !== undefined && breed.length ?
                                            breed.map((item, index) => {
                                                return (
                                                    <div className="inputsPlus" key={index}>
                                                        <Inputs
                                                            name={'breed'}
                                                            placeholder={index === 0 ? 'Порода собаки (или породы, если их несколько)' : 'Порода ' + (index + 1)}
                                                            value={breed[index]}
                                                            type={'text'}
                                                            onChange={this.updateState}
                                                            index={index}
                                                            className={(breedError[0] && index === 0 && ' error ')}
                                                            required={index === 0 ? 'required' : ''}
                                                        />
                                                        <div className="btn btn_blue btnPlus" onClick={this.addBreed}>+ порода</div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className="inputsPlus">
                                                <Inputs
                                                    name={'breed'}
                                                    placeholder={'Порода собаки (или породы, если их несколько)'}
                                                    type={'text'}
                                                    onChange={this.updateState}
                                                    className={(breedError[0] && ' error ')}
                                                    required={'required'}
                                                />
                                                <div className="btn btn_blue btnPlus" onClick={this.addBreed}>+ порода</div>
                                            </div>
                                        }

                                    </div>
                                    :
                                    null
                                }
                            </div>
                            <div className="buttons">
                                <button
                                    type='button'
                                    className="btn btn_blue"
                                    onClick={() => {
                                        this.sendDate()
                                    }}
                                >
                                    сохранить изменения
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="ProfileCard_certificates">
                    <div className="ProfileCard_certificates_head">
                        Ваши сертификаты:
                    </div>
                    {/*<img src={img1} alt="" className="ProfileCard_certificates_img"/>*/}
                    {
                        this.state.user.passed &&
                        <Document
                            className = "ProfileCard_certificates_pdf"
                            file={{ url: `${urlPdf}` }}
                            error = 'Не удалось загрузить сертификат'
                            loading = 'Загрузка сертификата...'
                        >
                            <Page pageNumber={1} />
                        </Document>
                    }
                </div>

            </div>
        )
    }
}
