import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson9/step4-1.png'
import img2 from '../../../image/lessons/lesson9/step3-2.png'
import img3 from '../../../image/lessons/lesson9/step3-3.png'
import img4_1popap from '../../../image/lessons/lesson9/step4-1popap.png';
import img4_2popap from '../../../image/lessons/lesson9/step4-2popap.png';
import img4_3popap from '../../../image/lessons/lesson9/step4-3popap.png';
import img4_4popap from '../../../image/lessons/lesson9/step4-4popap.png';
import img4_5popap from '../../../image/lessons/lesson9/step4-5popap.png';


export default class step4 extends React.Component {
    state = {
        pointActive: 0,
        points: [],
        pointsPassed: [0],
    };

    changePoint = pointActive => {
        const {pointsPassed} = this.state
        pointsPassed.push(pointActive)
        this.setState({pointActive}, () => {
            this.checkPoint(pointActive)
        })
    }

    checkPoint = (point) => {
        const {points} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        if (points.length === 6) {
            this.props.showNextBtn()
        }
    }

    popapWrp = {
        0: {img: '', head: '', text: ''},
        1: {
            img: img4_1popap,
            head: `Выгул`,
            text: `Выгул домашних животных должен осуществляться при условии обязательного обеспечения безопасности граждан, животных, сохранности имущества физических лиц и юридических лиц.`
        },
        2: {
            img: img4_2popap,
            head: `Уборка`,
            text: `При выгуле домашнего животного необходимо обеспечивать уборку продуктов жизнедеятельности животного в местах и на территориях общего пользования`
        },
        3: {
            img: img4_3popap,
            head: `Неконтролируемое перемещение собак`,
            text: `При выгуле домашнего животного необходимо исключать возможность свободного, неконтролируемого передвижения животного при пересечении проезжей части автомобильной дороги, в лифтах и помещениях общего пользования многоквартирных домов, во дворах таких домов, на детских и спортивных площадках.`
        },
        4: {
            img: img4_4popap,
            head: `Разрешение на выгул`,
            text: `При выгуле домашнего животного необходимо не допускать выгул животного вне мест, разрешенных решением органа местного самоуправления для выгула животных.`
        },
        5: {
            img: img4_5popap,
            head: `Осторожно, собака!`,
            text: `Выгул потенциально опасной собаки без намордника и поводка независимо от места выгула запрещается, за исключением случаев, если потенциально опасная собака находится на огороженной территории, принадлежащей владельцу потенциально опасной собаки на праве собственности или ином законном основании. О наличии этой собаки должна быть сделана предупреждающая надпись при входе на данную территорию.`
        },
        6: {
            img: img4_5popap,
            head: `Список потенциально опасных пород`,
            text: `<b>Акбаш</b>
                   <b>Американский бандог</b>
                   <b>Амбульдог</b>
                   <b>Бразильский бульдог</b>
                   <b>Булли кутта</b>
                   <b>Бульдог алапахский чистокровный (отто)</b>
                   <b>Бэндог</b>
                   <b>Волко-собачьи гибриды</b>
                   <b>Волкособ (гибрид волка)</b>
                   <b>Гуль донг</b>
                   <b>Питбульмастиф</b>
                   <b>Северокавказская собака, а также метисы этих пород.</b>
                   
                   <i>Это собаки, обладающие генетически детерминированными 
                   качествами агрессии и силы и представляющие потенциальную 
                   опасность для жизни и здоровья людей, собаки, используемые для 
                   травли, и собаки аборигенных пород, в которых не велась селекция на 
                   лояльность к человеку, и особенности их поведения не до конца 
                   изучены.</i>`
        }
    }

    points = [1,2,3,4,5,6]

    render() {
        const {pointActive, pointsPassed} = this.state;
        return (
            <div className="step4">
                <InfoBlock
                    text={`Ознакомьтесь с требованиями к выгулу собак, нажав на линию внизу.`}
                />

                <div className="imgDiv">
                    <img className="mainImg" src={img1} alt=""/>
                </div>
                <div className='scaleBlock'>
                    {this.points &&
                    <div className="pointsList">
                        {this.points.map(item => {
                            return (
                                <div
                                    key={item}
                                    className={'scalePoint ' + (pointsPassed.indexOf(item) >= 0 && 'active')/*(pointActive === item && 'active')*/}
                                >
                                    <span className="pointListNumber">{item}</span>
                                </div>
                            )})}
                        <img className={`scaleArrow scaleArrow--${pointActive}`} src={img3} alt=""/>
                    </div>
                    }
                    <img className="scaleImg" src={img2} alt="" onClick={() => {this.changePoint(1)}}/>
                </div>

                {pointActive !== 0 &&
                <div className={'infoPopap'}>
                    <div className="infoPopap_wrp">
                        <div className="popap_close" onClick={() => {
                            this.changePoint(0)
                        }}/>
                        <div className="image">
                            <div className="imageBg">
                                <img src={this.popapWrp[pointActive].img} alt=""/>
                            </div>
                        </div>
                        <div className="head"
                             dangerouslySetInnerHTML={{__html: this.popapWrp[pointActive].head}}/>
                        <TextBlock
                            text={this.popapWrp[pointActive].text}
                            fontSize={16}
                        />
                        <button className="btn btn_blue" onClick={() => {
                            this.changePoint(pointActive < 6 ? pointActive + 1 : 0)
                        }}>Продолжить</button>
                    </div>
                </div>
                }


            </div>



        )
    }
}
