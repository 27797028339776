import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson36/step9-1.png'

export default class step9 extends React.Component {


    render() {
        return (
            <div className="step9">
                <TextBlock
                    text={`Как вы знаете, существует три типа кормов в зависимости от состяния здоровья питомца.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Для здоровых животных
                        </div>
                        <div className="flex_el_wrp">
                            <p className="flex_el_text">
                                Обеспечивают организм здорового животного <b>всеми питательными веществами</b> и поддерживают здоровье.
                            </p>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Профилактические корма
                        </div>
                        <div className="flex_el_wrp">
                            <p className="flex_el_text">
                                <b>Cнижают риск</b> возникновения проблем со здоровьем, к которым <b>предрасположено</b> животное, например, существуют специальные корма для собак с чувствительным пищеварением (не является лечебным) и др.
                            </p>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_head">
                            Лечебные корма
                        </div>
                        <div className="flex_el_wrp">
                            <ul>
                                <li>Предназначены для больных животных с <b>определенными диагнозами;</b></li>
                                <li>имеют <b>особый состав;</b></li>
                                <li>используются как <b>часть комплексного лечения</b> животных.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
