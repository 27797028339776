import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import img1 from '../../../image/lessons/lesson1/step4-1.png';
import img2 from '../../../image/lessons/lesson1/step4-2.png';


export default class step4 extends React.Component {
    state = {
        flexActive: 0,
        flexs: [1],
        flexHidden: false,
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs, flexHidden: true})
        if (flexs.length === 2) {
            this.props.showNextBtn()
        }
    }

    closeImgDiv = () => {this.setState({flexHidden: false, flexActive: 0})}


    render() {
        const {flexActive, flexHidden} = this.state
        return (
            <div className="step4">
                <InfoBlock
                    text={`Ознакомьтесь с каждой группой, нажав на ключевые элементы.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />

                <div className={'flex ' + (flexHidden === true && 'hidden')}>
                    <div className={'flex_el ' + (flexActive === 1 && 'active')} onClick={() => {
                        this.changeFlex(1)
                    }}>Заводчик
                    </div>
                    <div className={'flex_el ' + (flexActive === 2 && 'active')} onClick={() => {
                        this.changeFlex(2)
                    }}>Приют
                    </div>
                </div>
                {flexActive !== 0 &&
                <div className={'imgDiv ' + (flexActive === 2 ? 'imgDiv--2' : flexActive === 3 ? 'imgDiv--3': flexActive === 4 ? 'imgDiv--4' : '')}>
                    <div
                        className="imgDiv_closed"
                        onClick={() => {this.closeImgDiv()}}
                    />
                    {flexActive === 1 &&
                    <div className="imgDiv_wrap">
                        <div className="imgPhone">
                            <img className="imgPhoneBg" src={img1} alt=""/>
                        </div>
                        <div className="imgDiv_flex">
                            <TextBlock
                                text={`Это ответственный и разумный подход. Причем не просто «брать у заводчика», а еще и посмотрев на мать и отца. Посмотрите, в каких условиях живут собаки и щенки, если собаки породистые – какими документами (с выставок, дрессировок, сертификатами о здоровье) они располагают. Но сначала – собаки, потом — документы, жить вам предстоит не с бумагой — если собаки не понравились, документы можно и не смотреть.`}
                            />
                            <img src={img1} alt=""/>
                        </div>
                    </div>
                    }
                    {flexActive === 2 &&
                    <div className="imgDiv_wrap">
                        <div className="imgPhone">
                            <img className="imgPhoneBg" src={img2} alt=""/>
                        </div>
                        <div className="imgDiv_flex">
                            <TextBlock
                                text={`В приютах очень разные собаки: есть щенки, есть взрослые, есть беспородные, есть породистые. Сейчас некоторые приюты организуют специализированные мероприятия, выставки (в помещениях и под открытым небом), общая направленность — «подари собаке дом».`}
                            />
                            <img src={img2} alt=""/>
                        </div>
                        <TextBlock
                            text={`Преимущество таких мероприятий по сравнению с «свободным поиском» заключается в том, что ответственные организаторы обычно сами решают часть вопросов, которые могут возникнуть, так как туда допускаются только здоровые, стерилизованные, неагрессивные животные, с которыми волонтеры уже провели определенные воспитательные мероприятия. Это хорошая страховка от приобретения собаки с проблемами или «дичка», который вообще не умеет (и не очень хочет) жить с людьми.`}
                        />
                    </div>
                    }
                </div>
                }
            </div>
        )
    }
}
