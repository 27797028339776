import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson4/step12-1.png'
import img2 from '../../../image/lessons/lesson4/step12-2.png'
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";


export default class step6 extends React.Component {
    state = {
        pointActive: 0,
        points: [],
    };

    changePoint = pointActive => {
        this.setState({pointActive}, () => {
            this.checkPoint(pointActive)
        })
    }

    checkPoint = (point) => {
        const {points} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        console.log(points)
        if (points.length === 5) {
            this.props.showNextBtn()
        }
    }

    popapWrp = {
        1: {
            head: 'Для чего выводились?',
            text: `Мелкие терьеры выводились для охоты на небольшую добычу и грызунов, они участвовали в норной охоте и ценились как умелые крысоловы.  Бесстрашный характер и азарт охотника до сих пор сопровождает многих терьеров,  несмотря на их маленький размер. Сейчас эти породы всё чаще заводят в качестве компаньонов.`
        },
        2: {
            head: 'Характер',
            text: `Маленькие терьеры славятся независимым характером, живым темпераментом и бесстрашием. При правильном воспитании из них получаются отличные компаньоны и напарники для активного досуга. У многих терьеров выражен охотничий инстинкт, который они реализуют в погоне за кошками и другими животными. 
            
            При правильном воспитании отлично ладят с другими домашними животными, необходимо тщательно контролировать поведение собаки. Как правило, они не склонны к агрессии на человека, хорошо ладят с людьми, но могут быть упрямы.`
        },
        3: {
            head: 'Особенности внешности',
            text: `Собаки этой группы маленького размера, но при этом очень крепкие, и отнюдь не хрупкие.  Большинство мелких терьеров имеют жёсткую шерсть, которой необходим регулярный тримминг. У некоторых мелких терьеров шерсть гладкая или длинная.`
        },
        4: {
            head: 'Типичные представители',
            text: `Наиболее известные представители - джек-рассел-терьер (собака из кинофильма "Маска"), вест-хайленд-уайт-терьер, норвич-терьер.`
        },
    }


    render() {
        const {pointActive, points} = this.state;
        return (
            <div className="step12">
                <TextBlock
                    text={`<span class="text_subheader">Мелкие терьеры (секция 2)</span>`}
                />
                <InfoBlock
                    text={`Ознакомьтесь с каждой группой, нажав на ключевые элементы.`}
                />
                <div className="flex">
                    <img src={img1} alt="" className='mainImg'/>
                    <div className="flex_el"><span className={points.indexOf(1) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(1)}}>Для чего выводились?</span></div>
                    <div className="flex_el"><span className={points.indexOf(2) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(2)}}>Характер</span></div>
                    <div className="flex_el"><span className={points.indexOf(3) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(3)}}>Особенности внешности</span></div>
                    <div className="flex_el"><span className={points.indexOf(4) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(4)}}>Типичные представители</span></div>
                </div>

                {pointActive !== 0 &&
                <PopupInStep
                    closePopup={() => this.changePoint(0)}
                    popupImg={img2}
                    popupHead={this.popapWrp[pointActive].head}
                    popupText={this.popapWrp[pointActive].text}
                    changePoint={() => this.changePoint(pointActive < 4 ? pointActive + 1 : 0)}
                />
                }
            </div>
        )
    }
}
