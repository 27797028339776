import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson5/step40-1.png'
import img2 from '../../../image/lessons/lesson5/step40-2.png'


export default class step6 extends React.Component {


    render() {
        return (
            <div className="step40">
                <TextBlock text={`На этой диаграмме показана зависимость количества потребляемой воды от степени влажности корма.`}/>
                <div className="flex">
                    <img src={img1} alt="" className="left"/>
                    <div className="right">
                        <img src={img2} alt="" className='img'/>
                        <TextBlock text={`Можно сделать вывод, что чем меньше влаги содержится в корме, тем больше воды выпьет животное. Это объясняется тем, что питомец стремится восполнять недостающее количество воды, что способствует <b>поддержанию оптимального водного баланса</b>.`}/>
                    </div>
                </div>
            </div>
        )
    }
}