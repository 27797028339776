import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import img1 from '../../../image/lessons/lesson6/step4-1.svg';
import img2 from '../../../image/lessons/lesson6/step4-2.svg';
import img3 from '../../../image/lessons/lesson6/step4-3.svg';
import img4 from '../../../image/lessons/lesson6/step4-4.svg';
import img5 from '../../../image/lessons/lesson6/step4-5.svg';


export default class step4 extends React.Component {
    state = {
        flexActive: 1,
        flexs: [1],
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs})
        if (flexs.length === 5) {
            this.props.showNextBtn()
        }
    }

    textActive = {
        1: `<span class="text_dark-blue"><b>Полноценно питаются</b></span>`,
        2: `<span class="text_dark-blue"><b>Регулярно получают необходимую физическую нагрузку</b></span>`,
        3: `<span class="text_dark-blue"><b>Размещены в надлежащих условиях (не в тесноте, не на сквозняке и в сырости и т.п.)</b></span>`,
        4: `<span class="text_dark-blue"><b>Владельцы которых ответственно относятся к их здоровью и выполняют все профилактические мероприятия, направленные на предотвращение заболеваний.</b></span>`,
        5: `<span class="text_dark-blue"><b>Большое значение имеет своевременный и регулярный уход за шерстью - вычесывание и тримминг и/или стрижка для собак с соответствующими типами шерсти. Неухоженная шерсть мешает нормальной терморегуляции, создает условия для размножения паразитов и повышает риск заболеваний кожи.</b></span>`
    }


    render() {
        const {flexActive} = this.state
        return (
            <div className="step4">
                <TextBlock
                text={`Стабильный обмен веществ и крепкий иммунитет бывают только у тех собак, которые:`}
                 />
                <InfoBlock text={`Нажмите на активные элементы, чтобы узнать больше.`}/>

                <div className="flex">
                    <div className={'flex_el ' + (flexActive === 1 && 'active')} onClick={() => {
                        this.changeFlex(1)
                    }}>
                        <div className="flex_wrp">
                            <div className="flex_img">
                                <img src={img1} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className={'flex_el ' + (flexActive === 2 && 'active')} onClick={() => {
                        this.changeFlex(2)
                    }}>
                        <div class="flex_wrp">
                            <div className="flex_img">
                                <img src={img2} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className={'flex_el ' + (flexActive === 3 && 'active')} onClick={() => {
                        this.changeFlex(3)
                    }}>
                        <div className="flex_wrp">
                            <div className="flex_img">
                                <img src={img3} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className={'flex_el ' + (flexActive === 4 && 'active')} onClick={() => {
                        this.changeFlex(4)
                    }}>
                        <div className="flex_wrp">
                            <div className="flex_img">
                                <img src={img4} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className={'flex_el ' + (flexActive === 5 && 'active')} onClick={() => {
                        this.changeFlex(5)
                    }}>
                        <div className="flex_wrp">
                            <div className="flex_img">
                                <img src={img5} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
                {flexActive !== 0 &&
                <div className={'textDiv ' + (flexActive !== 1 ? `textDiv--${flexActive}` : '')}>
                    {<TextBlock
                        text={this.textActive[flexActive]}
                    />
                    }
                </div>
                }
            </div>
        )
    }
}
