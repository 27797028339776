import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson4/step16-1.png'
import img2 from '../../../image/lessons/lesson4/step16-2.png'
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";


export default class step6 extends React.Component {
    state = {
        pointActive: 0,
        points: [],
    };

    changePoint = pointActive => {
        this.setState({pointActive}, () => {
            this.checkPoint(pointActive)
        })
    }

    checkPoint = (point) => {
        const {points} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        console.log(points)
        if (points.length === 5) {
            this.props.showNextBtn()
        }
    }

    popapWrp = {
        1: {
            head: 'Для чего выводились?',
            text: ` Удивительная физическая выносливость, неприхотливость, дружелюбный к людям нрав сделали ездовые породы собак настоящими помощниками людей в северных краях. Оба полюса Земли были покорены именно на собачьих упряжках.  Основное предназначение этих собак - работа в упряжке, перевозка грузов и нарт на большие расстояния. Сейчас племенное разведение идёт несколькими путями: разведение собак в качестве компаньонов,  для выставок и шоу, а также рабочее/спортивное разведение для участия в соревнованиях по ездовому спорту.`
        },
        2: {
            head: 'Характер',
                text: `Это очень активные собаки, требующие не просто продолжительных прогулок, но и настоящих физических нагрузок.  Ездовые собаки из этой группы сейчас необычайно популярны из-за своей внешности, но большинство неопытных людей не до конца понимают специфичность этих собак и всю сложность содержания их в городе в качестве домашних любимцев.  Самый удачный вариант, когда их заводят  именно как партнеров для активного образа жизни и участия в ездовых видах спорта. Хаски  склонны к шуму и вою в отсутствии людей, а также к побегам, бывают излишне самостоятельны и не отличаются высокими способностями к дрессировке.  В норме они не проявляют агрессию ни к людям, ни к животным, и не могут быть использованы в качестве охранников.`
        },
        3: {
            head: 'Особенности внешности',
            text: `Ездовые собаки имеют средний и крупный размер, физически крепкие, выносливые.  Шерсть средней длины, густая, с подшёрстком, имеется ярко выраженная сезонная линька. Собаки плохо переносят жаркий климат. Многим людям очень нравятся голубые глаза у хаски и приятная внешность.`
        },
        4: {
            head: 'Типичные представители',
            text: `Сибирский хаски, аляскинский маламут, самоед - наиболее известные и распространённые представители этой секции.`
        },
    }


    render() {
        const {pointActive, points} = this.state;
        return (
            <div className="step15">
                <TextBlock
                    text={`<span class="text_subheader">Секция 1. Северные ездовые собаки</span>`}
                />
                <InfoBlock
                    text={`Ознакомьтесь с каждой группой, нажав на ключевые элементы.`}
                />
                <div className="flex">
                    <img src={img1} alt="" className='mainImg'/>
                    <div className="flex_el"><span className={points.indexOf(1) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(1)}}>Для чего выводились?</span></div>
                    <div className="flex_el"><span className={points.indexOf(2) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(2)}}>Характер</span></div>
                    <div className="flex_el"><span className={points.indexOf(3) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(3)}}>Особенности внешности</span></div>
                    <div className="flex_el"><span className={points.indexOf(4) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(4)}}>Типичные представители</span></div>
                </div>

                {pointActive !== 0 &&
                <PopupInStep
                    closePopup={() => this.changePoint(0)}
                    popupImg={img2}
                    popupHead={this.popapWrp[pointActive].head}
                    popupText={this.popapWrp[pointActive].text}
                    changePoint={() => this.changePoint(pointActive < 4 ? pointActive + 1 : 0)}
                />
                }
            </div>
        )
    }
}
