import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson41/step31-1.png'
import img2 from '../../../image/lessons/lesson41/step31-2.png'
import img3 from '../../../image/lessons/lesson41/step31-3.png'
import img4 from '../../../image/lessons/lesson41/step31-4.png'
import img5 from '../../../image/lessons/lesson41/step31-5.png'
import img6 from '../../../image/lessons/lesson41/step31-6.png'
import img7 from '../../../image/lessons/lesson41/step31-7.png'
import img8 from '../../../image/lessons/lesson41/step31-8.png'
import img9 from '../../../image/lessons/lesson41/step31-9.png'
import img10 from '../../../image/lessons/lesson41/step31-10.png'
import img11 from '../../../image/lessons/lesson41/step31-11.png'
import img12 from '../../../image/lessons/lesson41/step31-12.png'
import img13 from '../../../image/lessons/lesson41/step31-13.png'
import img14 from '../../../image/lessons/lesson41/step31-14.png'
import img15 from '../../../image/lessons/lesson41/step31-15.png'
import img1_popup from '../../../image/lessons/lesson41/step31-popup1.png'
import img2_popup from '../../../image/lessons/lesson41/step31-popup2.png'
import img3_popup from '../../../image/lessons/lesson41/step31-popup3.png'


export default class step6 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        points: [0],
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    clickPoint = pointActive => {
        const {points, windowWidth} = this.state
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        if (windowWidth < 768) {
            document.body.scrollIntoView( true)
            document.body.style.overflow = "hidden"
        }

        if (pointActive > 3) {
            this.closePoint()
            this.setState({pointActive: 0, points})
        } else {
            this.setState({pointActive, points})
        }

        if (points.length === 3) {
            this.props.showNextBtn();
        }
    }

    closePoint = () => {
        document.body.style.overflow = ""
        this.setState({pointActive: 0})
    }

    render() {
        const {pointActive} = this.state
        return (
            <div className="step31">
                <TextBlock
                    text={`Чтобы не нарушить сбалансированный рацион питомца, нужно следить за правильным использованием угощений и соблюдать следующие рекомендации:`}
                />
                <div className="flex">
                    <img src={img1} alt="" className={'flex_img ' + (pointActive === 1 && 'active')} onClick={() => {this.clickPoint(1)}}/>
                    <img src={img2} alt="" className={'flex_img ' + (pointActive === 2 && 'active')} onClick={() => {this.clickPoint(2)}}/>
                    <img src={img3} alt="" className={'flex_img ' + (pointActive === 3 && 'active')} onClick={() => {this.clickPoint(3)}}/>
                </div>
                {pointActive > 0 &&
                <div className={'info ' + (pointActive === 2 ? 'center' : pointActive === 3 ? 'right' : '')}>
                    <div className="info_close" onClick={this.closePoint}/>
                    {/*{Object.values(this.infoData[pointActive]).map((item, index) => {
                        console.log(111, Object.values(this.infoData[pointActive]))
                        return (
                            <div key={index} className={'info_el'}>
                                <div className="info_el_head">
                                    {item}
                                </div>
                                <img src={item} alt="" className="info_el_img"/>
                            </div>
                        )
                    })}*/}
                    {pointActive === 1 &&
                        <div className={'info_box'}>
                            <div className="info_color first">
                                <img src={img1_popup} alt="" className="info_color_img"/>
                            </div>
                            <div className="info_el">
                                <div className="info_el_head">
                                    Кусочки в соусе для щенков с говядиной от 1 месяца
                                </div>
                                <img src={img4} alt="" className="info_el_img"/>
                            </div>
                            <div className="info_el">
                                <div className="info_el_head">
                                    Кусочки в соусе для щенков с ягненком от 1 месяца
                                </div>
                                <img src={img5} alt="" className="info_el_img"/>
                            </div>
                            <button
                                className="btn btn_blue"
                                onClick={() => {this.clickPoint(pointActive + 1)}}
                            >
                                продолжить
                            </button>
                        </div>
                    }
                    {pointActive === 2 &&
                    <div className={'info_box'}>
                        <div className="info_color second">
                            <img src={img2_popup} alt="" className="info_color_img"/>
                        </div>
                        <div className="info_el">
                            <div className="info_el_head">
                                Кусочки в соусе с говядиной
                            </div>
                            <img src={img6} alt="" className="info_el_img"/>
                        </div>
                        <div className="info_el">
                            <div className="info_el_head">
                                Кусочки в соусе с ягненком
                            </div>
                            <img src={img7} alt="" className="info_el_img"/>
                        </div>
                        <div className="info_el">
                            <div className="info_el_head">
                                Паштет с говядиной
                            </div>
                            <img src={img8} alt="" className="info_el_img"/>
                        </div>
                        <div className="info_el">
                            <div className="info_el_head">
                                Паштет с курицей
                            </div>
                            <img src={img9} alt="" className="info_el_img"/>
                        </div>
                        <button
                            className="btn btn_blue"
                            onClick={() => {this.clickPoint(pointActive + 1)}}
                        >
                            продолжить
                        </button>
                    </div>
                    }
                    {pointActive === 3 &&
                    <div className={'info_box'}>
                        <div className="info_color third">
                            <img src={img3_popup} alt="" className="info_color_img"/>
                        </div>
                        <div className="info_el">
                            <div className="info_el_head">
                                Кусочки в соусе с говядиной и ягненком
                            </div>
                            <img src={img10} alt="" className="info_el_img"/>
                        </div>
                        <div className="info_el">
                            <div className="info_el_head">
                                Кусочки в соусе с кроликом и индейкой
                            </div>
                            <img src={img11} alt="" className="info_el_img"/>
                        </div>
                        <div className="info_el">
                            <div className="info_el_head">
                                Кусочки в соусе с говядиной
                            </div>
                            <img src={img12} alt="" className="info_el_img"/>
                        </div>
                        <div className="info_el">
                            <div className="info_el_head">
                                Кусочки в соусе с курицей
                            </div>
                            <img src={img13} alt="" className="info_el_img"/>
                        </div>
                        <div className="info_el">
                            <div className="info_el_head">
                                Желе с ягненком
                            </div>
                            <img src={img14} alt="" className="info_el_img"/>
                        </div>
                        <div className="info_el">
                            <div className="info_el_head">
                                Желе с ягненком
                            </div>
                            <img src={img15} alt="" className="info_el_img"/>
                        </div>
                        <button
                            className="btn btn_blue"
                            onClick={() => {this.clickPoint(pointActive + 1)}}
                        >
                            продолжить
                        </button>
                    </div>
                    }
                </div>
                }

            </div>
        )
    }
}
