import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import ArrowBack from '../../../Components/LessonAndTestComponents/ArrowBack';
import List from '../../../Components/LessonAndTestComponents/List';

import img1 from '../../../image/lessons/lesson33/step6-1.jpg'


export default class step6 extends React.Component {
    state = {
        flexActive: 0,
        flexs: [1],
        flexHidden: false,
        ListData1: [
            `Вес практически не изменяется.`,
            `Энергетические потребности остаются стабильными на протяжении 4–5 недель.`,
            `Режим кормления остаётся прежним и изменяется только с 6 недели.`
        ],
        ListData2: [
            `Идеальный вес собаки на поздней стадии на 10–15% больше ее стандартного веса.`,
            `Энергетические потребности увеличиваются примерно на 10–15% каждую неделю, именно в этот период требуется перевод беременных сук на корм для щенков.`,
            `Последние две недели нужно увеличить частоту кормления, так как собака не может много съесть за один прием.`
        ]
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs, flexHidden: true})
        if (flexs.length === 2) {
            this.props.showNextBtn()
        }
    }

    closeImgDiv = () => {this.setState({flexHidden: false, flexActive: 0})}


    render() {
        const {flexActive, flexHidden, ListData1, ListData2} = this.state
        return (
            <div className="step6">
                <TextBlock
                    text={`На поздней стадии беременности энергетические потребности собаки сильно возрастают. Необходимо рассчитывать количество корма, исходя из ее энергетических потребностей.`}
                    hidden={flexHidden === true ? 'hidden' : ''}
                />
                <InfoBlock
                    text={`Сравните состояние собаки на ранней и поздней стадиях беременности, нажав на ключевые элементы`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />

                <div className={'flex ' + (flexHidden === true && 'hidden')}>
                    <div className={'flex_el ' + (flexActive === 1 && 'active')} onClick={() => {
                        this.changeFlex(1)
                    }}>Ранняя стадия
                    </div>
                    <div className={'flex_el ' + (flexActive === 2 && 'active')} onClick={() => {
                        this.changeFlex(2)
                    }}>Поздняя стадия
                    </div>
                </div>
                {flexActive !== 0 &&
                <div className={'imgDiv ' + (flexActive === 2 ? 'imgDiv--2' : '')}>
                    <ArrowBack
                        returnMenu={this.closeImgDiv}
                    />
                    {flexActive === 1 &&
                    <div className="imgDiv_wrap">
                        <List ListData={ListData1}></List>
                    </div>
                    }
                    {flexActive === 2 &&
                    <div className="imgDiv_wrap">
                        <List ListData={ListData2}></List>
                    </div>
                    }
                </div>
                }
                <img src={img1} alt=""/>
            </div>
        )
    }
}
