import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import img1 from '../../../image/lessons/lesson33/step2-1.png'
export default class step2 extends React.Component {

    state = {
    }

    render() {
        return (
            <div className="step2">
                <TextBlock
                    text={`В процессе беременности энергетические потребности собаки изменяются. Поэтому для этой жизненной стадии необходим особый рацион.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img src={img1} alt=""/>
                    </div>
                    <div className="flex_el">
                        <TextBlock
                            text={`Средняя продолжительность беременности собаки составляет 9 недель (63 дня).`}
                        />
                        <TextBlock
                            text={`Как видно из графика, первые 5 недель энергетические потребности не изменяются. С 6 недели они начинают постепенно возрастать, так как в это время собака набирает вес перед родами.`}
                        />
                        <TextBlock
                            text={`Далее вы узнаете, как правильно кормить беременную собаку.`}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
