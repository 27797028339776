import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson7/step3-1.png";
import img2 from "../../../image/lessons/lesson7/step3-2.png";
import img3 from "../../../image/lessons/lesson7/step3-3.png";
import img3_1popap from '../../../image/lessons/lesson7/step3-1popap.png';


export default class step3 extends React.Component {
    state = {
        popapActive: 0,
        popapShow: false,
        activeFlex: 0,
        points: [],
        flexs: []
    };

    changeFlex = activeFlex => {
        const {flexs, points} = this.state
        if (flexs.indexOf(activeFlex) === -1) {
            flexs.push(activeFlex)
        }
        this.setState({activeFlex, flexs})
        if (points.length === 3 && flexs.length === 3) {
            this.props.showNextBtn();
        }
    }

    showPopap = popapActive => {
        const {popapShow, points, flexs} = this.state;
        if (!popapShow) {
            if (points.indexOf(popapActive) === -1) {
                points.push(popapActive)
            }
            this.setState({
                popapActive,
                popapShow: !popapShow,
                points,
            })
        } else {
            this.setState({
                popapActive: 0,
                popapShow: !popapShow
            })
        }
        if (points.length === 3 && flexs.length === 3) {
            this.props.showNextBtn();
        }
    }

    navList = [
        {
            "index": 1,
            "text": "Миф 1"
        },
        {
            "index": 2,
            "text": "Миф 2"
        },
        {
            "index": 3,
            "text": "Миф 3"
        }
    ]

    contentActive = {
        1: {
            content: <>
                <TextBlock
                    text={`Собака никому ничего не должна. Она не может осознать, сколько вы для нее хорошего сделали, на какие шли жертвы и как бы ей было без вас плохо.`}
                />
                <div className="imgFloat">
                    <img src={img1} alt=""/>
                    <TextBlock text={`Собака ничего не знает о «добре и зле». Если человек не озаботился ее воспитанием и не регулирует ее поведение, она живет в соответствии с естественными устремлениями и, как правило, что ей нравится, то и «хорошо», что не нравится – «плохо».

                        Плохое поведение собаки – практически всегда <span class="text_dark-blue"><b>результат безответственности и недосмотра владельца</b></span> (исключая особые случаи, когда речь идет о психически нездоровых животных)`}
                    />
                </div>
            </>,
            img: false,
            text:  false,
            points: false
        },
        2: {
            text: `«Собаку породы N <span class="textBlue"><b>не нужно дрессировать,</b></span> потому что (варианты):

                <span class="textBlue"><b>- она прекрасно все понимает;
                
                - она не дрессируется, это же такая собака!
                
                - ни мне, ни ей это не нужно... »</b></span>`,
            img: img2,
            points: [1, 2, 3]
        },
        3: {
            content: <>
                <TextBlock
                    text={`<span class="text_dark-blue"><b>«Я не занимаюсь со своим, пока он маленький - «у ребенка должно быть детство», подрастет - всему научим...»</b></span>

                        Опасное заблуждение. Так не поступают с щенками, из которых впоследствии непременно вырастает взрослый кобель или сука.`}
                />
                <div className="imgFloat">
                    <img src={img3} alt=""/>
                    <TextBlock
                        text={`<span class="text_dark-blue"><b>Воспитание собаки начинается с первого дня появления ее в доме.</b></span>
                            И навыки послушания нужно начинать вырабатывать со щенка - тем легче вам будет со взрослой собакой. Наказание – не выход, так как на самом деле, мы должны «наказывать» скорее себя, а собака не виновата. `}
                    />
                </div>
                <TextBlock
                    text={`И в ответственности владельца было воспитать и обучить  животное так, чтобы у него не было проявлений нежелательного поведения.`}
                />
            </>,
            img: false,
            text:  false,
            points: false
        }
    }


    popapWrp = {
        0: {img: '', head: '', text: ''},
        1: {
            img: img3_1popap,
            head: `“...она прекрасно все понимает.”`,
            text: `У всех собак вырабатываются условные рефлексы и формируются навыки. У всех: служебных, декоративных, охотничьих. По одним и тем же законам и принципам. 

                <span class="text_dark-blue"><b>Любую собаку нужно дрессировать.</b></span>
                
                И базовые навыки послушания (подзыв, посадка, запрещающая команда, движение рядом, укладка, нахождение на «месте» –  обязательный минимум) должны быть выработаны.`
        },
        2: {
            img: img3_1popap,
            head: `“...она не дрессируется, это же такая собака!”`,
            text: `С любой собакой нужно заниматься, обязательно надо выработать навыки послушания, так как с неуправляемой собакой очень неудобно жить (а в городе - вообще невозможно).

                И помните - если вы ничему не учите собаку, она учится сама. Всегда и постоянно. И все свои «успехи» закрепляет в поведении.`
        },
        3: {
            img: img3_1popap,
            head: `“...ни мне, ни ей это не нужно...”`,
            text: `Например, если вы ее десять раз позвали, а она не подошла – это не означает, что вы ее всего-то не научили подходить на зов. Это означает еще, что она тоже приобрела опыт - что можно и не подходить. 
                
                И вообще все эти хозяйские крики - звуковой фон вроде шума от дороги. И если она хорошо это усвоит, подходить она будет только тогда, когда заняться нечем и когда сама захочет.`
        }
    }

    render() {
        const {popapShow, popapActive, activeFlex, points} = this.state;
        return (
            <div className="step3">
                <TextBlock
                    text={`В процессе воспитания щенка нужно проявить терпение и последовательность.

                        <b>Самые типичные заблуждения относительно обучения собак:</b>`}
                />
                <InfoBlock
                    text={
                        `Ознакомьтесь с распространёнными мифами, нажав на активные элементы.`
                    }
                />
                <div className="step_content">
                    <div className="flex">
                        {this.navList.map(item => {
                            return (
                                <div
                                    key={item.index}
                                    className={'flex_el ' + (activeFlex === item.index && 'active')}
                                    onClick={() => {
                                        this.changeFlex(item.index)
                                    }}
                                >{item.text}</div>
                            )})
                        }
                    </div>
                    {activeFlex !== 0 &&
                    <div className={'imgDiv ' + (activeFlex !== 1 ? `imgDiv--${activeFlex}` : '')}>
                        {this.contentActive[activeFlex].text &&
                            <TextBlock
                                text={this.contentActive[activeFlex].text}
                            />
                        }
                        {this.contentActive[activeFlex].content ? this.contentActive[activeFlex].content : ""}
                        {this.contentActive[activeFlex].img || this.contentActive[activeFlex].points ?
                            <div className="info">
                                {this.contentActive[activeFlex].img &&
                                <img src={this.contentActive[activeFlex].img} alt="" className='infoImg'/>
                                }
                                {this.contentActive[activeFlex].points &&
                                <div className="infoPoints">
                                    {this.contentActive[activeFlex].points.map(item => {
                                        return (
                                            <div
                                                key={item}
                                                className={'point ' + (points.indexOf(item) >= 0 && 'active')}
                                                onClick={() => {
                                                    this.showPopap(item)
                                                }
                                                }>
                                            </div>
                                        )
                                    })}
                                </div>
                                }
                            </div> : ""}
                    </div>
                    }
                </div>

                {popapShow &&
                <div className={'infoPopap'}>
                    <div className="infoPopap_wrp">
                        <div className="popap_close" onClick={() => {
                            this.showPopap(0)
                        }}/>
                        <div className="image">
                            <div className="imageBg">
                                <img src={this.popapWrp[popapActive].img} alt=""/>
                            </div>
                        </div>
                        <div className="scroll">
                            <div className="head"
                                 dangerouslySetInnerHTML={{__html: this.popapWrp[popapActive].head}}/>
                            <TextBlock
                                text={this.popapWrp[popapActive].text}
                                fontSize={16}
                            />
                            <button className="btn btn_blue" onClick={() => {
                                this.showPopap(0)
                            }}>Продолжить</button>
                        </div>
                    </div>
                </div>
                }

            </div>
        )
    }
}