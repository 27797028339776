import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import step3_1 from '../../../image/lessons/lesson3/step3-1.png'
import step3_pi1 from "../../../image/lessons/lesson3/step3-pi1.png";
import step3_p1 from "../../../image/lessons/lesson3/step3-p1.png";
import step3_pi2 from "../../../image/lessons/lesson3/step3-pi2.png";
import step3_p2 from "../../../image/lessons/lesson3/step3-p2.png";
import step3_pi3 from "../../../image/lessons/lesson3/step3-pi3.png";
import step3_p3 from "../../../image/lessons/lesson3/step3-p3.png";
import step3_pi4 from "../../../image/lessons/lesson3/step3-pi4.png";
import step3_p4 from "../../../image/lessons/lesson3/step3-p4.png";
import step3_pi5 from "../../../image/lessons/lesson3/step3-pi5.png";
import step3_p5 from "../../../image/lessons/lesson3/step3-p5.png";

export default class step3 extends React.Component {
    state = {
        linkActive: 0,
        links: [],
    };

    onClickLink = linkActive => {
        const {links} = this.state;
        if (links.indexOf(linkActive) === -1) {
            links.push(linkActive)
        }
        this.setState({linkActive, links});
        console.log(links.length)
        if(links.length === 6){
            this.props.showNextBtn()
        }
    }

    popaps = {
        1: {
            icon: step3_pi1,
            img: step3_p1,
            head: 'Гигиена жизни',
            text: 'Гигиена жизни играет ключевую роль для поддержания высокого качества жизни животных питомника. К ней относятся, в частности, гигиена питания (подбор подходящего животным высоко усвояемого корма, регулярная тщательная гигиена мисок для корма, хранение корма в оригинальной герметичной упаковке при надлежащей температуре и влажности), поддерживание необходимой активности (тренировки, обогащение среды), снижение стрессовых факторов в питомнике.'
        },
        2: {
            icon: step3_pi2,
            img: step3_p2,
            head: 'Физические средства защиты',
            text: 'К физических средствам защиты относятся: разделение питомника на сектора, применение отдельного инвентаря в каждом секторе питомника, использование бахил и спецодежды (инфекции могут переноситься на одежде и обуви), применение кварцевых ламп, парогенераторов, огнеметов и так далее.'
        },
        3: {
            icon: step3_pi3,
            img: step3_p3,
            head: 'Механические средства защиты',
            text: 'Уборка – важнейший этап поддержания гигиены без качественной уборки дезинфекций будет неэффективной. Инвентарь для уборки включает в себя совки, скребки, метлы, лопаты. Крайне важно использовать для каждого сектора питомника свой инвентарь, поскольку он может служить средством переноса болезнетворных агентов. Уборка также включается в себя регулярную замену/чистку подстилок.'
        },
        4: {
            icon: step3_pi4,
            img: step3_p4,
            head: 'Химические средства защиты',
            text: 'К химическим средствам защиты относятся различные моющие и дезинфицирующие средства (щелочные, кислотные и так далее). Важно строго следовать инструкции по применению таких средств, поскольку некоторые из них (например, кислоты и щелочи), могут нейтрализовать друг друга при совместном применении и таким образом утратить эффективность.'
        },
        5: {
            icon: step3_pi5,
            img: step3_p5,
            head: 'Биологические средства защиты',
            text: 'Обработка животных от эктопаразитов (блохи, клещи, вши) и эндопаразитов (гельминты), а также вакцинация. Вакцинация может проводиться живыми и убитыми вакцинами. Существуют базовые вакцины, которыми рекомендуется прививать большинство животных, и дополнительные вакцины, которые разумно применять при повышенном риске (например, вакцина против лептоспироза собак).'
        },

    }



    render() {
        const {links, linkActive} = this.state
        return (
            <div className="step3">
                <TextBlock
                    fontSize={16}
                    text={`Гигиена – комплекс мер для предупреждения заболеваний, представляющих потенциальную опасность для животных питомника.`}
                />
                <InfoBlock
                    text={'Ознакомьтесь с основами гигиены здоровья животных, нажав на ключевые элементы'}
                />
                <div className="blockImg">
                    <img src={step3_1} alt=""/>
                    <div className={"link link1 " + (links.indexOf(1) >= 0 && 'active')} onClick={()=>this.onClickLink(1)}/>
                    <div className={"link link2 " + (links.indexOf(2) >= 0 && 'active')} onClick={()=>this.onClickLink(2)}/>
                    <div className={"link link3 " + (links.indexOf(3) >= 0 && 'active')} onClick={()=>this.onClickLink(3)}/>
                    <div className={"link link4 " + (links.indexOf(4) >= 0 && 'active')} onClick={()=>this.onClickLink(4)}/>
                    <div className={"link link5 " + (links.indexOf(5) >= 0 && 'active')} onClick={()=>this.onClickLink(5)}/>
                </div>

                <InfoBlock
                    danger={true}
                    text={'«Гигиена» ≠ «чистота», «гигиена» = «профилактика».'}
                />
                {linkActive !== 0 &&
                    <div className="infoPopap">
                        <div className="infoPopap_wrp">
                            <div className="infoPopap_close" onClick={() => this.onClickLink(0)}/>
                            <div className="infoPopap_iconDiv">
                                <img src={this.popaps[linkActive].icon} alt="" className="infoPopap_icon"/>
                            </div>
                            <div className="infoPopap_head">{this.popaps[linkActive].head}</div>
                            <TextBlock
                                fontSize={16}
                                text={this.popaps[linkActive].text}
                            />
                            <div className="infoPopap_imgDiv">
                                <img src={this.popaps[linkActive].img} alt="" className="infoPopap_img"/>
                            </div>
                            <button className="btn btn_blue" onClick={() => this.onClickLink(0)}>Продолжить</button>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
