import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson4/step10-1.png'
import img2 from '../../../image/lessons/lesson4/step10-2.png'
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";


export default class step6 extends React.Component {
    state = {
        pointActive: 0,
        points: [],
    };

    changePoint = pointActive => {
        this.setState({pointActive}, () => {
            this.checkPoint(pointActive)
        })
    }

    checkPoint = (point) => {
        const {points} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        console.log(points)
        if (points.length === 5) {
            this.props.showNextBtn()
        }
    }

    popapWrp = {
        1: {
            head: 'Для чего выводились?',
            text: `К третьей секции относятся четыре породы швейцарских зенненхундов, произошедших от римских гуртовых собак. 
                    
                    По происхождению они близкие родственники ротвейлера, но весьма сильно от него отличаются как внешне, так и по поведению.`
        },
        2: {
            head: 'Характер',
            text: `По характеру они уравновешенные, чуткие, со склонностью к дисциплине. Несмотря на присущие защитные качества, они редко переступает черту «необходимой обороны».
                
                    Сегодня основное предназначение этих собак – эффектные и преданные компаньоны, успешные шоу-породы, они неприхотливы и хорошо подходят как для города, так и для загородного дома.
                    
                    Собаки легко обучаются, энтлебухер зенненхунд и аппенцеллер зенненхунд пригодны для занятий кинологическим спортом.`
        },
        3: {
            head: 'Особенности внешности',
            text: `Это привлекательные собаки с уникальным симметричным трехцветным окрасом.
                    
                    Три породы имеют короткую шерсть, одна – бернский зенненхунд, обладает длинной шерстью, требующей регулярного ухода.
                    
                    Размер – от среднего до крупного.`
            },
        4: {
            head: 'Типичные представители',
            text: `Наиболее популярны у отечественных любителей две самые крупные породы зенненхундов. Этих внушительных и спокойных собак чаще всего заводят в качестве компаньонов.`
        },
    }


    render() {
        const {pointActive, points} = this.state;
        return (
            <div className="step10">
                <TextBlock
                    text={`<span class="text_subheader">Швейцарские зенненхунды (секция 3)</span> `}
                />
                <InfoBlock
                    text={`Ознакомьтесь с каждой группой, нажав на ключевые элементы.`}
                />
                <div className="flex">
                    <img src={img1} alt="" className='mainImg'/>
                    <div className="flex_el"><span className={points.indexOf(1) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(1)}}>Для чего выводились?</span></div>
                    <div className="flex_el"><span className={points.indexOf(2) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(2)}}>Характер</span></div>
                    <div className="flex_el"><span className={points.indexOf(3) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(3)}}>Особенности внешности</span></div>
                    <div className="flex_el"><span className={points.indexOf(4) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(4)}}>Типичные представители</span></div>
                </div>

                {pointActive !== 0 &&
                <PopupInStep
                    closePopup={() => this.changePoint(0)}
                    popupImg={img2}
                    popupHead={this.popapWrp[pointActive].head}
                    popupText={this.popapWrp[pointActive].text}
                    changePoint={() => this.changePoint(pointActive < 4 ? pointActive + 1 : 0)}
                />
                }
            </div>
        )
    }
}
