import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'

import img1 from '../../../image/lessons/lesson5/step23-1.png'
import img2 from '../../../image/lessons/lesson5/step23-2.png'
import img3 from '../../../image/lessons/lesson5/step22-3.png'


export default class step6 extends React.Component {
    state = {
        pointActive: 0,
        points: [],
    };

    changePoint = pointActive => {
        this.setState({pointActive}, () => {
            this.checkPoint(pointActive)
        })
    }

    checkPoint = (point) => {
        const {points} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        if (points.length === 2) {
            this.props.showNextBtn()
        }
    }

    popapWrp = {
        1: {
            icon: img3,
            head: 'Энергетическая потребность питомца',
            text: `Формула расчета энергетической потребности:

для взрослой собаки <span class="textBlue"><b>Э = 95 * M</b></span> 

<small>Э — энергетическая потребность;
М — масса тела в килограммах.</small>`
        },
    }

    render() {
        const {pointActive} = this.state;

        return (
            <div className="step23">
                <TextBlock
                    text={`Теперь попробуйте рассчитать суточную норму рациона самостоятельно.`}
                />

                <div className="flex">
                    <div className="left">
                        <img src={img1} alt=""/>
                    </div>
                    <div className="right">
                        <div className="right_row">
                            <div className="row_block">
                                <TextBlock
                                    text={`Это - немецкая овчарка Рекс. Ему 5 лет, он весит 34 кг. Хозяин кормит его сухим кормом премиум класса Pedigree® для взрослых собак крупных пород.

                                    Рассчитайте, сколько граммов корма 
                                    необходимо Рексу каждый день?`}
                                />
                            </div>
                        </div>
                        <div className="right_row">
                            <div className="row_block">
                                <TextBlock
                                    text={`Энергетическая ценность корма:
                                        360 ккал / 100 г.`}
                                />
                            </div>
                            <img src={img2} alt=""/>
                        </div>
                    </div>
                </div>

                <InfoBlock
                    text={`Эту задачу удобно решать на бумаге. Произведите необходимые расчеты и перейдите далее, чтобы выбрать вариант ответа и проверить свое решение.`}
                />

                <div className="textDown">
                    Используйте шпаргалку, чтобы посмотреть нужные вам формулы. <br/>
                    Просто нажмите на эту кнопку.
                </div>

                <button className="btn btn_blue btn_shpora" onClick={() => {this.changePoint(1)}}>
                    ШПАРГАЛКА
                </button>

                {pointActive !== 0 &&
                <div className={'infoPopap'}>
                    <div className="infoPopap_wrp">
                        <div className="popap_close" onClick={() => {
                            this.changePoint(0)
                        }}/>
                        <div className="image">
                            <div className="imageBg">
                                <img src={this.popapWrp[pointActive].icon} alt=""/>
                            </div>
                        </div>
                        <div className="head"
                             dangerouslySetInnerHTML={{__html: this.popapWrp[pointActive].head}}/>
                        <TextBlock
                            text={this.popapWrp[pointActive].text}
                            fontSize={16}
                        />
                        {this.popapWrp[pointActive].img && <img src={this.popapWrp[pointActive].img} alt="" className={'img'}/>}
                        <button className="btn btn_blue" onClick={() => {
                            this.changePoint(0)
                        }}>Продолжить</button>
                    </div>
                </div>
                }
            </div>
        )
    }
}
