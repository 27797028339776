import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import img1 from '../../../image/lessons/lesson6/step3-1.svg';
import img2 from '../../../image/lessons/lesson6/step3-2.svg';


export default class step3 extends React.Component {
    state = {
        flexActive: 1,
        flexs: [1],
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs})
        if (flexs.length === 2) {
            this.props.showNextBtn()
        }
    }


    render() {
        const {flexActive} = this.state
        return (
            <div className="step3">
                <InfoBlock text={`Нажмите на активные элементы, чтобы узнать больше.`}/>

                <div className="flex">
                    <div className={'flex_el ' + (flexActive === 1 && 'active')} onClick={() => {
                        this.changeFlex(1)
                    }}>
                        <div className="flex_wrp">
                            <div className="flex_img">
                                <img src={img1} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className={'flex_el ' + (flexActive === 2 && 'active')} onClick={() => {
                        this.changeFlex(2)
                    }}>
                        <div class="flex_wrp">
                            <div className="flex_img">
                                <img src={img2} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
                {flexActive !== 0 &&
                <div className={'textDiv ' + (flexActive === 2 ? 'textDiv--2' : '')}>
                        {flexActive === 1 &&
                            <TextBlock
                                text={`Сегодня известно <b>около полутысячи</b> генетически обусловленных заболеваний и аномалий развития у собаки. Это меньше, чем у человека, но не потому, что у собак их меньше, а потому, что человек лучше изучен.
                                        
                                        Чтобы минимизировать риски, внимательно отнеситесь к выбору щенка, поинтересуйтесь здоровьем и наличием тестов у его предков.
                                        Но имейте в виду - даже наличие всех необходимых исследований и сертификатов не всегда дает полную гарантию от возможных проблем. Дело в том, что многие врожденные дефекты носят полигенный характер, и их отсутствие у родителей не дает гарантии не появления у части потомства.`}
                            />
                        }
                        {flexActive === 2 &&
                            <TextBlock
                                text={`Вторая сложность состоит в том, что некоторые проблемы могут передаваться
                                        по наследству в форме предрасположенностей, а проявятся они или нет (будет компенсированная или декомпенсированная форма), зависит от многих факторов содержания и образа жизни собаки.
                                        
                                        Но не следует делать из вышесказанного ошибочный вывод, что все тесты – бессмыслица, так как без них ситуация вряд ли была бы лучше и они нужны для минимизации рисков и <b>ответственного разведения.</b>`}
                            />
                        }
                    </div>
                }
            </div>
        )
    }
}