import React from "react";
import "./style.sass"
import DropDownPanel from "../../Components/DropDownPanel"

const faq = [
    {
        question: "Как начисляются баллы?",
        answer: "За прохождение каждого урока вы получаете определенную сумму баллов. Информацию об этом вы можете найти во вкладке «Мое обучение». Прохождение тестов также дает вам дополнительные баллы, которые зависят от количества правильных ответов и от времени прохождения теста. Чем быстрее и правильнее вы решаете тест, тем больше баллов вы получаете. Важно! Баллы за время прохождения теста начисляются только в том случае, если у вас 8 и более правильных ответов. Будьте внимательны! Для получения сертификата нужно пройти обучение по всем темам раздела, ответив правильно на 70% вопросов тестов в каждом уроке. Сертификат будет доступен в личном кабинете."
    },
    {
        question: "Возможна ли обратная связь по урокам? Кому я могу задать вопрос, если что-то непонятно?",
        answer: [`Напишите свои вопросы и пожелания администраторам системы по электронному адресу: `,<a href="mailto:support@dogstudy.ru">support@dogstudy.ru</a>, ` Ответ придет максимально оперативно.`]
    },
    {
        question: "Приравнивается ли прохождение всех уроков к прохождению кинологических курсов?",
        answer: [`Уроки, представленные на портале, носят информационно-ознакомительный характер. Если вы заинтересованы в профессиональном обучении или профессиональной переподготовке – посетите сайт `, <a href="https://rkf.academy/">Образовательного центра РКФ.</a>]
    },
    {
        question: "Будут ли появляться новые уроки на портале?",
        answer: "На нашем портале планируются новые уроки, по мере обновления вы получите информационное письмо."
    },
    {
        question: "Как присваиваются статусы?",
        answer: [`Статус присваивается в соответствии с заработанными в процессе обучения баллами и, в зависимости от их количества, пользователю присваивается один из следующих статусов: `,<br/>,
                `Новичок - 0 баллов `, <br/>,
                `Специалист - 50 баллов `, <br/>,
                `Профессионал - 100 баллов `, <br/>,
                `Мастер - 200 баллов `, <br/>,
                `Знаток - 400 баллов `, <br/>,
                `Мудрец - 600 баллов `, <br/>,
                `Гуру - 850 баллов `, <br/>,
                `Обращаем Ваше внимание, что статус не отображается в сертификате.`]
    },
]

export default function Faq() {

    return (
        <div>
            {faq.map((item, index) => {
                return (
                    <DropDownPanel
                        key={index}
                        number={index + 1}
                        question={item.question}
                        answer={item.answer}
                    />
                )
            })}
        </div>
    )
}

