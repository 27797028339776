import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import img1 from '../../../image/lessons/lesson2/step6-1.png';
// import img1xs from '../../../image/lessons/lesson2/step6-1xs.png';
import img2 from '../../../image/lessons/lesson2/step6-2.png';
import img3 from '../../../image/lessons/lesson2/step6-3.png';
import img4 from '../../../image/lessons/lesson2/step6-4.png';
import img5 from '../../../image/lessons/lesson2/step6-5.png';
import img6 from '../../../image/lessons/lesson2/step6-6.png';


export default class step6 extends React.Component {
    state = {
        showPopap: false,
        showStep2: false,
        numberShow: 0,
        validShow: []
    }

    setShowStep2 = showStep2 => {
        this.setState({
            showStep2
        }, ()=>{
            this.showPopap(1)
        })
    }

    popapClose = () => {
        document.body.style.overflow = "";
        this.setState({
            showPopap: false,
            numberShow: 0
        })
    }

    showPopap = el => {
        const {showStep2, validShow} = this.state;
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
        let numberShow = 1;
        if (el === 2){
            numberShow = 3
        }else if(el === 1 && showStep2){
            numberShow = 2
        }
        this.setState({
            showPopap: true,
            numberShow
        })
        if (validShow.indexOf(numberShow) === -1) {
            validShow.push(numberShow)
        }
        this.setState({validShow})
        if (validShow.length === 3) {
            this.props.showNextBtn()
        }
    }


    infoPopoaWrp = {
        1: {
            icon: img2,
            head: 'Собака – социальное животное',
            text: `Собака – социальное животное. У нее есть потребность общаться с собратьями, это необходимо для ее нормальной социализации. 
            
            Иногда они ссорятся и тогда можно вмешаться, просто выбирайте компанию без агрессоров и драчунов.`,
            img: img4
        },
        2: {
            icon: img2,
            head: 'Играйте с щенком',
            text: `То что разыгравшись, щенок не обращает на вас внимания, можно тоже использовать – обучайте его. Только не зовите, когда он заведомо не подойдет, а выбирайте момент, когда он отвлечется от собак: позовите и сразу дайте лакомство. Или подойдите и, взяв за ошейник, отведите в сторону, повторяя кличку и давая лакомство, а потом отпускайте играть дальше.
            
            Если вы будете не только отпускать щенка резвиться с собратьями, но и заниматься с ним дрессировкой, проблема с «бананами в ушах» вскоре пройдет.`,
            img: img5
        },
        3: {
            icon: img3,
            head: 'Собака – социальное животное',
            text:
            `Несмотря на то, что собака – социальное животное (и ей нужно и полезно общаться с сородичами) – это тоже правильно.
            
            Тем более, что совсем крошечные собачки или собаки с высоким уровнем зооагрессии часто объективно имеют ограничения в общении с собратьями: первые, по причине опасности травм (им лучше общаться с собаками сопоставимого размера или хорошо знакомыми и спокойными), а вторые, из-за отсутствия должного воспитания и социализации, обычно не контактируют с посторонними собаками по причине агрессии.
            
            Нужно иметь в виду, что, гуляя с щенком «отдельно», вам потребуется уделять ему еще больше внимания, занимая его и играя, чтобы дать выход его активности. Поначалу он будет очень стремиться к собратьям, появляющимся в поле зрения, но потом это пройдет.
            
            Чтобы минимально социализировать выращиваемого таким образом щенка, его нужно всё-таки знакомить со спокойными собаками, чтобы он научился нормальному ритуальному поведению. Можно позволять щенку общаться и играть на поводке, даже если в основном собаке предстоит общаться со своим хозяином.
            
            Недостатком этого подхода к выращиванию в дальнейшем будет весьма ограниченная способность собаки к коммуникации с сородичами`,
            img: img6
        },
    }


    render() {
        const {showPopap, showStep2, numberShow} = this.state
        return (
            <div className="step6">
                <div className="flex1">
                    <img src={img1} alt=""/>
                </div>
                <InfoBlock text={`Ознакомьтесь с каждой характеристикой, нажав на кнопки внизу страницы.`}/>

                <div className="flex">
                    <div className="flex_el">
                        <div className="img"><img src={img2} alt=""/></div>
                        <div className="text">
                            Мне говорили, что щенку полезно как можно больше общаться с собратьями.
                            Но когда он возится с ними, он меня «не видит и не слышит»; кроме того, я боюсь, что
                            какая-нибудь собака его обидит. Правильно ли я поступаю?
                        </div>
                        <div
                            className="btn btn_blue"
                            onClick={()=>{
                                this.showPopap(1)
                            }}
                        >Узнать</div>
                    </div>
                    <div className="flex_el">
                        <div className="img"><img src={img3} alt=""/></div>
                        <div className="text">
                            Мне говорили, что с щенком лучше гулять отдельно от собак и стоит максимально втягивать его
                            в «общение» только
                            с хозяином. Правильно ли это?
                        </div>
                        <div
                            className="btn btn_blue"
                            onClick={()=>{
                                this.showPopap(2)
                            }}
                        >Узнать</div>
                    </div>
                </div>

                {showPopap &&
                    <div className="infoPopap">
                    <div className="infoPopap_wrp">
                        <div
                            className="popap_close"
                            onClick={this.popapClose}
                        />
                        <div className="image">
                            <div className="imageBg">
                                <img src={this.infoPopoaWrp[numberShow].icon} alt=""/>
                            </div>
                        </div>
                        <div className="head">
                            {this.infoPopoaWrp[numberShow].head}
                        </div>
                        <TextBlock
                            text={this.infoPopoaWrp[numberShow].text}
                            fontSize={16}
                        />
                        <img src={this.infoPopoaWrp[numberShow].img} alt="" className="popap_img"/>
                        {!showStep2 && numberShow === 1 ?
                            <button
                                className="btn btn_blue"
                                onClick={()=>{this.setShowStep2(true)}}
                            >
                                ДАЛЕЕ
                            </button>
                            :
                            showStep2 && numberShow === 2 &&
                                <button
                                    className="btn btn_blue"
                                    onClick={()=>{this.setShowStep2(false)}}
                                >
                                    Вернуться
                                </button>
                        }
                    </div>

                </div>
                }

            </div>
        )
    }

}
