import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";

import img1 from '../../../image/lessons/lesson34/step14-1.png'
import img1_1 from '../../../image/lessons/lesson34/step14-1-1.png'
import img1_2 from '../../../image/lessons/lesson34/step14-1-2.png'
import img2_1 from '../../../image/lessons/lesson34/step13-2-1.png'
import img2_2 from '../../../image/lessons/lesson34/step13-2-2.png'
import img3_1 from '../../../image/lessons/lesson34/step14-3-1.png'
import img3_2 from '../../../image/lessons/lesson34/step14-3-2.png'
import img4_1 from '../../../image/lessons/lesson34/step14-4-1.png'
import img4_2 from '../../../image/lessons/lesson34/step14-4-2.png'
import img5_1 from '../../../image/lessons/lesson34/step14-5-1.png'
import img5_2 from '../../../image/lessons/lesson34/step14-5-2.png'


export default class step14 extends React.Component {

    state = {
        popupActive: 0,
        popupShow: false,
        flexHidden: false,
        points: [],
    };

    showPopup = popupActive => {
        const {points} = this.state;
        if (points.indexOf(popupActive) === -1) {
            points.push(popupActive)
        }

        if (points.length === 5) {
            this.setState({popupActive: 0, popupShow: false})
        }

        this.setState({
            popupShow: true,
            popupActive: popupActive,
            points: points
        })

        if (popupActive > 5) {
            this.setState({popupActive: 0, popupShow: false})
        }

        if (points.length === 5) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({popupShow: false})
    }

    popupWrp = {
        1: {
            img_head: img1_1,
            head: 'Сырьё',
            text: `Сырье для влажного производства <b>поставляется в замороженном виде,</b> чтобы обеспечить его микробиологическую безопасность и свежесть.
            
            При этом каждая партия сырья проверяется в лаборатории на показатели <b>качества и пищевой безопасности.</b>`,
            img: img1_2
        },
        2: {
            img_head: img2_1,
            head: 'Перемешивание и измельчение',
            text: `Так же, как и в сухом производстве, производство влажного продукта начинается с этапа «перемешивание и измельчение». Все сырьевые компоненты <b>соединяются вместе согласно рецептуре</b> и подвергаются измельчению.
            
            Результатом этого этапа является получение <b>однородной мясной смеси,</b> которая напоминает однородный, хорошо помолотый фарш.
            
            Далее из этого фарша будут готовиться мясные кусочки.`,
            img: img2_2
        },
        3: {
            img_head: img3_1,
            head: 'Приготовление кусочков и соуса',
            text: `Приготовление кусочков происходит в паровом тоннеле. На плавно движущийся конвейер <b>подается</b> однородная эмульсия (мясной фарш) через специальные краны и, <b>проходя по конвейеру, обдается паром.</b> На выходе располагаются ножи, которые <b>режут</b> единообразную массу на кусочки. Параллельно с этим <b>варится подлива</b> — соус или желе.`,
            img: img3_2,
            text2: `Преимущества парового тоннеля:`,
            ListData: [
                `щадящая паровая обработка;`,
                `короткое время термической обработки (5 минут).`,
            ],
        },
        4: {
            img_head: img4_1,
            head: 'Наполнение упаковки готовым продуктом',
            text: `Когда готовы кусочки и подлива, наступает этап наполнения упаковки (например, пауча) готовым продуктом.
            
            На этом этапе в пустой пакетик влажного корма <b>кладётся определенное количество кусочков,</b> далее <b>заливается подлива,</b> и, наконец, пауч <b>герметично закрывается.</b> Иными словами, в результате этого этапа получается закрытый пауч с кормом внутри.
            
            Однако на этом процесс производства влажного продукта не заканчивается, т.к. важно <b>простерилизовать</b> этот закрытый пакет, чтобы обеспечить его <b>микробиологическую безопасность и длительный срок годности без использования консервантов.</b>`,
            img: img4_2
        },
        5: {
            img_head: img5_1,
            head: 'Стерилизация',
            text: `Стерилизация (автоклавирование) — финальный этап производства влажного корма, происходящий в специальных установках, работающих по принципу скороварки. В процессе стерилизации важно следить за временем, давлением и температурой.`,
            img: img5_2,
            text2: `Преимущества стерилизации:`,
            ListData: [
                `уменьшение микробиологических рисков, т.к. стерилизуется продукт и упаковка;`,
                `герметичный процесс;`,
                `дает возможность длительного хранения продукта.`
            ],
        },
    }

    render() {
        const {popupShow, popupActive} = this.state
        return (
            <div className="step14">
                <TextBlock
                    text={`Влажный процесс отличается от сухого. Рассмотрите схему, чтобы понять отличия.`}
                />
                <InfoBlock
                    text={`Нажмите на вопросительные знаки, чтобы посмотреть описания производственных процессов.`}
                />
                <div className="imgDiv">
                    {Object.keys(this.popupWrp).map(item => {
                        return (
                            <div
                                key={item}
                                className="point"
                                onClick={() => {this.showPopup(Number(item))}}
                            >
                                ?
                            </div>
                        )
                    })
                    }
                    <img src={img1} alt="" className="img"/>
                </div>
                {popupShow &&
                <PopupInStep
                    closePopup={this.closePopup}
                    popupImg={this.popupWrp[popupActive].img_head}
                    popupHead={this.popupWrp[popupActive].head}
                    popupText={this.popupWrp[popupActive].text}
                    popupImg2={this.popupWrp[popupActive].img}
                    popupText2={this.popupWrp[popupActive].text2}
                    ListData={this.popupWrp[popupActive].ListData}
                    changePoint={() => this.showPopup(popupActive + 1)}
                />
                }
            </div>
        )
    }
}
