import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson40/step8-1.png'
import img1xs from '../../../image/lessons/lesson40/step8-1xs.png'

export default class step8 extends React.Component {

    render() {
        return (
            <div className="step8">
                <TextBlock
                    text={`Практикующие ветеринарные врачи в России подтверждают эффективность PEDIGREE® на основании клинических исследований.`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img className="img" src={img1} alt=""/>
                </picture>
            </div>
        )
    }
}
