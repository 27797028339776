import React from 'react'
import Test from "../../Test";
// import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

export default class step3 extends React.Component {
    state = {
    };


    render() {
        return (
            <div className="step3">
                <Test lesson={this.props.lesson} stepId={this.props.stepId} showNextStep={this.props.showNextStep} showPrevStep={this.props.showPrevStep}/>
            </div>
        )
    }
}
