import React from 'react'
import './style.sass'
import Partners from '../LeftSideBar/Partners'
import {Link} from "react-router-dom";

export default function SiteFooter(props) {
    const rout = window.location.href
    const start = rout.indexOf('/startPage')
    return (
        <footer className="footer" style={(props.style && props.style)}>
            {/*<Partners className={'partners--tablet'}/>*/}
            <div className="footer__box ">
                {/*<div className="footer__rights">

                </div>*/}
                <div className="footer__policy">
                    <div className="footer__policy__box footer__rights">
                        <div className="footer__item footer__first">
                            &copy; {(new Date).getFullYear()} Марс или аффилированные лица. Все права защищены.
                        </div>
                        <div className="footer__item">
                            Права на товарный знак РКФ принадлежат РКФ.
                        </div>
                    </div>
                    <div className="footer__policy__box">
                        <div className="footer__item">
                            <a href="https://www.mars.com/global/policies/privacy/pp-russian" className="footer__link" target="_blank" rel="noopener noreferrer">Положение о конфиденциальности</a>
                        </div>
                        <div className="footer__item">
                            <a href="https://www.mars.com/global/policies/legal/ld-russian" className="footer__link" target="_blank" rel="noopener noreferrer">Правила использования сайта</a>
                        </div>
                    </div>
                    <div className="footer__policy__box">
                        <div className="footer__item">
                            <a href="https://www.mars.com/global/policies/note-to-parents/np-russian" className="footer__link" target="_blank" rel="noopener noreferrer">Уведомление для родителей</a>
                        </div>
                        <div className="footer__item">
                            <a href="https://www.mars.com/global/policies/cookie/cn-russian" className="footer__link" target="_blank" rel="noopener noreferrer">Использование cookies</a>
                        </div>
                    </div>
                    <div className="footer__policy__box">
                        <div className="footer__item">
                            <Link
                                className='footer__link'
                                to={start >=0 ? 'startPage/dostupnost' : '/dostupnost'}
                            >
                                Доступность
                            </Link>
                        </div>
                        <div className="footer__item">
                            <Link
                                className='footer__link'
                                to={start >=0 ? 'startPage/coociesform' : '/coociesform'}
                            >
                                Связаться с нами
                            </Link>
                        </div>
                    </div>
                    <div className="footer__policy__box">
                        <div className="footer__item">
                            <button id="ot-sdk-btn" className="ot-sdk-show-settings">Настройки файлов cookie</button>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
