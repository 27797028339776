import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import img1 from '../../../image/lessons/lesson3/step7-1.png';
import img2 from "../../../image/lessons/lesson3/step7-2.png";
import img3 from "../../../image/lessons/lesson3/step7-3.png";
import step7_p1 from "../../../image/lessons/lesson3/step7-p1.png";
import step7_p2 from "../../../image/lessons/lesson3/step7-p2.png";
import step7_p3 from "../../../image/lessons/lesson3/step7-p3.png";
import step7_p4 from "../../../image/lessons/lesson3/step7-p4.png";

export default class step7 extends React.Component {
    state = {
        linkActive: 0,
        links: [],
    };

    onClickLink = linkActive => {
        const {links} = this.state;
        if (links.indexOf(linkActive) === -1) {
            links.push(linkActive)
        }
        this.setState({linkActive, links});
        if (links.length === 5) {
            this.props.showNextBtn()
        }
    }




    render() {
        const {links, linkActive} = this.state;
        return (
            <div className="step7">
                <TextBlock
                    fomtSize={16}
                    text={`Все помещения, задействованные в процессе разведения («своя грязь»).`}
                />
                <InfoBlock
                    text={`Ознакомьтесь с основными понятиями гигиены, нажав на ключевые элементы.`}
                />
                <div className="flex">
                    <div className={'flex_el ' + (links.indexOf(1) >= 0  && 'active')} onClick={()=>{this.onClickLink(1)}}>
                        <div className="img">
                            <img src={img1} alt=""/>
                        </div>
                        <div className="text">
                            Родильное <br/>
                            Отделение
                        </div>
                    </div>
                    <div className={'flex_el ' + (links.indexOf(3) >= 0 && 'active')} onClick={()=>{this.onClickLink(3)}}>
                        <div className="img">
                            <img src={img2} alt=""/>
                        </div>
                        <div className="text">
                            Здоровые <br/>
                            взрослые собаки
                        </div>
                    </div>
                    <div className={'flex_el ' + (links.indexOf(4) >= 0 && 'active')} onClick={()=>{this.onClickLink(4)}}>
                        <div className="img">
                            <img src={img3} alt=""/>
                        </div>
                        <div className="text">
                            Изолятор
                        </div>
                    </div>
                </div>

                {linkActive === 1 &&
                    <div className="infoPopap">
                    <div className="infoPopap_wrp">
                        {/*<div className="infoPopap_close" onClick={() => this.onClickLink(0)}/>*/}
                        <div className="infoPopap_scroll">
                            <div className="infoPopap_iconDiv">
                                <img src={img1} alt="" className="infoPopap_icon"/>
                            </div>
                            <div className="infoPopap_head">Родильное гнездо</div>
                            <div className="infoPopap_text">
                                Здесь находятся наиболее уязвимые животные: самки после родов, восприимчивые к риску инфекций; щенки, иммунная система которых еще не сформирована.
                                <br/>
                                <br/>
                                В большом питомнике родильное отделение следует размещать с наветренной стороны, чтобы исключить перенос туда микробов из других секторов. В малых питомниках для сук, ожидающих потомство, целесообразно выделить отдельную комнату, которая будет использоваться только для этой цели и куда не будут допускаться другие животные, даже когда родильное отделение не функционирует.
                                <br/>
                                <br/>
                                В «родильном отделении» должно быть оборудовано <span className="textBlue">«гнездо» для родов</span>. Важно, чтобы подстилку можно было легко менять по мере загрязнения. Не следует использовать впитывающие салфетки, подушки и опилки, т.к. они задерживают влагу.
                            </div>
                            <div className="infoPopap_imgDiv">
                                <img src={step7_p1} alt="" className="infoPopap_img"/>
                            </div>

                        </div>
                        <button className="btn btn_blue" onClick={() => this.onClickLink(2)}>Это важно</button>

                    </div>
                </div>
                }
                {linkActive === 2 &&
                    <div className="infoPopap">
                    <div className="infoPopap_wrp danger">
                        <div className="infoPopap_close" onClick={() => this.onClickLink(0)}/>
                        <div className="infoPopap_scroll">
                            <div className="infoPopap_iconDiv">
                                <img src={step7_p2} alt="" className="infoPopap_icon"/>
                            </div>
                            <div className="infoPopap_head">Важно знать!</div>
                            <div className="infoPopap_text">
                                <ul>
                                    <li>Допуск людей в «родильное отделение» ограничивают до минимума.</li>
                                    <li>Руки моют водой с мылом либо протирают дезинфицирующим средством на
                                        водно-спиртовой основе.
                                    </li>
                                    <li>На обувь надевают полиэтиленовые бахилы, а в крупных питомниках также надевают
                                        халат, используемые только в «родильном отделении».
                                    </li>
                                    <li>Идеальное покрытие пола и стен – крупная плитка. Можно использовать также
                                        полимерные смолы, вакуумированный бетон и даже линолеум, при условии, что он
                                        гладкий, в хорошем состоянии, не имеет пор и трещин.
                                    </li>
                                    <li>Необходимо соблюдать постоянную температуру (не ниже 24°С) и влажность
                                        (65-70°).
                                    </li>
                                    <li>Необходима правильная вентиляция помещения: необходимо создать такую разницу
                                        давлений, чтобы в «родильное отделение» не проникал воздух из других помещений
                                        питомника, сквозняки недопустимы.
                                    </li>
                                    <li>Инвентарь для уборки не должен использоваться в других секторах. Половые щетки,
                                        ведра, совки, швабры, губки не должны покидать пределов «родильного отделения».
                                        Будет полезно выделить уборочный инвентарь определенного цвета.
                                    </li>
                                    <li>Весь инвентарь (обогреватели, манежи) должен легко разбираться и
                                        дезинфицироваться.
                                    </li>
                                    <li>В «родильном отделении» не должно содержаться слишком много пометов
                                        одновременно: это повышает уровень микробной нагрузки и риск болезней. Норма
                                        площади: не менее 5 м2 на собаку.
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <button className="btn btn_blue" onClick={() => this.onClickLink(3)}>Продолжить</button>

                    </div>
                </div>
                }
                {linkActive === 3 &&
                <div className="infoPopap">
                    <div className="infoPopap_wrp">
                        <div className="infoPopap_close" onClick={() => this.onClickLink(0)}/>
                        <div className="infoPopap_scroll">
                            <div className="infoPopap_iconDiv">
                                <img src={img2} alt="" className="infoPopap_icon"/>
                            </div>
                            <div className="infoPopap_head">Зона для здоровых животных</div>
                            <div className="infoPopap_flex">
                                <div className="infoPopap_text">
                                    В этом отделении содержатся здоровые взрослые животные, участвующие в разведении.
                                    В идеале питомник должен располагать отдельными помещениями для беременных самок, самок в покое
                                    и самцов, но это не всегда возможно.
                                </div>
                                <img src={step7_p3} alt=""/>
                            </div>
                            <InfoBlock
                                danger={true}
                                text={`В зоне для здоровых животных, участвующих в разведении, необходимо:

                                    • Оборудовать механическую вентиляцию во всех помещениях;

                                    • Предусмотреть достаточное жизненное пространство для комфортного существования животного;

                                    • Ограничивать количество животных в комнате / боксе / вольере;

                                    • Предусмотреть хорошую санитарную изоляцию боксов / вольеров друг от друга (сплошные перегородки);

                                    • Если питомник находится в квартире, недопустимы ковры на полу и нежелательны обои на стенках.`
                                }
                            />

                        </div>
                        <button className="btn btn_blue" onClick={() => this.onClickLink(4)}>Продолжить</button>

                    </div>
                </div>
                }
                {linkActive === 4 &&
                <div className="infoPopap">
                    <div className="infoPopap_wrp">
                        <div className="infoPopap_close" onClick={() => this.onClickLink(0)}/>
                        <div className="infoPopap_scroll">
                            <div className="infoPopap_iconDiv">
                                <img src={img3} alt="" className="infoPopap_icon"/>
                            </div>
                            <div className="infoPopap_head">Обустройство изолятора</div>
                            <div className="infoPopap_flex">
                                <div className="infoPopap_text">
                                    Здесь находятся больные
                                    и выздоравливающие животные питомника, а также потенциальные носители патогенных микроорганизмов.
                                    <br/>
                                    <br/>
                                    В домашних условиях создать полноценный изолятор очень сложно.
                                </div>
                                <img src={step7_p4} alt=""/>
                            </div>
                            <InfoBlock
                                danger={true}
                                text={`В изоляторе питомника, необходимо:

                                    • Изолятор должен находится в «чистом» секторе питомника.

                                    • При посещении изолятора следует пользоваться бахилами и халатами.

                                    • Изолятор должно быть оборудован собственным мусорным баком.

                                    • Помещение изолятора должно быть герметичным, что позволяет проводить его полную дезинфекцию, в том числе и горячими парами формалина.

                                    • Выбор отделочных материалов должен позволять произвести эффективную дезинфекцию и полную санитарную обработку после того, как животное выйдет из изолятора.

                                    • Пол и стены изолятора должны быть отделаны плиткой, в изоляторе не должно быть мебели. Гладкие поверхности гораздо эффективнее подвергаются обработке, чем пористые (древесина, обои, текстиль)`
                                }
                            />

                        </div>
                        {/*<button className="btn btn_blue" onClick={() => this.onClickLink(0)}>Продолжить</button>*/}

                    </div>
                </div>
                }
            </div>
        )
    }
}