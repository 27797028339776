import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img6_6 from '../../../image/lessons/lesson6/step6-6.png'
import img6_popap1 from '../../../image/lessons/lesson6/step6-popap1.png'
import img6_popap2 from '../../../image/lessons/lesson6/step6-popap2.png'
import img6_popap3 from '../../../image/lessons/lesson6/step6-popap3.png'
import img6_popap4 from '../../../image/lessons/lesson6/step6-popap4.png'
import img6_popap5 from '../../../image/lessons/lesson6/step6-popap5.png'
import img6_popap6 from '../../../image/lessons/lesson6/step6-popap6.png'
import img6_popap7 from '../../../image/lessons/lesson6/step6-popap7.png'


export default class step6 extends React.Component {
    state = {
        popapActive: 0,
        popapShow: false,
        points0: []
    };

    showPopap = popapActive => {
        const {popapShow, points0} = this.state;
        if (!popapShow) {
            if (points0.indexOf(popapActive) === -1) {
                points0.push(popapActive)
            }
            this.setState({
                popapActive,
                popapShow: !popapShow,
                points0
            })
        } else {
            this.setState({
                popapActive: popapActive,
                //popapShow: !popapShow
            })
            if(popapActive === 0 || popapActive === 8) {
                this.setState({popapShow: !popapShow})
            }
            if (points0.indexOf(popapActive) === -1) {
                points0.push(popapActive)
            }
        }
        if (points0.length === 8) {
            this.props.showNextBtn();
        }
    }


    popapWrp = {
        0: {img: '', head: '', text: ''},
        1: {
            img: img6_popap1,
            head: `Ежедневный осмотр`,
            text: `<span class="text_dark-blue"><b>Собак ежедневно тщательно осматривают.</b></span> 

            При осмотре обращают внимание на общее состояние животного, его настроение, активность, упитанность, состояние шерстного покрова и отдельных органов.
            `
        },
        2: {
            img: img6_popap2,
            head: `Глаза`,
            text: `По состоянию глаз (веселый или грустный взгляд, цвет слизистой оболочки, отсутствию выделений) можно судить в известной степени о состоянии организма собаки в целом.`
        },
        3: {
            img: img6_popap3,
            head: `Нос`,
            text: `Нет ли царапин, слизистых и гнойных выделений.`
        },
        4: {
            img: img6_popap4,
            head: `Ротовая полость`,
            text: `Цвет слизистых оболочек, состояние зубов и эмали, нет ли застрявших мелких костей и других инородных предметов.`
        },
        5: {
            img: img6_popap5,
            head: `Уши`,
            text: `В ушах можно обнаружить ушную серу (коричневого цвета) и небольшие инородные предметы (щепки, насекомые и др.). 
            При заболевании ушей возможны выделения из них, неприятный запах. У собак с длинной шерстью нужно выстригать уши внутри. Допускается очищать ушную раковину изнутри, но недоспустима глубокая чистка ушей собак ватными палочками из-за особенностей строения слухового канала.`
        },
        6: {
            img: img6_popap6,
            head: `Шерстный покров`,
            text: `Общее состояние, наличие блеска, выпадения волос, неприятного запаха.`
        },
        7: {
            img: img6_popap7,
            head: `Лапы`,
            text: `Состояние когтей, нет ли заноз, ран, как собака двигается.`
        }
    }

    infoPoints0 = [1, 2, 3, 4, 5, 6, 7];


    render() {
        const {popapShow, popapActive, points0} = this.state;
        return (
            <div className="step6">
                <InfoBlock
                    text={
                        `Нажмите на активные элементы, чтобы узнать больше.`
                    }
                />
                <div className="imgDiv info">
                    <img src={img6_6} alt="" className='infoImg'/>
                    <div className="infoPoints">
                        {this.infoPoints0.map(item => {
                            return (
                                <div
                                    key={item}
                                    className={'point ' + (points0.indexOf(item) >= 0 && 'active')}
                                    onClick={() => {
                                        this.showPopap(item)
                                    }
                                    }>
                                    ?
                                </div>
                            )
                        })}
                    </div>
                </div>
                {popapShow &&
                <div className={'infoPopap'}>
                    <div className="infoPopap_wrp">
                        <div className="popap_close" onClick={() => {
                            this.showPopap(0)
                        }}/>
                        <div className="image">
                            <div className="imageBg">
                                <img src={this.popapWrp[popapActive].img} alt=""/>
                            </div>
                        </div>
                        <div className="scroll">
                            <div className="head"
                                 dangerouslySetInnerHTML={{__html: this.popapWrp[popapActive].head}}/>
                            {this.popapWrp[popapActive].subHead &&
                            <div
                                className="subHead"
                                dangerouslySetInnerHTML={{__html: this.popapWrp[popapActive].subHead}}/>
                            }
                            <TextBlock
                                text={this.popapWrp[popapActive].text}
                                fontSize={16}
                            />
                            <button className="btn btn_blue" onClick={() => {
                                this.showPopap(popapActive + 1)
                            }}>Продолжить</button>
                        </div>
                    </div>
                </div>
                }

            </div>
        )
    }
}