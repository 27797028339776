import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson41/step33-1.png'
import img1xs from '../../../image/lessons/lesson41/step33-1xs.png'

export default class step33 extends React.Component {

    render() {
        return (
            <div className="step33">
                <div className="flex">
                    <TextBlock
                        text={`<b>Энергетические потребности щенка в период отъема от матери в 2-2,5 раза больше, чем у взрослой собаки такого же веса</b>
                        
                        Начиная с 3 недель щенку уже недостаточно питания материнским молоком. Необходимо начинать первый прикорм, помогая пищеварительной системе щенка постепенно адаптироваться к усвоению новых источников питательных веществ.
                        
                        <b>PEDIGREE® Первый прикорм</b> специально создан для перевода щенков с материнского молока на специальные рационы для щенков.
                        
                        <b>При смешивании с теплой водой</b> гранулы быстро впитывают воду и становятся мягкими.
                        
                        PEDIGREE® Первый прикорм необходимо предлагать щенку <b>перед кормлением материнским молоком.</b>`}
                    />
                    <picture>
                        <source srcSet={img1xs} media="(max-width: 767px)"/>
                        <img src={img1} alt="" className="flex_img"/>
                    </picture>

                </div>
            </div>
        )
    }
}
