import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson4/step11-1.png'
import img2 from '../../../image/lessons/lesson4/step11-2.png'
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";


export default class step6 extends React.Component {
    state = {
        pointActive: 0,
        points: [],
    };

    changePoint = pointActive => {
        this.setState({pointActive}, () => {
            this.checkPoint(pointActive)
        })
    }

    checkPoint = (point) => {
        const {points} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        console.log(points)
        if (points.length === 5) {
            this.props.showNextBtn()
        }
    }

    popapWrp = {
        1: {
            head: 'Для чего выводились?',
            text: `Почти все терьеры выведены в Англии, а немногочисленные «неанглийские» терьеры получены, опять-таки, на основе английских терьеров.

                    Изначально это были собаки небогатого фермера и охотника, предназначенные для защиты скота и птицы от мелких хищников и для норной охоты. Обладают огненным темпераментом и бешеной энергией.

                    Могли бы быть лучшими собаками для спорта, но как объяснить терьеру, что он должен делать то, что ему неинтересно с тем же азартом, с которым он делает то, что ему нравится? Это удалось немногим тренерам и спортсменам, но результаты неизменно впечатляют.`
        },
        2: {
            head: 'Характер',
            text: `Все терьеры – эмоциональные, непоседы, они очень преданны, но несколько своенравны и лишены чувства страха на аппаратном уровне.

                    Это очень приятные компаньоны, хотя и беспокойные, неизменно способствующие бодрости духа. Держат в тонусе.

                    Собаки крепкие, неприхотливые, долгожители, до преклонного возраста не теряющие живости.`
        },
        3: {
            head: 'Особенности внешности',
            text: `Гладкошерстные, не требующие особого ухода за шерстью породы терьеров немногочисленны, большинство терьеров – жесткошерстные и длинношерстные собаки, требующие регулярной стрижки и тримминга.

                    Размер – от карликового до среднего.`
        },
        4: {
            head: 'Типичные представители',
            text: `Сегодня у наших охотников наиболее популярен ягдтерьер, а большая часть прочих терьеров не ходит на охоту, а являются  компаньонами и успешными выставочными собаками.

                    Некогда очень популярные фокстерьеры  и скотчтерьеры сегодня встречаются реже, чем очень популярные йоркширские терьеры, вест-хайленд-уайт-терьеры и джек-рассел-терьеры. И, конечно, по-прежнему имеет преданных поклонников «король терьеров» - эрдельтерьер, самый крупный представитель группы.`
        },
    }


    render() {
        const {pointActive, points} = this.state;
        return (
            <div className="step11">
                <TextBlock
                    text={`<span class="text_subheader">Терьеры</span>`}
                />
                <InfoBlock
                    text={`Ознакомьтесь с каждой группой, нажав на ключевые элементы.`}
                />
                <div className="flex">
                    <img src={img1} alt="" className='mainImg'/>
                    <div className="flex_el"><span className={points.indexOf(1) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(1)}}>Для чего выводились?</span></div>
                    <div className="flex_el"><span className={points.indexOf(2) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(2)}}>Характер</span></div>
                    <div className="flex_el"><span className={points.indexOf(3) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(3)}}>Особенности внешности</span></div>
                    <div className="flex_el"><span className={points.indexOf(4) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(4)}}>Типичные представители</span></div>
                </div>

                {pointActive !== 0 &&
                <PopupInStep
                    closePopup={() => this.changePoint(0)}
                    popupImg={img2}
                    popupHead={this.popapWrp[pointActive].head}
                    popupText={this.popapWrp[pointActive].text}
                    changePoint={() => this.changePoint(pointActive < 4 ? pointActive + 1 : 0)}
                />
                }
            </div>
        )
    }
}
