import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";

export default class step2 extends React.Component {
    render() {
        return (
            <div className="step10">
                <InfoBlock text={`Ознакомьтесь с напутствующим советом при обучении видеокурса “Приучение собаки к наморднику”, а затем нажмите кнопку продолжить для проверки полученных знаний.`}/>
                <VideoBlock
                    src={'https://www.youtube.com/embed/EvvrCo8Rz4E'}
                    title={'https://www.youtube.com/watch?v=EvvrCo8Rz4E'}
                />
            </div>
        )
    }
}
