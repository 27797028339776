import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson5/step24-1.png'


export default class step5 extends React.Component {


    render() {
        return (
            <div className="step25">
                <TextBlock
                    text={`Используем рассмотренный ранее алгоритм из трех шагов для расчета суточной нормы рациона собаки.`}
                />
                <div className="ul">
                    <div className="li">
                        <div className="number">1.</div>
                        <div className="text">
                            Исходя из веса Рекса (34 кг), рассчитаем его суточную энергетическую потребность по формуле: <span
                            className="textBlue">Э = 95 * 34 <sup>0.75</sup> = 1 338 ккал / сутки.</span>
                        </div>
                    </div>
                    <div className="li">
                        <div className="number">2.</div>
                        <div className="text">
                            Мы уже знаем энергетическую ценность корма — 360 ккал / 100 г. Можно переходить
                            к финальному шагу.
                        </div>
                    </div>
                    <div className="li">
                        <div className="number">3.</div>
                        <div className="text">
                            Рассчитаем суточную норму рациона: <span className="textBlue">1 338 / 360 * 100 = 372 г / сутки</span> (с учетом округления).
                        </div>
                    </div>
                </div>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}