import React from 'react'
import img1 from '../../../image/lessons/lesson5/step17-1.png'


export default class step6 extends React.Component {


    render() {
        return (
            <div className="step17">
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_head">ДЛЯ ПИТОМЦЕВ</div>
                        <div className="flex_wrp">
                            <ul>
                                <li>Высокая энергетическая ценность. Сухой корм примерно в 4 раза более калорийный, чем влажный, поэтому порции имеют меньший размер.</li>
                                <li>Улучшает гигиену ротовой полости за счет механической чистки зубов.</li>
                                <li>Поддерживает моторику желудочно-кишечного тракта за счет высокого содержания клетчатки.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_head">ДЛЯ ХОЗЯЕВ</div>
                        <div className="flex_wrp">
                            <ul>
                                <li>Экономия. За счет маленьких порций корм расходуется медленнее
                                    и покупать его можно реже.</li>
                                <li>Длительный срок годности.</li>
                                <li>Удобно хранить и использовать.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}