import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";
import img1 from "../../../image/lessons/lesson40/step9-1.png";
import img1xs from "../../../image/lessons/lesson40/step9-1xs.png";

export default class step9 extends React.Component {


    render() {
        return (
            <div className="step9">
                <TextBlock
                    text={`Практикующие ветеринарные врачи в России подтверждают эффективность PEDIGREE® на основании клинических исследований.`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img className="img" src={img1} alt=""/>
                </picture>
            </div>
        )
    }
}
