import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import img1 from '../../../image/lessons/lesson4/step4-1.png';
import img2 from '../../../image/lessons/lesson4/step4-2.png';


export default class step4 extends React.Component {
    state = {
        flexActive: 0,
        flexs: [1],
        flexHidden: false,
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs, flexHidden: true})
        if (flexs.length === 2) {
            this.props.showNextBtn()
        }
    }

    closeImgDiv = () => {this.setState({flexHidden: false, flexActive: 0})}


    render() {
        const {flexActive, flexHidden} = this.state
        return (
            <div className="step4">
                <TextBlock
                    text={`Итак, всех собак можно условно разделить на две большие группы по породной принадлежности: собаки бывают породистые и беспородные.`}
                    hidden={flexHidden === true ? 'hidden' : ''}
                />
                <InfoBlock
                    text={`Ознакомьтесь с каждой группой, нажав на ключевые элементы.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />

                <div className={'flex ' + (flexHidden === true && 'hidden')}>
                    <div className={'flex_el ' + (flexActive === 1 && 'active')} onClick={() => {
                        this.changeFlex(1)
                    }}>Беспородные
                    </div>
                    <div className={'flex_el ' + (flexActive === 2 && 'active')} onClick={() => {
                        this.changeFlex(2)
                    }}>Породистые
                    </div>
                </div>
                {flexActive !== 0 &&
                <div className={'imgDiv ' + (flexActive === 2 ? 'imgDiv--2' : flexActive === 3 ? 'imgDiv--3': flexActive === 4 ? 'imgDiv--4' : '')}>
                    <div
                        className="imgDiv_closed"
                        onClick={() => {this.closeImgDiv()}}
                    />
                    {flexActive === 1 &&
                    <div className="imgDiv_wrap">
                        <div className="imgPhone">
                            <img className="imgPhoneBg" src={img1} alt=""/>
                        </div>
                        <div className="imgDiv_flex">
                            <TextBlock
                                text={`Беспородные собаки могут быть совсем неизвестного происхождения или помесями пород, что, в свою очередь, не позволяет с высокой вероятностью предугадать особенности их поведения и экстерьера.`}
                            />
                            <img src={img1} alt=""/>
                        </div>
                        <TextBlock
                            text={`Среди беспородных собак можно обнаружить весь спектр собачьих свойств и характеров, чаще всего в таких сочетаниях, которые не может «предложить» ни одна специализированная порода. Поэтому если вам нужен просто четвероногий друг, есть смысл обратить внимание на беспородных собак.`}
                        />
                    </div>
                    }
                    {flexActive === 2 &&
                    <div className="imgDiv_wrap">
                        <div className="imgPhone">
                            <img className="imgPhoneBg" src={img2} alt=""/>
                        </div>
                        <div className="imgDiv_flex">
                            <TextBlock
                                text={`Породистые. Изначально «пород» не было — просто были беспородные собаки, из которых человек, руководствуясь теми или иными целями, отбирал и скрещивал между собой определенных особей. Порода – это совокупность собак общего происхождения, которые обладают специфическим экстерьером, особыми свойствами темперамента и поведения, а также другими породными качествами, описанными в стандарте породы. Эти породные качества передаются по наследству и поддерживаются племенной работой. 
                                
                                        Облик и поведение породистых собак являются синтезом элементов конкретной культуры и эстетических взглядов, характер большинства пород отражает особенности характера их создателей. Поведение породистой собаки определяется целями, для которых выводилась порода: охранная, ездовая, охотничья, пастушья или собака-компаньон. Сейчас существует множество разных пород, с которыми нам предстоит ознакомиться.`}
                            />
                            <img src={img2} alt=""/>
                        </div>
                    </div>
                    }
                </div>
                }
            </div>
        )
    }
}
