import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";

export default class step12 extends React.Component {
    render() {
        return (
            <div className="step12">
                <InfoBlock text={`Ознакомьтесь с содержанием видео ролика.`}/>
                <VideoBlock
                    src={'https://www.youtube.com/embed/MgXSy4WYGZI'}
                    title={'https://www.youtube.com/watch?v=MgXSy4WYGZI'}
                />
            </div>
        )
    }
}
