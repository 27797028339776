import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson36/step13-1.png'
import img2 from '../../../image/lessons/lesson36/step13-2.png'
import img3 from '../../../image/lessons/lesson36/step13-3.png'
import img4 from '../../../image/lessons/lesson36/step13-4.png'


export default class step13 extends React.Component {

    render() {
        return (
            <div className="step13">
                <TextBlock
                    text={`Как вы помните, по балансу питательных веществ готовые рационы делятся на полнорационные и неполнорационные корма.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <h3 className="flex_el_head">
                            Полнорационные корма
                        </h3>
                        <p className="flex_el_text">
                            <b>Полностью удовлетворяют все потребности</b> животного. Питомца можно кормить готовыми полнорационными кормами <b>на протяжении всей его жизни.</b>
                        </p>
                        <img className="flex_el_img" src={img1} alt=""/>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_el_head">
                            Неполнорационные корма
                        </h3>
                        <p className="flex_el_text">
                            Лакомства – это неполнорационные корма, поэтому они не могут использоваться как основное питание, а являются дополнением к нему.
                        </p>
                        <img className="flex_el_img" src={img4} alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}
