import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson4/step17-1-1.png'
import img2 from '../../../image/lessons/lesson4/step17-1-2.png'
import img3 from '../../../image/lessons/lesson4/step17-2.png'
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";


export default class step6 extends React.Component {
    state = {
        pointActive: 0,
        points: [],
    };

    changePoint = pointActive => {
        this.setState({pointActive}, () => {
            this.checkPoint(pointActive)
        })
    }

    checkPoint = (point) => {
        const {points} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        console.log(points)
        if (points.length === 5) {
            this.props.showNextBtn()
        }
    }

    popapWrp = {
        1: {
            head: 'Для чего выводились?',
            text: `Если вы планируете охотиться, то более универсальной и надежной рабочей собаки вам не найти. Не случайно охотники-промысловики (профессиональные охотники) используют только лаек. 
            
            Все лайки – очень активные собаки, требующие продолжительных прогулок. Имеют сильный характер и склонность к самостоятельности, высокую работоспособность, исключительные охотничьи качества. К людям достаточно дружелюбны, но при случае могут встать на защиту. `
        },
        2: {
            head: 'Характер',
            text: `Лайкообразные – очень выносливые, неприхотливые, жизнестойкие собаки. Ездовые собаки и охотничьи лайки отличаются тем, что рассматривают человека в довольно широком диапазоне – от партнера до союзника, но никак не господина.
            
            Лайка может отважно атаковать зверя на охоте, но если она гуляет и не хочет идти домой – почти никакая сила не может заставить ее изменить свое решение.
            
            Поэтому содержание лаек в городе сопряжено с определенными трудностями и требует очень ответственного подхода к обучению собаки послушанию.`
        },
        3: {
            head: 'Особенности внешности',
            text: `Лайки отличаются весьма простым, крепким телосложением. У них прямая шерсть средней длины с густым подшёрстком,  небольшие остроконечные уши, хвост закручен в кольцо. 
            
            Долгожители. Шерсть большинства пород охотничьих лаек ухода не требует, но в период сезонной линьки требует вычёсывания.`
        },
        4: {
            head: 'Типичные представители',
            text: `Отечественные охотничьи лайки (русско-европейские и западно-сибирские) – самые массовые собаки у наших охотников.`
        },
    }


    render() {
        const {pointActive, points} = this.state;
        return (
            <div className="step13">
                <TextBlock
                    text={`<span class="text_subheader">Секция 2. Северные охотничьи собаки</span> `}
                />
                <InfoBlock
                    text={`Ознакомьтесь с каждой группой, нажав на ключевые элементы.`}
                />
                <div className="flex">
                    <div className="flexImg">
                        <img src={img1} alt="" className=''/>
                        <img src={img2} alt="" className=''/>
                    </div>
                    <div className="flex_el"><span className={points.indexOf(1) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(1)}}>Для чего выводились?</span></div>
                    <div className="flex_el"><span className={points.indexOf(2) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(2)}}>Характер</span></div>
                    <div className="flex_el"><span className={points.indexOf(3) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(3)}}>Особенности внешности</span></div>
                    <div className="flex_el"><span className={points.indexOf(4) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(4)}}>Типичные представители</span></div>
                </div>

                {pointActive !== 0 &&
                <PopupInStep
                    closePopup={() => this.changePoint(0)}
                    popupImg={img3}
                    popupHead={this.popapWrp[pointActive].head}
                    popupText={this.popapWrp[pointActive].text}
                    changePoint={() => this.changePoint(pointActive < 4 ? pointActive + 1 : 0)}
                />
                }
            </div>
        )
    }
}
