import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson5/step31-1.png'


export default class step6 extends React.Component {


    render() {
        return (
            <div className="step31">
                <TextBlock
                    text={`Чтобы не нарушить сбалансированный рацион питомца, нужно следить за правильным использованием угощений и соблюдать следующие рекомендации:`}
                />
                <div className="flex">
                    <div className="ul">
                        <div className="li">
                            <div className="number">1</div>
                            <div className="text">
                                Количество энергии, потребляемой
                                с лакомствами, не должно
                                превышать <b> 15% от суточной
                                потребности</b> животного в энергии.
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">2</div>
                            <div className="text">
                                Следует использовать только
                                лакомства, <b>специально
                                предназначенные</b> для животных.
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">3</div>
                            <div className="text">
                                В качестве <b>поощрения</b> нужно
                                использовать не только корм,
                                но также игрушки и подвижные игры.
                            </div>
                        </div>
                    </div>
                    <img src={img1} alt=""/>
                </div>
                <div className="ul">
                    <div className="li">
                        <div className="number">4</div>
                        <div className="text">
                            При применении специализированных лакомств (например, для поддержания здоровья ротовой полости) следует тщательно <b>соблюдать рекомендации</b> по их количеству.
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}