import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import img1 from '../../../image/lessons/lesson3/step8-1.png';
import img2 from "../../../image/lessons/lesson3/step8-2.png";
import img3 from "../../../image/lessons/lesson3/step8-3.png";
import img4 from "../../../image/lessons/lesson3/step8-4.png";
import img5 from "../../../image/lessons/lesson3/step8-5.png";
import step7_p2 from "../../../image/lessons/lesson3/step7-p2.png";
import step8_p2 from "../../../image/lessons/lesson3/step8-p2.png";

export default class step8 extends React.Component {
    state = {
        linkActive: 0,
        links: [],
    };

    onClickLink = linkActive => {
        const {links} = this.state;
        if (links.indexOf(linkActive) === -1) {
            links.push(linkActive)
        }
        this.setState({linkActive, links});
        if (links.length === 7) {
            this.props.showNextBtn()
        }
    }


    render() {
        const {links, linkActive} = this.state;
        return (
            <div className="step8">
                <TextBlock
                    fomtSize={16}
                    text={`Все помещения, куда допускаются люди или животные со стороны («чужая грязь»).`}
                />
                <InfoBlock
                    text={`Ознакомьтесь с определением гигиены «грязного» сектора, нажав на ключевые элементы.`}
                />
                <div className="flex">
                    <div className={'flex_el ' + (links.indexOf(1) >= 0 && 'active')} onClick={() => {
                        this.onClickLink(1)
                    }}>
                        <div className="img">
                            <img src={img1} alt=""/>
                        </div>
                        <div className="text">
                            Зоогостиница
                        </div>
                    </div>
                    <div className={'flex_el ' + (links.indexOf(2) >= 0 && 'active')} onClick={() => {
                        this.onClickLink(2)
                    }}>
                        <div className="img">
                            <img src={img2} alt=""/>
                        </div>
                        <div className="text">
                            Карантинное <br/>
                            отделение
                        </div>
                    </div>
                    <div className={'flex_el ' + (links.indexOf(4) >= 0 && 'active')} onClick={() => {
                        this.onClickLink(4)
                    }}>
                        <div className="img">
                            <img src={img3} alt=""/>
                        </div>
                        <div className="text">
                            Помещение <br/>
                            для вязок
                        </div>
                    </div>
                    <div className={'flex_el ' + (links.indexOf(5) >= 0 && 'active')} onClick={() => {
                        this.onClickLink(5)
                    }}>
                        <div className="img">
                            <img src={img4} alt=""/>
                        </div>
                        <div className="text">
                            Помещение <br/>
                            для груминга
                        </div>
                    </div>
                    <div className={'flex_el ' + (links.indexOf(6) >= 0 && 'active')} onClick={() => {
                        this.onClickLink(6)
                    }}>
                        <div className="img">
                            <img src={img5} alt=""/>
                        </div>
                        <div className="text">
                            Помещение <br/>
                            для пожилых <br/>
                            Собак
                        </div>
                    </div>
                </div>

                {linkActive === 1 &&
                <div className="infoPopap">
                    <div className="infoPopap_wrp">
                        <div className="infoPopap_close" onClick={() => this.onClickLink(0)}/>
                        <div className="infoPopap_scroll">
                            <div className="infoPopap_iconDiv">
                                <img src={img1} alt="" className="infoPopap_icon"/>
                            </div>
                            <div className="infoPopap_head">Зоогостиница</div>
                            <div className="infoPopap_text">
                                В зоогостинице размещают сторонних животных, которые не попадут в помещения питомника,
                                поэтому она должна быть последним сектором питомника.
                                <br/>
                                <br/>
                                Необходимо разделить зоны выгула животных питомника и зоогостиницы, хорошим вариантом
                                станет живая изгородь. Если
                                в зоогостинице содержится несколько животных из разных мест, важно обеспечить их
                                изоляцию друг от друга и соблюдать правила карантина.
                            </div>
                        </div>
                        <button className="btn btn_blue" onClick={() => this.onClickLink(2)}>Продолжить</button>

                    </div>
                </div>
                }
                {linkActive === 2 &&
                <div className="infoPopap">
                    <div className="infoPopap_wrp">
                        {/*<div className="infoPopap_close" onClick={() => this.onClickLink(0)}/>*/}
                        <div className="infoPopap_scroll">
                            <div className="infoPopap_iconDiv">
                                <img src={img2} alt="" className="infoPopap_icon"/>
                            </div>
                            <div className="infoPopap_head">Карантинное отделение</div>
                            <div className="infoPopap_text">
                                <span className="textBlue">Карантин необходим для предотвращения проникновения чужеродных микроорганизмов в питомник.</span>
                                <br/>
                                <br/>
                                Здесь содержатся все  поступившие в питомник животные, в том числе вернувшиеся
                                <span className="textBlue">с выставок, вязок в других питомниках, из зоогостиниц</span>.
                                <br/>
                                <br/>
                                Помещение в карантин позволяет:
                                <br/>
                                <br/>
                                <ul>
                                    <li>подробно обследовать животное, в том числе обнаружить, имеются ли у него
                                        симптомы инфекционных заболеваний;
                                    </li>
                                    <li>избежать появления в питомнике новых патогенов;</li>
                                    <li>подготовить организм нового животного к присутствующей условно патогенной флоре
                                        питомника (для этого некоторые заводчики подселяют к новичку другую взрослую
                                        особь на вторую половину периода карантина).
                                    </li>
                                </ul>
                            </div>
                            <div className="infoPopap_imgDiv">
                                <img src={step8_p2} alt="" className="infoPopap_img"/>
                            </div>
                        </div>
                        <button className="btn btn_blue" onClick={() => this.onClickLink(3)}>Это важно</button>

                    </div>
                </div>
                }
                {linkActive === 3 &&
                <div className="infoPopap">
                    <div className="infoPopap_wrp danger">
                        <div className="infoPopap_close" onClick={() => this.onClickLink(0)}/>
                        <div className="infoPopap_scroll">
                            <div className="infoPopap_iconDiv">
                                <img src={step7_p2} alt="" className="infoPopap_icon"/>
                            </div>
                            <div className="infoPopap_head">Важно знать! Карантин!</div>
                            <div className="infoPopap_text">
                                Помещение для карантина должно быть герметично, изолировано  от остальных животных и оборудовано индивидуальным инвентарем для уборки и мусорным баком.
                                <br/>
                                <br/>
                                Перед прохождением карантина необходима чистка шерстяного покрова животных, так как на нем могут иметься болезнетворные микробы и паразиты.
                            </div>
                        </div>
                        <button className="btn btn_blue" onClick={() => this.onClickLink(4)}>Продолжить</button>

                    </div>
                </div>
                }
                {linkActive === 4 &&
                <div className="infoPopap">
                    <div className="infoPopap_wrp">
                        <div className="infoPopap_close" onClick={() => this.onClickLink(0)}/>
                        <div className="infoPopap_scroll">
                            <div className="infoPopap_iconDiv">
                                <img src={img3} alt="" className="infoPopap_icon"/>
                            </div>
                            <div className="infoPopap_head">Помещение для вязок</div>
                            <div className="infoPopap_text">
                                Желательно привозить самку к самцу, а не наоборот. При вязке
                                у животных должно быть достаточно места, чтобы поиграть друг
                                с другом. В питомнике для вязок следует выделить отдельное помещение в целях обеспечения его санитарной изоляции.
                            </div>
                        </div>
                        <button className="btn btn_blue" onClick={() => this.onClickLink(5)}>продолжить</button>

                    </div>
                </div>
                }
                {linkActive === 5 &&
                <div className="infoPopap">
                    <div className="infoPopap_wrp">
                        <div className="infoPopap_close" onClick={() => this.onClickLink(0)}/>
                        <div className="infoPopap_scroll">
                            <div className="infoPopap_iconDiv">
                                <img src={img4} alt="" className="infoPopap_icon"/>
                            </div>
                            <div className="infoPopap_head">Помещение для груминга</div>
                            <div className="infoPopap_text">
                                В помещении для груминга обслуживаются только животные питомника. Для его отделки желательно выбирать легко моющиеся
                                и дезинфицирующиеся материалы (гладкие невпитывающие поверхности).
                            </div>
                        </div>
                        <button className="btn btn_blue" onClick={() => this.onClickLink(6)}>продолжить</button>
                    </div>
                </div>
                }
                {linkActive === 6 &&
                <div className="infoPopap">
                    <div className="infoPopap_wrp">
                        <div className="infoPopap_close" onClick={() => this.onClickLink(0)}/>
                        <div className="infoPopap_scroll">
                            <div className="infoPopap_iconDiv">
                                <img src={img5} alt="" className="infoPopap_icon"/>
                            </div>
                            <div className="infoPopap_head">Помещение для пожилых собак</div>
                            <div className="infoPopap_text">
                                Для отделки помещения для животных, выведенных из разведения, желательно выбирать легко моющиеся и дезинфицирующиеся материалы (гладкие невпитывающие поверхности).
                            </div>
                        </div>
                        {/*<button className="btn btn_blue" onClick={() => this.onClickLink(0)}>продолжить</button>*/}
                    </div>
                </div>
                }

            </div>
        )
    }
}