import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson33/step13-1.png'


export default class step13 extends React.Component {

    render() {
        return (
            <div className="step13">
                <TextBlock
                    text={`Собаки различных размеров растут с разной скоростью и становятся взрослыми в разное время. <b>Чем меньше собака, тем быстрее проходит ее период роста и развития.</b>`}
                />
                <img src={img1} alt=""/>
                <TextBlock
                    text={`На графике показано различие в темпах роста различных пород собак. Как вы видите, йоркширский терьер вырастает до взрослых размеров уже в 9 месяцев, в то время как ньюфаундленд достигает взрослых размеров в 2 года.`}
                />
            </div>
        )
    }
}
