import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import img1 from '../../../image/lessons/lesson2/step3-1.png';

export default class step3 extends React.Component {
    state = {
        points: []
    };

    setPoints = point => {
        const {points} = this.state
        if(points.indexOf(point) === -1){
            points.push(point)
            this.setState({points})
        }

        if(points.length === 3){
            this.props.showNextBtn()
        }
    }




    render() {
        const {points} = this.state
        return (
            <div className="step3">
                <InfoBlock text={`Ознакомьтесь с существующими вариантами, нажав на активные элементы.`}/>

                <div className="flex">
                    <div className="points">
                        <div className={'point ' + (points.indexOf(1) >= 0 && 'active')}>
                            <div className="text">
                                С щенком можно гулять только после того, как сделаны все прививки.
                            </div>
                            <div className="number" onClick={()=>{this.setPoints(1)}}>1</div>
                        </div>
                        <div className={'point ' + (points.indexOf(2) >= 0 && 'active')}>
                            <div className="text">
                                С маленьким щенком можно выходить почаще на короткое время, выводить после еды, чтобы быстрее приучить его к туалету на улице (и заодно приучить себя убирать за питомцем).
                            </div>
                            <div className="number" onClick={()=>{this.setPoints(2)}}>2</div>
                        </div>
                        <div className={'point ' + (points.indexOf(3) >= 0 && 'active')}>
                            <div className="text">
                                Более продолжительных прогулок должно быть не менее двух (до еды или через полтора-два часа после еды).
                                Во время этих прогулок щенок может подвигаться, с ним можно позаниматься.
                            </div>
                            <div className="number" onClick={()=>{this.setPoints(3)}}>3</div>
                        </div>
                    </div>
                    <img src={img1} alt="" className={'flex_img'}/>
                </div>
            </div>
        )
    }
}