import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";

export default class step3 extends React.Component {
    state = {
    };


    render() {
        return (
            <div className="step3">
                <TextBlock
                    text={`Усложним – положим еду внутрь или около раздражителя.`}
                />
                <InfoBlock text={`Ознакомьтесь с содержанием видео ролика.`}/>
                <VideoBlock
                    src={'https://www.youtube.com/embed/HiGq1OSQ-wc'}
                    title={'https://www.youtube.com/watch?v=HiGq1OSQ-wc'}
                />
            </div>
        )
    }
}
