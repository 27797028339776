import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1 from '../../../image/lessons/lesson3/step13.png'

import dopImg1 from '../../../image/lessons/lesson3/step13-1.png'
import dopImg2 from '../../../image/lessons/lesson3/step13-2.png'
import dopImg3 from '../../../image/lessons/lesson3/step13-3.png'
import dopImg4 from '../../../image/lessons/lesson3/step13-4.png'

import dopPopapImg1 from '../../../image/lessons/lesson3/step13-dp1.png'
import dopPopapImg2 from '../../../image/lessons/lesson3/step13-dp2.png'
import dopPopapImg3 from '../../../image/lessons/lesson3/step13-dp3.png'
import dopPopapImg4 from '../../../image/lessons/lesson3/step13-dp4.png'

import dop2img from '../../../image/lessons/lesson3/step13-d2.png'



export default class step13 extends React.Component {
    state = {
        linkActive: 0,
        links: [],
        dopLinkActive: 0,
        dopLinks: [],
        dop3LinkActive: 0,
        dop3Links: [],
        showDop1Btn: false,
        showDop3Btn: false,
    };

    onClickLink = linkActive => {
        const {links} = this.state;
        if (links.indexOf(linkActive) === -1) {
            links.push(linkActive)
        }
        this.setState({linkActive, links});
        if (links.length === 5 && linkActive === 0) {
            this.props.showNextBtn()
        }else{
            this.props.hideNextBtn()
        }
    }
    onClickDop = dopLinkActive => {
        const {dopLinks} = this.state;
        if (dopLinks.indexOf(dopLinkActive) === -1) {
            dopLinks.push(dopLinkActive)
        }
        this.setState({dopLinkActive, dopLinks});

        if (dopLinks.length === 5 && dopLinkActive === 0) {
            this.setState({
                showDop1Btn: true
            })
        }
    }
    onClickDop3 = dop3LinkActive => {
        const {dop3Links} = this.state;
        if (dop3Links.indexOf(dop3LinkActive) === -1) {
            dop3Links.push(dop3LinkActive)
        }
        this.setState({dop3LinkActive, dop3Links});

        if (dop3Links.length === 2) {
            this.setState({
                showDop3Btn: true
            })
        }
    }

    dopPopap = {
        1: {
            icon: dopPopapImg1,
            head: 'Контроль влажности',
            ul: {
                li1: 'Оптимальный уровень влажности воздуха в помещениях для взрослых животных – 55 ± 10%;',
                li2: 'Используйте при обустройстве питомника водоотталкивающие материалы;',
                li3: 'Старайтесь проводить уборку в солнечные перио ды с целью ускорения сушки;',
                li4: 'Используйте осушители с фильтром от запахов;',
                li5: 'Увеличивайте частоту уборок во влажную или дождливую погоду (во избежание застаивания запахов);',
                li6: 'О том, что достигнут необходимый уровень влажности, свидетельствует отсутствие конденсата на стенах и стёклах;',
                li7: 'Желательно иметь в питомнике гигрометр, чтобы с его помощью осуществлять постоянный контроль этого параметра.'
            }
        },
        2: {
            icon: dopPopapImg2,
            head: 'Контроль температуры',
            text: `Для каждого животного, в зависимости от его возраста и породы, существует диапазон температур воздуха, в котором животному не приходится затрачивать энергию на поддержание температуры своего тела.

                Не существует идеального температурного значения, подходящего для всех особей. Поэтому у животных всегда должны быть возможность укрыться от жары или холода.`,
        },
        3: {
            icon: dopPopapImg3,
            head: 'Контроль циркуляции воздуха',
            text: `Вентиляция – чрезвычайно важный фактор в организации питомника, она должна работать постоянно и эффективно. Воздух необходимо обновлять от 8 до 30 раз в час (в летний период, а также при высокой плотности заселения питомника).
                Следите за исправностью, из верхней части обитаемых помещений питомника, поскольку неприятные запахи (амиак, метан, гнилостные газы) имеют тенденцию перемещаться вверх, следствие эффекта дымохода.
                Используйте очистители воздуха.`,
        },
        4: {
            icon: dopPopapImg4,
            head: 'Контроль питания и соблюдение гигиены',
            text: `Кормите животных высокоусвояемым промышленным кормом, который хорошо ими усваивается, - это уменьшает объем фекалий и количество газов. 

                Не рекомендуется использовать ароматизаторы, поскольку они только маскируют неприятные запахи, но не устраняют их, и могут вызвать у некоторых животных аллергию. Подстилки и лежаки должны быть пригодны для регулярной стирки или замены.`,
        },
    }


    _renderMain = () => {
        const {links} = this.state
        return (
            <div>
                <TextBlock
                    fontSize={16}
                    text={`Эти факторы также являются важными аспектами гигиены питомникаи оказывают существенное влияние на качество жизни животных.`}
                />
                <InfoBlock
                    text={`Ознакомьтесь с наиболее неблагоприятными факторами гигиены питомника, нажав на ключевые элементы.`}
                />
                <div className="flex">
                    <img src={img1} alt="" className={'flex_img'}/>
                    <div className="flex_btns">
                        <div className={'flex_btns_el ' + (links.indexOf(1) >= 0 && 'active')} onClick={() => {
                            this.onClickLink(1)
                        }}>Неприятные запахи
                        </div>
                        <div className={'flex_btns_el ' + (links.indexOf(2) >= 0 && 'active')} onClick={() => {
                            this.onClickLink(2)
                        }}>Шум
                        </div>
                        <div className={'flex_btns_el ' + (links.indexOf(3) >= 0 && 'active')} onClick={() => {
                            this.onClickLink(3)
                        }}>Паразиты
                        </div>
                        <div className={'flex_btns_el ' + (links.indexOf(4) >= 0 && 'active')} onClick={() => {
                            this.onClickLink(4)
                        }}>Стресс
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    _renderDop = () => {
        const {linkActive, dopLinkActive, showDop1Btn, showDop3Btn, dop3LinkActive} = this.state;
        if(linkActive === 1) {
            return (
                <div>
                    <TextBlock
                        fontSize={16}
                        text={`Борьба с неприятными запахами = контроль влажности + контроль температуры + контроль циркуляции воздуха + ограничение возможностей для их появления (питание и гигиена).`}
                    />
                    <InfoBlock
                        text={`Ознакомьтесь с факторами неприятого запаха, нажав на ключевые элементы.`}
                    />
                    <div className="flexDop">
                        <div className="flexDop_el" onClick={() => this.onClickDop(1)}>
                            <img src={dopImg1} alt="" className="flexDop_img"/>
                            Контроль <br/>
                            влажности
                        </div>
                        <div className="flexDop_el" onClick={() => this.onClickDop(2)}>
                            <img src={dopImg2} alt="" className="flexDop_img"/>
                            Контроль <br/>
                            температуры
                        </div>
                        <div className="flexDop_el" onClick={() => this.onClickDop(3)}>
                            <img src={dopImg3} alt="" className="flexDop_img"/>
                            Контроль <br/>
                            циркуляции <br/>
                            воздуха
                        </div>
                        <div className="flexDop_el" onClick={() => this.onClickDop(4)}>
                            <img src={dopImg4} alt="" className="flexDop_img"/>
                            Контроль <br/>
                            питания и <br/>
                            соблюдение <br/>
                            гигиены
                        </div>
                    </div>

                    {showDop1Btn &&
                        <button className="btn btn_blue btn_absolute"
                                onClick={() => this.onClickLink(0)}
                        >
                            Продолжить
                        </button>
                    }
                    {dopLinkActive !== 0 && this._remderDopPopap()}

                </div>
            )
        }else if (linkActive === 2){
            return (
                <div className={'dop2'}>
                    <TextBlock
                        fontSize={16}
                        text={`Предвидеть причины лая и предотвратить его проще, чем устранять его последствия.`}
                    />
                    <img src={dop2img} alt="" className={'dop2Img'}/>
                    <ul className={'dop2li'}>
                        <li>Приучать собак к размеренному ритму жизни, создаваемому работами по обслуживанию питомника,
                            раздачей корма, распорядком посещений и прогулок.
                        </li>
                        <li>Посетителей всегда должен сопровождать кто-то из персонала, к кому собаки привыкли.</li>
                        <li>Непрозрачное ограждение по периметру питомника или густая живая изгородь перед решеткой
                            ограждения позволят снизить воздействие визуальных раздражителей.
                        </li>
                        <li>Собаки, запертые в боксах или вольерах, не должны видеть собак на выгуле (разделить эти
                            зоны живой изгородью).
                        </li>
                        <li>Размещать собак, разбивая их на совместимые пары, чтобы предупредить лай от скуки.</li>
                        <li>Давать корм каждой собаке отдельно, в достаточном количестве, в первую очередь кормить
                            наиболее шумных.
                        </li>
                        <li>Свести к минимуму время на приготовление и раздачу корма (использовать сухой корм, при
                            раздаче пользоваться тележкой).
                        </li>
                        <li>Приучать собак к человеческому голосу (транслировать радиопередачи в здании).</li>
                    </ul>
                    <InfoBlock
                        danger={true}
                        text={`Соблюдать санитарные нормы по количеству собак в питомнике!`}
                    />
                    <button className="btn btn_blue btn_absolute"
                            onClick={() => this.onClickLink(0)}
                    >
                        Продолжить
                    </button>
                </div>
            )
        }else if (linkActive === 3){
            return (
                <div className={'dop3'}>
                    <TextBlock
                        fontSize={16}
                        text={
                            `Животные питомника могут заразиться самыми различными заболеваниями (пироплазмоз, лейшманиоз, гемобартонеллез, иерсиниоз) через укусы насекомых (блох, клещей, комаров), испражнения, мочу грызунов. Переносчиками инфекций могут оказаться даже трупы грызунов.

                            Присутствие грызунов в питомнике – это риск привнесения чужеродной флоры: вирусов, бактерий, паразитов.`
                        }
                    />
                    <div className="flexDop3">
                        <div className={'flexDop3_el ' + (dop3LinkActive === 1 && 'active')} onClick={()=>this.onClickDop3(1)}>ПРОФИЛАКТИКА</div>
                        <div className={'flexDop3_el ' + (dop3LinkActive === 2 && 'active')} onClick={()=>this.onClickDop3(2)}>ОТРАВЛЕНИЯ</div>
                    </div>
                    {dop3LinkActive === 1 &&
                    <div className="slide">
                        <TextBlock
                            fontSize={16}
                            text={`Регулярно обрабатывайте всех животных питомника средствами от блох и гельминтов – наиболее подходящее средство и интервал обработки поможет определить ветеринарный врач.`}
                        />
                        <InfoBlock
                            danger={true}
                            text={`Не пренебрегайте возможностями антипаразитарной обработки профилактическими средствами (пироплазмом, лейшманиоз, дирофиляриоз), о применении и подборе которых будет полезно проконсультироваться  с ветеринарным врачом.`}
                        />
                    </div>
                    }
                    {dop3LinkActive === 2 &&
                        <div className="slide right">
                            <TextBlock
                                fontSize={16}
                                text={`Средства против грызунов следует применять очень осторожно из-за их аппетитности и высокой токсичности. Необходимо:`}
                            />
                            <ul>
                                <li>Тщательно соблюдать указания по применению препарата;</li>
                                <li>Тщательно выбирать препараты дератизации и места для их размещения;</li>
                                <li>Заранее определить антидот к выбранному препарату (на случай, если животное
                                    питомника съест отравленного грызуна).
                                </li>
                            </ul>
                            <TextBlock
                                fontSize={16}
                                text={`Отравления домашних животных родентицидными антикоагулянтными ядами происходят наиболее часто, поскольку приманки очень привлекательны, а результат их действия (внутреннее кровотечение) проявляется только через несколько дней после проглатывания.`}
                            />

                        </div>
                    }
                    {showDop3Btn &&
                    <button className="btn btn_blue btn_absolute"
                            onClick={() => this.onClickLink(0)}
                    >
                        Продолжить
                    </button>
                    }
                </div>
            )
        }else if(linkActive === 4){
            return (
                <div>
                    <TextBlock
                        fontSize={16}
                        text={`Социальный стресс – важный фактор окружающей среды. Среди мер, позволяющих его избежать: внесение разнообразия в обстановку, грамотное формирование групп, недопущение перенаселения питомника и даже приятная музыка, звучащая в питомнике.

                            Последствия хронического стресса разнообразны:`}
                    />
                    <ul>
                        <li>Развитие стрессовой диареи у собак;</li>
                        <li>Выгрызание шерсти у собак;</li>
                        <li>Снижение качества жизни;</li>
                        <li>Проявления нежелательного поведения (метки мочой, лай, порча мебели и др.).</li>
                    </ul>
                    <TextBlock
                        fontSize={16}
                        text={`<span class="textBlue"><b>Нужно найти компромисс</b></span> между полным отсутствием раздражителей и слишком активным побуждением к активности, становящимся <span class="textBlue"><b>причиной «стресса от переутомления»</b></span>. Этот баланс может быть найден в регулярности повседневного быта (кормление, посещения, игры, уборка помещений), а также в использовании слуховых (трансляция по радио успокаивающей музыки и речи) или визуальных раздражителей.`}
                    />
                    <button className="btn btn_blue btn_absolute"
                            onClick={() => this.onClickLink(0)}
                    >
                        Продолжить
                    </button>
                </div>
            )
        }
    }

    _remderDopPopap = () => {
        const {dopLinkActive} = this.state
        return (
            <div className="infoPopap">
                <div className="infoPopap_wrp">
                    <div className="infoPopap_close" onClick={() => this.onClickDop(0)}/>
                    <div className="infoPopap_scroll">
                        <div className="infoPopap_iconDiv">
                            <img src={this.dopPopap[dopLinkActive].icon} alt="" className="infoPopap_icon"/>
                        </div>
                        <div className="infoPopap_head">{this.dopPopap[dopLinkActive].head}</div>
                        {this.dopPopap[dopLinkActive].ul !== undefined ?
                            <div>
                                <ul>
                                    {Object.values(this.dopPopap[dopLinkActive].ul).map(li => {
                                        return (
                                            <li>
                                                {li}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            :
                            <div className="infoPopap_text">
                                {this.dopPopap[dopLinkActive].text}
                            </div>
                        }
                    </div>
                    <button className="btn btn_blue" onClick={() => this.onClickDop(dopLinkActive !== 4 ? dopLinkActive + 1 : 0)}>Продолжить</button>

                </div>
            </div>
        )
    }


    render() {
        const {linkActive} = this.state;

        return (
            <div className="step13">
                {linkActive === 0 ?
                    this._renderMain()
                    :
                    this._renderDop()
                }
            </div>
        )
    }
}