import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step1">
                <TextBlock
                    text={`В этом уроке вы узнаете о том, как происходит разработка и изготовление корма от рецептуры до упаковки.`}
                />
                <div className="head">план урока</div>
                <div className="text">
                    Вы изучите:
                </div>

                <div className="lessonSteps">
                    <div className="lessonSteps_el">Этапы создания готовых кормов для животных</div>
                    <div className="lessonSteps_el">Научную разработку готовых кормов</div>
                    <div className="lessonSteps_el">Отбор и анализ сырья</div>
                    <div className="lessonSteps_el">Производство продукта</div>
                    <div className="lessonSteps_el">Хранение кормов</div>
                    <div className="lessonSteps_el">Итоги урока</div>
                    <div className="lessonSteps_el">Тест</div>
                </div>

            </div>
        )
    }
}
