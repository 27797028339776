import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import img1 from '../../../image/lessons/lesson5/step4-1.png';


export default class step4 extends React.Component {
    state = {
    };



    render() {
        return (
            <div className="step4">

                <InfoBlock text={`Рассмотрите схему пищеварительного тракта собаки. Цифры на схеме соответствуют номеру факта`}/>

                <div className="flex">
                    <div className="ul">
                        <div className="li">
                            <div className="number">1</div>
                            <div className="text">
                                Обоняние собак в 10 000 раз чувствительнее, чем у человека.
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">2</div>
                            <div className="text">
                                У собак 42 зуба, предназначенные для разгрызания, разрывания и перемалывания пищи. Эмаль зубов собаки в 5 раз тоньше, чем у зубов человека.
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">3</div>
                            <div className="text">
                                Меньше вкусовых рецепторов, чем у человека.
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">4</div>
                            <div className="text">Выработка слюнной амилазы ограничена.</div>
                        </div>
                        <div className="li">
                            <div className="number">5</div>
                            <div className="text">Очень растяжимый желудок, способный переварить большое количество пищи.</div>
                        </div>
                        <div className="li">
                            <div className="number">6</div>
                            <div className="text">Желудочный сок более кислый, чем у человека. Это способствует частичному перевариванию костей и уничтожению патогенных бактерий.</div>
                        </div>
                        <div className="li">
                            <div className="number">7</div>
                            <div className="text">Время кишечного транзита — 12-30 часов (намного быстрее, чем у человека).</div>
                        </div>
                        <div className="li">
                            <div className="number">8</div>
                            <div className="text">Бактериальная ферментация в незначительном объёме происходит только в тостом кишечнике.</div>
                        </div>
                    </div>

                    <img src={img1} alt=""/>

                </div>

            </div>
        )
    }
}
