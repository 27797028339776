import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import img1 from "../../../image/lessons/lesson6/step12-1.png";
import img2 from "../../../image/lessons/lesson6/step12-2.png";
import img3 from "../../../image/lessons/lesson6/step12-3.png";
import img4 from "../../../image/lessons/lesson6/step12-4.png";
import img5 from "../../../image/lessons/lesson6/step12-5.png";
import img6 from "../../../image/lessons/lesson6/step12-6.png";
import img7 from "../../../image/lessons/lesson6/step12-7.png";
import img8 from "../../../image/lessons/lesson6/step12-8.png";


export default class step12 extends React.Component {
    state = {
        flexActive: 0,
        flexs: [],
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs})
        if (flexs.length === 7) {
            this.props.showNextBtn()
        }
    }

    navList = [
        {
            "index": 1,
            "text": "ПОРОДА"
        },
        {
            "index": 2,
            "text": "генетика"
        },        {
            "index": 3,
            "text": "возраст"
        },        {
            "index": 4,
            "text": "Пол и репродуктивный статус"
        },        {
            "index": 5,
            "text": "влияние владельца"
        },        {
            "index": 6,
            "text": "диетологические факторы"
        },
        {
            "index": 7,
            "text": "физическая активность"
        }
    ]

    contentActive = {
        1: <>
            <TextBlock
            text={`Некоторые породы собак 
                    предрасположены к набору лишнего веса. 
                    К ним относятся:`}
            />
            <ul>
                <li>бигль;</li>
                <li>бернский зенненхунд;</li>
                <li>колли;</li>
                <li>лабрадор ретривер;</li>
                <li>керн-терьер;</li>
                <li>кавалер-кинг-чарльз-спаниель.</li>
            </ul>
            <img src={img2} alt=""/>
        </>,
        2: <>
            <TextBlock
            text={`Генетические факторы обуславливают предрасположенность животного к избыточному весу, причем на это <b>влияют различные гены.</b> 

                    Животные одной и той же породы могут обладать <b>индивидуальными</b> особенностями. Эти не зависимые от породы вариации указывают на существование комплекса генетических элементов, который предопределяет способность животного к сохранению нормального веса.`}
            />
            <img src={img3} alt=""/>
        </>,
        3: <>
            <TextBlock
            text={`<b>С возрастом</b> склонность к набору избыточного веса <b>повышается.</b> Считается, что лишь 20% собак в возрасте до 4 лет склонны к избыточному весу. В возрастной категории 7-8 лет этот показатель составляет уже 50%.`}
            />
            <img src={img4} alt=""/>
        </>,
        4: <>
            <TextBlock
            text={`У собак <b>суки более предрасположены к ожирению,</b> чем кобели. А после стерилизации у животных обоих полов эта предрасположенность становится еще выше. `}
            />
            <img src={img5} alt=""/>
        </>,
        5: <>
            <TextBlock
            text={`По данным исследований, владельцы животных, страдающих избыточным весом, <b>сами склонны к набору лишних килограммов и отказываются признать</b> наличие у своих питомцев этой проблемы. 
                
                Образ жизни владельца является важным фактором, определяющим возможность поддержания у животного нормального веса: когда владелец большую часть времени находится дома, для животного возникает повышенный риск набора избыточного веса, причиной которого является <b>недостаточная физическая активность владельца.</b>`}
            />
            <img src={img6} alt=""/>
        </>,
        6: <>
            <TextBlock
            text={`На возможность сохранения нормального веса влияет <b>режим кормления</b> питомца, а также <b>тип корма.</b> 

                Кормление собак и кошек без ограничения приводит к перееданию и вызывает увеличение веса животных. Деление дневного рациона <b>на несколько порций</b> не приведет к такому результату. 
                
                Основным фактором ожирения животных является <b>подкармливание</b> едой со стола. Большое количество лакомств также способствует увеличению веса.`}
            />
            <img src={img7} alt=""/>
        </>,
        7: <>
            <TextBlock
            text={`Регулярные <b>физические нагрузки</b> являются основным фактором профилактики ожирения. 

            Для владельцев <b>собак</b> прогулки и игры с животными являются частью жизненного уклада. `}
            />
            <img src={img8} alt=""/>
        </>,
    }

    render() {
        const {flexActive} = this.state
        return (
            <div className="step12 step36">
                <TextBlock
                    text={`Избыток веса обусловлен потреблением животным калорий в большем количестве, чем ему требуется. Тем не менее, есть немало факторов, которые оказывают влияние на способность животного сохранять нормальный вес.`}
                />
                <div className="step_content">
                    <div className="flex">
                        {this.navList.map(item => {
                            return (
                                <div
                                    key={item.index}
                                    className={'flex_el ' + (flexActive === item.index && 'active')}
                                    onClick={() => {
                                    this.changeFlex(item.index)
                                }}
                                >{item.text}</div>
                            )})
                        }
                    </div>
                    <div className="right_content">
                        {flexActive !== 0 &&
                        <div className={'textDiv ' + (flexActive !== 1 ? `textDiv--${flexActive}` : '')}>
                            {this.contentActive[flexActive]}
                        </div>
                        }
                        <div className="content_bg">
                            <InfoBlock text={`Нажмите на кнопки, чтобы узнать подробнее о факторах.`}/>
                            <img src={img1} alt="" className='mainImg'/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}