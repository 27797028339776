import React from 'react'
import './style.sass'
import TextBlock from "../TextBlock";
import Popup from "../../Popup";

export default class ScaleBlock extends React.Component {

    //Пример использования lesson 40, step 2

    state = {
        windowWidth: window.innerWidth,
        pointActive: window.innerWidth < 767 ? 1 : 0,
        points: [0, 1],
        showPopup: false,
        stepCount: [],
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        const stepCount = this.props.stepCount
        this.setState({
            stepCount: stepCount
        })
        window.addEventListener('resize', this.handlerResize)
        document.querySelector('#progress').style.setProperty('--progress', (100 / (stepCount.length - 1)) + '%')
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    changePoint = (event, pointActive) => {
        const {points, windowWidth} = this.state
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }
        if (windowWidth < 767) {
            /*if (pointActive > 10) {
                this.setState({
                    pointActive: 10,
                    showPopup: false,
                })
            } else {
                this.setState({
                    pointActive: pointActive,
                    //showPopup: true,
                    points: points
                })
            }*/
            console.log(888);

        } else {
            const progress = document.querySelector('#progress')
            const blockLeft = progress.getBoundingClientRect().left
            const elementLeft = event.target.getBoundingClientRect().left
            console.log(777, blockLeft)
            progress.style.setProperty('--left', ((elementLeft - blockLeft) + 15) + 'px')
            this.setState({pointActive, points})
        }
        this.props.changeStep(pointActive);
    }

    changePointMobile = (pointActive) => {
        const {points} = this.state
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }
            if (pointActive > 10) {
                this.setState({
                    pointActive: 10,
                    showPopup: false,
                })
            } else {
                this.setState({
                    pointActive: pointActive,
                    //showPopup: true,
                    points: points
                })
            }
        this.props.changeStep(pointActive);
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    infoData = {
        1: {
            year: `1975`,
            text: `Участие в определении потребностей взрослых собак в аминокислотах`,
            cursor: 1, // Положение курсора
        },
        2: {
            year: `1985`,
            text: `Участие в публикации книги WALTHAM о питании домашних животных`,
            cursor: 11.8,
        },
        3: {
            year: `1997`,
            text: `Патент на уровень цинка и линолевой кислоты, определяющих состояние кожи и шерсти`,
            cursor: 22.4,
        },
        4: {
            year: `1999`,
            text: `Создание рациона для собак на основе исследования о применении зеленогубого моллюска для поддержания суставов`,
            cursor: 33,
        },
        5: {
            year: `2000`,
            text: `Демонстрация положительного влияния уровня антиоксидантов в рационе щенков на здоровье иммунной системы`,
            cursor: 43.6,
        },
        6: {
            year: `2005`,
            text: `Первый Lactobacillus пробиотик, принятый к использованию в рационых для собак`,
            cursor: 54.4,
        },
        7: {
            year: `2007`,
            text: `Открытие гена, обуславливающего размер собак`,
            cursor: 65,
        },
        8: {
            year: `2008`,
            text: `Определение генетического влияния на стереотипические характеристики собак`,
            cursor: 75.6,
        },
        9: {
            year: `2009`,
            text: `Совместно с FEDIAF и ключевыми учёными, установлен новый безопасный верхний уровень витамина А в питании щенков на ранних стадиях развития `,
            cursor: 86.3,
        },
        10: {
            year: `2012`,
            text: `Показано, что снижение массы тела является значимым фактором в повышении качества жизни животных при ожирении`,
            cursor: 97,
        },
    }

    render() {
        const {points, pointActive, showPopup, stepCount} = this.state
        return (
            <React.Fragment>
                <div id="progress" className="scaleBlock_progress">
                    <div className={'scaleBlock_progress_cursor show'}>
                        <svg width="16" height="29" viewBox="0 0 16 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 19.5652L9.58721 27.9297C8.78667 28.9739 7.21333 28.9739 6.41279 27.9297L9.12238e-07 19.5652L16 19.5652Z" fill="#005EAD"/>
                            <path d="M16 19.5652L8.75491 22.5184C8.27097 22.7156 7.72903 22.7156 7.24509 22.5184L9.12238e-07 19.5652L2.44784e-06 1.99999C2.5444e-06 0.895426 0.895433 -3.22783e-06 2 -3.13127e-06L14 -2.08219e-06C15.1046 -1.98563e-06 16 0.89543 16 2L16 19.5652Z" fill="#005EAD"/>
                        </svg>
                    </div>
                    <div className="scaleBlock_progress_big"/>
                    <div className="scaleBlock_progress_small">
                        {stepCount.map(item => {
                            return (
                                <div
                                    key={item}
                                    className={'scaleBlock_progress_small_item ' + (points.indexOf(Number(item)) >= 0 && item === '1' ? 'active-first' : points.indexOf(Number(item)) >= 0 ? 'active' : '')}
                                />
                            )
                        })}
                    </div>
                    <div className="scaleBlock_progress_points">
                        {stepCount.map(item => {
                            return (
                                <div
                                    key={item}
                                    className={'scaleBlock_progress_points_item'}
                                >
                                    <div
                                        className={'scaleBlock_progress_points_item_blue ' + (points.indexOf(Number(item)) >= 0 && 'active')}
                                        onClick={(event) => {event.persist();this.changePoint(event, Number(item))}}
                                    >
                                        {item}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="scaleBlock_progress-phone">
                    {stepCount.map(item => {
                        return (
                            <div
                                key={item}
                                className={'scaleBlock_progress-phone_box ' + (pointActive === 2 &&  Number(item) === 1 && points.length < 3 ? 'active' : pointActive === 2 &&  Number(item) === 2 && points.length < 3 ? '' : pointActive === Number(item) ? 'active' : '')}
                            >
                                <div className="scaleBlock_progress-phone_el first">
                                    {Number(item) > 1 &&
                                    <div className="scaleBlock_progress-phone_el_big active">
                                        <div className="scaleBlock_progress-phone_el_small active"/>
                                    </div>
                                    }
                                </div>
                                <div className="scaleBlock_progress-phone_el second">
                                    <div className={'scaleBlock_progress-phone_el_point ' + (points.indexOf(Number(item)) >= 0 && 'active')} onClick={() => {this.changePointMobile(Number(item))}}>
                                        <div className={'scaleBlock_progress-phone_el_small ' + (points.indexOf(Number(item)) >= 0 && 'active')}/>
                                        <div className={'scaleBlock_progress-phone_el_point_red ' + (points.indexOf(Number(item)) >= 0 && 'active')}>
                                            {item}
                                        </div>
                                    </div>
                                    {Number(item) < (stepCount.length) &&
                                    <div className={'scaleBlock_progress-phone_el_big ' + (points.indexOf(Number(item)) >= 0 && 'active')}>
                                        <div className={'scaleBlock_progress-phone_el_small ' + (points.indexOf(Number(item)) >= 0 && 'active')}/>
                                    </div>
                                    }
                                </div>
                                {Number(item) < stepCount.length &&
                                <div className="scaleBlock_progress-phone_el third">
                                    <div className={'scaleBlock_progress-phone_el_point ' + (pointActive === 2 && points.length < 3 && 'active')} onClick={() => {this.changePointMobile(Number(item) + 1)}}>
                                        <div className={'scaleBlock_progress-phone_el_small ' + (pointActive === 2 && points.length < 3 && 'active')}/>
                                        <div className={'scaleBlock_progress-phone_el_point_red ' + (pointActive === 2 && points.length < 3 && 'active')}>
                                            {Number(item) + 1}
                                        </div>
                                    </div>
                                    {Number(item) < (stepCount.length - 1) &&
                                    <div className={'scaleBlock_progress-phone_el_big ' + (pointActive === 2 && points.length < 3 && 'active')}>
                                        <div className={'scaleBlock_progress-phone_el_small ' + (pointActive === 2 && points.length < 3 && 'active')}/>
                                    </div>}
                                </div>
                                }
                            </div>
                        )
                    })}
                </div>
                {/*{showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <div className="Popup_year">
                        {this.infoData[pointActive].year}
                    </div>
                    <TextBlock
                        text={this.infoData[pointActive].text}
                    />
                    <button
                        className="btn btn_blue"
                        onClick={() => {this.changePoint(pointActive + 1)}}
                    >
                        продолжить
                    </button>
                </Popup>
                }*/}
            </React.Fragment>
        )
    }
}
