import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'

import img1 from '../../../image/lessons/lesson5/step38-1.png'


export default class step6 extends React.Component {


    render() {
        return (
            <div className="step38">
                <div className="flex">
                    <TextBlock
                        text={`Животным жизненно необходимо получать достаточное количество воды, так как вода — ключевой элемент в организме, <b>способствующий правильному обмену веществ</b>.

Вода выполняет ряд важнейших функций для организма. При нехватке воды наступает нарушение водного баланса, что приводит к проблемам со здоровьем.`}
                    />
                    <img src={img1} alt=""/>
                </div>
                <InfoBlock
                    text={`Далее вы ознакомитесь со свойствами воды, суточными нормами потребления для собак, а также с понятием водного баланса.`}
                />
            </div>
        )
    }
}
