import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import img1 from '../../../image/lessons/lesson5/step29-1.png'


export default class step6 extends React.Component {


    render() {
        return (
            <div className="step29">
                <TextBlock
                    text={`Рассмотрите таблицу. На ней показано, каким образом кормление животного пищей для людей влияет на суточное потребление калорий животным. Некоторые из этих популярных угощений составляют более половины суточного рациона животного!`}
                />
                <img src={img1} alt="" className="imgCenter"/>
                <div className="info">
                    Можно сделать вывод, что частое угощение животных едой <br/>
                    со стола ведет к ожирению <br/>
                    и последующим проблемам со здоровьем.
                </div>
            </div>
        )
    }
}