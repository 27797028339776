import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import PopupInStep from '../../../Components/LessonAndTestComponents/PopupInStep';

import img1 from '../../../image/lessons/lesson4/step6-1-1.png'
import img2 from '../../../image/lessons/lesson4/step6-1-2.png'
import img3 from '../../../image/lessons/lesson4/step6-2.png'
import img2xs from '../../../image/lessons/lesson4/step6-2xs.png'


export default class step6 extends React.Component {
    state = {
        pointActive: 0,
        points: [],
    };

    changePoint = pointActive => {
        this.setState({pointActive}, () => {
            this.checkPoint(pointActive)
        })
    }

    checkPoint = (point) => {
        const {points} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        console.log(points)
        if (points.length === 5) {
            this.props.showNextBtn()
        }
    }

    popapWrp = {
        1: {
            head: 'Для чего выводились?',
            text: `Породы первой группы образовались из пастушьих собак. 
                    Эти «пастухи» использовались как для охраны домашних животных и ферм, так и для управления стадами и гуртами скота.
                    
                    Это их важное отличие от собак, предназначенных только для охраны скота – овчарки первой группы веками приучались работать в тесном контакте с человеком и управляли стадом по его требованию.`
        },
        2: {
            head: 'Характер',
            text: `Из них получаются прекрасные служебные и спортивные собаки, так как склонность к послушанию, живой темперамент, выносливость и работоспособность закреплены длительным отбором.

                    В то же время, эти собаки часто недоверчивы к посторонним, активны и требуют тщательного воспитания. Из них получаются прекрасные компаньоны, но они больше подходят для активного человека, планирующего посвящать собаке много времени, много гулять, заниматься спортивной или прикладной дрессировкой.
                    
                    Породы этой группы являются практически безоговорочными лидерами в большинстве видов кинологического спорта. Несмотря на присущие многим представителям этой группы прекрасные сторожевые качества, для них нехарактерна склонность к агрессии к членам семьи. `
        },
        3: {
            head: 'Особенности внешности',
            text: `В основном для группы типичен шерстный покров средней длинны (жесткошерстные и длинношерстные породы в этой группе малочисленны), собаки жизнестойки, неприхотливы и легко приспосабливаются к любому климату. 
                    
                    Могут жить как в городской квартире, так и круглый год на открытом воздухе. Размер – от среднего до крупного.`
        },
        4: {
            head: 'Типичные представители',
            text: `Самые известные представители группы: немецкая и бельгийская овчарки. Сейчас среди спортсменов большой популярностью пользуется бордер колли и бельгийские овчарки, набирают популярность австралийские породы, а желающие иметь приятного некрупного компаньона могут обратить внимание на маленьких британских овчарок – вельш корги (любимая порода английской королевы) и шелти (уменьшенная копия колли). 
                    
                    Несмотря на небольшой размер, они обладают всеми качествами настоящих овчарок.`
        },
    }


    render() {
        const {pointActive, points} = this.state;
        return (
            <div className="step6">
                <TextBlock
                    text={`<span class="text_subheader">Пастушьи (секция 1) и скотогонные собаки (секция 2)</span> `}
                />
                <InfoBlock
                    text={`Ознакомьтесь с каждой группой, нажав на ключевые элементы.`}
                />
                <div className="flex">
                    <div className="flexImg">
                        <img src={img1} alt="" className=''/>
                        <img src={img2} alt="" className=''/>
                    </div>
                    <div className="flex_el"><span className={points.indexOf(1) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(1)}}>Для чего выводились?</span></div>
                    <div className="flex_el"><span className={points.indexOf(2) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(2)}}>Характер</span></div>
                    <div className="flex_el"><span className={points.indexOf(3) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(3)}}>Особенности внешности</span></div>
                    <div className="flex_el"><span className={points.indexOf(4) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(4)}}>Типичные представители</span></div>
                </div>

                {pointActive !== 0 &&
                <PopupInStep
                    closePopup={() => this.changePoint(0)}
                    popupImg={img3}
                    // popupImgMobile={img2xs}
                    popupHead={this.popapWrp[pointActive].head}
                    popupText={this.popapWrp[pointActive].text}
                    changePoint={() => this.changePoint(pointActive < 4 ? pointActive + 1 : 0)}
                />
                }
            </div>
        )
    }
}
