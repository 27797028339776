import React from 'react'

import img1 from '../../../image/lessons/lesson33/step8-1.jpg'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";


export default class step6 extends React.Component {


    render() {
        return (
            <div className="step9">
                <TextBlock
                    text={`<span class="text_dark-blue">Расчет делается в два шага:</span>`}
                />
                <div className="ul">
                    <div className="li">
                        <div className="number">1.</div>
                        <div className="text">
                            <b>Определим новые энергетические потребности.</b><br/>
                            Мы знаем, что до беременности они составляли 1 200 ккал / сутки. Посмотрим на график и найдем, сколько процентов от базового значения составляют потребности на 8-й неделе беременности: <b>150%.</b>
                            Значит, новые энергетические потребности <b>увеличились на 50% от 1 200,</b> то есть на 600 ккал.
                            <b>1 200 + 600 = 1 800 ккал / сутки</b> — новые энергетические потребности.
                        </div>
                    </div>
                    <div className="li">
                        <div className="number">2.</div>
                        <div className="text">
                            Мы знаем <b>энергетическую ценность корма и новые энергетические потребности</b> собаки. Используя формулу для расчета суточного рациона,
                            рассчитаем, какой объем корма нужно Молли в сутки: <b>1 800 / 370 = 486 г.</b>
                        </div>
                    </div>
                </div>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
