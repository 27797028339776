import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import PopupInStep from '../../../Components/LessonAndTestComponents/PopupInStep'

import img1 from '../../../image/lessons/lesson33/step23-1.png'
import img2 from '../../../image/lessons/lesson33/step23-2.jpg'


export default class step23 extends React.Component {
    state = {
        pointActive: 0,
        points: [],
    };

    changePoint = pointActive => {
        this.setState({pointActive}, () => {
            this.checkPoint(pointActive)
        })
    }

    checkPoint = (point) => {
        const {points} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        if (points.length === 2) {
            this.props.showNextBtn()
        }
    }

    closePopap = () => {
        this.setState({pointActive: 0})
    }

    popapWrp = {
        1: {
            icon: img2,
            head: 'Вспомните материалы предыдущих уроков!',
            text: 'И <b>сухой</b>, и <b>влажный</b> корм являются <b>сбалансированными</b> и содержат необходимые собакам питательные вещества.',
            text2: 'Сочетание сухих и влажных рационов обеспечивает <b>преимущества обоих видов.</b> Сухой корм улучшает гигиену зубов и богат клетчаткой для хорошего пищеварения, а также удобен в использовании. Влажный корм помогает разнообразить рацион и избежать переедания, так как его калорийность меньше в 4-5 раз.'
        },
    }

    render() {
        const {pointActive} = this.state;

        return (
            <div className="step23">
                <TextBlock
                    text={`В течение периода взрослой жизни собаки цель питания — поддержание оптимального состояния здоровья и идеальной физической формы.
                    
                    Для достижения этих целей производители кормов разрабатывают рационы, позволяющие собакам получать незаменимые питательные вещества, употребляя при этом оптимальное количество калорий.`}
                />
                <InfoBlock
                    text={`Вспомните материал предыдущих уроков
о правильном питании взрослых собак.`}
                />
                <div className="better">
                    <p className="better_header">Лучшее решение:</p>
                    <TextBlock
                        text={`кормить собак сбалансированным кормом для взрослых собак, сочетая сухой и влажный рацион каждый день.`}
                    />
                </div>
                <img className="img" src={img1} alt=""/>
                <button className="btn btn_blue btn_shpora" onClick={() => {this.changePoint(1)}}>
                    повторить изученное
                </button>

                {pointActive !== 0 &&
                <PopupInStep
                    closePopup={this.closePopap}
                    popupHead={this.popapWrp[pointActive].head}
                    popupText={this.popapWrp[pointActive].text}
                    popupText2={this.popapWrp[pointActive].text2}
                    popupImg3={this.popapWrp[pointActive].icon}
                    changePoint={this.closePopap}
                />
                }
            </div>
        )
    }
}
