import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson5/step16-1.png'
import img2 from '../../../image/lessons/lesson5/step16-2.png'


export default class step6 extends React.Component {

    render() {
        return (
            <div className="step16">
                <TextBlock
                    text={`Каждый из видов корма имеет свои преимущества. И сухой, и влажный корм являются полноценными сбалансированными рационами.

                    Сравните особенности сухого и влажного корма.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_head">СУХОЙ КОРМ</div>
                        <div className="flex_wrp">
                            <ul>
                                <li>Содержит меньше влаги.</li>
                                <li>Долго и удобно хранится.</li>
                                <li>В одной упаковке содержится несколько порций, поэтому нужно измерять размер порции
                                    в соответствии с рекомендациями.</li>
                            </ul>
                            <img src={img1} alt=""/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_head">ВЛАЖНЫЙ КОРМ</div>
                        <div className="flex_wrp">
                            <ul>
                                <li>Содержит большое количество жидкости.</li>
                                <li>Сниженная энергетическая ценность в одном пауче.</li>
                                <li>Разнообразие текстур и вкусов.</li>
                                <li>Порционный формат.</li>
                            </ul>
                            <img src={img2} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}