import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';

import img1_1_1 from '../../../image/lessons/lesson4/step5-1-1-1.png'
import img1_1_2 from '../../../image/lessons/lesson4/step5-1-1-2.png'
import img1_1_3 from '../../../image/lessons/lesson4/step5-1-1-3.png'
import img1_1_4 from '../../../image/lessons/lesson4/step5-1-1-4.png'
import img1_2_1 from '../../../image/lessons/lesson4/step5-1-2-1.png'
import img2_1_1 from '../../../image/lessons/lesson4/step5-2-1-1.png'
import img2_1_2 from '../../../image/lessons/lesson4/step5-2-1-2.png'
import img2_1_3 from '../../../image/lessons/lesson4/step5-2-1-3.png'
import img2_1_4 from '../../../image/lessons/lesson4/step5-2-1-4.png'
import img2_2_1 from '../../../image/lessons/lesson4/step5-2-2-1.png'
import img2_2_2 from '../../../image/lessons/lesson4/step5-2-2-2.png'
import img2_3_1 from '../../../image/lessons/lesson4/step5-2-3-1.png'
import img3_1_1 from '../../../image/lessons/lesson4/step5-3-1-1.png'
import img3_2_1 from '../../../image/lessons/lesson4/step5-3-2-1.png'
import img3_2_2 from '../../../image/lessons/lesson4/step5-3-2-2.png'
import img3_3_1 from '../../../image/lessons/lesson4/step5-3-3-1.png'
import img3_4_1 from '../../../image/lessons/lesson4/step5-3-4-1.png'
import img4_1_1 from '../../../image/lessons/lesson4/step5-4-1-1.png'
import img5_1_1 from '../../../image/lessons/lesson4/step5-5-1-1.png'
import img5_2_1 from '../../../image/lessons/lesson4/step5-5-2-1.png'
import img5_2_2 from '../../../image/lessons/lesson4/step5-5-2-2.png'
import img5_3_1 from '../../../image/lessons/lesson4/step5-5-3-1.png'
import img5_4_1 from '../../../image/lessons/lesson4/step5-5-4-1.png'
import img5_5_1 from '../../../image/lessons/lesson4/step5-5-5-1.png'
import img5_6_1 from '../../../image/lessons/lesson4/step5-5-6-1.png'
import img5_6_2 from '../../../image/lessons/lesson4/step5-5-6-2.png'
import img5_6_3 from '../../../image/lessons/lesson4/step5-5-6-3.png'
import img5_7_1 from '../../../image/lessons/lesson4/step5-5-7-1.png'
import img5_7_2 from '../../../image/lessons/lesson4/step5-5-7-2.png'
import img5_8_1 from '../../../image/lessons/lesson4/step5-5-8-1.png'
import img6_1_1 from '../../../image/lessons/lesson4/step5-6-1-1.png'
import img6_2_1 from '../../../image/lessons/lesson4/step5-6-2-1.png'
import img6_3_1 from '../../../image/lessons/lesson4/step5-6-3-1.png'
import img7_1_1 from '../../../image/lessons/lesson4/step5-7-1-1.png'
import img7_2_1 from '../../../image/lessons/lesson4/step5-7-2-1.png'
import img7_2_2 from '../../../image/lessons/lesson4/step5-7-2-2.png'
import img8_1_1 from '../../../image/lessons/lesson4/step5-8-1-1.png'
import img8_1_2 from '../../../image/lessons/lesson4/step5-8-1-2.png'
import img8_2_1 from '../../../image/lessons/lesson4/step5-8-2-1.png'
import img8_3_1 from '../../../image/lessons/lesson4/step5-8-3-1.png'
import img9_1_1 from '../../../image/lessons/lesson4/step5-9-1-1.png'
import img9_1_2 from '../../../image/lessons/lesson4/step5-9-1-2.png'
import img9_2_1 from '../../../image/lessons/lesson4/step5-9-2-1.png'
import img9_3_1 from '../../../image/lessons/lesson4/step5-9-3-1.png'
import img9_3_2 from '../../../image/lessons/lesson4/step5-9-3-2.png'
import img9_4_1 from '../../../image/lessons/lesson4/step5-9-4-1.png'
import img9_5_1 from '../../../image/lessons/lesson4/step5-9-5-1.png'
import img9_6_1 from '../../../image/lessons/lesson4/step5-9-6-1.png'
import img9_7_1 from '../../../image/lessons/lesson4/step5-9-7-1.png'
import img9_8_1 from '../../../image/lessons/lesson4/step5-9-8-1.png'
import img9_9_1 from '../../../image/lessons/lesson4/step5-9-9-1.png'
import img9_10_1 from '../../../image/lessons/lesson4/step5-9-10-1.png'
import img9_11_1 from '../../../image/lessons/lesson4/step5-9-11-1.png'
import img10_1_1 from '../../../image/lessons/lesson4/step5-10-1-1.png'
import img10_2_1 from '../../../image/lessons/lesson4/step5-10-2-1.png'
import img10_3_1 from '../../../image/lessons/lesson4/step5-10-3-1.png'



export default class step5 extends React.Component {
    state = {
        pointActive: 0,
        subPointActive: 0,
        points: [],
    };

    changePoint = pointActive => {
        this.setState({pointActive, subPointActive: 0})
    }

    checkPoint = (point) => {
        const {points} = this.state
        if (points.indexOf(point[1]) === -1) {
            points.push(point[1])
        }
        this.setState({subPointActive: point})
        if (points.length === 40) {
            this.props.showNextBtn()
        }
    }

    returnToPointsMenu = () => {
        this.setState({pointActive: 0})
    }

    returnToSubMenu = () => {
        this.setState({subPointActive: 0})
    }

    stepPoints = {
        1: {
            index: 1,
            title: `1 группа FCI`,
            content: {
                1: {
                    index: 1,
                    dataPoint: `1-1`,
                    title: `Секция 1. Пастушьи собаки (Овчарки)`,
                    content: {
                        1: {
                            index: 1,
                            img: img1_1_1
                        },
                        2: {
                            index: 2,
                            img: img1_1_2
                        },
                        3: {
                            index: 3,
                            img: img1_1_3
                        },
                        4: {
                            index: 4,
                            img: img1_1_4
                        }
                    }
                },
                2: {
                    index: 2,
                    dataPoint: `1-2`,
                    title: `Секция 2. Скотогонные собаки (кроме швейцарских Зенненхундов)`,
                    content: {
                        1: {
                            index: 1,
                            img: img1_2_1
                        },
                    }
                }
            }
        },
        2: {
            index: 2,
            title: `2 группа FCI`,
            content: {
                1: {
                    index: 1,
                    dataPoint: `2-1`,
                    title: `Секция 1. Пинчеры и шнауцеры`,
                    content: {
                        1: {
                            index: 1,
                            img: img2_1_1
                        },
                        2: {
                            index: 2,
                            img: img2_1_2
                        },
                        3: {
                            index: 3,
                            img: img2_1_3
                        },
                        4: {
                            index: 3,
                            img: img2_1_4
                        }
                    }
                },
                2: {
                    index: 2,
                    dataPoint: `2-2`,
                    title: `Секция 2. Молоссы`,
                    content: {
                        1: {
                            index: 1,
                            img: img2_2_1
                        },
                        2: {
                            index: 2,
                            img: img2_2_2
                        },
                    }
                },
                3: {
                    index: 3,
                    dataPoint: `2-3`,
                    title: `Секция 3. Швейцарские зенненхунды`,
                    content: {
                        1: {
                            index: 1,
                            img: img2_3_1
                        },
                    }
                }
            }
        },
        3: {
            index: 3,
            title: `3 группа FCI`,
            content: {
                1: {
                    index: 1,
                    dataPoint: `3-1`,
                    title: `Секция 1. Терьеры большого и среднего размера`,
                    content: {
                        1: {
                            index: 1,
                            img: img3_1_1
                        },
                    }
                },
                2: {
                    index: 2,
                    dataPoint: `3-2`,
                    title: `Секция 2. Мелкие терьеры`,
                    content: {
                        1: {
                            index: 1,
                            img: img3_2_1
                        },
                        2: {
                            index: 2,
                            img: img3_2_2
                        }
                    }
                },
                3: {
                    index: 3,
                    dataPoint: `3-3`,
                    title: `Секция 3. Терьеры в типе бульдога`,
                    content: {
                        1: {
                            index: 1,
                            img: img3_3_1
                        },
                    }
                },
                4: {
                    index: 4,
                    dataPoint: `3-4`,
                    title: `Секция 4. Карликовые терьеры`,
                    content: {
                        1: {
                            index: 1,
                            img: img3_4_1
                        },
                    }
                }
            }
        },
        4: {
            index: 4,
            title: `4 группа FCI`,
            content: {
                1: {
                    index: 1,
                    dataPoint: `4-1`,
                    title: `Секция 1. Таксы`,
                    content: {
                        1: {
                            index: 1,
                            img: img4_1_1
                        },
                    }
                }
            }
        },
        5: {
            index: 5,
            title: `5 группа FCI`,
            content: {
                1: {
                    index: 1,
                    dataPoint: `5-1`,
                    title: `Секция 1. Северные ездовые собаки`,
                    content: {
                        1: {
                            index: 1,
                            img: img5_1_1
                        },
                    }
                },
                2: {
                    index: 2,
                    dataPoint: `5-2`,
                    title: `Секция 2. Охотничьи лайки`,
                    content: {
                        1: {
                            index: 1,
                            img: img5_2_1
                        },
                        2: {
                            index: 1,
                            img: img5_2_2
                        },
                    }
                },
                3: {
                    index: 3,
                    dataPoint: `5-3`,
                    title: `Секция 3. Северные сторожевые собаки и пастушьи шпицы`,
                    content: {
                        1: {
                            index: 1,
                            img: img5_3_1
                        },
                    }
                },
                4: {
                    index: 4,
                    dataPoint: `5-4`,
                    title: `Секция 4. Немецкие шпицы`,
                    content: {
                        1: {
                            index: 1,
                            img: img5_4_1
                        },
                    }
                },
                5: {
                    index: 5,
                    dataPoint: `5-5`,
                    title: `Секция 5. Азиатские шпицы и родственные породы`,
                    content: {
                        1: {
                            index: 1,
                            img: img5_5_1
                        },
                    }
                },
                6: {
                    index: 6,
                    dataPoint: `5-6`,
                    title: `Секция 6. Примитивные породы`,
                    content: {
                        1: {
                            index: 1,
                            img: img5_6_1
                        },
                        2: {
                            index: 1,
                            img: img5_6_2
                        },
                        3: {
                            index: 1,
                            img: img5_6_3
                        },
                    }
                },
                7: {
                    index: 7,
                    dataPoint: `5-7`,
                    title: `Секция 7. Примитивные охотничьи породы`,
                    content: {
                        1: {
                            index: 1,
                            img: img5_7_1
                        },
                        2: {
                            index: 1,
                            img: img5_7_2
                        },
                    }
                },
                8: {
                    index: 8,
                    dataPoint: `5-8`,
                    title: `Секция 8. Примитивные охотничьи собаки с риджем на спине`,
                    content: {
                        1: {
                            index: 1,
                            img: img5_8_1
                        },
                    }
                }
            }
        },
        6: {
            index: 6,
            title: `6 группа FCI`,
            content: {
                1: {
                    index: 1,
                    dataPoint: `6-1`,
                    title: `Секция 1. Гончие`,
                    content: {
                        1: {
                            index: 1,
                            img: img6_1_1
                        },
                    }
                },
                2: {
                    index: 2,
                    dataPoint: `6-2`,
                    title: `Секция 2. Гончие по кровяному следу`,
                    content: {
                        1: {
                            index: 1,
                            img: img6_2_1
                        },
                    }
                },
                3: {
                    index: 3,
                    dataPoint: `6-3`,
                    title: `Секция 3. Родственные им породы`,
                    content: {
                        1: {
                            index: 1,
                            img: img6_3_1
                        },
                    }
                },
            }
        },
        7: {
            index: 7,
            title: `7 группа FCI`,
            content: {
                1: {
                    index: 1,
                    dataPoint: `7-1`,
                    title: `Секция 1. Континентальные легавые`,
                    content: {
                        1: {
                            index: 1,
                            img: img7_1_1
                        },
                    }
                },
                2: {
                    index: 2,
                    dataPoint: `7-2`,
                    title: `Секция 2. Островные легавые`,
                    content: {
                        1: {
                            index: 1,
                            img: img7_2_1
                        },
                        2: {
                            index: 1,
                            img: img7_2_2
                        },
                    }
                }
            }
        },
        8: {
            index: 8,
            title: `8 группа FCI`,
            content: {
                1: {
                    index: 1,
                    dataPoint: `8-1`,
                    title: `Секция 1. Ретриверы`,
                    content: {
                        1: {
                            index: 1,
                            img: img8_1_1
                        },
                        2: {
                            index: 1,
                            img: img8_1_2
                        },
                    }
                },
                2: {
                    index: 2,
                    dataPoint: `8-2`,
                    title: `Секция 2. Спаниели`,
                    content: {
                        1: {
                            index: 1,
                            img: img8_2_1
                        },
                    }
                },
                3: {
                    index: 3,
                    dataPoint: `8-3`,
                    title: `Секция 3. Водяные собаки`,
                    content: {
                        1: {
                            index: 1,
                            img: img8_3_1
                        },
                    }
                }
            }
        },
        9: {
            index: 9,
            title: `9 группа FCI`,
            content: {
                1: {
                    index: 1,
                    dataPoint: `9-1`,
                    title: `Секция 1. Болонки и родственные породы`,
                    content: {
                        1: {
                            index: 1,
                            img: img9_1_1
                        },
                        2: {
                            index: 2,
                            img: img9_1_2
                        },
                    }
                },
                2: {
                    index: 2,
                    dataPoint: `9-2`,
                    title: `Секция 2. Пудели`,
                    content: {
                        1: {
                            index: 1,
                            img: img9_2_1
                        },
                    }
                },
                3: {
                    index: 3,
                    dataPoint: `9-3`,
                    title: `Секция 3. Бельгийские гриффоны`,
                    content: {
                        1: {
                            index: 1,
                            img: img9_3_1
                        },
                        2: {
                            index: 2,
                            img: img9_3_2
                        },
                    }
                },
                4: {
                    index: 4,
                    dataPoint: `9-4`,
                    title: `Секция 4. Голые собаки`,
                    content: {
                        1: {
                            index: 1,
                            img: img9_4_1
                        },
                    }
                },
                5: {
                    index: 5,
                    dataPoint: `9-5`,
                    title: `Секция 5. Тибетские породы`,
                    content: {
                        1: {
                            index: 1,
                            img: img9_5_1
                        },
                    }
                },
                6: {
                    index: 6,
                    dataPoint: `9-6`,
                    title: `Секция 6. Чихуахуа`,
                    content: {
                        1: {
                            index: 1,
                            img: img9_6_1
                        },
                    }
                },
                7: {
                    index: 7,
                    dataPoint: `9-7`,
                    title: `Секция 7. Кавалер Кинг Чарльз спаниель`,
                    content: {
                        1: {
                            index: 1,
                            img: img9_7_1
                        },
                    }
                },
                8: {
                    index: 8,
                    dataPoint: `9-8`,
                    title: `Секция 8. Японские хины и пекинесы`,
                    content: {
                        1: {
                            index: 1,
                            img: img9_8_1
                        },
                    }
                },
                9: {
                    index: 9,
                    dataPoint: `9-9`,
                    title: `Секция 9. Европейские той-спаниели`,
                    content: {
                        1: {
                            index: 1,
                            img: img9_9_1
                        },
                    }
                },
                10: {
                    index: 10,
                    dataPoint: `9-10`,
                    title: `Секция 10. Кромфорлендер`,
                    content: {
                        1: {
                            index: 1,
                            img: img9_10_1
                        },
                    }
                },
                11: {
                    index: 11,
                    dataPoint: `9-11`,
                    title: `Секция 11. Маленькие молоссы`,
                    content: {
                        1: {
                            index: 1,
                            img: img9_11_1
                        },
                    }
                }
            }
        },
        10: {
            index: 10,
            title: `10 группа FCI`,
            content: {
                1: {
                    index: 1,
                    dataPoint: `10-1`,
                    title: `Секция 1. Длинношерстные борзые и родственные породы`,
                    content: {
                        1: {
                            index: 1,
                            img: img10_1_1
                        },
                    }
                },
                2: {
                    index: 2,
                    dataPoint: `10-2`,
                    title: `Секция 2. Жесткошерстные борзые и родственные породы`,
                    content: {
                        1: {
                            index: 1,
                            img: img10_2_1
                        },
                    }
                },
                3: {
                    index: 3,
                    dataPoint: `10-3`,
                    title: `Секция 3. Короткошерстные борзые и родственные породы`,
                    content: {
                        1: {
                            index: 1,
                            img: img10_3_1
                        },
                    }
                }
            }
        }
    }

    render() {
        const {pointActive, subPointActive} = this.state;
        return (
            <div className="step5">
                <InfoBlock
                    text={
                        `Ознакомьтесь с типичными представителями каждой группы, нажимая на ключевые элементы`
                    }
                />
                <div className="points">
                    <div className={'points_menu' + (pointActive > 0 ? ' hidden' : '')}>
                        {Object.values(this.stepPoints).map(item => {
                            return (
                                <div
                                    key={item.index}
                                    className={`point ${pointActive === item.index ? ' active' : ''}`   + (subPointActive.length > 0 ? ' path' : '')}
                                    onClick={() => {
                                        this.changePoint(item.index)
                                    }}>
                                    {item.title}
                                </div>
                            )
                        })}
                    </div>
                    {Object.values(this.stepPoints).map(item => {
                        return (
                            <div
                                key={item.index}
                                className={`points_submenu${pointActive === item.index ? ' active' : ''}` + (subPointActive.length > 0 ? ' hidden' : '')}>
                                {Object.values(item.content).map(item => {
                                    return (
                                        <div
                                            key={item.title}
                                            className={`point${subPointActive[1] === item.dataPoint ? ' active' : subPointActive === 0 ? '' : ' inactive'}`}
                                            onClick={() => {
                                                this.checkPoint([item.index, item.dataPoint])
                                        }}>
                                            {item.title}
                                            <span className="point_el"></span>
                                        </div>
                                    )
                                })}
                                <div
                                    className="submenu_arrow"
                                    onClick={this.returnToPointsMenu}
                                >
                                    <div></div>
                                </div>
                            </div>
                        )
                    })}
                    {Object.values(this.stepPoints).map(item => {
                        return (
                            Object.values(item.content).map(item => {
                                return (
                                    item.content ? (
                                        <div
                                            key={item.index}
                                            className={`points_submenu-imgs${subPointActive[1] === item.dataPoint ? ' active' : ''}`}
                                        >
                                            <div className={'points_submenu-imgs_head'}>Типичные представители породы</div>
                                            {Object.values(item.content).map(item => {
                                                return (
                                                    <div
                                                        key={item.index}
                                                        className={`submenu_img`}
                                                    >
                                                        <img src={item.img} alt=""/>
                                                    </div>
                                                )
                                            })}
                                            <div
                                                className="submenu_arrow"
                                                onClick={this.returnToSubMenu}
                                            >
                                                <div></div>
                                            </div>
                                        </div>
                                    ) : false
                                )
                            })
                        )
                    })}
                </div>
            </div>
        )
    }
}
