import React from 'react'
import MainPage from "../Page/Main";
import MyTraining from "../Page/MyTraining";
import MyTesting from "../Page/MyTesting";
import Faq from "../Page/Faq";
import { Switch, Route, Redirect } from 'react-router-dom'
import TestPage from "../Page/Test";
import Lessons from "../Page/Lessons";
import ProfileCard from "../Components/ProfileCard";
import CoocieForm from "../Page/CoocieForm"
import Dostupnost from "../Page/dostupnost"
import CertificateModal from "../Components/CertificateModal"

export default class Main extends React.Component{

    render() {
        return (
            <main>
                <Switch>
                    {/*<Route exact path='/' component={MyTraining}/>*/}
                    <Route path='/mainPage' component={MainPage}/>
                    <Route path='/myTraining' component={MyTraining}/>
                    <Route path='/myTesting' component={MyTesting}/>
                    <Route path='/faq' component={Faq}/>
                    <Route path='/test' component={TestPage}/>
                    <Route path='/lesson' component={Lessons}/>
                    <Route path='/profileCard' component={ProfileCard}/>
                    <Route path='/coociesform' component={CoocieForm}/>
                    <Route path='/dostupnost' component={Dostupnost}/>
                    <Redirect from='/' to='/myTraining'/>
                </Switch>
            </main>
        )
    }
}
