import React from 'react'
import '../style.sass'

import logo from '../../../image/logo-start.png'
import Pedigree from '../../../image/ped-start.png'
import PerfectFit from '../../../image/perf-start.png'
import Inputs from '../../../Components/Inputs'
// import Partners from '../../../Components/LeftSideBar/Partners'
import SiteFooter from '../../../Components/SiteFooter'
import Checkbox from '../../../Components/Checkbox'
import {Link} from "react-router-dom";
import Api from '../../../Service/Api'
import {withRouter} from 'react-router-dom';

//checkpoint

class Owner extends React.Component {

    state = {
        // clubs: {},
        // club_id: '',
        // option_club_show: false,
        status: {1: 'Владелец собаки', 2: 'Будущий владелец собаки', 3: 'Заводчик собак'},
        status_id: '',
        type: '',
        statusError: false,
        option_status_show: false,
        month: {1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10, 11: 11, 12: 12,},
        monthSelected: [''],
        month_id: '',
        monthError: false,
        option_month_show: [false],
        email: '',
        emailError: false,
        emailErrorText: false,
        login: '',
        loginError: false,
        password: '',
        passwordError: false,
        passwordErrorText: false,
        city: '',
        cityError: false,
        hasDog: false,
        breed: [''],
        breedError: false,
        club: '',
        clubError: false,
        agree: false,
        agreeError: false,
        eighteen: false,
        eighteenError: false,
        subscribe: false,
        subscribeError: false,
        surname: '',
        name: '',
        phone: '',
        quantity: '',
        quantityError: false,
        // nursery: '',
        // nurseryError: false,
        // federation: '',
        // federationError: false,
        year: [''],
        yearError: [false]
    }

    Api = new Api()

    componentDidMount() {
        /*this.Api.getClubs('test')
            .then(this.getClubSuccess)*/
        this.Api.getTypeUser('typeUser')
            .then((res) => {
                if (res.status === 200) {
                    this.getTypeUserSuccess(res)
                }
            })
    }

    getTypeUserSuccess = (status) => {
        this.setState({status})
    }

    /*getClubSuccess = (clubs) => {
        this.setState({clubs})
    }*/

    /*optionClubShow = () => {
        this.setState({option_club_show: !this.state.option_club_show})
    }*/

    optionStatusShow = () => {
        this.setState({option_status_show: !this.state.option_status_show})
    }

    optionMonthShow = (index) => {
        const {option_month_show} = this.state

        option_month_show[index] = !option_month_show[index]

        this.setState({option_month_show: option_month_show})
    }

    /*setClubId = club_id => {
        this.setState({
            club_id,
            option_club_show: false,
            clubError: false,
        })
    }*/

    setStatusId = status_id => {
        this.setState({
            status_id,
            option_status_show: false,
            clubError: false,
            type: this.state.status[status_id],
            hasDog: false,
        })
    }

    setMonth = (month_id, index) => {
        const {option_month_show} = this.state
        let monthItem = this.state.month[month_id]
        let monthSelectedNew = this.state.monthSelected

        monthSelectedNew[index] = monthItem
        option_month_show[index] = false
        this.setState({
            month_id,
            option_month_show: option_month_show,
            monthError: false,
            monthSelected: monthSelectedNew
        })
    }

    changeType = type => {
        this.setState({
            type_page: type,
            emailError: false,
            loginError: false,
            passwordError: false,

        })
    }

    changeHasDog = (text) => {
        if (text === 'Да') {
            this.setState({
                hasDog: true
            })
        } else {
            this.setState({
                hasDog: false
            })
        }
    }

    changeAgree = () => {
        this.setState({
            agree: !this.state.agree,
            agreeError: false,
        })
    }

    changeEighteen = () => {
        this.setState({
            eighteen: !this.state.eighteen,
            eighteenError: false,
        })
    }
    changeSubscribe = () => {
        this.setState({
            subscribe: !this.state.subscribe,
            subscribeError: false,
        })
    }

    updateState = (type, value, index) => {
        // eslint-disable-next-line
        switch (type) {
            case 'email' : {
                this.setState({
                    email: value,
                    emailError: false,
                    emailErrorText: false
                });
                break;
            }
            case 'login' : {
                this.setState({
                    login: value,
                    loginError: false
                })
                break;
            }
            case 'password' : {
                this.setState({
                    password: value,
                    passwordError: false,
                    passwordErrorText: false
                })
                break;
            }
            case 'city' : {
                this.setState({
                    city: value,
                    cityError: false
                })
                break;
            }
            case 'month' : {
                const {monthSelected} = this.state
                monthSelected[index] = value
                this.setState({
                    monthSelected: monthSelected,
                    monthError: false})
                break;
            }
            case 'year' : {
                const {year, yearError} = this.state
                year[index] = value
                if (yearError[index]) {
                    yearError[index] = false
                }
                this.setState({
                    year: year,
                    yearError: yearError})
                break;
            }
            case 'breed' : {
                const {breed} = this.state
                breed[index] = value
                /*if (breedError[index]) {
                    breedError[index] = false
                }*/
                this.setState({
                    breed: breed,
                    breedError: false})
                break;
            }
            case 'surname' : {
                this.setState({
                    surname: value,
                })
                break;
            }
            case 'name' : {
                this.setState({
                    name: value,
                })
                break;
            }
            case 'phone' : {
                this.setState({
                    phone: value,
                })
                break;
            }
            case 'quantity' : {
                this.setState({
                    quantity: value,
                    quantityError: false
                })
                break;
            }
            /*case 'nursery' : {
                this.setState({
                    nursery: value,
                    nurseryError: false
                })
                break;
            }
            case 'federation' : {
                this.setState({
                    federation: value,
                    federationError: false
                })
                break;
            }*/
            case 'status' : {
                this.setState({
                    type: value,
                    statusError: false
                })
                break;
            }
            case 'club' : {
                this.setState({
                    club: value,
                    clubError: false
                })
                break;
            }
        }
    }

    sendDate = () => {
        const {
            email,
            password,
            city,
            breed,
            monthSelected,
            year,
            yearError,
            // club_id,
            club,
            agree,
            hasDog,
            type,
            status_id,
            surname,
            name,
            // patronymic,
            phone,
            quantity,
            // nursery,
            // federation,
        } = this.state

        let dogBirthday = year.slice()
        dogBirthday.map((item, index) => {
            if (monthSelected[index] === '') {
                dogBirthday[index] = '01.' + item
            } else if (monthSelected[index] < 10) {
                dogBirthday[index] = '0' + monthSelected[index] + '.' + item
            } else if (monthSelected[index] > 10) {
                dogBirthday[index] = monthSelected[index] + '.' + item
            } else {
                dogBirthday[index] = item
            }
            console.log(555, monthSelected[index])
            return dogBirthday
        })

        if (!this.valid_email(email)) {
            this.setState({emailError: true})
        } else if (!city.length) {
            this.setState({cityError: true})
        } else if (!password.length) {
            this.setState({passwordError: true})
        } else if (!agree) {
            this.setState({agreeError: true})
        } else if (type === 'Владелец собаки' && !quantity.length) {
            this.setState({quantityError: true})
        } else if (type === 'Владелец собаки' && !breed[0].length) {
            this.setState({breedError: true})
        } else if (type === 'Владелец собаки' && !monthSelected.length) {
            this.setState({monthError: true})
        } else if (type === 'Владелец собаки' && !this.valid_year()) {
            this.setState({yearError: yearError})
        } /*else if (type === 'Заводчик собак' && !nursery.length) {
            this.setState({nurseryError: true})
        } else if (type === 'Заводчик собак' && !club.length) {
            this.setState({clubError: true})
        } else if (type === 'Заводчик собак' && !federation.length) {
            this.setState({federationError: true})
        }*/ else if (type === 'Заводчик собак' && !breed[0].length) {
            this.setState({breedError: true})
        } /*else if (type === 'Заводчик собак' && !this.valid_year()) {
            this.setState({yearError: yearError})
        } else if (type === 'Дрессировщик' && hasDog && !quantity.length) {
            this.setState({quantityError: true})
        } else if (type === 'Дрессировщик' && hasDog && !breed[0].length) {
            this.setState({breedError: true})
        } else if (type === 'Дрессировщик' && hasDog && !this.valid_year()) {
            this.setState({yearError: yearError})
        } else if (type === 'Хендлер' && hasDog && !quantity.length) {
            this.setState({quantityError: true})
        } else if (type === 'Хендлер' && hasDog && !breed[0].length) {
            this.setState({breedError: true})
        } else if (type === 'Хендлер' && hasDog && !this.valid_year()) {
            this.setState({yearError: yearError})
        } */else {
            this.Api.registrationOwner(email, city, club, password, breed, surname, name, phone, status_id, quantity, /*nursery, federation,*/ hasDog, dogBirthday)
                .then((res) => {
                    if (res.status === 200) {
                        this.props.changeType();
                    }else{
                        Object.keys(res.error).map(key => {
                            // eslint-disable-next-line
                            this.state[key + 'Error'] = true;
                            // eslint-disable-next-line
                            this.state[key + 'ErrorText'] = res.error[key];
                            this.setState({errors: new Date()})
                            return false
                        })
                    }
                })
        }
    }

    valid_email = (el) => {
        // let reg = /^[a-z-._0-9]+@[a-z-_0-9]+\.[a-z0-9]{2,10}$/i;
        let reg = /^[a-z-._0-9]+@[a-z-._0-9]+\.[a-z0-9]{2,10}$/i;
        return reg.test(el)
    }

    valid_year = () => {
        const {year, yearError} = this.state
        // eslint-disable-next-line
        year.map((item, index) => {
            if (year[index].length) {
                yearError[index] = false
            } else {
                yearError[index] = true
            }
            console.log(10, yearError)
        })
        this.setState({yearError: yearError})
        // console.log(yearError)
        if (yearError.indexOf(true) === - 1) {
            return true
        }
        return false
    }

    addBreed = () => {
        const {breed, monthSelected, option_month_show, year, yearError} = this.state
        breed.push('')
        monthSelected.push('')
        option_month_show.push(false)
        year.push('')
        console.log(111, year)
        this.setState({breed: breed, monthSelected: monthSelected, yearError: yearError})
    }

    render() {
        const {
            // clubs,
            // club_id,
            status,
            status_id,
            type,
            statusError,
            month,
            month_id,
            monthError,
            monthSelected,
            email,
            emailError,
            emailErrorText,
            city,
            cityError,
            passwordError,
            hasDog,
            breed,
            breedError,
            agree,
            agreeError,
            eighteen,
            eighteenError,
            subscribe,
            subscribeError,
            clubError,
            // option_club_show,
            option_status_show,
            option_month_show,
            nurseryError,
            federationError,
            quantityError,
            yearError,
        } = this.state
        return (
            <div className="Entrance">
                <div className="wrp">
                    <form action="" className="form">
                        <div className="logo">
                            <img className="logo_subbrend" src={Pedigree} alt=""/>
                            <img className="logo_rkf" src={logo} alt=""/>
                            <img className="logo_subbrend" src={PerfectFit} alt=""/>
                        </div>
                        <div className="head">
                            Добрый день! <br/>
                            <Link to={'/startPage/auth'}>Войдите</Link> или для регистрации в обучающей программе <br/>
                            для владельцев собак, пожалуйста, заполните поля ниже.
                        </div>
                        <Inputs
                            name={'email'}
                            placeholder={'E-mail'}
                            onChange={this.updateState}
                            type={'email'}
                            className={emailError && 'error'}
                            errorText={emailErrorText}
                            required={'required'}
                        />
                        <div className="flex">
                            <Inputs
                                name={'surname'}
                                placeholder={'Фамилия'}
                                type={'text'}
                                onChange={this.updateState}
                            />
                            <Inputs
                                name={'name'}
                                placeholder={'Имя'}
                                type={'text'}
                                onChange={this.updateState}
                            />
                            {/*<Inputs
                                name={'patronymic'}
                                placeholder={'Отчество'}
                                type={'text'}
                                onChange={this.updateState}
                            />*/}
                        </div>
                        <div className="flex">
                            <Inputs
                                name={'city'}
                                placeholder={'Город'}
                                onChange={this.updateState}
                                type={'text'}
                                className={cityError && 'error'}
                                required={'required'}
                            />
                            <Inputs
                                name={'phone'}
                                placeholder={'Телефон'}
                                type={'tel'}
                                onChange={this.updateState}
                                mask={'+7 (999) 999-99-99'}
                            />
                        </div>
                        <div>
                            <Inputs
                                name={'password'}
                                placeholder={'Придумайте пароль'}
                                type={'password'}
                                onChange={this.updateState}
                                className={passwordError && 'error'}
                                required={'required'}
                            />
                            <Inputs
                                name={'status'}
                                placeholder={'Укажите Ваш статус'}
                                type={'text'}
                                onChange={this.updateState}
                                onClick={this.optionStatusShow}
                                className={statusError && 'error'}
                                value={status_id && status[status_id]}
                                options={status}
                                option_active={status_id}
                                option_show={option_status_show}
                                option_click={this.setStatusId}
                            />
                            {/*{type === 'Заводчик собак' &&
                            <Inputs
                                name={'nursery'}
                                placeholder={'Питомник / заводская приставка'}
                                type={'text'}
                                onChange={this.updateState}
                                className={nurseryError && 'error'}
                                required={'required'}
                            />
                            }
                            {type === 'Заводчик собак' &&
                            <Inputs
                                name={'club'}
                                placeholder={'Клуб'}
                                type={'text'}
                                onChange={this.updateState}
                                className={clubError && 'error'}
                                required={'required'}
                            />
                            <Inputs
                                name={'club'}
                                placeholder={'Клуб'}
                                type={'text'}
                                onChange={this.updateState}
                                onClick={this.optionClubShow}
                                className={clubError && 'error'}
                                value={club_id && clubs[club_id]}
                                options={clubs}
                                option_active={club_id}
                                option_show={option_club_show}
                                option_click={this.setClubId}
                            />
                            }
                            {type === 'Заводчик собак' &&
                            <Inputs
                                name={'federation'}
                                placeholder={'Федерация'}
                                type={'text'}
                                onChange={this.updateState}
                                className={federationError && 'error'}
                                required={'required'}
                            />
                            }
                            {type === 'Дрессировщик' || type === 'Хендлер' ?
                                <div className="checkboxs">
                                    <div className="checkboxsLabel">
                                        У вас есть собака?
                                    </div>
                                    <div className={'switch ' + (hasDog ? 'switch_right' : '')}>
                                        {hasDog &&
                                            <span className="switch_yes">Да</span>
                                        }
                                        {!hasDog &&
                                            <span className="switch_no">Нет</span>
                                        }

                                        <Checkbox
                                            active={hasDog}
                                            text={'Да'}
                                            onClick={this.changeHasDog}
                                        />
                                        <Checkbox
                                            active={!hasDog}
                                            text={'Нет'}
                                            onClick={this.changeHasDog}
                                        />
                                    </div>
                                </div>
                                :
                                null
                            }*/}
                            {type === 'Владелец собаки' || hasDog === true ?
                                <Inputs
                                    name={'quantity'}
                                    placeholder={'Сколько у Вас собак?'}
                                    type={'number'}
                                    onChange={this.updateState}
                                    className={quantityError && 'error'}
                                    required={'required'}
                                />
                                :
                                null
                            }
                            {type === 'Владелец собаки' || hasDog === true ?
                                <div className="inputsPlusList">
                                    {breed.map((item, index) => {
                                        return (
                                            <div className="inputsPlus" key={index}>
                                                <div className="inputsBirthday">
                                                    <div className="inputsBirthdayEl">
                                                        <p className="inputsBirthdayName">Дата рождения собаки:</p>
                                                        <p className="inputsBirthdayNote">(необязательно указывать точную дату)</p>
                                                    </div>
                                                    <div className="inputsBirthdayEl">
                                                        <Inputs
                                                            name={'month'}
                                                            placeholder={'месяц'}
                                                            type={'number'}
                                                            onChange={this.updateState}
                                                            onClick={() => {this.optionMonthShow(index)}}
                                                            className={monthError && 'error'}
                                                            value={month_id && monthSelected[index]}
                                                            options={month}
                                                            option_active={month_id}
                                                            option_show={option_month_show[index]}
                                                            option_click={this.setMonth}
                                                            index={index}
                                                        />
                                                    </div>
                                                    <div className="inputsBirthdayEl">
                                                        <Inputs
                                                            name={'year'}
                                                            placeholder={'год'}
                                                            type={'number'}
                                                            onChange={this.updateState}
                                                            index={index}
                                                            className={yearError[index] && ' error'}
                                                            required={'required'}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="inputsPlusBox">
                                                    <Inputs
                                                        name={'breed'}
                                                        placeholder={index === 0 ? 'Собака 1' : 'Собака ' + (index + 1)}
                                                        type={'text'}
                                                        onChange={this.updateState}
                                                        index={index}
                                                        className={(breedError && index === 0 && ' error ')/* + (!hasDog && ' disabled ')*/}
                                                        required={index === 0 ? 'required' : ''}
                                                    />
                                                    <div className="btn btn_white btnPlus" onClick={this.addBreed}>+ собака</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                :
                                null
                            }
                            {type === 'Заводчик собак' || hasDog === true ?
                                <div className="inputsPlusList">
                                    {breed.map((item, index) => {
                                        return (
                                            <div className="inputsPlus" key={index}>
                                                <div className="inputsPlusBox">
                                                    <Inputs
                                                        name={'breed'}
                                                        placeholder={index === 0 ? 'Собака 1' : 'Собака ' + (index + 1)}
                                                        type={'text'}
                                                        onChange={this.updateState}
                                                        index={index}
                                                        className={(breedError && index === 0 && ' error ')/* + (!hasDog && ' disabled ')*/}
                                                        required={index === 0 ? 'required' : ''}
                                                    />
                                                    <div className="btn btn_white btnPlus" onClick={this.addBreed}>+ собака</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                                :
                                null
                            }
                            <div className="flex_wrp">
                                <div className="agree">
                                    <span className="agree_star">*</span>
                                    <Checkbox
                                        active={agree}
                                        error={agreeError}
                                        onClick={this.changeAgree}
                                    />
                                    <div className="agree_text">
                                        <span className="link-no">Я ознакомился с </span><a href="/confidentiality.pdf" target="_blank" rel="noopener noreferrer"><span className="link-yes">Положением о конфиденциальности</span>, </a><a href="/agreement.pdf" target="_blank" rel="noopener noreferrer"><span className="link-yes">Пользовательским соглашением</span></a><span className="link-no"> и даю свое согласие на обработку персональных данных.</span>
                                    </div>
                                </div>
                                <div className="agree">
                                    <span className="agree_star">*</span>
                                    <Checkbox
                                        active={eighteen}
                                        error={eighteenError}
                                        onClick={this.changeEighteen}
                                    />
                                    <span className="link-no">Мне исполнилось 18 лет.</span>
                                </div>
                                <div className="agree">
                                    <Checkbox
                                        active={subscribe}
                                        error={subscribeError}
                                        onClick={this.changeSubscribe}
                                    />
                                    <span className="link-no">Я даю согласие на получение рекламной рассылки (в т.ч. в виде смс, электронных писем и/или через месенджеры).</span>
                                </div>
                                <div className="info">
                                    <p className="info__icon">
                                        <svg width="20" height="20" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M17.7665 0.999331C7.82737 1.11672 -0.116107 9.29498 0.00128454 19.2341C0.118676 29.1732 8.29694 37.1167 18.2361 36.9993C28.1752 36.8819 36.1187 28.7037 36.0013 18.7645C35.8839 8.82542 27.7056 0.88194 17.7665 0.999331ZM19.7622 6.98629C21.6013 6.98629 22.1491 8.04281 22.1491 9.25585C22.1491 10.7819 20.9361 12.1906 18.8622 12.1906C17.1404 12.1906 16.3187 11.3298 16.3578 9.88194C16.3187 8.6689 17.3361 6.98629 19.7622 6.98629ZM15.0665 30.2689C13.8143 30.2689 12.9143 29.5254 13.7752 26.1602L15.223 20.2515C15.4578 19.3124 15.4969 18.9211 15.223 18.9211C14.8317 18.9211 13.2274 19.5863 12.2491 20.2124L11.623 19.195C14.6752 16.6515 18.1578 15.1645 19.6448 15.1645C20.8969 15.1645 21.0926 16.6515 20.4665 18.9211L18.823 25.1428C18.5491 26.2385 18.6665 26.6298 18.9404 26.6298C19.3317 26.6298 20.5448 26.1602 21.7578 25.2211L22.4622 26.1602C19.5274 29.095 16.3187 30.2689 15.0665 30.2689Z"
                                                fill="#99BFDE"/>
                                        </svg>
                                    </p>
                                    <p className="info__text">
                                        <span className="info__star">&#42;</span>
                                        поля отмеченные звездочкой обязательны для заполнения
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="buttons">
                        <button
                            type='button'
                            className="btn btn_white"
                            onClick={() => {
                                this.sendDate()
                            }}
                            disabled={email.length && city.length && agree && eighteen && type.length ? '' : 'disabled'}
                        >
                            зарегистрироваться
                        </button>
                        </div>
                        {/*<Partners brStyle={{display: 'none'}}/>*/}
                    </form>
                </div>
                <SiteFooter style={{backgroundColor: '#00559C'}}/>
            </div>
        )
    }

}

export default withRouter(Owner);
