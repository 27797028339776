import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import img1 from '../../../image/lessons/lesson3/step9.png';

export default class step9 extends React.Component {


    render() {
        return (
            <div className="step9">
                <TextBlock
                    fomtSize={16}
                    text={`Все помещения, куда допускаются люди или животные со стороны («чужая грязь»).`}
                />

                <img src={img1} alt="" className={'img'}/>

                <InfoBlock
                    danger={true}
                    text={`Обход в a порядке может иметь катастрофические последствия, поскольку инфекционные агенты могут легко переносится на одежде или обуви. Тот же порядок необходимо соблюдать и во время ухода за помещениями или уборки.`}
                />
            </div>
        )
    }
}