import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";
// import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'


export default class step3 extends React.Component {
    state = {
    };

    render() {
        return (
            <div className="step4">
                <InfoBlock text={`Ознакомьтесь с содержанием видео ролика.`}/>
                <VideoBlock
                    src={'https://www.youtube.com/embed/Z0s6NQHmcP0'}
                    title={'https://www.youtube.com/watch?v=Z0s6NQHmcP0'}
                />
            </div>
        )
    }
}
