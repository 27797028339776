import React from 'react'

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step1">
                <div className="head">план урока</div>

                <div className="text">
                    В этом уроке мы рассмотрим видео-курс из цикла “Основы дрессировки” и узнаем как правильно научить навыку “Прекращение нежелательных действий”.
                </div>

                <div className="lessonSteps">
                    <div className="lessonSteps_el">
                        1 Этап
                    </div>
                    <div className="lessonSteps_el">
                        2 Этап
                    </div>
                    <div className="lessonSteps_el">
                        Отучение запрыгиванию на руки (1 этап)
                    </div>
                    <div className="lessonSteps_el">
                        Отучение запрыгиванию на руки (2 этап)
                    </div>
                    <div className="lessonSteps_el">
                        Итоги урока. Основные советы
                    </div>
                </div>

            </div>
        )
    }
}
