import React from 'react'

import img1 from '../../../image/lessons/lesson5/step42-1.png'


export default class step6 extends React.Component {


    render() {
        return (
            <div className="step42">
                <div className="flex">
                    <div className="ul">
                        <div className="li">
                            <div className="number">1</div>
                            <div className="text">
                                Вода жизненно важна для животных и необходима <b>для всех процессов</b> в организме.
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">2</div>
                            <div className="text">
                                Пища с высоким содержанием жидкости способствует <b>профилактике</b> заболевания мочевыводящих путей.
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">3</div>
                            <div className="text">
                                Польза влажного корма для животных <b>научно доказана</b>.
                            </div>
                        </div>
                    </div>
                    <img src={img1} alt=""/>
                </div>
            </div>
        )
    }
}
