import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from "../../../image/lessons/lesson41/step44-1.png";
import img1xs from "../../../image/lessons/lesson41/step44-1xs.png";


export default class step44 extends React.Component {


    render() {
        return (
            <div className="step44">
                <div className="head">
                    5. Большая продолжительность жизни
                </div>
                <TextBlock
                    text={`<b>Нередко стареющие собаки отказываются от привычной еды, либо едят неохотно и с трудом.</b>

                    Влажные рационы имеют высокие вкусовые характеристики и с большим удовольствием поедаются стареющими собаками, помогая решить проблему анорексии.`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt="" className="imgCenter"/>
                </picture>
                <TextBlock
                    text={`Технология производства пауча (сокращенное время приготовления в сравнении с банками) способствует <b>сохранению нативных свойств продукта.</b>
                    
                    <b>Подогрев влажного корма</b> способствует <b>усилению его запаха и вкуса</b>, повышая привлекательность корма для собак`}
                />
            </div>
        )
    }
}
