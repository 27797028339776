import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import Slider from "react-slick";
import img1 from '../../../image/lessons/lesson1/step5-1.png';
import img2 from '../../../image/lessons/lesson1/step5-2.png';
import img3 from '../../../image/lessons/lesson1/step5-3.png';
import img4 from '../../../image/lessons/lesson1/step5-4.png';
import SwipeBlock from "../../../Components/LessonAndTestComponents/SwipeBlock";


export default class step5 extends React.Component{

    changeSlide = (next) => {
        if(next === 2){
            this.props.showNextBtn();
        }
        console.log(next)
    }
    render() {
        let settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 2,
            adaptiveHeight: true,
            beforeChange: (current, next) => {
                this.changeSlide(next)
            },
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        dots: true,
                        arrows: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
            ]
        };
        return (
            <div className="step5">
                <TextBlock
                    text={`Здесь речь пойдет о выборе щенка в возрасте 6-12 недель — чаще всего именно в этом возрасте люди выбирают и приобретают собаку (бывают случаи, когда по взаимной договоренности выбранный щенок проводит еще какое-то время в питомнике).`}
                />
                <InfoBlock
                    text={`Ознакомьтесь с каждой характеристикой, переключая слайды`}
                />
                <SwipeBlock
                    text={`<p class="swiper-block__tablet">Ознакомьтесь с каждой группой нажав на ключевые элементы.<br>Используйте свайп для перемещения между элементами.</p><p class="swiper-block__mobile">Используйте свайп для ознакомления с каждой характеристикой.</p>`}
                />
                <Slider {...settings}>
                    <div className="flex_el">
                        <div className="img">
                            <img src={img1} alt=""/>
                        </div>
                        <TextBlock text={`Прежде всего нужно обратить внимание на здоровье и кондицию помета. Щенки должны быть энергичные и активные.

                            Рост и вес должны соответствовать породе и возрасту. Обращаем внимание на то, что правильно вскармливаемые малыши плотные, но ни в коем случае не жирные и не рыхлые, тем более нежелательны тощие, с торчащими костями.`}
                            fontSize={16}
                        />
                    </div>
                    <div className="flex_el">
                        <div className="img">
                            <img src={img2} alt=""/>
                        </div>
                        <TextBlock text={`Если вы планируете заниматься с собакой спортивной дрессировкой, спросите кто из щенков найболее активный.

                           Иногда самых шустрых можно увидеть с самого раннего возраста.
                           
                           У них выше способность к обучению и общая активность.`}
                           fontSize={16}
                        />
                    </div>
                    <div className="flex_el">
                        <div className="img">
                            <img src={img3} alt=""/>
                        </div>
                        <TextBlock text={`У предлагаемых собак не должно быть явных признаков инфекционных заболеваний: понос, рвота, кашель, торчащая, неопрятная, тусклая шерсть, истечения из глаз и носа.

                            Косвенными признаками нездоровья являются вялость, апатия, отказ от корма, перхоть.`}
                            fontSize={16}
                        />
                    </div>
                    <div className="flex_el">
                        <div className="img">
                            <img src={img4} alt=""/>
                        </div>
                        <TextBlock text={`Добротный помет обычно бывает «ровным», лучший щенок не сразу бросается в глаза, так как все они ухоженные и приятные.`}
                           fontSize={16}
                        />
                    </div>
                </Slider>

            </div>
        )
    }
}
