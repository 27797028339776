import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson5/step10-1.png'


export default class step6 extends React.Component {

    render() {
        return (
            <div className="step10">
                <TextBlock
                    text={`<b>Собака – потомок хищника</b>, за миллионы лет эволюции привыкшего питаться преимущественно мясной пищей. В то же время даже дикие псовые – весьма пластичные в этом отношении виды, в зависимости от ареала и сезона в их рационе мясо может составлять от 40 до 90 и более процентов.`}
                />
                <TextBlock
                    text={`Еще один факт заключается том, что <b>у домашних собак (особенно породистых) распространенность врожденных проблем пищеварения</b> даже выше, чем у живущего в городах современного человека, поэтому сегодня наличие десятков тысяч собак, которые не могут есть почти ничего, кроме готовых промышленных диет – объективный факт, новая реальность.`}
                />

                <div className="flex">
                    <img src={img1} alt=""/>
                    <div className="flex_el">
                        И именно эти два факта лежат в основе непрекращающихся дискуссий о том, чем лучше кормить собаку?
                    </div>
                </div>
            </div>
        )
    }
}