import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import ArrowBack from "../../../Components/LessonAndTestComponents/ArrowBack";
import List from "../../../Components/LessonAndTestComponents/List";

import img1 from '../../../image/lessons/lesson33/step16-1.jpg'
import img2 from '../../../image/lessons/lesson33/step16-2.png'
import img3 from '../../../image/lessons/lesson33/step16-3.png'
import img4 from '../../../image/lessons/lesson33/step16-4.png'
import img5 from '../../../image/lessons/lesson33/step16-5.png'
import img6 from '../../../image/lessons/lesson33/step16-6.png'
import img7 from '../../../image/lessons/lesson33/step16-7.png'
import img8 from '../../../image/lessons/lesson33/step16-8.png'
import img9 from '../../../image/lessons/lesson33/step16-9.png'


export default class step16 extends React.Component {

    state = {
        flexActive: 0,
        flexs: [1],
        flexHidden: false,
    };

    //Данные попапа
    imgDivData = {
        1: {
            text: `Энергия необходима щенкам для нормального метаболизма, который в период роста очень быстрый.
            
            Вспомните <b>три составляющие энергии:</b> белки, жиры, углеводы.
            
            Жиры содержат в <b>2 раза больше энергии,</b> чем белки и углеводы.`,
            list: false,
            img: img2
        },
        2: {
            text: `Жирные кислоты группы Омега-3 необходимы для нормального развития <b>мозга</b> и <b>нервной системы</b> щенка.
            
            Анализ материнского молока собаки показал наличие в нем некоторых кислот Омега-3, которые полезны для формирования нервной системы у растущего животного.
            
            Жирные кислоты Омега-6 также важны для роста и развития щенка. Они способствуют поддержанию <b>здорового состояния кожи</b> и <b>шерсти.</b>`,
            list: false,
            img: img3
        },
        3: {
            text: `Белок особенно критичен в фазе быстрого роста — <b>до 14 недель</b> жизни щенка.
            
            Очень важно обеспечивать щенку не только нужное количество белка, но также <b>правильное соотношение незаменимых аминокислот.</b>
            
            Существует 12 видов незаменимых аминокислот, которые должны присутствовать в рационе щенков, так как их организм <b>не может</b> синтезировать их самостоятельно.`,
            list: false,
            img: img4
        },
        4: {
            text: `Кальций и фосфор играют важную роль в <b>формировании костей</b> и <b>росте зубов</b> на протяжении всего периода роста.

            Также важно обеспечивать щенку не только достаточные количества кальция и фосфора, но их <b>правильное соотношение</b>.`,
            list: false,
            img: img5
        },
        5: {
            text: `Цинк необходим для здоровья <b>кожи</b> и <b>белкового метаболизма.</b>
            
            При нехватке цинка в рационе рост щенков замедляется.`,
            list: false,
            img: img6
        },
        6: {
            text: `Потребность щенков в железе выше, чем <b>у взрослых собак.</b>
            
            Если щенок не получает достаточное количество железа, нарушения в организме проявляются в следующих симптомах:`,
            list: [
                `медленный рост;`,
                `слабость и вялость.`,
            ],
            img: img7
        },
        7: {
            text: `Главная роль витамина D — <b>формирование здоровых костей.</b>
            
            Недостаток витамина D приводит к <b>рахиту —</b> заболеванию, которое характеризуется искривлением и болью в конечностях.
            
            В отличие от человека, собаки <b>не могут</b> синтезировать витамин D при воздействии солнечного света, поэтому они должны получать этот витамин в достаточном количестве из рациона.`,
            list: false,
            img: img8
        },
        8: {
            text: `Витамин А необходим для здорового <b>зрения</b>, а также для <b>синтеза белка</b> в организме. Это один из важнейших микроэлементов для животных в период их роста.`,
            list: false,
            img: img9
        },
    }

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs, flexHidden: true})
        if (flexs.length === 8) {
            this.props.showNextBtn()
        }
    }

    closeImgDiv = () => {this.setState({flexHidden: false, flexActive: 0})}


    render() {
        const {flexActive, flexHidden} = this.state
        return (
            <div className="step16">
                <TextBlock
                    text={`Питание щенков на ранних стадиях жизни напрямую влияет на здоровье в будущем. В корме для растущих животных должны содержаться все элементы для поддержания роста.`}
                    hidden={flexHidden === true ? 'hidden' : ''}
                />
                <InfoBlock
                    text={`Вы видите ключевые элементы, необходимые щенку. Нажмите на элемент, чтобы узнать, для чего он нужен растущему организму.`}
                    className={' InfoBlockTablet'}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />
                <div className="wrp">
                    <div className={'flex ' + (flexHidden === true && 'hidden')}>
                        <div className={'flex_el ' + (flexActive === 1 && 'active')} onClick={() => {
                            this.changeFlex(1)
                        }}>Энергия
                        </div>
                        <div className={'flex_el ' + (flexActive === 2 && 'active')} onClick={() => {
                            this.changeFlex(2)
                        }}>Омега-3 и Омега-6
                        </div>
                        <div className={'flex_el ' + (flexActive === 3 && 'active')} onClick={() => {
                            this.changeFlex(3)
                        }}>Белок
                        </div>
                        <div className={'flex_el ' + (flexActive === 4 && 'active')} onClick={() => {
                            this.changeFlex(4)
                        }}>Кальций и фосфор
                        </div>
                        <div className={'flex_el ' + (flexActive === 5 && 'active')} onClick={() => {
                            this.changeFlex(5)
                        }}>Цинк
                        </div>
                        <div className={'flex_el ' + (flexActive === 6 && 'active')} onClick={() => {
                            this.changeFlex(6)
                        }}>Железо
                        </div>
                        <div className={'flex_el ' + (flexActive === 7 && 'active')} onClick={() => {
                            this.changeFlex(7)
                        }}>Витамин D
                        </div>
                        <div className={'flex_el ' + (flexActive === 8 && 'active')} onClick={() => {
                            this.changeFlex(8)
                        }}>Витамин А
                        </div>
                    </div>
                    {flexActive === 0 &&
                    <div className="wrp_right">
                        <InfoBlock
                            text={`Вы видите ключевые элементы, необходимые щенку. Нажмите на элемент, чтобы узнать, для чего он нужен растущему организму.`}
                        />
                        <img className="wrp_img" src={img1} alt=""/>
                    </div>
                    }
                    {flexActive !== 0 &&
                    <div className={'imgDiv ' + (flexActive === 2 ? 'imgDiv--2' : flexActive === 3 ? 'imgDiv--3': flexActive === 4 ? 'imgDiv--4' : flexActive === 5 ? 'imgDiv--5' : flexActive === 6 ? 'imgDiv--6' : flexActive === 7 ? 'imgDiv--7' : flexActive === 8 ? 'imgDiv--8' : '')}>
                        <ArrowBack
                            returnMenu={this.closeImgDiv}
                        />
                        <img className="imgDiv_img" src={this.imgDivData[flexActive].img} alt=""/>
                        <TextBlock text={this.imgDivData[flexActive].text}/>

                        {this.imgDivData[flexActive].list &&
                        <List ListData={this.imgDivData[flexActive].list}/>
                        }
                    </div>
                    }
                </div>
            </div>
        )
    }
}
