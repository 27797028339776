import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson5/step8-1.png'


export default class step6 extends React.Component {


    render() {
        return (
            <div className="step8">
                <TextBlock
                    text={`На основе исследований научно-исследовательского центра по питанию домашних животных WALTHAM Petcare Science Institute были сделаны следующие выводы о пищевых предпочтениях собак.`}
                />
                <TextBlock
                    text={`Собаки менее прихотливы к еде, чем кошки. <span class="textBlue"><b>Температура корма не влияет значительно на вкусовые предпочтения собак в силу их особенностей.</b></span> Поскольку в дикой природе волки и собаки часто охотятся стаями, иногда питаются падалью, а также склонны запасать пищу, то они привыкли есть как теплую, так и холодную пищу.`}
                />
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}
