import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson38/step11-1.png'

export default class step11 extends React.Component {

    render() {
        return (
            <div className="step11">
                <div className="flex">
                    <div className="flex_el">
                        {/*<TextBlock*/}
                        {/*    text={`Вы изучили функциональные особенности рационов PERFECT FIT™ для собак. Теперь Вам предстоит выполнить финальное задание по этой теме.*/}
                        {/*    */}
                        {/*    После успешного прохождения данного задания, Вы сможете перейти к следующей части урока.`}*/}
                        {/*/>*/}
                        <TextBlock
                            text={`Вы изучили функциональные особенности рационов PERFECT FIT™ для собак.`}
                        />
                    </div>
                    <div className="flex_el">
                        <img src={img1} alt="" className="img"/>
                    </div>
                </div>
            </div>
        )
    }
}
