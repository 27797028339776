import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import img from '../../../image/lessons/lesson3/step10.png'

import step10_p1 from '../../../image/lessons/lesson3/step10-p1.png'
import step10_p2 from '../../../image/lessons/lesson3/step10-p2.png'
import step10_p3 from '../../../image/lessons/lesson3/step10-p3.png'
import step10_p4 from '../../../image/lessons/lesson3/step10-p4.png'
import step10_p5 from '../../../image/lessons/lesson3/step10-p5.png'
import step10_p6 from '../../../image/lessons/lesson3/step10-p6.png'
import step10_p7 from '../../../image/lessons/lesson3/step10-p7.png'


export default class step10 extends React.Component {
    state = {
        linkActive: 0,
        links: [],
    };

    onClickLink = linkActive => {
        const {links} = this.state;
        if (links.indexOf(linkActive) === -1) {
            links.push(linkActive)
        }
        this.setState({linkActive, links});
        if (links.length === 8) {
            this.props.showNextBtn()
        }
    }

    popaps = {
        1: {
            icon: step10_p1,
            color: 'red',
            head: 'Сухая уборка',
            text: 'Сбор наполнителя, экскрементов, шерсти и других органических загрязнений (совок, пылесос).',
        },
        2: {
            icon: step10_p2,
            color: 'red',
            head: 'Влажная уборка',
            text: 'Чистка химическим или физическим методом (давление водяного пара) для удаления микроорганизмов.',
        },
        3: {
            icon: step10_p3,
            color: 'red',
            head: '1-е ополаскивание',
            text: 'Использование большого объема количества воды, для ополаскивания помещения с целью убрать остатки вредных микробов.',
        },
        4: {
            icon: step10_p4,
            color: '',
            head: 'Дезинфекция',
            text: 'Обезвреживание поверхности питомника с помощью химических средств очистки пола и поверхностей.',
        },
        5: {
            icon: step10_p5,
            color: '',
            head: 'Ожидание',
            text: 'Необходимо подождать пока подействует химическое средство, фактическое время действия хим. средства.',
        },
        6: {
            icon: step10_p6,
            color: '',
            head: '2-е ополаскивание',
            text: 'Смывание небольшим количеством воды нанесенного химического средства, с целью дезинфекции.',
        },
        7: {
            icon: step10_p7,
            color: '',
            head: 'Высушивание',
            text: 'Высушивание помещения с применением вентиляции, и обдува воздушным потоком горячего воздуха.',
        },
    }


    render() {
        const {links, linkActive} = this.state;
        return (
            <div className="step10">
                <TextBlock
                    fomtSize={16}
                    text={`Все помещения, куда допускаются люди или животные со стороны («чужая грязь»).`}
                />
                <InfoBlock
                    text={`Ознакомьтесь с определением уборки и дезинфекции, нажав на ключевые элементы`}
                />

                <div className="blockImg">
                    <img src={img} alt=""/>
                    <div className={"link link1 red " + (links.indexOf(1) >= 0 && 'active')} onClick={() => {
                        this.onClickLink(1)
                    }}/>
                    <div className={"link link2 red " + (links.indexOf(2) >= 0 && 'active')} onClick={() => {
                        this.onClickLink(2)
                    }}/>
                    <div className={"link link3 red " + (links.indexOf(3) >= 0 && 'active')} onClick={() => {
                        this.onClickLink(3)
                    }}/>
                    <div className={"link link4 " + (links.indexOf(4) >= 0 && 'active')} onClick={() => {
                        this.onClickLink(4)
                    }}/>
                    <div className={"link link5 " + (links.indexOf(5) >= 0 && 'active')} onClick={() => {
                        this.onClickLink(5)
                    }}/>
                    <div className={"link link6 " + (links.indexOf(6) >= 0 && 'active')} onClick={() => {
                        this.onClickLink(6)
                    }}/>
                    <div className={"link link7 " + (links.indexOf(7) >= 0 && 'active')} onClick={() => {
                        this.onClickLink(7)
                    }}/>
                </div>

                <InfoBlock
                    danger={true}
                    text={`Органическое вещество обеспечивает микробам превосходную защиту от дезинфицирующих средств, поэтому дезинфекция не может быть эффективной без предварительной уборки! Лучше уборка без дезинфекции, чем дезинфекция без уборки.`}
                />

                {linkActive !== 0 &&
                <div className="infoPopap">
                    <div className="infoPopap_wrp">
                        <div className="infoPopap_close" onClick={() => this.onClickLink(0)}/>
                        <div className="infoPopap_scroll">
                            <div className="infoPopap_iconDiv">
                                <img src={this.popaps[linkActive].icon} alt="" className="infoPopap_icon"/>
                            </div>
                            <div
                                className={'infoPopap_head ' + (this.popaps[linkActive].color)}>{this.popaps[linkActive].head}
                            </div>
                            <TextBlock
                                fontSize={16}
                                text={this.popaps[linkActive].text}
                            />
                        </div>
                        {linkActive !== 7 &&
                        <button className="btn btn_blue"
                                onClick={() => this.onClickLink(linkActive + 1)}>Продолжить</button>
                        }

                    </div>
                </div>
                }




            </div>
        )
    }
}