import React from 'react'
import './style.sass'

export default (props) => {
    return (
        <div
            className={'ProjectHeader ' + props.ProjectHeaderType}
        >
            <p className="ProjectHeader_top">
                Основы ответственного собаководства:<br/> информационно-образовательный портал для владельцев собак
            </p>
            <p className="ProjectHeader_bottom">
                Совместный проект Российской кинологической федерации и MARS PETCARE (PEDIGREE® и PERFECT FIT™)
            </p>
        </div>
    )
}
