import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step6 extends React.Component {

    render() {
        return (
            <div className="step14">
                <TextBlock
                    text={`Как вы знаете, готовый корм для животных отвечает всем потребностям в питательных веществах. В домашней еде недостаточно макро- и микронутриентов, что может негативно сказаться на здоровье питомца в долгосрочном периоде.`}
                />
                <InfoBlock
                    text={`Изучите таблицу, чтобы понять, почему домашняя еда не является сбалансированным рационом для собак.`}
                />
                <div className="flex">
                    <div className="flex_row">
                        <div className="flex_el head">ДОМАШНЯЯ ЕДА</div>
                        <div className="flex_el head">ПОТРЕБНОСТИ СОБАК</div>
                    </div>
                    <div className="flex_row">
                        <div className="flex_el">
                            3 части риса <br/>
                            2 части куриной грудки <br/>
                            1 часть овощей на пару
                        </div>
                        <div className="flex_el">
                            <ul>
                                <li>в 1,5 раза больше цинка</li>
                                <li>в 36 раз больше витамина Е</li>
                                <li>в 10 раз больше витамина В</li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex_row">
                        <div className="flex_el">
                            2 части риса <br/>
                            1 часть рыбы на пару
                        </div>
                        <div className="flex_el">
                            <ul>
                                <li>в 2 раза больше жиров</li>
                                <li>2 3 раза больше цинка</li>
                                <li>в 11 раз больше витамина Е</li>
                                <li>в 2 раза больше витамина В12</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
