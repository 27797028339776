import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';


export default class step5 extends React.Component {
    state = {
        flexActive: 1,
        flexs: [1],
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs})
        if (flexs.length === 4) {
            this.props.showNextBtn()
        }
    }

    textActive = {
        1: `<span class="text_dark-blue"><b>Делаются строго в срок и ежегодно</b></span> (особенно это касается бешенства, так как Российская Федерация неблагополучна по этой опаснейшей и для животных, и для людей инфекции). 
            
            Легкомысленное отношение к прививкам крайне опасно, так как те, кто ими пренебрегает, могут чувствовать себя в безопасности только до тех пор, пока пребывают в меньшинстве. Как только они увеличиваются до заметного количества, <span class="text_dark-blue"><b>начинается эпизоотия</b></span>, при которой заболевают не только не привитые собаки, но даже какая-то часть привитых с ослабленным иммунитетом.
            `,
        2: `<span class="text_dark-blue"><b>Служебным, охотничьим собакам и животным на сырых рационах профилактическая  дегельминтизация проводится ежемесячно, домашним собакам ее необходимо проводить не реже раза в три месяца.</b></span>

            Особенно внимательным нужно быть родителям маленьких детей (легко могут заразиться) и владельцам племенных сук, так как некоторые паразиты могут попасть в организм щенка внутриутробно, и он родится уже зараженным и ослабленным. 
            
            Для щенка заражение гельминтами гораздо опаснее, чем для взрослой собаки.
            
            Обратите внимание, что верная рекомендация есть дальше в уроке!
            `,
        3: `<span class="text_dark-blue"><b>Профилактические обработки от блох и других паразитических насекомых должны проводиться круглогодично,</b></span> а от клещей – в сезон их активности, который варьируется в зависимости от региона и температуры окружающей среды. Обработки проводятся с частотой, указанной в инструкции к препарату.
`,
        4: `<span class="text_dark-blue"><b>При содержании собак за городом необходимо так же бороться с грызунами, которые могут быть переносчиками опасных инфекций.</b></span>
            
            Это сопряжено с некоторыми сложностями, так как необходимо не допускать поедания собакой отравленных приманок для грызунов и их трупов. В противном случае собака может погибнуть! Поэтому нужно очень внимательно следить за питомцем при проведении дератизации. В случае отравления собаки необходимо немедленно обратиться в ветеринарную клинику!
            
            Либо использовать биологические средства против грызунов, например завести хорошую кошку-охотницу. Даже если ваша собака не очень любит кошек, со своей, как показывает практика, она скорее всего подружится.
            `,
    }

    render() {
        const {flexActive} = this.state
        return (
            <div className="step5">
                <TextBlock
                    text={`К вопросу выбора ветеринарного врача нужно <b>отнестись ответственно.</b> На современном рынке ветеринарных услуг существует широкий спектр клиник на любой вкус и кошелёк, от частных веткабинетов и вызывных служб, до многопрофильных ветеринарных госпиталей, а также специалистов с разным уровнем компетенции и специализациями.
                    Остальное не этично! 

                    Это ничем хорошим для вас и вашей собаки не кончится, поэтому поинтересуйтесь рекомендациями и наблюдайте собаку не по принципу «ближе или дешевле», а там,
                    <b>где репутация заслуживает доверия и оказывают услуги надлежащего качества.</b>
                    `}
                />
                <InfoBlock text={`Нажмите на активные элементы, чтобы узнать больше.`}/>
                <div className="step_content">
                    <div className="flex">
                        <div className={'flex_el ' + (flexActive === 1 && 'active')} onClick={() => {
                            this.changeFlex(1)
                        }}>Профи&shy;лакти&shy;ческие прививки (вакцинация)
                        </div>
                        <div className={'flex_el ' + (flexActive === 2 && 'active')} onClick={() => {
                            this.changeFlex(2)
                        }}>Профилактика гельминтозов
                        </div>
                        <div className={'flex_el ' + (flexActive === 3 && 'active')} onClick={() => {
                            this.changeFlex(3)
                        }}>Борьба с эктопаразитами
                        </div>
                        <div className={'flex_el ' + (flexActive === 4 && 'active')} onClick={() => {
                            this.changeFlex(4)
                        }}>Борьба с грызунами
                        </div>
                    </div>
                    {flexActive !== 0 &&
                    <div className={'textDiv ' + (flexActive !== 1 ? `textDiv--${flexActive}` : '')}>
                        {<TextBlock
                            text={this.textActive[flexActive]}
                        />
                        }
                    </div>

                    }
                </div>
            </div>
        )
    }
}
