import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import List from "../../../Components/LessonAndTestComponents/List";

import img1 from '../../../image/lessons/lesson8/step2-1.png'
import img2 from '../../../image/lessons/lesson8/step2-2.png'
import img3 from '../../../image/lessons/lesson8/step2-4.png'

export default class step2 extends React.Component {
    state = {
        flexActive: 0,
        flex: [],
    };

    changeFlex = flexActive => {
        const {flex} = this.state
        this.setState({flexActive}, () => {})
        if (flex.indexOf(flexActive) === -1) {
            flex.push(flexActive)
        }
        this.setState({flex})
        if (flex.length === 2) {
            this.props.showNextBtn()
        }
    }

    render() {
        const {flexActive} = this.state;
        return (
            <div className="step2">
                <TextBlock
                    text={`<b>Кинологические мероприятия - важная часть жизни активного владельца.</b> `}
                />
                {/*<InfoBlock*/}
                {/*    text={`Ознакомьтесь с мероприятиями, нажав на активные элементы.`}*/}
                {/*/>*/}
                {/*<div className="flex">*/}
                {/*    <div className={'flex_el ' + (flexActive === 1 ? 'active' : '')}*/}
                {/*         onClick={() => {this.changeFlex(1)}}>*/}
                {/*        Племенной смотр*/}
                {/*    </div>*/}
                {/*    <div className={'flex_el ' + (flexActive === 2 ? 'active' : '')}*/}
                {/*         onClick={() => {this.changeFlex(2)}}>*/}
                {/*        Выставка*/}
                {/*    </div>*/}
                {/*</div>*/}
                    <div className='flex_el'>
                        <h4 className="step2__header">Племенной смотр</h4>
                        <p className="step2__text">Племенные смотры служат для отбора племенного поголовья. Их прохождение рекомендовано для всех собак, используемых
                            в разведении. Племенной смотр является основным племенным мероприятием при решении вопроса о допуске в разведение породистых собак.</p>
                    </div>

{/*                {flexActive !== 0 &&*/}
{/*                <div className={'info ' + (flexActive === 2 && "left")}>*/}
{/*                    {flexActive === 1 ?*/}
{/*                        <div className="info_flex">*/}
{/*                            <TextBlock*/}
{/*                                text={`Сугубо зоотехническое мероприятие,  на котором определяют пригодность породистых собак к племенному использованию. На нем собаку тщательно обследуют и достаточно подробно описывают. */}

{/*<b>Конкуренции как таковой нет, так как задача выделить «первых» не стоит.</b> `}*/}
{/*                            />*/}
{/*                            <img src={img1} alt=""/>*/}
{/*                        </div>*/}
{/*                        :*/}
{/*                        <div>*/}
{/*                            <div className="info_flex">*/}
{/*                                <TextBlock*/}
{/*                                    text={`Современная выставка собак – скорее зрелище, конкурс показа, нежели зоотехническое мероприятие. */}

{/*Присутствует конкуренция, подчас – острая, на престижных и международных выставках – очень острая.  <b>Цель – выбрать лучших.</b> Это адреналин и толика тщеславия.`}*/}
{/*                                />*/}
{/*                                <img src={img2} alt=""/>*/}
{/*                            </div>*/}
{/*                            <TextBlock*/}
{/*                                text={`Показу на выставке собаку надо учить (и учиться самому, если хотите достигнуть успеха). Для многих собаководов выставки превращаются в образ жизни, они объезжают со своими питомцами весь мир, многие наши соотечественники выигрывают самые престижные выставки мира.`}*/}
{/*                            />*/}
{/*                        </div>*/}
{/*                    }*/}
{/*                </div>*/}
{/*                }*/}

                    <div className="info">
                        <div className="info_flex">
                            <div className="info__el">
                                <TextBlock
                                    text={`Племенной смотр состоит из двух этапов.`}
                                />
                                <ul className="step2__list">
                                    <li className="step2__list-item">
                                        <div className="step2__list-item-left">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="12" cy="12" r="12" fill="#005EAD"/>
                                                <path d="M9.515 16.35H11.6V10.425L11.825 9.405L11.105 10.29L9.77 11.205L8.945 10.08L12.365 7.32H13.46V16.35H15.5V18H9.515V16.35Z" fill="white"/>
                                            </svg>
                                        </div>
                                        <div className="step2__list-item-right">
                                            <h5 className="step2__list-item-header">Первый этап</h5>
                                            <p className="step2__list-item-text">На первом этапе судья (либо комиссия из двух судей) производит проверку документов и идентификацию собаки</p>
                                        </div>
                                    </li>
                                    <li className="step2__list-item">
                                        <div className="step2__list-item-left">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="12" cy="12" r="12" fill="#005EAD"/>
                                                <path d="M15.125 9.065C15.125 9.575 15.03 10.09 14.84 10.61C14.66 11.13 14.425 11.64 14.135 12.14C13.855 12.64 13.54 13.125 13.19 13.595C12.85 14.055 12.515 14.48 12.185 14.87L11.39 15.485V15.56L12.47 15.35H15.35V17H9.035V15.995C9.275 15.745 9.545 15.455 9.845 15.125C10.145 14.785 10.445 14.43 10.745 14.06C11.055 13.68 11.355 13.29 11.645 12.89C11.935 12.49 12.195 12.09 12.425 11.69C12.655 11.28 12.835 10.885 12.965 10.505C13.105 10.125 13.175 9.765 13.175 9.425C13.175 9.015 13.055 8.68 12.815 8.42C12.585 8.15 12.215 8.015 11.705 8.015C11.385 8.015 11.055 8.085 10.715 8.225C10.385 8.355 10.105 8.525 9.875 8.735L9.095 7.355C9.485 7.035 9.925 6.78 10.415 6.59C10.915 6.39 11.5 6.29 12.17 6.29C12.6 6.29 12.995 6.35 13.355 6.47C13.715 6.59 14.025 6.765 14.285 6.995C14.545 7.225 14.75 7.515 14.9 7.865C15.05 8.205 15.125 8.605 15.125 9.065Z" fill="white"/>
                                            </svg>
                                        </div>
                                        <div className="step2__list-item-right">
                                            <h5 className="step2__list-item-header">Второй этап</h5>
                                            <p className="step2__list-item-text">
                                                На втором этапе судья проводит общий осмотр собаки, проверяет прикус, наличие и состояние зубов, делает подробное полное описание собаки и лично производит все промеры
                                            </p>
                                        </div>
                                    </li>
                                    <li className="step2__list-item">
                                        <div className="step2__list-item-left">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="12" cy="12" r="12" fill="#005EAD"/>
                                                <path d="M11 5H13.68V11.58L13.16 14.84H11.52L11 11.58V5ZM10.72 17.68C10.72 17.2133 10.8667 16.8467 11.16 16.58C11.4533 16.3 11.84 16.16 12.32 16.16C12.8267 16.16 13.2267 16.3 13.52 16.58C13.8133 16.8467 13.96 17.2133 13.96 17.68C13.96 18.1467 13.8133 18.52 13.52 18.8C13.2267 19.08 12.8267 19.22 12.32 19.22C11.84 19.22 11.4533 19.08 11.16 18.8C10.8667 18.52 10.72 18.1467 10.72 17.68Z" fill="white"/>
                                            </svg>
                                        </div>
                                        <div className="step2__list-item-right">
                                            <h5 className="step2__list-item-header">Обращаем внимание</h5>
                                            <p className="step2__list-item-text">
                                                На всем протяжении прохождения племенного смотра судья внимательно наблюдает за темпераментом и характером собаки
                                            </p>
                                        </div>
                                    </li>
                                    <li className="step2__list-item">
                                        <div className="step2__list-item-left">
                                            <img className="step2__list-item-image" src={img3} alt=""/>
                                        </div>
                                        <div className="step2__list-item-right">
                                            <h5 className="step2__list-item-header">Итоги смотра</h5>
                                            <p className="step2__list-item-text">
                                                По итогам племенного смотра допуск в племенное разведение дается: однократный – с разрешением на одну вязку; пожизненный – до окончания племенного использования собаки
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <img src={img1} alt=""/>
                        </div>
                    </div>
            </div>
        )
    }
}