import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import img1 from '../../../image/lessons/lesson3/step4-1.png';
import img2 from '../../../image/lessons/lesson3/step4-2.png';


export default class step4 extends React.Component {
    state = {

    };



    render() {
        return (
            <div className="step4">
                <div className="flex">
                    <img src={img1} alt="" className={'img1'}/>
                    <div>
                        <TextBlock
                            fontSize={16}
                            text={
                                `Поддержание гигиены – это поддержание равновесия между местной и привнесенной микрофлорой. В некоторых случаях полностью искоренить источник инфекции в питомнике невозможно. Искусство заводчика состоит в том, чтобы научиться удерживать его ниже уровня клинических проявлений с помощью соблюдения гигиены.`
                            }
                        />
                        <img src={img2} alt="" className={'img2'}/>
                    </div>
                </div>
            </div>
        )
    }
}