import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson4/step9-1-1.png'
import img2 from '../../../image/lessons/lesson4/step9-1-2.png'
import img3 from '../../../image/lessons/lesson4/step9-2.png'
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";


export default class step6 extends React.Component {
    state = {
        pointActive: 0,
        points: [],
    };

    changePoint = pointActive => {
        this.setState({pointActive}, () => {
            this.checkPoint(pointActive)
        })
    }

    checkPoint = (point) => {
        const {points} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        console.log(points)
        if (points.length === 5) {
            this.props.showNextBtn()
        }
    }

    popapWrp = {
        1: {
            head: 'Для чего выводились?',
            text: `Догообразные собаки выведены в глубокой древности из сторожей стад, но употреблялись как боевые собаки в войнах, для травли крупных и опасных зверей и, позднее, для организованных боев с собратьями и другими животными.
                    
                    Однако бешеный нрав воинов древности сегодня значительно смягчился в результате селекции.`
        },
        2: {
            head: 'Характер',
            text: `Догообразные собаки веками жили с человеком, сопровождали хозяина на охоте и в военных походах, в связи с чем эти собаки хорошо адаптированы для жизни рядом с человеком, в доме, прекрасно обучаются послушанию и заинтересованы в общении с хозяином. 
                    
                    Многие представители группы весьма упрямы, но в большинстве не склонны к агрессии, и при правильном воспитании очень послушны. 
                    
                    Часто недоверчивы к посторонним и агрессивны по отношению к собакам. Все догообразные хорошо поддаются прикладной дрессировке.`
        },
        3: {
            head: 'Особенности внешности',
            text: `В отличие от охранников стад, догообразные – в основном, гладкошерстные собаки, поэтому в отношении их содержания на улице справедливо то, что говорилось о пинчерах.
                    
                    Размер – от среднего до гигантского, среди догообразных есть породы, считающиеся самыми большими собаками из существующих на Земле. Так, в «Книгу рекордов Гиннеса» внесены: английский мастиф Зорбо, весивший 154 кг., сенбернар Бенедектин – 135 кг., немецкий дог Шмагрет Данзас  ростом 104,5 см и весом 108 кг.
                    
                    Собаки этой группы много едят и, несмотря на богатырскую внешность, подчас не отличаются крепким здоровьем и долголетием.`
        },
        4: {
            head: 'Типичные представители',
            text: `Самые известные в России породы: немецкий дог, ротвейлер, сенбернар, боксер, ньюфаундленд, английский и неаполитанский мастифы, кане-корсо.`
        },
    }


    render() {
        const {pointActive, points} = this.state;
        return (
            <div className="step9">
                <TextBlock
                    text={`<span class="text_subheader">Догообразные (секция 2)</span>`}
                />
                <InfoBlock
                    text={`Ознакомьтесь с каждой группой, нажав на ключевые элементы.`}
                />
                <div className="flex">
                    <div className="flexImg">
                        <img src={img1} alt="" className=''/>
                        <img src={img2} alt="" className=''/>
                    </div>
                    <div className="flex_el"><span className={points.indexOf(1) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(1)}}>Для чего выводились?</span></div>
                    <div className="flex_el"><span className={points.indexOf(2) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(2)}}>Характер</span></div>
                    <div className="flex_el"><span className={points.indexOf(3) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(3)}}>Особенности внешности</span></div>
                    <div className="flex_el"><span className={points.indexOf(4) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(4)}}>Типичные представители</span></div>
                </div>

                {pointActive !== 0 &&
                <PopupInStep
                    closePopup={() => this.changePoint(0)}
                    popupImg={img3}
                    popupHead={this.popapWrp[pointActive].head}
                    popupText={this.popapWrp[pointActive].text}
                    changePoint={() => this.changePoint(pointActive < 4 ? pointActive + 1 : 0)}
                />
                }
            </div>
        )
    }
}
