import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import img1 from '../../../image/lessons/lesson4/step2-1.png';
import img2 from '../../../image/lessons/lesson4/step2-2.png';
import img3 from '../../../image/lessons/lesson4/step2-3.png';


export default class step2 extends React.Component {
    state = {
        flexActive: 0,
        flexs: [1],
        flexHidden: false,
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs, flexHidden: true})
        if (flexs.length === 3) {
            this.props.showNextBtn()
        }
    }

    closeImgDiv = () => {this.setState({flexHidden: false, flexActive: 0})}


    render() {
        const {flexActive, flexHidden} = this.state
        return (
            <div className="step2">
                <InfoBlock
                    text={`Ознакомьтесь с наиболее распространёнными сомнениями, нажав на активные элементы.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />

                <div className={'flex ' + (flexHidden === true && 'hidden')}>
                    <div className={'flex_el ' + (flexActive === 1 && 'active')} onClick={() => {
                        this.changeFlex(1)
                    }}>Щенок
                    </div>
                    <div className={'flex_el ' + (flexActive === 2 && 'active')} onClick={() => {
                        this.changeFlex(2)
                    }}>Взрослая
                    </div>
                    <div className={'flex_el ' + (flexActive === 3 && 'active')} onClick={() => {
                        this.changeFlex(3)
                    }}>Пожилая
                    </div>
                </div>
                {flexActive !== 0 &&
                <div className={'imgDiv ' + (flexActive === 2 ? 'center' : flexActive === 3 ? 'right' : '')}>
                    <div
                        className="imgDiv_closed"
                        onClick={() => {this.closeImgDiv()}}
                    />
                    {flexActive === 1 &&
                        <div className="imgDiv_wrap">
                            <div className="imgPhone">
                                <img className="imgPhoneBg" src={img1} alt=""/>
                            </div>
                            <TextBlock
                                text={`<span class="text_dark-blue"><b>Щенок в возрасте 2-4 месяца зачастую требует такого же внимания, как и маленький ребенок.</b></span> Его нужно кормить до шести раз в день, он ещё не может долго терпеть до прогулки и пачкает дома, его нужно своевременно прививать, он хочет играть и бегать всё время, пока не спит. Он будет совать свой нос везде и всюду, и за короткое время может разгрызть и испортить много вещей. Щенок требует общения и внимательного грамотного воспитания.`}
                            />
                            <div className="imgDiv_flex">
                                <TextBlock
                                    text={`Если с малышом не заниматься, то он может вырасти в неуправляемую, невоспитанную собаку, которая приносит много хлопот.`}
                                />
                                <img src={img1} alt=""/>
                            </div>
                        </div>
                    }
                    {flexActive === 2 &&
                    <div className="imgDiv_wrap">
                        <div className="imgPhone">
                            <img className="imgPhoneBg" src={img2} alt=""/>
                        </div>
                        <div className="imgDiv_flex">
                            <TextBlock
                                text={`По мере взросления собаки, трудности, связанные с уходом, не исчезают: собаку необходимо выгуливать несколько раз в день <span class="text_dark-blue"><b>независимо от погоды</b></span> и самочувствия владельца. Собаку нужно кормить полноценным кормом, ухаживать за шерстью, ей надо чистить зубы и стричь когти. Собака <span class="text_dark-blue"><b>ежедневно</b></span> требует ласки, заботы и внимания!`}
                            />
                            <img src={img2} alt=""/>
                        </div>
                        <TextBlock
                            text={`Необходимо продумать, где будет собака на время Вашего долгого отсутствия: с вами, в зоогостинице или с вашими родственниками. Если вы уделяли мало внимания воспитанию собаки, она может дурно себя вести: грызть вещи, воровать еду, выть в ваше отсутствие, на прогулках облаивать прохожих и других собак, сильно тянуть поводок. Такие собаки приносят много хлопот. Наконец, собака требует немалых материальных затрат на свое содержание.`}
                        />
                    </div>
                    }
                    {flexActive === 3 &&
                    <div className="imgDiv_wrap">
                        <div className="imgPhone">
                            <img className="imgPhoneBg" src={img3} alt=""/>
                        </div>
                        <div className="imgDiv_flex">
                            <TextBlock
                                text={`К сожалению, век собаки много короче нашего. И проходит совсем немного времени, пока из пушистого беспомощного комочка вырастает взрослый пес, который потом неизбежно становится стар, в связи с чем прибавляется ещё больше забот, связанных с уходом за пожилыми собаками.`}
                            />
                            <img src={img3} alt=""/>
                        </div>
                    </div>
                    }
                </div>
                }
            </div>
        )
    }
}
