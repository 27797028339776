import React from 'react'

import img1 from '../../../image/lessons/lesson40/step3-1.png';
import img2 from '../../../image/lessons/lesson40/step3-2.png';
import img3 from '../../../image/lessons/lesson40/step3-3.png';
import img4 from '../../../image/lessons/lesson40/step3-4.png';
import img5 from '../../../image/lessons/lesson40/step3-5.png';
import img6 from '../../../image/lessons/lesson40/step3-6.png';
import img7 from '../../../image/lessons/lesson40/step3-7.png';


export default class step3 extends React.Component {
    state = {
    };

    render() {
        return (
            <div className="step3">
                <div className="header">
                    Развитие пародонтита
                </div>
                <div className="statistics">
                    <div className="statistics_text">
                        У 4 из 5 собак в возрасте старше 3 лет наблюдаются признаки заболеваний зубов и десен
                    </div>
                    <div className="statistics_dogs">
                        <img src={img1} alt="" className="statistics_img"/>
                        <img src={img1} alt="" className="statistics_img"/>
                        <img src={img1} alt="" className="statistics_img"/>
                        <img src={img1} alt="" className="statistics_img"/>
                        <img src={img2} alt="" className="statistics_img"/>
                    </div>
                </div>
                <div className="teeth">
                    <div className="teeth_el">
                        <div className="teeth_el_head">
                            Здоровый зуб
                        </div>
                        <div className="teeth_el_img-box">
                            <img src={img3} alt="" className="teeth_el_img"/>
                            <span className="teeth_el_arrow">
                                <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5991 4.08551L17.5991 11.628L2.23799e-05 11.628L2.27096e-05 4.0855L17.5991 4.08551Z" fill="#BA0D0E"/>
                                    <path d="M14.6274 -3.49691e-07L22.6274 8L14.6274 16L14.6274 -3.49691e-07Z" fill="#BA0D0E"/>
                                </svg>
                            </span>
                        </div>
                        <div className="teeth_el_description">
                            Здоровые зубы должны быть гладкими и белыми; десны – бледно-розовыми, гладкими и плотно прилегающими к зубам.
                            <span className="teeth_el_arrow-mobile">
                                <svg width="34" height="48" viewBox="0 0 34 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.2745 37.3333L9.27446 37.3333L9.27446 0L25.2745 1.39876e-06L25.2745 37.3333Z" fill="#BA0D0E"/>
                                    <path d="M33.9411 31.0294L16.9706 48L0 31.0294L33.9411 31.0294Z" fill="#BA0D0E"/>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div className="teeth_el">
                        <div className="teeth_el_head">
                            Стадии развития пародонтита
                        </div>
                        <div className="teeth_el_img-box">
                            <span className="teeth_el_arrow">
                                <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5991 4.08551L17.5991 11.628L2.23799e-05 11.628L2.27096e-05 4.0855L17.5991 4.08551Z" fill="#BA0D0E"/>
                                    <path d="M14.6274 -3.49691e-07L22.6274 8L14.6274 16L14.6274 -3.49691e-07Z" fill="#BA0D0E"/>
                                </svg>
                            </span>
                            <span className="teeth_el_arrow">
                                <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5991 4.08551L17.5991 11.628L2.23799e-05 11.628L2.27096e-05 4.0855L17.5991 4.08551Z" fill="#BA0D0E"/>
                                    <path d="M14.6274 -3.49691e-07L22.6274 8L14.6274 16L14.6274 -3.49691e-07Z" fill="#BA0D0E"/>
                                </svg>
                            </span>
                            <span className="teeth_el_arrow">
                                <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5991 4.08551L17.5991 11.628L2.23799e-05 11.628L2.27096e-05 4.0855L17.5991 4.08551Z" fill="#BA0D0E"/>
                                    <path d="M14.6274 -3.49691e-07L22.6274 8L14.6274 16L14.6274 -3.49691e-07Z" fill="#BA0D0E"/>
                                </svg>
                            </span>
                            <span className="teeth_el_arrow-mobile">
                                <svg width="30" height="25" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.3397 14.7555L8.19754 14.7555L8.19754 0.795897L22.3397 0.795898L22.3397 14.7555Z" fill="#BA0D0E"/>
                                    <path d="M30 9.18359L15 24.1836L1.31134e-06 9.18359L30 9.18359Z" fill="#BA0D0E"/>
                                </svg>
                            </span>
                            <span className="teeth_el_arrow-mobile">
                                <svg width="30" height="25" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.3397 14.7555L8.19754 14.7555L8.19754 0.795897L22.3397 0.795898L22.3397 14.7555Z" fill="#BA0D0E"/>
                                    <path d="M30 9.18359L15 24.1836L1.31134e-06 9.18359L30 9.18359Z" fill="#BA0D0E"/>
                                </svg>
                            </span>
                            <span className="teeth_el_arrow-mobile">
                                <svg width="30" height="25" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.3397 14.7555L8.19754 14.7555L8.19754 0.795897L22.3397 0.795898L22.3397 14.7555Z" fill="#BA0D0E"/>
                                    <path d="M30 9.18359L15 24.1836L1.31134e-06 9.18359L30 9.18359Z" fill="#BA0D0E"/>
                                </svg>
                            </span>
                            <img src={img4} alt="" className="teeth_el_img"/>
                            <img src={img5} alt="" className="teeth_el_img"/>
                            <img src={img6} alt="" className="teeth_el_img"/>
                            <img src={img7} alt="" className="teeth_el_img"/>
                        </div>
                        <div className="teeth_el_description">
                            <ul>
                                <li>Ежедневно на поверхности зуба появляется налет, содержащий бактерии</li>
                                <li>Ели его не счищать, образуется зубной камень, начинают кровоточить десны</li>
                                <li>Без лечения и профилактики зубы расшатываются и выпадают</li>
                                <li>Инфекция может затронуть внутренние органы, вызвав заболевания печени, почек и сердца</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="info">
                    Основной способ профилактики заболеваний зубов и десен – регулярное очищение зубного налета
                </div>
            </div>
        )
    }
}
