import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson4/step20-1-1.png'
import img2 from '../../../image/lessons/lesson4/step20-1-2.png'
import img3 from '../../../image/lessons/lesson4/step20-2.png'
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";


export default class step6 extends React.Component {
    state = {
        pointActive: 0,
        points: [],
    };

    changePoint = pointActive => {
        this.setState({pointActive}, () => {
            this.checkPoint(pointActive)
        })
    }

    checkPoint = (point) => {
        const {points} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        console.log(points)
        if (points.length === 5) {
            this.props.showNextBtn()
        }
    }

    popapWrp = {
        1: {
            head: 'Для чего выводились?',
            text: `Так же как и легавые, это – охотники, выведенные прежде всего для охоты на птицу.

Роль этих собак – принести из воды битую птицу, хотя, в разные периоды своей истории некоторые породы осваивали весьма разные функции – от вытаскивания на берег лодок и сетей (в качестве упряжных животных), до поиска наркотиков и пострадавших в завалах.`
        },
        2: {
            head: 'Характер',
            text: `В основном, собаки этой группы – прекрасные компаньоны, послушные, дружелюбные, пригодные к различным видам прикладной и спортивной дрессировки.

Все породы группы – прекрасные пловцы. Как правило, не сторожа. Активные, требующие длительных прогулок и внимания к физической кондиции (склонны к ожирению) собаки.`
        },
        3: {
            head: 'Особенности внешности',
            text: `Размер – от малого до среднего. В группе есть собаки как с короткой, так и длинной шерстью.`
        },
        4: {
            head: 'Типичные представители',
            text: `В России наиболее известны спаниели, лабрадор-ретривер и золотистый ретривер.`
        },
    }


    render() {
        const {pointActive, points} = this.state;
        return (
            <div className="step16">
                <TextBlock
                    text={`<span class="text_subheader">Ретриверы, спаниели, водяные собаки</span>`}
                />
                <InfoBlock
                    text={`Ознакомьтесь с каждой группой, нажав на ключевые элементы.`}
                />
                <div className="flex">
                    <div className="flexImg">
                        <img src={img1} alt="" className=''/>
                        <img src={img2} alt="" className=''/>
                    </div>
                    <div className="flex_el"><span className={points.indexOf(1) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(1)}}>Для чего выводились?</span></div>
                    <div className="flex_el"><span className={points.indexOf(2) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(2)}}>Характер</span></div>
                    <div className="flex_el"><span className={points.indexOf(3) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(3)}}>Особенности внешности</span></div>
                    <div className="flex_el"><span className={points.indexOf(4) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(4)}}>Типичные представители</span></div>
                </div>

                {pointActive !== 0 &&
                <PopupInStep
                    closePopup={() => this.changePoint(0)}
                    popupImg={img3}
                    popupHead={this.popapWrp[pointActive].head}
                    popupText={this.popapWrp[pointActive].text}
                    changePoint={() => this.changePoint(pointActive < 4 ? pointActive + 1 : 0)}
                />
                }
            </div>
        )
    }
}
