import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson5/step22-1.png'
import img2 from '../../../image/lessons/lesson5/step22-2.png'
import img3 from '../../../image/lessons/lesson5/step22-3.png'
import img4 from '../../../image/lessons/lesson5/step22-4.png'
import img5 from '../../../image/lessons/lesson5/step22-5.png'


export default class step6 extends React.Component {
    state = {
        pointActive: 0,
        points: [],
    };

    changePoint = pointActive => {
        this.setState({pointActive}, () => {
            this.checkPoint(pointActive)
        })
    }

    checkPoint = (point) => {
        const {points} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        if (points.length === 3) {
            this.props.showNextBtn()
        }
    }

    popapWrp = {
        1: {
            icon: img3,
            head: 'Энергетическая потребность питомца',
            text: `Формула расчета энергетической потребности:

для взрослой собаки <span class="textBlue"><b>Э = 95 * M</b></span> 

<small>Э — энергетическая потребность;
М — масса тела в килограммах.</small>`
        },
        2: {
            icon: img4,
            head: 'Энергетическую ценность корма',
            text: `Энергетическая ценность корма пишется на обратной стороне упаковки. Например, энергетическая ценность Pedigree®
с говядиной составляет 70 ккал / 100 г.`,
            img: img5
        },

    }

    render() {
        const {pointActive} = this.state;

        return (
            <div className="step22">
                <TextBlock
                    text={`Инструкция по кормлению рассчитывается путем деления суточной энергетической потребности питомца на энергетическую ценность корма.`}
                />
                <div className="ul">
                    <div className="liHead">Расчет делается в три шага:</div>
                    <div className="li">
                        <div className="number">1.</div>
                        <div className="text">Рассчитать энергетическую потребность питомца, исходя из его массы. Измеряется в килокалориях (ккал).</div>
                        <div className="quest" onClick={() => {this.changePoint(1)}}/>
                    </div>
                    <div className="li">
                        <div className="number">2.</div>
                        <div className="text">Узнать энергетическую ценность корма. Эта информация есть на каждой упаковке. Измеряется в ккал / 100 г.</div>
                        <div className="quest" onClick={() => {this.changePoint(2)}}/>
                    </div>
                    <div className="li">
                        <div className="number">3.</div>
                        <div className="text"><img src={img1} alt=""/></div>
                    </div>
                </div>

                <img src={img2} alt="" className="imgCenter"/>

                {pointActive !== 0 &&
                <div className={'infoPopap'}>
                    <div className="infoPopap_wrp">
                        <div className="popap_close" onClick={() => {
                            this.changePoint(0)
                        }}/>
                        <div className="image">
                            <div className="imageBg">
                                <img src={this.popapWrp[pointActive].icon} alt=""/>
                            </div>
                        </div>
                        <div className="head"
                             dangerouslySetInnerHTML={{__html: this.popapWrp[pointActive].head}}/>
                        <TextBlock
                            text={this.popapWrp[pointActive].text}
                            fontSize={16}
                        />
                        {this.popapWrp[pointActive].img && <img src={this.popapWrp[pointActive].img} alt="" className={'img'}/>}
                        <button className="btn btn_blue" onClick={() => {
                            this.changePoint(pointActive < 2 ? pointActive + 1 : 0)
                        }}>Продолжить</button>
                    </div>
                </div>
                }
            </div>
        )
    }
}
