import React from 'react'

import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import ArrowBack from "../../../Components/LessonAndTestComponents/ArrowBack";

import img1 from "../../../image/lessons/lesson7Owner/step3-1.png";
import img1xs from "../../../image/lessons/lesson7Owner/step3-1xs.png";
import img2 from "../../../image/lessons/lesson7Owner/step3-2.png";
import img2xs from "../../../image/lessons/lesson7Owner/step3-2xs.png";
import img3 from "../../../image/lessons/lesson7Owner/step3-3.png";
import img3xs from "../../../image/lessons/lesson7Owner/step3-3xs.png";
import img1i from "../../../image/lessons/lesson7Owner/step3-1i.png";
import img2i from "../../../image/lessons/lesson7Owner/step3-2i.png";
import img3i from "../../../image/lessons/lesson7Owner/step3-3i.png";

export default class step3 extends React.Component {
    state = {
        activeFlex: 0,
        flexs: [],
        pointActive: 0,
        points: [],
        flexHidden: false,
    };

    changeFlex = activeFlex => {
        const {flexs} = this.state
        this.setState({
            activeFlex,
            pointActive: 0,
            flexHidden: true,
        })
        if (flexs.indexOf(activeFlex) === -1) {
            flexs.push(activeFlex)
        }
        this.checkBtn()
    }
    changePoint = pointActive => {
        const {points, activeFlex} = this.state
        if (pointActive > 0) {
            document.body.scrollIntoView( true);
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
        this.setState({
            pointActive
        })
        if (points.indexOf(activeFlex.toString() + pointActive.toString()) === -1) {
            points.push(activeFlex.toString() + pointActive.toString())
        }
        console.log(555, points)
        this.checkBtn()
    }

    closeFlexClick = () => {
        this.setState({flexHidden: false, activeFlex: 0})
    }

    checkBtn = () => {
        const {flexs, points} = this.state
        if (flexs.length === 3 && points.length === 10) {
            this.props.showNextBtn();
        }
    }

    click_flex = {
        1: {
            img: img1,
            imgXs: img1xs,
        },
        2: {
            img: img2,
            imgXs: img2xs,
        },
        3: {
            img: img3,
            imgXs: img3xs,
        }
    }

    points = {
        1: {
            icon: img1i,
            wrp: {
                1: {
                    head: '1. Удобно и дорого',
                    text: 'Очень распространенный сейчас подход, а при отсутствии поблизости учебно-дрессировочной площадки или при наличии собаки с проблемным поведением (которую трудно туда доставить), часто - единственно возможный.',
                    plus1: 'Удобно (к вам приезжают в удобное для вас время)',
                    minus1: 'Дороже, чем на площадке.'
                },
                2: {
                    head: '2. Внимание собаки и круг общения',
                    text: '',
                    plus1: 'Все внимание только вам и вашей собаке',
                    minus1: 'И у собаки, и у вас нет того расширения круга общения и интересов, которое может произойти при групповых занятиях.'
                },
                3: {
                    head: '3. Возможно единственный выход и риск',
                    text: 'Неопытный владелец должен заниматься под руководством кинолога сам, так как не только собаку нужно научить навыкам, но и человека - ей управлять. Кроме того, тогда не происходит «переключения» собаки - ситуации, в которой кинолог заменяет собой хозяина (его она слушается, а хозяев - нет)',
                    plus1: 'В ряде случаев  единственный выход.',
                    minus1: 'Выше риск «переключения» собаки, чем при групповых занятиях.'
                }
            }
        },
        2: {
            icon: img2i,
            wrp: {
                1: {
                    head: '1. Дешевле, но надо подстроиться',
                    text: 'Молодая собака на таких занятиях прекрасно социализируется, у вас есть возможность лучше узнать и почувствовать кинологический мир, могут проснуться неожиданные интересы - вдруг у вас и вашего питомца окажется талант для увлекательного мира спорта с собаками? Вы никогда не узнаете об этом, если не познакомитесь с этим миром поближе.',
                    plus1: 'Дешевле, чем индивидуальные занятия.',
                    minus1: 'График занятий жесткий, вам придется подстроиться.',
                    plus2: 'Собака социализируется, учится вести себя с собратьями.',
                    minus2: 'Необходимо иметь время и самоорганизацию для выполнения домашних заданий, иначе это все будет без толку, хоть вечно ходите.\n',
                    plus3: 'Минимальный риск «переключения» собаки на инструктора.',
                    minus3: 'Часто приходится далеко ездить, не везде доступная услуга.',
                },
            }
        },
        3: {
            icon: img3i,
            wrp: {
                1: {
                    head: '1. Только для опытных',
                    text: `Подходит опытным людям, хорошо знающим собак и умеющим с ними обращаться, но не по тем или иным причинам - <b>не располагающим временем</b>.`,
                },
                2: {
                    head: '2. Долгое закрепление навыков',
                    text: `При отсутствии должной передачи от дрессировщика к хозяину неопытный владелец получит наполовину не свою, привыкшую работать с посторонним собаку, <b>у которой необходимо еще долго закреплять навык</b> (выше написано, сколько времени нужно для его закрепления - обычно передержка с дрессировкой длится намного меньше).`,
                },
                3: {
                    head: '3. Компромиссная методика',
                    text: `В некоторых ситуациях возможна компромиссная методика - в этом случае в конце курса владелец приезжает и встраивается в процесс обучения под руководством инструктора. В таком случае многих неприятностей можно избежать, но нужно заранее предусмотреть на это время (и обговорить саму такую возможность).`,
                },
            }
        }
    }

    render() {
        const {activeFlex, pointActive, points, flexHidden} = this.state

        return (
            <div className="step3">
                <TextBlock
                    text={`При выборе подхода к обучению собак, как правило, выбирают между тремя распространёнными вариантами.

                    <b>Попробуем разобраться, что больше подходит, какие плюсы и минусы.</b>`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />
                <InfoBlock
                    text={`Ознакомьтесь с распространёнными мифами, нажав на активные элементы.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />
                <div className={'flex ' + (flexHidden === true && 'hidden')}>
                    <div className={'flex_el ' + (activeFlex === 1 && 'flex_el_active')} onClick={() => {
                        this.changeFlex(1)
                    }}>
                        Индивидуальные занятия
                        с кинологом
                    </div>
                    <div className={'flex_el ' + (activeFlex === 2 && 'flex_el_active')} onClick={() => {
                        this.changeFlex(2)
                    }}>Обучение
                        на учебно-дрессировочной площадке</div>
                    <div className={'flex_el ' + (activeFlex === 3 && 'flex_el_active')} onClick={() => {
                        this.changeFlex(3)
                    }}>
                        Передача собаки на временное содержание с обучением
                    </div>
                </div>
                {activeFlex !== 0 &&
                <div>
                    <div className={'flex_click active_' + activeFlex}>
                        <ArrowBack
                            returnMenu={this.closeFlexClick}
                        />
                        <picture>
                            <source srcSet={this.click_flex[activeFlex].imgXs} media="(max-width: 450px)"/>
                            <img src={this.click_flex[activeFlex].img} alt=""/>
                        </picture>
                        <div className="points">
                            {Object.keys(this.points[activeFlex].wrp).map((point) => {
                                return (
                                    <div
                                        key={activeFlex + point}
                                        className={"point " + (points.indexOf(activeFlex.toString() + point.toString()) >= 0 && 'active')}
                                        onClick={() => {
                                            this.changePoint(point)
                                        }}>
                                        {point}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {pointActive !== 0 &&
                    <div className="pointPopap">
                        <div className="popapWrp">
                            <img src={this.points[activeFlex].icon} alt="" className="icon"/>
                            <div className="close" onClick={() => (this.changePoint(0))}/>
                            <div className="scroll">
                                <div className="head">{this.points[activeFlex].wrp[pointActive].head}</div>
                                <TextBlock
                                    text={this.points[activeFlex].wrp[pointActive].text}
                                />
                                {this.points[activeFlex].wrp[pointActive].plus1 && this.points[activeFlex].wrp[pointActive].minus1 &&
                                <div className="flex">
                                    <div className="flex_el plus">{this.points[activeFlex].wrp[pointActive].plus1}</div>
                                    <div
                                        className="flex_el minus">{this.points[activeFlex].wrp[pointActive].minus1}</div>
                                </div>
                                }
                                {this.points[activeFlex].wrp[pointActive].plus2 && this.points[activeFlex].wrp[pointActive].minus2 &&
                                <div className="flex">
                                    <div className="flex_el plus">{this.points[activeFlex].wrp[pointActive].plus2}</div>
                                    <div
                                        className="flex_el minus">{this.points[activeFlex].wrp[pointActive].minus2}</div>
                                </div>
                                }
                                {this.points[activeFlex].wrp[pointActive].plus3 && this.points[activeFlex].wrp[pointActive].minus3 &&
                                <div className="flex">
                                    <div className="flex_el plus">{this.points[activeFlex].wrp[pointActive].plus3}</div>
                                    <div
                                        className="flex_el minus">{this.points[activeFlex].wrp[pointActive].minus3}</div>
                                </div>
                                }
                            </div>
                            <div
                                className="btns"
                                onClick={()=>{
                                    if(pointActive < Object.keys(this.points[activeFlex].wrp).length){
                                        this.changePoint(parseInt(pointActive) + 1)
                                    }else{
                                        this.changePoint(0)
                                    }
                                }}
                            >
                                <button className="btn btn-blue">
                                    продолжить
                                </button>
                            </div>
                        </div>
                    </div>
                    }
                </div>
                }
            </div>
        )
    }
}
