import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson9/step3-1.png'
import img2 from '../../../image/lessons/lesson9/step3-2.png'
import img3 from '../../../image/lessons/lesson9/step3-3.png'
import img3_1popap from '../../../image/lessons/lesson9/step3-1popap.png';
import img3_2popap from '../../../image/lessons/lesson9/step3-2popap.png';
import img3_3popap from '../../../image/lessons/lesson9/step3-3popap.png';
import img3_4popap from '../../../image/lessons/lesson9/step3-4popap.png';
import img3_5popap from '../../../image/lessons/lesson9/step3-5popap.png';


export default class step3 extends React.Component {
    state = {
        pointActive: 0,
        points: [],
        pointsPassed: [0],
    };

    changePoint = pointActive => {
        console.log(pointActive)
        const {pointsPassed} = this.state
        pointsPassed.push(pointActive)
        this.setState({pointActive}, () => {
            this.checkPoint(pointActive)
        })
    }

    checkPoint = (point) => {
        const {points} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        if (points.length === 5) {
            this.props.showNextBtn()
        }
    }

    popapWrp = {
        0: {img: '', head: '', text: ''},
        1: {
            img: img3_1popap,
            head: `Содержание собаки`,
            text: `При содержании домашних животных их владельцам необходимо соблюдать общие требования к содержанию животных, а также права и законные интересы лиц, проживающих в многоквартирном доме, в помещениях которого содержатся домашние животные.`
        },
        2: {
            img: img3_2popap,
            head: `Запрет предпринимательской деятельности`,
            text: `Не допускается использование домашних животных в предпринимательской деятельности, за исключением случаев, установленных Правительством Российской Федерации (селекция домашних собак не попадает под действие этой статьи).`
        },
        3: {
            img: img3_3popap,
            head: `Предельное количество питомцев`,
            text: `Предельное количество домашних животных в местах содержания животных определяется исходя из возможности владельца обеспечивать животным условия, соответствующие ветеринарным нормам и правилам, а также с учетом соблюдения санитарно-эпидемиологических правил и нормативов.`
        },
        4: {
            img: img3_4popap,
            head: `Запрещено`,
            text: `Собак нельзя содержать в помещениях общего пользования (лестничных клетках, приквартирных тамбурах), а при содержании в коммунальной квартире - заручиться согласием соседей.`
        },
        5: {
            img: img3_5popap,
            head: `Тишина и Шум`,
            text: `Так же собаководов касается ФЗ 52 (закон о тишине и шуме), на основании которого владелец собаки может быть привлечен к ответственности за лай или вой ночью, точное время уточняйте в своём регионе проживания, так как в разных регионах эти периоды разные.`
        }
    }

    points = [1,2,3,4,5]

    render() {
        const {pointActive, pointsPassed} = this.state;
        return (
            <div className="step3">
                <InfoBlock
                    text={`Ознакомьтесь с требованиями к содержанию собаки, нажав на линию внизу.`}
                />

                <div className="imgDiv">
                    <img className="mainImg" src={img1} alt=""/>
                </div>
                <div className='scaleBlock'>
                    {this.points &&
                        <div className="pointsList">
                            {this.points.map(item => {
                                return (
                                    <div
                                        key={item}
                                        className={'scalePoint ' + (pointsPassed.indexOf(item) >= 0 && 'active')/*(pointActive === item && 'active ')*/}
                                    >
                                        <span className="pointListNumber">{item}</span>
                                    </div>
                                )})}
                            <img className={`scaleArrow scaleArrow--${pointActive}`} src={img3} alt=""/>
                        </div>
                    }
                    <img
                        className="scaleImg"
                        src={img2}
                        alt=""
                        onClick={() => {this.changePoint(1)}}
                    />
                </div>

                {pointActive !== 0 &&
                    <div className={'infoPopap'}>
                        <div className="infoPopap_wrp">
                            <div className="popap_close" onClick={() => {
                                this.changePoint(0)
                            }}/>
                            <div className="image">
                                <div className="imageBg">
                                    <img src={this.popapWrp[pointActive].img} alt=""/>
                                </div>
                            </div>
                            <div className="head"
                                 dangerouslySetInnerHTML={{__html: this.popapWrp[pointActive].head}}/>
                            <TextBlock
                                text={this.popapWrp[pointActive].text}
                                fontSize={16}
                            />
                            <button className="btn btn_blue" onClick={() => {
                                this.changePoint(pointActive < 5 ? pointActive + 1 : 0)
                            }}>Продолжить</button>
                        </div>
                    </div>
                }


            </div>



        )
    }
}
