import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";

export default class step4 extends React.Component {
    state = {
    };


    render() {
        return (
            <div className="step4">
                <TextBlock
                    text={`1 этап: попробуйте присесть, опуститься на уровень собаки`}
                />
                <InfoBlock text={`Ознакомьтесь с содержанием видео ролика.`}/>
                <VideoBlock
                    src={'https://www.youtube.com/embed/Io_hi4RRk0Q'}
                    title={'https://www.youtube.com/watch?v=Io_hi4RRk0Q'}
                />
            </div>
        )
    }
}
