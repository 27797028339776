import React from 'react'
import VideoBlock from '../../../Components/LessonAndTestComponents/VideoBlock'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

export default class step5 extends React.Component {

    render() {
        return (
            <div className="step5">
                <TextBlock
                    text={`Посмотрите увлекательный информационный видеоролик про научный центр WALTHAM`}
                />
                <div className="box">
                    <VideoBlock
                        src={'https://www.youtube.com/embed/Vi78y1f3oKc'}
                        title={'https://www.youtube.com/watch?time_continue=3&v=Vi78y1f3oKc&feature=emb_title'}
                    />
                </div>
            </div>
        )
    }
}
