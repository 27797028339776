import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson33/step5-1.png'


export default class step5 extends React.Component {
    state = {
    };


    render() {
        return (
            <div className="step5">
                <TextBlock
                    text={`Сравните <span class="text_dark-blue">влажные корма</span> для взрослых собак с кормами для щенков, чтобы понять, почему корма для щенков действительно лучше удовлетворяет потребности беременных собак.`}
                />
                <img src={img1} alt=""/>
                <TextBlock
                    text={`Таким образом, мы видим, что <span class="text_dark-blue">влажные корма для щенков обладают большей энергетической ценностью и количеством белка в составе, чем влажные корма для взрослых собак,</span> что положительно влияет на здоровье беременных собак.`}
                />
            </div>
        )
    }
}
