import React from 'react'

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step1">
                <div className="head">план урока</div>

                <div className="text">
                    В этом уроке мы рассмотрим видеокурс Азбуки трюков: зайка.
                </div>

                <div className="lessonSteps">
                    <div className="lessonSteps_el">
                        1 Этап
                    </div>
                    <div className="lessonSteps_el">
                        2 Этап
                    </div>
                    <div className="lessonSteps_el">
                        3 Этап
                    </div>
                    <div className="lessonSteps_el">
                        Выполнение команды «Зайка» только по голосу или только по жесту
                    </div>
                    <div className="lessonSteps_el">
                        БОНУС. Упражнение «Зайка-лежать»
                    </div>
                </div>

            </div>
        )
    }
}
