import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import img1 from "../../../image/lessons/lesson6/step2-1.png";


export default class step2 extends React.Component {
    state = {
    };

    render() {
        return (
            <div className="step2">
                <TextBlock
                    fonSize={16}
                    text={`Здоровье собаки зависит от многих факторов:
<ul>
                                <li>врожденных особенностей собаки;</li>
                                <li>кормления и условий содержания;</li>
                                <li>вашего ответственного отношения к профилактике и выбору ветеринарного врача</li>
                            <ul>`}
                />
                <div className="blockImg">
                    <img src={img1} alt="" className='mainImg'/>
                </div>
            </div>
        )
    }
}