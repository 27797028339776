import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import img1 from '../../../image/lessons/lesson4/step3-1.png';
import img2 from '../../../image/lessons/lesson4/step3-2.png';
import img3 from '../../../image/lessons/lesson4/step3-3.png';
import img4 from '../../../image/lessons/lesson4/step3-4.png';


export default class step3 extends React.Component {
    state = {
        flexActive: 0,
        flexs: [1],
        flexHidden: false,
    };

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs, flexHidden: true})
        if (flexs.length === 4) {
            this.props.showNextBtn()
        }
    }

    closeImgDiv = () => {this.setState({flexHidden: false, flexActive: 0})}


    render() {
        const {flexActive, flexHidden} = this.state
        return (
            <div className="step3">
                <TextBlock
                    text={`И, тем не менее, несмотря на все сложности, человек заводит собаку. Давайте проверим, можем ли мы ответить на вопрос, «зачем мы хотим собаку»?`}
                    hidden={flexHidden === true ? 'hidden' : ''}
                />
                <InfoBlock
                    text={`Ознакомьтесь с наиболее распространёнными мотивами, нажав на активные элементы.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />

                <div className={'flex ' + (flexHidden === true && 'hidden')}>
                    <div className={'flex_el ' + (flexActive === 1 && 'active')} onClick={() => {
                        this.changeFlex(1)
                    }}>Выставки
                    </div>
                    <div className={'flex_el ' + (flexActive === 2 && 'active')} onClick={() => {
                        this.changeFlex(2)
                    }}>Мода
                    </div>
                    <div className={'flex_el ' + (flexActive === 3 && 'active')} onClick={() => {
                        this.changeFlex(3)
                    }}>Друг
                    </div>
                    <div className={'flex_el ' + (flexActive === 4 && 'active')} onClick={() => {
                        this.changeFlex(4)
                    }}>Ещё одна собака
                    </div>
                </div>
                {flexActive !== 0 &&
                <div className={'imgDiv ' + (flexActive === 2 ? 'imgDiv--2' : flexActive === 3 ? 'imgDiv--3': flexActive === 4 ? 'imgDiv--4' : '')}>
                    <div
                        className="imgDiv_closed"
                        onClick={() => {this.closeImgDiv()}}
                    />
                    {flexActive === 1 &&
                    <div className="imgDiv_wrap">
                        <div className="imgPhone">
                            <img className="imgPhoneBg" src={img1} alt=""/>
                        </div>
                        <div className="imgDiv_flex">
                            <TextBlock
                                text={`Для выставок вам подойдёт породистая собака, с хорошей родословной и экстерьером. Для выбора перспективного щенка обратитесь в профессиональные питомники, которые специализируются на собаках и щенках шоу-класса. Помните: выставочного щенка недостаточно просто приобрести, потребуется много ресурсов на грамотное выращивание и воспитание будущей звезды рингов.`}
                            />
                            <img src={img1} alt=""/>
                        </div>
                        <TextBlock
                            text={`<span class="text_dark-blue"><b>Вам потребуется посетить специализированные мероприятия (выставки или испытания и состязания) для того, чтобы найти щенка, соответствующего вашим ожиданиям.</b></span>`}
                        />
                    </div>
                    }
                    {flexActive === 2 &&
                    <div className="imgDiv_wrap">
                        <div className="imgPhone">
                            <img className="imgPhoneBg" src={img2} alt=""/>
                        </div>
                        <div className="imgDiv_flex">
                            <TextBlock
                                text={`Не самая лучшая мотивация. Мода на различные породы приходит и уходит, а собака — это живое существо, которое проживёт в вашей семье около 15 лет, и за чью жизнь и благополучие вы несёте полную ответственность. Внимательно прочитайте соответствующие разделы нашего урока, чтобы еще раз взвесить все «за» и «против».`}
                            />
                            <img src={img2} alt=""/>
                        </div>
                    </div>
                    }
                    {flexActive === 3 &&
                    <div className="imgDiv_wrap">
                        <div className="imgPhone">
                            <img className="imgPhoneBg" src={img3} alt=""/>
                        </div>
                        <div className="imgDiv_flex">
                            <TextBlock
                                text={`В вашем случае породная принадлежность будущего питомца не имеет никакого значения. Размер, шерстный покров — исключительно вопрос вкуса.
                                
                                        Но нужно помнить, что собака — это <span class="text_dark-blue"><b>ответственность</b></span> и определенные расходы, вам следует подойти к выбору обдуманно и взвешенно.`}
                            />
                            <img src={img3} alt=""/>
                        </div>
                    </div>
                    }
                    {flexActive === 4 &&
                    <div className="imgDiv_wrap">
                        <div className="imgPhone">
                            <img className="imgPhoneBg" src={img4} alt=""/>
                        </div>
                        <div className="imgDiv_flex">
                            <TextBlock
                                text={`С одной стороны, вы уже что-то для себя решили. С другой, нужно иметь ввиду, что породы собак, как и люди – <span class="text_dark-blue"><b>не остаются неизменны</b></span> во времени.
                                 
                                        Еще раз подумайте над своим выбором. Учтите, что меняются не только собаки, но общество, в котором мы живем: законы и правила содержания, экономические условия. Собака одной и той же породы за 10 – 15 лет <span class="text_dark-blue"><b>может измениться очень сильно</b></span> – как внешне, так и по поведенческим особенностям.`}
                            />
                            <img src={img4} alt=""/>
                        </div>
                    </div>
                    }
                </div>
                }
            </div>
        )
    }
}
