import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'

import step2_1 from '../../../image/lessons/lesson3/step2-1.png'
import step2_pi1 from '../../../image/lessons/lesson3/step2-pi1.png'
import step2_pi2 from '../../../image/lessons/lesson3/step2-pi2.png'
import step2_pi3 from '../../../image/lessons/lesson3/step2-pi3.png'
import step2_pi4 from '../../../image/lessons/lesson3/step2-pi4.png'
import step2_p1 from '../../../image/lessons/lesson3/step2-p1.png'
import step2_p2 from '../../../image/lessons/lesson3/step2-p2.png'
import step2_p3 from '../../../image/lessons/lesson3/step2-p3.png'
import step2_p4 from '../../../image/lessons/lesson3/step2-p4.png'


export default class step2 extends React.Component {
    state = {
        linkActive: 0,
        links: [],
    };

    onClickLink = linkActive => {
        const {links} = this.state;
        if (links.indexOf(linkActive) === -1) {
            links.push(linkActive)
        }
        this.setState({linkActive, links});
        console.log(links.length)
        if(links.length === 5){
            this.props.showNextBtn()
        }
    }


    popaps = {
        1: {
            icon: step2_pi1,
            img: step2_p1,
            head: 'Генетика и селекция',
            text: 'Знание основ генетики и грамотная селекция позволяют снизить риск развития наследственных заболеваний, таких как волчья пасть, прогрессивная атрофия сетчатки глаза, наследственный поликистоз почек, первичная гипертрофическая кардиомиопатия и т.д.'
        },
        2: {
            icon: step2_pi2,
            img: step2_p2,
            head: 'Медицинская профилактика',
            text: 'Регулярная противопаразитарная обработка и вакцинация позволяют избежать развития паразитарных инфекционных заболеваний. Обработка животных от внешних и внутренних паразитов должна проводиться на регулярной основе, независимо от того, наблюдаются ли симптомы инвазии. Кратность обработки и выбор препаратов подскажет ветеринарный врач. Противопаразитарная обработка необходима перед плановой вакцинацией животных.'
        },
        3: {
            icon: step2_pi3,
            img: step2_p3,
            head: 'Гигиена в питомнике',
            text: 'Соблюдение санитарно-гигиенических правил позволяет предотвратить появление или распространение болезнетворных агентов в питомнике. Гигиена питомника не ограничивается поддержанием чистоты и включает в себя множество факторов, которые повышают качество жизни животных в питомнике.'
        },
        4: {
            icon: step2_pi4,
            img: step2_p4,
            head: 'Питание',
            text: 'Сбалансированный и адаптированный к потребностям животных корм играет важнейшую роль в сохранении их здоровья. Высоко усвояемый корм снижает количество фекалий, а значит, и риск развития заболеваний, передаваемых фекально-оральным путем. Корм необходимо правильно хранить и осуществлять его раздачу в надлежащих условиях.'
        }
    }



    render() {
        const {links, linkActive} = this.state;
        return (
            <div className="step2">
                <TextBlock
                    fontSize={16}
                    text={
                        `Общие гигиенические и санитарные нормы применимы к любому питомнику, вне зависимости от количества находящихся в нем животных и их видовой принадлежности!

                        Здоровье животных в питомнике - это совокупность множества факторов, которые рассматриваются ниже: гигиена, питание, медицинская профилактика и генетика и селекция.`
                    }
                />
                <InfoBlock
                    text={
                        `Ознакомьтесь с основами гигиены здоровья животных, нажав на ключевые элементы`
                    }
                />
                <div className="blockImg">
                    <img src={step2_1} alt="" className="mainImg"/>
                    <div className={"link link1 " + (links.indexOf(1) >= 0 && 'active')} onClick={()=>this.onClickLink(1)}/>
                    <div className={"link link2 " + (links.indexOf(2) >= 0 && 'active')} onClick={()=>this.onClickLink(2)}/>
                    <div className={"link link3 " + (links.indexOf(3) >= 0 && 'active')} onClick={()=>this.onClickLink(3)}/>
                    <div className={"link link4 " + (links.indexOf(4) >= 0 && 'active')} onClick={()=>this.onClickLink(4)}/>
                </div>

                {linkActive !== 0 &&
                    <div className="infoPopap">
                    <div className="infoPopap_wrp">
                        <div className="infoPopap_close" onClick={() => this.onClickLink(0)}/>
                        <div className="infoPopap_iconDiv">
                            <img src={this.popaps[linkActive].icon} alt="" className="infoPopap_icon"/>
                        </div>
                        <div className="infoPopap_head">{this.popaps[linkActive].head}</div>
                        <TextBlock
                            fontSize={16}
                            text={this.popaps[linkActive].text}
                        />
                        <div className="infoPopap_imgDiv">
                            <img src={this.popaps[linkActive].img} alt="" className="infoPopap_img"/>
                        </div>
                        <button className="btn btn_blue" onClick={() => this.onClickLink(0)}>Продолжить</button>
                    </div>
                </div>
                }

            </div>
        )
    }
}