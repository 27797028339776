import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step1">
                <TextBlock
                    text={`Готовые корма способны не только обеспечивать животных всеми питательными веществами (белками, жирами, углеводами, витаминами и минералами), но и благодаря функциональным компонентам значительно улучшать их здоровье. В этом уроке вы узнаете подробнее о функциональных составляющих рациона.`}
                />
                <div className="head">план урока</div>

                <div className="lessonSteps">
                    <div className="lessonSteps_el">Основные функциональные потребности животных</div>
                    <div className="lessonSteps_el">Здоровое пищеварение</div>
                    <div className="lessonSteps_el">Здоровье кожи и шерсти</div>
                    <div className="lessonSteps_el">Хороший иммунитет</div>
                    <div className="lessonSteps_el">Здоровые зубы</div>
                    <div className="lessonSteps_el">Другие потребности собак</div>
                    <div className="lessonSteps_el">Итоги урока</div>
                    <div className="lessonSteps_el">Тест</div>
                </div>

            </div>
        )
    }
}
