import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson5/step11-1.png'
import img2 from '../../../image/lessons/lesson5/step11-2.png'
// import imgi1 from '../../../image/lessons/lesson5/step11-i1.png'
import imgi2 from '../../../image/lessons/lesson5/step11-i2.png'
import imgi3 from '../../../image/lessons/lesson5/step11-i3.png'
import imgi4 from '../../../image/lessons/lesson5/step11-i4.png'
import imgi5 from '../../../image/lessons/lesson5/step11-i5.png'
import imgi6 from '../../../image/lessons/lesson5/step11-i6.png'


export default class step6 extends React.Component {
    state = {
        flexActive: 1,
        flex: [1],
        pointActive: 0,
        points: [],
    };

    changeFlex = flexActive => {
        const {flex, points} = this.state
        this.setState({flexActive}, () => {})
        if (flex.indexOf(flexActive) === -1) {
            flex.push(flexActive)
        }
        this.setState({flex})
        if (points.length === 6 && flex.length === 2) {
            this.props.showNextBtn()
        }
    }



    changePoint = pointActive => {
        this.setState({pointActive}, () => {
            this.checkPoint(pointActive)
        })
    }

    checkPoint = (point) => {
        const {flex, points} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        if (points.length === 6 && flex.length === 2) {
            this.props.showNextBtn()
        }
    }

    popapWrp = {
        1: {
            icon: imgi2,
            head: 'Не только мясо',
            text: `При кормлении домашней пищей помните – это должно быть не столько «кашей с мясом», сколько «мясом с кашей», то есть мясная составляющая в рационе должна быть никак не меньше половины по объему.`
        },
        2: {
            icon: imgi3,
            head: 'Мясо',
            text: `Не стремитесь, даже если вам позволяют финансы, покупать собаке только отборное мясо и вырезку. В этом нет необходимости.`
        },
        3: {
            icon: imgi4,
            head: 'Субпродукты ',
            text: `Субпродукты – важная и полезная составляющая рациона собак, к кому же любимая ими. Субпродукты можно и нужно давать собаке.`
        },
        4: {
            icon: imgi5,
            head: 'Кости ',
            text: `Мнение, что собак надо кормить костями ошибочно, не смотря на то что все собаки их любят. Не все кости можно давать собакам. Запрещены трубчатые, плоские и любые вареные кости. Они могут стать причиной травмы желудочно-кишечного тракта, а большое количество костей в рационе может привести к закупорке кишечника и запорам.`
        },
        5: {
            icon: imgi6,
            head: 'Углеводы ',
            text: `Не все крупы усваиваются у собак. Лучше проконсультироваться с ветеринарным врачом какие крупы лучше давать Вашему питомцу. Овощи, фрукты, ягоды, зелень – добавляются «по вкусу» и без фанатизма. Мучное и сладкое собакам не рекомендуется.`
        },
    }


    render() {
        const {pointActive, flexActive, points} = this.state;
        return (
            <div className="step11">
                <InfoBlock
                    text={`Ознакомьтесь с наиболее распространёнными сомнениями, нажав на активные элементы.`}
                />
                <div className="flex">
                    <div className={'flex_el ' + (flexActive === 1 ? 'active' : '')}
                          onClick={() => {this.changeFlex(1)}}>
                        Домашняя еда
                    </div>
                    <div className={'flex_el ' + (flexActive === 2 ? 'active' : '')}
                          onClick={() => {this.changeFlex(2)}}>
                        Готовые промышленные корма
                    </div>
                </div>

                {flexActive !== 0 &&
                    <div className={'info ' + (flexActive === 2 && "left")}>
                        {flexActive === 1 ?
                            <div className="popapLink">
                                <div className={'link ' + (points.indexOf(1) >= 0 && 'active')} onClick={() => {this.changePoint(1)}}/>
                                <div className={'link ' + (points.indexOf(2) >= 0 && 'active')} onClick={() => {this.changePoint(2)}}/>
                                <div className={'link ' + (points.indexOf(3) >= 0 && 'active')} onClick={() => {this.changePoint(3)}}/>
                                <div className={'link ' + (points.indexOf(4) >= 0 && 'active')} onClick={() => {this.changePoint(4)}}/>
                                <div className={'link ' + (points.indexOf(5) >= 0 && 'active')} onClick={() => {this.changePoint(5)}}/>
                                <img src={img1} alt=""/>
                            </div>
                            :
                            <div>
                                <TextBlock
                                    text={`Готовый корм – это сбалансированный рацион, который содержит все необходимые организму питательные вещества в правильных пропорциях и удовлетворяет ежедневные энергетические потребности животного на определенной жизненной стадии.`}
                                />
                                <div className="flex">
                                    <div className="left">
                                        <TextBlock
                                            text={`<span class="textBlue"><b>Критерии сбалансированного питания:</b></span>`}
                                        />
                                        <ul>
                                            <li>Количество каждого питательного вещества должно соответствовать
                                                оптимальной норме.
                                            </li>
                                            <li>Каждое питательное вещество добавляется в правильном соотношении с
                                                пищевой ценностью корма и с другими веществами.
                                            </li>
                                            <li>Питательные вещества должны хорошо усваиваться.</li>
                                        </ul>
                                    </div>
                                    <img src={img2} alt=""/>
                                </div>
                                <div className="infoBlock">
                                    Собакам необходимо около 40 питательных веществ, каждое из которых должно содержаться в правильном количестве и правильной форме.
                                </div>
                                <TextBlock
                                    text={`<span class="textBlue"><b>Помните о важности соблюдения условий хранения готового корма!</b></span>`}
                                />
                                <ul>
                                    <li>
                                        на каждой упаковке готового корма указаны рекомендации по хранению
                                    </li>
                                    <li>
                                        замораживание или хранение готового корма при высоких температурах могут стать причиной потери питательной ценности, снижения качества и привести к негодности продукта
                                    </li>
                                    <li>
                                        сухой корм рекомендуется хранить в сухом прохладном месте
                                    </li>
                                    <li>
                                        открытую упаковку сухого корма можно хранить не более 1 мес, обязательно закрывая продукт
                                    </li>
                                    <li>
                                        открытую упаковку влажного корма можно хранить в холодильнике не более 1 суток
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                }

                {pointActive !== 0 &&
                <div className={'infoPopap'}>
                    <div className="infoPopap_wrp">
                        <div className="popap_close" onClick={() => {
                            this.changePoint(0)
                        }}/>
                        <div className="image">
                            <div className="imageBg">
                                <img src={this.popapWrp[pointActive].icon} alt=""/>
                            </div>
                        </div>
                        <div className="head"
                             dangerouslySetInnerHTML={{__html: this.popapWrp[pointActive].head}}/>
                        <TextBlock
                            text={this.popapWrp[pointActive].text}
                            fontSize={16}
                        />
                        <button className="btn btn_blue" onClick={() => {
                            this.changePoint(pointActive < 5 ? pointActive + 1 : 0)
                        }}>Далее</button>
                    </div>
                </div>
                }


            </div>



        )
    }
}
