import React from 'react'
import Test from "../../Test";

export default class step2 extends React.Component {
    render() {
        return (
            <div className="step10">
                <Test lesson={this.props.lesson} stepId={this.props.stepId} showNextStep={this.props.showNextStep} showPrevStep={this.props.showPrevStep}/>
            </div>
        )
    }
}
