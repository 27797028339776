import React from 'react'

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step1">
                <div className="head">план урока</div>

                <div className="text">
                    В этом уроке мы рассмотрим видео-курс из цикла “Основы дрессировки” и узнаем как правильно научить команде “Ко мне”.
                </div>

                <div className="lessonSteps">
                    <div className="lessonSteps_el">
                        Введение
                    </div>
                    <div className="lessonSteps_el">
                        1 Этап
                    </div>
                    <div className="lessonSteps_el">
                        2 Этап
                    </div>
                    <div className="lessonSteps_el">
                        Разбираем ошибки
                    </div>
                    <div className="lessonSteps_el">
                        Выполнение команды по свистку
                    </div>
                    <div className="lessonSteps_el">
                        Итоги урока. Основные советы
                    </div>
                    <div className="lessonSteps_el">
                        Тест
                    </div>
                </div>

            </div>
        )
    }
}
