import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import img1 from '../../../image/lessons/lesson3/step5-1.png';
import img2 from '../../../image/lessons/lesson3/step5-2.png';
import img3 from '../../../image/lessons/lesson3/step5-3.png';
import step5_p1 from "../../../image/lessons/lesson3/step5-p1.png";
import step5_p21 from "../../../image/lessons/lesson3/step5-p21.png";
import step5_p22 from "../../../image/lessons/lesson3/step5-p22.png";
import step5_p23 from "../../../image/lessons/lesson3/step5-p23.png";
import step5_p24 from "../../../image/lessons/lesson3/step5-p24.png";
import step5_p25 from "../../../image/lessons/lesson3/step5-p25.png";
import step5_p31 from "../../../image/lessons/lesson3/step5-p31.png";
import step5_p32 from "../../../image/lessons/lesson3/step5-p32.png";
import step5_p33 from "../../../image/lessons/lesson3/step5-p33.png";
import step5_p34 from "../../../image/lessons/lesson3/step5-p34.png";
import step5_p35 from "../../../image/lessons/lesson3/step5-p35.png";
import step5_p36 from "../../../image/lessons/lesson3/step5-p36.png";
import step5_p4 from "../../../image/lessons/lesson3/step5-p4.png";


export default class step5 extends React.Component {

    state = {
        linkActive: 0,
        links: [],
    };

    onClickLink = linkActive => {
        const {links} = this.state;
        if (links.indexOf(linkActive) === -1) {
            links.push(linkActive)
        }
        this.setState({linkActive, links});
        if (links.length === 5) {
            this.props.showNextBtn()
        }
    }


    render() {
        const {links, linkActive} = this.state;

        return (
            <div className="step5">
                <InfoBlock
                    text={`Ознакомьтесь с основными понятиями гигиены, нажав на ключевые элементы.`}
                />
                <div className="flex">
                    <div className={'flex_el ' + (links.indexOf(1) >= 0  && 'active')} onClick={()=>{this.onClickLink(1)}}>
                        <div className="img">
                            <img src={img1} alt=""/>
                        </div>
                        <div className="text">
                            «Своя» и <br/>
                            «Чужая» ГРЯЗЬ
                        </div>
                    </div>
                    <div className={'flex_el ' + (links.indexOf(2) >= 0 && 'active')} onClick={()=>{this.onClickLink(2)}}>
                        <div className="img">
                            <img src={img2} alt=""/>
                        </div>
                        <div className="text">
                            ПОТЕНЦИАЛЬНЫЕ <br/>
                            И ОСНОВНЫЕ <br/>
                            ПАТОГЕНЫ
                        </div>
                    </div>
                    <div className={'flex_el ' + (links.indexOf(4) >= 0 && 'active')} onClick={()=>{this.onClickLink(4)}}>
                        <div className="img">
                            <img src={img3} alt=""/>
                        </div>
                        <div className="text">
                            ПЕРЕКРЕСТНОЕ <br/>
                            ЗАРАЖЕНИЕ
                        </div>
                    </div>
                </div>

                {linkActive === 1 &&
                <div className="infoPopap">
                    <div className="infoPopap_wrp">
                        <div className="infoPopap_close" onClick={() => this.onClickLink(0)}/>
                        <div className="infoPopap_scroll">
                            <div className="infoPopap_iconDiv">
                                <img src={img1} alt="" className="infoPopap_icon"/>
                            </div>
                            <div className="infoPopap_head">«Своя» и «чужая» грязь</div>
                            <div className="infoPopap_text">
                                <span className="textBlue"><b>«Своя грязь»</b></span>. Микроорганизмы (бактерии, вирусы,
                                грибы, простейшие) присутствуют в любом питомнике. Эта флора формирует базовый уровень
                                загрязнения микроорганизмами. Животных питомника защищает от нее естественный иммунитет.
                                Гигиеническая обработка помещений поддерживает количество микроорганизмов на уровне, не
                                способствующем заражению.
                                <br/>
                                <br/>
                                <span className="textBlue"><b>«Чужая грязь»</b></span>. Это чуждые для среды питомника
                                микроорганизмы,
                                от которых животных не защищает иммунная система. Следует предотвращать их попадание в
                                помещения питомника и создавать
                                в питомнике среду, препятствующую чрезмерному развитию патогенов. В создании такой среды
                                заводчику помогают грамотное устройство питомника, общая гигиена и медицинская
                                профилактика.
                            </div>
                            <div className="infoPopap_imgDiv">
                                <img src={step5_p1} alt="" className="infoPopap_img"/>
                            </div>
                            <InfoBlock
                                danger={true}
                                text={`Чтобы избежать заболеваний, число микроорганизмов в питомнике («своя грязь») должно удерживаться ниже определенного порога. Ослабление мер гигиены приведет  к превышение этого порога и возникновению болезней. Необходимо максимально предотвращать попадание в питомник чужеродных микроорганизмов («чужая грязь»).`}
                            />
                        </div>
                        <button className="btn btn_blue" onClick={() => this.onClickLink(2)}>Продолжить</button>

                    </div>
                </div>
                }
                {linkActive === 2 &&
                <div className="infoPopap">
                    <div className="infoPopap_wrp">
                        <div className="infoPopap_close" onClick={() => this.onClickLink(0)}/>
                        <div className="infoPopap_scroll">
                            <div className="infoPopap_iconDiv">
                                <img src={img2} alt="" className="infoPopap_icon"/>
                            </div>
                            <div className="infoPopap_head">Потенциальные патогены</div>
                            <div className="infoPopap_flex">
                                <div className="infoPopap_flexImgDiv chain">
                                    <img src={step5_p21} alt="" className="infoPopap_flexImg"/>
                                </div>
                                <div className="infoPopap_text">
                                    <span className="textBlue"><b>1.	Обратимое закрепление</b></span> бактерий
                                    на поверхности
                                </div>
                            </div>
                            <div className="infoPopap_flex">
                                <div className="infoPopap_flexImgDiv chain">
                                    <img src={step5_p22} alt="" className="infoPopap_flexImg"/>
                                </div>
                                <div className="infoPopap_text">
                                    <span className="textBlue"><b>2.	Окончательное закрепление</b></span> бактерий –
                                    при помощи внеклеточного матрикса. Новые бактерии закрепляются на уже
                                    присутствующих, увеличивая число бактерий и разнообразие их видов.
                                </div>
                            </div>
                            <div className="infoPopap_flex">
                                <div className="infoPopap_flexImgDiv chain">
                                    <img src={step5_p23} alt="" className="infoPopap_flexImg"/>
                                </div>
                                <div className="infoPopap_text">
                                    <span className="textBlue"><b>3.	Размножение и распространение</b></span>
                                </div>
                            </div>
                            <div className="infoPopap_flex">
                                <div className="infoPopap_flexImgDiv chain">
                                    <img src={step5_p24} alt="" className="infoPopap_flexImg"/>
                                </div>
                                <div className="infoPopap_text">
                                    <span className="textBlue"><b>4.	Утолщение слоя</b></span> – колонии объединяются
                                    и образуют мега колонии, которые проходят каналы, через которые поступают вода и
                                    питательные вещества. Биопленка становится видимой невооруженным глазом и
                                    представляет собой блестящую и клейкую пленку, образованную миллионами колоний
                                    микробов.
                                </div>
                            </div>
                            <div className="infoPopap_flex">
                                <div className="infoPopap_flexImgDiv chain">
                                    <img src={step5_p25} alt="" className="infoPopap_flexImg"/>
                                </div>
                                <div className="infoPopap_text">
                                    <span className="textBlue"><b>5.	Рассеивание</b></span>. Если не выполнять
                                    уборку, то биопленка развивается и утолщается, в ней находят пристанище другие
                                    микроорганизмы: вирусы, паразиты и грибки. Фрагменты биопленки, содержащие множество
                                    бактерий, могут отделятся и, переместившись, колонизировать новые поверхности,
                                    расположенные на расстоянии.
                                </div>
                            </div>
                        </div>
                        <button className="btn btn_blue" onClick={() => this.onClickLink(3)}>Продолжить</button>
                    </div>
                </div>
                }
                {linkActive === 3 &&
                <div className="infoPopap">
                    <div className="infoPopap_wrp">
                        <div className="infoPopap_close" onClick={() => this.onClickLink(0)}/>
                        <div className="infoPopap_scroll">
                            <div className="infoPopap_iconDiv">
                                <img src={img2} alt="" className="infoPopap_icon"/>
                            </div>
                            <div className="infoPopap_head">Основные патогены</div>
                            <div className="infoPopap_flex">
                                <div className="infoPopap_flexImgDiv">
                                    <img src={step5_p31} alt="" className="infoPopap_flexImg"/>
                                </div>
                                <div className="infoPopap_text">
                                    <div className="infoPopap_text_head">Бактерии</div>
                                    Leptospira spp. (лептоспироз), Anaplasma phagocytophilum (анаплазмоз), Ehrlichia
                                    canis (эрлихиоз), Bordetella bronchiseptica (бордетеллез, питомниковый кашель),
                                    Micoplasma spp. (микоплазмоз) Salmonella (сальмонеллез).
                                </div>
                            </div>
                            <div className="infoPopap_flex">
                                <div className="infoPopap_flexImgDiv">
                                    <img src={step5_p32} alt="" className="infoPopap_flexImg"/>
                                </div>
                                <div className="infoPopap_text">
                                    <div className="infoPopap_text_head">Грибы</div>
                                    Microsporum canis – дерматофитозы (лишаи), Trichophyton mentagrophytes – дерматофитозы (лишаи), Microsporum gypseum  – дерматофитозы (лишаи), Aspergillus – Аспергиллез, Candida albicana – кандидоз
                                </div>
                            </div>
                            <div className="infoPopap_flex">
                                <div className="infoPopap_flexImgDiv">
                                    <img src={step5_p33} alt="" className="infoPopap_flexImg"/>
                                </div>
                                <div className="infoPopap_text">
                                    <div className="infoPopap_text_head">Вирусы</div>
                                    Canine Parvovirus – парвовирусный (гемморагический) энтерит собак
                                    Canine Adenovirus – инфекционный гепатит собак
                                    Feline calicivirus – калицивирусная инфекция кошек

                                </div>
                            </div>
                            <div className="infoPopap_flex">
                                <div className="infoPopap_flexImgDiv">
                                    <img src={step5_p34} alt="" className="infoPopap_flexImg"/>
                                </div>
                                <div className="infoPopap_text">
                                    <div className="infoPopap_text_head">Простейшие</div>
                                    Isospora canis (кокцидиоз), Babesia canis (пироплазмоз), Leishmania infantum (лейшманиоз), Cryptosporidium felis (криптоспоридиоз)
                                    Giardia duodenalis (лямблиоз)
                                </div>
                            </div>
                            <div className="infoPopap_flex">
                                <div className="infoPopap_flexImgDiv">
                                    <img src={step5_p35} alt="" className="infoPopap_flexImg"/>
                                </div>
                                <div className="infoPopap_text">
                                    <div className="infoPopap_text_head">Гельминты</div>
                                    Nematoda (круглые гельминты) – нематодозы: токсокароз, дирофиляриоз, аскаридоз, трихоцефалез и др.
                                    Trematoda (плоские гельминты) – трематодозы: описторхоз и др.
                                    Cestoda (ленточные гельминты) – цестодозы: дипилидиоз, экинококкоз и др.
                                </div>
                            </div>
                            <div className="infoPopap_flex">
                                <div className="infoPopap_flexImgDiv">
                                    <img src={step5_p36} alt="" className="infoPopap_flexImg"/>
                                </div>
                                <div className="infoPopap_text">
                                    <div className="infoPopap_text_head">Эктопаразиты</div>
                                    Клещи – демодекоз, саркоптоз, отодектоз, нотоэдроз
                                    Блохи – блошиный дерматит, переносчики инфекционных
                                    и паразитарных заболеваний
                                    Вши – сифункулятозы
                                </div>
                            </div>
                        </div>
                        <button className="btn btn_blue" onClick={() => this.onClickLink(4)}>Продолжить</button>
                    </div>
                </div>
                }
                {linkActive === 4 &&
                <div className="infoPopap">
                    <div className="infoPopap_wrp">
                        <div className="infoPopap_close" onClick={() => this.onClickLink(0)}/>
                        <div className="infoPopap_scroll">
                            <div className="infoPopap_iconDiv">
                                <img src={img3} alt="" className="infoPopap_icon"/>
                            </div>
                            <div className="infoPopap_head">Перекрестное заражение</div>
                            <div className="infoPopap_text">
                                Риск возникновения инфекции в питомнике увеличивается вместе
                                с увеличением числа контактирующих между собой особей. Вероятность заражения в этом случае возрастает по экспоненте.
                                Так, при контакте двух особей вероятность заражения <span className="textBlue">равна 2, четырех – 12, а 8 – уже 56</span>.
                            </div>
                            <InfoBlock
                                danger={true}
                                text={`Питомник – не статичный, а постоянно «развивающийся организм».`}
                            />
                            <div className="infoPopap_imgDiv">
                                <img src={step5_p4} alt="" className="infoPopap_img"/>
                            </div>

                        </div>
                        <button className="btn btn_blue" onClick={() => this.onClickLink(0)}>Продолжить</button>

                    </div>
                </div>
                }
            </div>
        )
    }
}
