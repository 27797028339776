import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step2">
                <TextBlock
                    text={`Отвлекайте внимание собаки, когда она пытается совершить действие, которое вам не нужно`}
                />
                <InfoBlock text={`Ознакомьтесь с содержанием видео ролика.`}/>
                <VideoBlock
                    src={'https://www.youtube.com/embed/J4Pnjf_Btgc'}
                    title={'https://www.youtube.com/watch?v=J4Pnjf_Btgc'}
                />
            </div>
        )
    }
}
