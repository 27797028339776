import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import List from "../../../Components/LessonAndTestComponents/List";

import img1 from '../../../image/lessons/lesson8/step7-1.png'
import img2 from '../../../image/lessons/lesson8/step7-2.png'
import img3 from '../../../image/lessons/lesson8/step7-3.png'
import img4 from '../../../image/lessons/lesson8/step7-4.png'
import img5 from '../../../image/lessons/lesson8/step7-5.png'
import img6 from '../../../image/lessons/lesson8/step7-6.png'

export default class step7 extends React.Component {
    state = {
        flexActive: 0,
        flex: [],
    };

    changeFlex = flexActive => {
        const {flex} = this.state
        this.setState({flexActive}, () => {})
        if (flex.indexOf(flexActive) === -1) {
            flex.push(flexActive)
        }
        this.setState({flex})
        if (flex.length === 2) {
            this.props.showNextBtn()
        }
    }

    listOne = [
        'Для изучения максимального количества представителей пород',
        'Для оценки поголовья разных питомников и заводчиков',
        'Для показа своего племенного поголовья',
    ]

    listTwo = [
        'Для показа своего питомца',
        'Новые знакомства с единомышленниками',
        'Консультации различных специалистов в области кормления и ветеринарии'
    ]

    listThree = [
        'Возможность познакомиться с большим количеством пород',
        'Выбрать себе питомца',
        'Полезное и интересное времяпровождение',
    ]

    render() {
        const {flexActive} = this.state;
        const listOne = this.listOne;
        const listTwo = this.listTwo;
        const listThree = this.listThree;
        return (
            <div className="step7">
                <TextBlock
                    text={`<b>Кинологические мероприятия - важная часть жизни активного владельца.</b> `}
                />
                {/*<InfoBlock*/}
                {/*    text={`Ознакомьтесь с мероприятиями, нажав на активные элементы.`}*/}
                {/*/>*/}
                {/*<div className="flex">*/}
                {/*    <div className={'flex_el ' + (flexActive === 1 ? 'active' : '')}*/}
                {/*         onClick={() => {this.changeFlex(1)}}>*/}
                {/*        Племенной смотр*/}
                {/*    </div>*/}
                {/*    <div className={'flex_el ' + (flexActive === 2 ? 'active' : '')}*/}
                {/*         onClick={() => {this.changeFlex(2)}}>*/}
                {/*        Выставка*/}
                {/*    </div>*/}
                {/*</div>*/}
                    <div className='flex_el'>
                        <h4 className="step7__header">Выставка</h4>
                        <p className="step7__text"><span><strong>Выставка</strong></span> — это зоотехническое мероприятие, на котором собрано и продемонстрировано максимальное количество представителей одной или разных пород.<br/>
                            Кому будет интересно:</p>
                    </div>

{/*                {flexActive !== 0 &&*/}
{/*                <div className={'info ' + (flexActive === 2 && "left")}>*/}
{/*                    {flexActive === 1 ?*/}
{/*                        <div className="info_flex">*/}
{/*                            <TextBlock*/}
{/*                                text={`Сугубо зоотехническое мероприятие,  на котором определяют пригодность породистых собак к племенному использованию. На нем собаку тщательно обследуют и достаточно подробно описывают. */}

{/*<b>Конкуренции как таковой нет, так как задача выделить «первых» не стоит.</b> `}*/}
{/*                            />*/}
{/*                            <img src={img1} alt=""/>*/}
{/*                        </div>*/}
{/*                        :*/}
{/*                        <div>*/}
{/*                            <div className="info_flex">*/}
{/*                                <TextBlock*/}
{/*                                    text={`Современная выставка собак – скорее зрелище, конкурс показа, нежели зоотехническое мероприятие. */}

{/*Присутствует конкуренция, подчас – острая, на престижных и международных выставках – очень острая.  <b>Цель – выбрать лучших.</b> Это адреналин и толика тщеславия.`}*/}
{/*                                />*/}
{/*                                <img src={img2} alt=""/>*/}
{/*                            </div>*/}
{/*                            <TextBlock*/}
{/*                                text={`Показу на выставке собаку надо учить (и учиться самому, если хотите достигнуть успеха). Для многих собаководов выставки превращаются в образ жизни, они объезжают со своими питомцами весь мир, многие наши соотечественники выигрывают самые престижные выставки мира.`}*/}
{/*                            />*/}
{/*                        </div>*/}
{/*                    }*/}
{/*                </div>*/}
{/*                }*/}

                    <div className="info">
                        <div className="info_flex">
                            <div className="info__el">
                                <ul className="step7__list">
                                    <li className="step7__list-item">
                                        <div className="step7__list-item-left">
                                            <img className="step2__list-item-image" src={img4} alt=""/>
                                        </div>
                                        <div className="step7__list-item-right">
                                            <h5 className="step7__list-item-header">Профессионалам</h5>
                                            <List ListData = {listOne}/>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <img src={img1} alt=""/>
                        </div>
                        <div className="info_flex">
                            <div className="info__el">
                                <ul className="step7__list">
                                    <li className="step7__list-item">
                                        <div className="step7__list-item-left">
                                            <img className="step2__list-item-image" src={img5} alt=""/>
                                        </div>
                                        <div className="step7__list-item-right">
                                            <h5 className="step7__list-item-header">Простым собаководам</h5>
                                            <List ListData = {listTwo}/>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <img src={img2} alt=""/>
                        </div>
                        <div className="info_flex">
                            <div className="info__el">
                                <ul className="step7__list">
                                    <li className="step7__list-item">
                                        <div className="step7__list-item-left">
                                            <img className="step2__list-item-image" src={img6} alt=""/>
                                        </div>
                                        <div className="step7__list-item-right">
                                            <h5 className="step7__list-item-header">Зрителям</h5>
                                            <List ListData = {listThree}/>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <img src={img3} alt=""/>
                        </div>
                    </div>
                <p className="step7__subscribe step7__subscribe--mt26">Посещение выставки в системе РКФ в качестве участника возможно только для собак с родословными РКФ по предварительной записи. Если вы хотите посетить мероприятие как зритель, необходимо также заранее уточнить условия посещения.</p>
            </div>
        )
    }
}