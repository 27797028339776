import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import img from '../../../image/lessons/lesson3/step1.png'

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step1">
                <div className="flex_head">
                    Елизавета Лежнева, ветеринарный врач.
                </div>
                <div className="flex">
                    <img src={img} alt="" className='flexImg'/>
                    <TextBlock
                        fontSize={16}
                        text={
                            `Интересы ветеринарных врачей и владельцев сосредоточены
                            на сохранении здоровья животных, обеспечении им хорошего качества жизни и безопасности людей.

                            Именно соблюдение мер гигиены (профилактики негативных воздействий) позволяет достичь этой цели. Надеюсь, что курс, посвященный основам гигиены питомников, позволит вам достичь всех целей в развитии питомника с комфортом
                            и безопасностью для себя и животных.`
                        }
                    />
                </div>

                <div className="head">план урока</div>

                <TextBlock
                    text={`В данном уроке вы узнаете`}
                />

                <div className="lessonSteps">
                    <div className="lessonSteps_el">
                        Основные понятия
                    </div>
                    <div className="lessonSteps_el">
                        Правильная организация питомника
                    </div>
                    <div className="lessonSteps_el">
                        Гигиена питомника
                    </div>
                </div>

            </div>
        )
    }
}