import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import img1 from "../../../image/lessons/lesson6/step10-1.png";
import img2 from "../../../image/lessons/lesson6/step10-2.png";
import img3 from "../../../image/lessons/lesson6/step10-3.png";
import img4 from "../../../image/lessons/lesson6/step10-4.png";
import img5 from "../../../image/lessons/lesson6/step10-5.png";


export default class step10 extends React.Component {
    state = {
    };

    tableRows = [
        {
            "img": img1,
            "text": `Легко заметны ребра, позвоночник, лопатки и кости таза
                    (у короткошерстных собак). 
                    Явно снижена мышечная масса. 
                    Жировые отложения в области ребер не пальпируются.`
        },
        {
            "img": img2,
            "text": `Легко заметны ребра, позвоночник, лопатки и кости таза. 
                    Отчетливое сужение в области живота (талия). 
                    Минимальные жировые отложения в области живота.`
        },
        {
            "img": img3,
            "text": `Ребра и позвоночник незаметны, но легко пальпируются. 
                    Отчетливое сужение в области живота (талия). 
                    Незначительные отложения жира в области живота.`
        },
        {
            "img": img4,
            "text": `Ребра и позвоночник пальпируются с трудом. 
                    Сужение в области живота (талия) отсутствует. 
                    Заметно отвисший живот.`
        },
        {
            "img": img5,
            "text": `Значительные жировые отложения в области грудной клетки, позвоночника и живота. 
                    Сильно отвисший живот.`
        },
    ]

    render() {
        return (
            <div className="step10 step34">
                <TextBlock
                    text={`Вес животного оценивается по пятибалльной шкале. 1 балл означает истощение, а 5 баллов — ожирение. 3 балла соответствуют идеальной упитанности. 

                    При оценке упитанности нужно обратить внимание на то, насколько выступают грудная клетка
                    и позвоночник.
                    `}
                />
                <div className="table">
                    <div className="table_head">
                        <div className="th_left">
                            ГРАДАЦИЯ
                        </div>
                        <div  className="th_right">
                            КРИТЕРИИ
                        </div>
                    </div>
                    <div className="table_body">
                        {this.tableRows.map(item => {
                        return (
                            <div
                                key={item}
                                className="table_row"
                                >
                                <div className="td_left">
                                    <img src={item.img} alt=""/>
                                </div>
                                <div className="td_right">
                                    <TextBlock
                                        text={item.text}
                                    />
                                </div>
                            </div>
                        )})}
                    </div>
                </div>
            </div>
        )
    }
}