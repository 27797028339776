import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step1">
                <TextBlock
                    text={`Пищевые потребности животных во многом зависят от возраста. В этом уроке вы узнаете о том, как кормить собак на разных стадиях жизни — во время беременности и при рождении щенков, во время активного роста щенка, во взрослом и в пожилом возрасте.`}
                />
                <div className="head">план урока</div>

                <div className="text">
                    Каждая жизненная стадия имеет свои особенности, которые нужно учитывать при кормлении собаки.
                </div>
                <div className="text">
                    В этом уроке рассматриваются:
                </div>

                <div className="lessonSteps">
                    <div className="lessonSteps_el">Беременность и лактация</div>
                    <div className="lessonSteps_el">Рост и развитие щенков</div>
                    <div className="lessonSteps_el">Подбор рациона для щенков</div>
                    <div className="lessonSteps_el">Питание взрослых и старых собак</div>
                    <div className="lessonSteps_el">Итоги урока</div>
                    <div className="lessonSteps_el">Тест</div>
                </div>

            </div>
        )
    }
}
