import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson9/step6-1.png";
import img2 from "../../../image/lessons/lesson9/step6-2.png";
import img3 from "../../../image/lessons/lesson9/step6-3.png";
import img4 from "../../../image/lessons/lesson9/step6-4.png";
import img5 from "../../../image/lessons/lesson9/step6-5.png";
import img6_1popap from '../../../image/lessons/lesson9/step6-1popap.png';
import img6_2popap from '../../../image/lessons/lesson9/step6-2popap.png';

export default class step6 extends React.Component {
    state = {
        popapActive: 0,
        activeFlex: 0,
        points: [],
        flexs: []
    };

    changeFlex = activeFlex => {
        const {flexs, points} = this.state
        if (flexs.indexOf(activeFlex) === -1) {
            flexs.push(activeFlex)
        }
        if(activeFlex === 1) {
            this.changePoint(1)
        }

        this.setState({activeFlex, flexs})
        if (points.length === 11 && flexs.length === 2) {
            this.props.showNextBtn();
        }
    }

    changePoint = popapActive => {
        this.setState({popapActive}, () => {
            this.checkPoint(popapActive)
        })
    }

    checkPoint = (point) => {
        const {points, flexs} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        if (points.length === 11 && flexs.length === 2) {
            this.props.showNextBtn();
        }
    }

    navList = [
        {
            "index": 1,
            "text": "Я хочу отправить собаку, на выставку с хендлером",
            "img": img1
        },
        {
            "index": 2,
            "text": "Я отправляюсь в путешествие и беру собаку с собой",
            "img": img2
        }
    ]

    contentActive = {
        1: {
            img: img3,
            points: [2, 3, 4, 5, 6, 7, 8, 9]
        },
        2: {
            img: img4,
            points: [10]
        }
    }


    popapWrp = {
        0: {img: '', head: '', text: ''},
        1: {
            img: img6_1popap,
            head: `Будьте внимательны!`,
            text: `Если кто-то вам оказывает услугу по передержке собаки или берется отвезти ее для участия в кинологическом мероприятии, очень ответственно отнеситесь к своему выбору.

            Наведите справки и обязательно оформите свои отношения договором, оговорив ответственность за животное – документ дисциплинирует того, кто берется оказать услугу.`
        },
        2: {
            img: img6_1popap,
            head: `Хендлер`,
            subHead: `<span class="textBlue"><b>Не путайте хендлера с юным хендлером.</b></span>`,
            text: `Так как, во-первых, этот человек ни за что не отвечает (юридически), во-вторых, в случае, если из-за собаки ему будет причинен вред, вы будете в полной мере нести за это ответственность.`,
            warning: `Категорически недопустимо отправлять собаку в поездку с несовершеннолетним.`
        },
        3: {
            img: img6_1popap,
            head: `Договор с хендлером`,
            text: `Обязательно оформите ваши отношения с хендлером договором.`
        },
        4: {
            img: img6_1popap,
            head: `Здоровая собака`,
            text: `Собака, когда вы ее отдаете, должна быть здорова.`
        },
        5: {
            img: img6_1popap,
            head: `Всегда на связи`,
            text: `Если вы хотите ежедневно иметь информацию о собаке, не забудьте, что связь стоит денег – было бы правильным положить деньги на счет телефона лица, которое с ней уезжает.`
        },
        6: {
            img: img6_1popap,
            head: `Достаточно места`,
            text: `Убедитесь, что в поездке будет достаточно места - проводите собаку до посадки, проследите за размещением.`
        },
        7: {
            img: img6_1popap,
            head: `Собаки и хендлер`,
            text: `Поинтересуйтесь, сколько собак везет и собирается выставлять хендлер кроме вашей - у него должно быть достаточно времени, чтобы показать Вашу собаку. 

            Если хендлер едет с помощником, собак может быть больше, но лучше все-таки не иметь дела с хендлерами, старающимися собрать побольше разных собак.`
        },
        8: {
            img: img6_1popap,
            head: `Собаки и хендлер`,
            text: `Заранее решите все финансовые вопросы, помня о том, что в поездке будет не только ваша собака, но и сопровождающий ее человек.`
        },
        9: {
            img: img6_1popap,
            head: `Собаки и хендлер`,
            text: `Сами собирайте собаку в дорогу, не перепоручая это хендлеру (если вы делаете это в первый раз, спросите у хендлера, что потребуется) – тогда вы будете уверены, что в дороге она будет обеспечена всем необходимым.`
        },
        10: {
            img: img6_2popap,
            head: `Как путешествовать с собакой`,
            text: `Прежде всего, никуда нельзя поехать с собакой, которая не приучена спокойно переносить транспортировку, ни с кем нельзя оставить собаку, которая не привыкла оставаться одна и не доверяет посторонним - об этом нужно позаботиться заранее и подготовить собаку к этому, иначе это неизбежно ничем хорошим не кончится.

            Перед дорогой - позаботьтесь о наличии необходимых ветеринарных документов и, если вам предстоит путешествие за границу, заранее внимательно изучите действующие в странах вашего маршрута правила содержания, выгула и транспортировки собак: они могут сильно отличаться от наших, и их нарушение чревато серьезными штрафами. И, конечно, позаботьтесь о том, чтобы в дорогу было взято все необходимое не только вам, но и собаке.`
        }
    }

    render() {

        const {popapActive, activeFlex, points} = this.state;

        return (
            <div className="step6">
                <TextBlock
                    text={`Иногда возникает необходимость отправить ее в поездку с незнакомым человеком (например, с хендлером на выставку).

                        Или нам нужно куда-то отправиться в путешествие, собаку планируем взять с собой <span class="text_dark-blue"><b>– о чем нам нужно позаботиться прежде всего и как избежать неприятностей?</b></span>`}
                />
                <InfoBlock
                    text={
                        `Ознакомьтесь с существующими вариантами, нажав на активные элементы.`
                    }
                />
                <div className="step_content">
                    <div className="flex">
                        {this.navList.map(item => {
                            return (
                                <div
                                    key={item.index}
                                    className={`flex_el ${activeFlex === 0 ? 'disabled ' : ''}` + (activeFlex === item.index && 'active')}
                                    onClick={() => {
                                        this.changeFlex(item.index)
                                    }}
                                >
                                    <div className="elImg"><img src={item.img} alt=""/></div>
                                    <div className="elText">{item.text}</div>

                                </div>
                            )})
                        }
                    </div>
                    {activeFlex !== 0 &&
                    <div className={'imgDiv ' + (activeFlex !== 1 ? `imgDiv--${activeFlex}` : '')}>
                        {this.contentActive[activeFlex].text &&
                        <TextBlock
                            text={this.contentActive[activeFlex].text}
                        />
                        }
                        {this.contentActive[activeFlex].content ? this.contentActive[activeFlex].content : ""}
                        {this.contentActive[activeFlex].img || this.contentActive[activeFlex].points ?
                            <div className="info">
                                {this.contentActive[activeFlex].img &&
                                <img src={this.contentActive[activeFlex].img} alt="" className='infoImg'/>
                                }
                                {this.contentActive[activeFlex].points &&
                                <div className="infoPoints">
                                    {this.contentActive[activeFlex].points.map(item => {
                                        return (
                                            <div
                                                key={item}
                                                className={'point ' + (points.indexOf(item) >= 0 && 'active')}
                                                onClick={() => {
                                                    this.changePoint(item)
                                                }
                                                }>
                                            </div>
                                        )
                                    })}
                                </div>
                                }
                            </div> : ""}
                    </div>
                    }
                </div>

                {popapActive !== 0 &&
                    <div className={'infoPopap'}>
                        <div className="infoPopap_wrp">
                            <div className="popap_close" onClick={() => {
                                this.changePoint(0)
                            }}/>
                            <div className="image">
                                <div className="imageBg">
                                    <img src={this.popapWrp[popapActive].img} alt=""/>
                                </div>
                            </div>
                            <div className="scroll">
                                <div className="head"
                                     dangerouslySetInnerHTML={{__html: this.popapWrp[popapActive].head}}/>
                                {this.popapWrp[popapActive].subHead &&
                                    <div className="subHead"
                                        dangerouslySetInnerHTML={{__html: this.popapWrp[popapActive].subHead}}/>
                                }
                                {this.popapWrp[popapActive].warning &&
                                    <div className="warning">
                                        <img src={img5} alt=""/>
                                        {this.popapWrp[popapActive].warning}
                                    </div>
                                }

                                <TextBlock
                                    text={this.popapWrp[popapActive].text}
                                    fontSize={16}
                                />
                                <button className="btn btn_blue" onClick={() => {
                                    this.changePoint(popapActive < 9 && popapActive > 1  ? popapActive + 1 : 0)
                                }}>Продолжить</button>
                            </div>
                        </div>
                    </div>
                }

            </div>
        )
    }
}
