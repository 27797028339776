import React from 'react'
import img1 from '../../../image/lessons/lesson33/step4-1.png';
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";


export default class step4 extends React.Component {
    state = {
    };

    render() {
        return (
            <div className="step4">

                <TextBlock
                    text={`Сравните <span class="text_dark-blue">сухие корма</span> для взрослых собак с кормами для щенков, чтобы понять, почему корма для щенков действительно лучше удовлетворяет потребности беременных собак.`}
                />
                <img src={img1} alt=""/>
                <TextBlock
                    text={`Таким образом, мы видим, что <span class="text_dark-blue">сухие корма для щенков обладают большей энергетической ценностью и количеством белка в составе, чем сухие корма для взрослых собак,</span> что положительно влияет на здоровье беременных собак.`}
                />

            </div>
        )
    }
}
