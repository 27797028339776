import React from 'react'

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step1">
                <div className="head">план урока</div>

                <div className="text">
                    Вашему вниманию предлагается цикл уроков, предназначенный для широкого круга людей, интересующихся собаководством – от планирующих завести собаку до тех, у кого она уже есть.
                    <br/>
                    <br/>
                    Здесь речь пойдет о выборе щенка в возрасте 6-12 недель – чаще всего именно в этом возрасте люди выбирают и (или) приобретают собаку (бывают случаи, когда по взаимной договоренности выбранный щенок проводит еще какое-то время в питомнике).
                    <br/>
                    <br/>
                    К выбору нужно подойти <b>очень ответственно.</b> И, по возможности, не дать эмоциям возобладать над разумом.
                </div>

                <div className="lessonSteps">
                    <div className="lessonSteps_el">
                        Где и как приобретать собаку?
                    </div>
                    <div className="lessonSteps_el">
                        Как выбрать щенка?
                    </div>
                </div>

            </div>
        )
    }
}
