import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson4/step8-1-1.png'
import img2 from '../../../image/lessons/lesson4/step8-1-2.png'
import img3 from '../../../image/lessons/lesson4/step8-2.png'
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";


export default class step6 extends React.Component {
    state = {
        pointActive: 0,
        points: [],
    };

    changePoint = pointActive => {
        this.setState({pointActive}, () => {
            this.checkPoint(pointActive)
        })
    }

    checkPoint = (point) => {
        const {points} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        console.log(points)
        if (points.length === 5) {
            this.props.showNextBtn()
        }
    }

    popapWrp = {
        1: {
            head: 'Для чего выводились?',
            text: `В подгруппу входят пастушьи собаки, охранявшие стада (в России называемые так же «овчарками»), догообразные (потомки военных и травильных собак) и гуртовые собаки.
                    
                    Охранники стад – самая древняя группа пород среди молоссов, останки таких собак датируются пятым-седьмым тысячелетием до нашей эры. Это примитивные пастушьи и сторожевые породы, в задачи которых входила охрана скота и имущества от хищников  и злоумышленников. 
                    
                    По большей части эти собаки были предоставлены сами себе и не обременены воспитанием. Что, естественно, наложило отпечаток на их облик и характер. Из охранников стад получаются отличные, возможно, лучшие из всех караульные собаки.
                    
                    В идеале, такая собака должна жить за городом, иметь территорию и «собственность», которую будет самозабвенно охранять и с хозяином будет держаться скорее как партнер, нежели как слуга. 
                    
                    При неправильном воспитании могут проявлять агрессию и демонстрировать непослушание хозяину и его домочадцам.`
        },
        2: {
            head: 'Характер',
            text: `Так как пастухи и скотоводы предпочитали собак, не требующих ухода и заботы, употребляющих «подножный корм», суровые условия отбора сделали этих животных очень жизнестойкими и неприхотливыми.
            
            Однако, в мире нет ничего идеального: оборотной стороной такого отбора является низкая склонность к послушанию и дисциплине, и низкая заинтересованность в дрессировке.`
        },
        3: {
            head: 'Особенности внешности',
            text: `Размер – в основном, от крупного до очень крупного. Среди некоторых пород встречаются настоящие гиганты, весящие более 80 кг. Грубая, не требующая ухода шерсть.
            
            Очень эффективный обмен веществ – эти громадные собаки могут обходиться очень скудной пищей, подчас совершенно непригодной для собак культурных пород.`
        },
        4: {
            head: 'Типичные представители',
            text: `Самые известные в России собаки такого типа – кавказская и среднеазиатская овчарки.`
        },
    }


    render() {
        const {pointActive, points} = this.state;
        return (
            <div className="step8">
                <TextBlock
                    text={`<span class="text_subheader">Молоссы (секция 2)</span>`}
                />
                <InfoBlock
                    text={`Ознакомьтесь с каждой группой, нажав на ключевые элементы.`}
                />
                <div className="flex">
                    <div className="flexImg">
                        <img src={img1} alt="" className=''/>
                        <img src={img2} alt="" className=''/>
                    </div>
                    <div className="flex_el"><span className={points.indexOf(1) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(1)}}>Для чего выводились?</span></div>
                    <div className="flex_el"><span className={points.indexOf(2) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(2)}}>Характер</span></div>
                    <div className="flex_el"><span className={points.indexOf(3) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(3)}}>Особенности внешности</span></div>
                    <div className="flex_el"><span className={points.indexOf(4) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(4)}}>Типичные представители</span></div>
                </div>

                {pointActive !== 0 &&
                <PopupInStep
                    closePopup={() => this.changePoint(0)}
                    popupImg={img3}
                    popupHead={this.popapWrp[pointActive].head}
                    popupText={this.popapWrp[pointActive].text}
                    changePoint={() => this.changePoint(pointActive < 4 ? pointActive + 1 : 0)}
                />
                }
            </div>
        )
    }
}
