import React from 'react'
import {Link} from "react-router-dom";
import './style.sass'


export default class Buttons extends React.Component{
    render() {
        return (
            <Link
                className={`btn ${this.props.class}`}
                style={this.props.style}
                to={this.props.to}
            >
                {this.props.svg && this.props.svg}
                {this.props.name}
            </Link>
        )
    }
}