import React from 'react'

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step1">
                <div className="head">план урока</div>

                <div className="text">
                    Вашему вниманию предлагается цикл уроков, предназначенный для широкого круга людей, интересующихся собаководством - от планирующих завести собаку до тех, у кого она уже есть.
                    <br/>
                    <br/>
                    Кинологические мероприятия - важная часть жизни активного владельца собаки. Среди них есть такие, которые требуют наличия собаки определенной породы (выставки и племенные смотры), но есть и совершенно демократично относящиеся к породной принадлежности собаки.  Для участия
                    в них потребуется подготовка собаки в той или иной форме и наличие документации (клубной, ветеринарной), требуемой организатором. Для участия в мероприятиях в других городах
                    и странах потребуется приучать собаку к транспортировке и необходимо понимание того,
                    что это достаточно затратно.

                </div>

                <div className="lessonSteps">
                    <div className="lessonSteps_el">
                        Где оценить породные достоинства?
                    </div>
                    <div className="lessonSteps_el">
                        Выставки
                    </div>
                    {/*<div className="lessonSteps_el">*/}
                    {/*    Города, проводящие выставки ранга CACIB*/}
                    {/*</div>*/}
                    <div className="lessonSteps_el">
                        Готовимся к выставке
                    </div>
                    <div className="lessonSteps_el">
                        Спортивные соревнования
                    </div>
                </div>

            </div>
        )
    }
}
