import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import Slider from "react-slick";

import img1 from '../../../image/lessons/lesson1/step7-1.png';
import img1xs from '../../../image/lessons/lesson1/step7-1xs.png';
import img2xs from '../../../image/lessons/lesson1/step7-2xs.png';
import img2 from '../../../image/lessons/lesson1/step7-2.png';
import img3 from '../../../image/lessons/lesson1/step7-3.png';
import img3xs from '../../../image/lessons/lesson1/step7-3xs.png';
import img4 from '../../../image/lessons/lesson1/step7-4.png';
import img4xs from '../../../image/lessons/lesson1/step7-4xs.png';
import img5 from '../../../image/lessons/lesson1/step7-5.png';
import img5xs from '../../../image/lessons/lesson1/step7-5xs.png';
import SwipeBlock from "../../../Components/LessonAndTestComponents/SwipeBlock";


export default class step7 extends React.Component{
    state = {
        slideShow: 0
    }
    changeSlide = (next) => {
        if (next === 4) {
            this.props.showNextBtn();
        }
        this.setState({
            slideShow: next
        })
    }

    slider = {
        1: {
            text1: `В отдельную комнату, где находится один человек (допустим, потенциальный владелец, незнакомый щенку), запускают щенка и закрывают дверь. Человек на другом конце комнаты через какое-то время (20-30 секунд) привлекает внимание щенка, подзывая его к себе.`,
            text2: `Нормальный, здоровый, контактный щенок довольно быстро реагирует на человеческий голос и без колебаний приходит на зов. Собаки более нервного склада обычно на какое-то время «задумываются», стоит ли подходить к незнакомцу, совсем робкие и трусливые – не подходят вовсе.`,
            img: img1xs
        },
        2: {
            text1: `Когда щенок в непосредственной близости от предполагаемого владельца, необходимо активно поиграть с щенком.`,
            text2: `Если щенок пытается кусать руки со злобой, то это говорит о  том, что он легко перевозбуждается, следовательно, стоит обратить особое внимание на его нервную систему и дальнейшее воспитание, поскольку чрезмерно злобные и легковозбудимые собаки могут создать проблемы. Это не значит, что такая собака «плохая», просто вам следует рассчитывать свои силы. `,
            img: img2xs
        },
        3: {
            text1: `Затем незнакомец предлагает щенку последовать за ним: таким образом можно проверить заинтересованность щенка в общении с человеком, а в будущем в работе с ним. `,
            text2: `Некоторые собаки буквально сразу бегут вслед за уходящим, но есть и такие, которые предпочитают сначала подумать, прежде чем последовать за незнакомцем. В любом случае, это неплохо. Хуже, если собака вообще не обращает внимания на зов или пытается избегать контакта, двигаться в противоположном направлении.`,
            img: img3xs
        },
        4: {
            text1: `Еще одно немаловажное упражнение – проверка реакции щенка на резкий и внезапный громкий звук (свисток, дудку и т.п.). Щенок находится на полу (ни в коем случае не на руках!) в свободном состоянии. Звук должен быть неожиданным, резким, но не слишком громким.`,
            text2: `Щенок, как правило, сразу настораживается, поднимает уши и через какое-то время идет исследовать то место, откуда был слышен звук. Если же собака вздрагивает, пытается убежать, прячется и начинает дрожать и долго не может успокоиться, это говорит о неуверенном поведении щенка.`,
            img: img4xs
        },
        5: {
            text1: `Щенку предлагают поиграть с предметом. Обычно это какая-нибудь мягкая тряпочка в руках незнакомца, с помощью которой он сначала привлекает внимание щенка, а затем предоставляет ему возможность самостоятельно решать, что делать с незнакомым предметом. Обычно щенки с азартом гоняются за тряпкой, пытаясь схватить ее зубами. Можно попытаться осторожно отнять тряпку у щенка и проследить за его реакцией. `,
            text2: `Как правило, щенок принимает предмет за свою «добычу» и не спешит с ней расставаться добровольно. Если же щенок вообще не проявляет желания ознакомиться с предметом, побаивается его, сразу, по первому же требованию незнакомца, уступает ему тряпку – он робок. Не проявляющие интереса к игре щенки тоже должны вызвать сомнение, так как в будущем игра должна быть важным инструментов в вашем взаимодействии с собакой.`,
            img: img5xs
        },
    }
    render(){
        let settings = {
            dots: false,
            arrows: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            beforeChange: (current, next) => {
                this.changeSlide(next)
            },
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        dots: true
                    }
                },
            ]
        };
        const {slideShow} = this.state
        return (
            <div className="step7">
                <TextBlock text={`Существует довольно много различных тестов для щенков, в основном похожих друг на друга. Обычно проверяют реакции на человека и его действия, незнакомый звук и предметы. Во время тестирования щенок должен быть отделен от однопометников (проверять что-то, когда щенки вместе, бесполезно, так как реакции «размазываются» тем, что они подражают друг другу).`}/>
                <InfoBlock text={`Ознакомьтесь с каждой характеристикой, нажав на ключевые элементы.`}/>
                <SwipeBlock text={`<p class="swiper-block__tablet">Ознакомьтесь с каждой группой нажав на ключевые элементы.<br>Используйте свайп для перемещения между элементами.</p><p class="swiper-block__mobile">Используйте свайп для ознакомления с каждой характеристикой.</p>`}/>
                <div className="flex">
                    <div
                        className={"flex_el " + (slideShow === 0 && 'active')}
                        onClick={() => this.slickSlider.slickGoTo(0)}
                    >
                        <img src={img1} alt=""/>
                    </div>
                    <div
                        className={"flex_el " + (slideShow === 1 && 'active')}
                        onClick={() => this.slickSlider.slickGoTo(1)}
                    >
                        <img src={img2} alt=""/>
                    </div>
                    <div
                        className={"flex_el " + (slideShow === 2 && 'active')}
                        onClick={() => this.slickSlider.slickGoTo(2)}
                    >
                        <img src={img3} alt=""/>
                    </div>
                    <div
                        className={"flex_el " + (slideShow === 3 && 'active')}
                        onClick={() => this.slickSlider.slickGoTo(3)}
                    >
                        <img src={img4} alt=""/>
                    </div>
                    <div
                        className={"flex_el " + (slideShow === 4 && 'active')}
                        onClick={() => this.slickSlider.slickGoTo(4)}
                    >
                        <img src={img5} alt=""/>
                    </div>
                </div>
                <div className={"slider slider" + slideShow }>
                    <Slider ref={slickSlider => (this.slickSlider = slickSlider)} {...settings}>
                        {Object.values(this.slider).map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={"slider_el "}
                                >
                                    <div className="img"><img src={item.img} alt=""/></div>
                                    <div className="head">Что надо сделать?</div>
                                    <TextBlock text={item.text1}/>
                                    <div className="head">Интерпретация.</div>
                                    <TextBlock text={item.text2}/>
                                    {index !== 4 ? <button className="btn btn_blue" onClick={() => this.slickSlider.slickGoTo(index + 1)}>Продолжить</button> : null}
                                </div>

                            )
                        })}
                    </Slider>
                </div>
            </div>
        )
    }

}
