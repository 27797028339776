import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from "../../../image/lessons/lesson9/step7-1.png";
import img2 from "../../../image/lessons/lesson9/step7-2.png";
import img7_1popap from '../../../image/lessons/lesson9/step7-1popap.png';
import img7_2popap from '../../../image/lessons/lesson9/step7-2popap.png';

export default class step7 extends React.Component {
    state = {
        popapActive: 0,
        activeFlex: 0,
        points: [],
        flexs: []
    };

    changeFlex = activeFlex => {
        const {flexs, points} = this.state
        if (flexs.indexOf(activeFlex) === -1) {
            flexs.push(activeFlex)
        }

        this.setState({activeFlex, flexs})
        if (points.length === 18 && flexs.length === 2) {
            this.props.showNextBtn();
        }
    }

    changePoint = popapActive => {
        this.setState({popapActive}, () => {
            this.checkPoint(popapActive)
        })
    }

    checkPoint = (point) => {
        const {points, flexs} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        if (points.length === 18 && flexs.length === 2) {
            this.props.showNextBtn();
        }
    }

    navList = [
        {
            "index": 1,
            "text": "Требования к номерам для животных",
        },
        {
            "index": 2,
            "text": "Выгул",
        }
    ]

    contentActive = {
        1: {
            img: img1,
            points: [1, 2, 3, 4, 5]
        },
        2: {
            img: img2,
            points: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
        }
    }


    popapWrp = {
        0: {img: '', head: '', text: ''},
        1: {
            img: img7_1popap,
            head: `Индивидуальное размещение`,
            text: `Животных рекомендуется содержать в индивидуальных номерах согласно их особенностям и физиологическим потребностям. Номера должны быть изолированы друг от друга, не иметь визуального и воздушного контакта. При временном содержании собак мелких пород они могут иметь визуальный контакт с другими собаками мелкого размера, так как это не угрожает их безопасности и сохранности.`
        },
        2: {
            img: img7_1popap,
            head: `Оснащение гостиницы`,
            text: `Полы и фундамент под номером и выгульной площадкой должны исключать возможность копания и организации подкопов и нор, позволяющих побег и контакт животных. В каждом номере должно быть предусмотрено естественное и искусственное освещение, естественная или искусственная вентиляция. Содержание животных в клетках, ограничивающих перемещение, не допускается.`,
        },
        3: {
            img: img7_1popap,
            head: `Условия в номерах`,
            text: `Собак средних, крупных и гигантских пород домашнего содержания следует содержать в зоогостинице в индивидуальных номерах. Номер должен иметь высоту стандартного жилого помещения 2.4 м. площадь не менее 3.5 м2 для собак средних и крупных пород и не менее 5 м2 для собак гигантских пород. В номере должно быть предусмотрено окно не менее 0.7 м2, номер должен иметь внутри антивандальную отделку и сплошную, не решетчатую дверь с надежным замком и замком-засовом.`
        },
        4: {
            img: img7_1popap,
            head: `Комфортные условия для собаки`,
            text: `Полы в номере должны быть из плитки, наливные или из специальных прочных антивандальных материалов. Полы и стены номеров должны быть легко очищаемыми и устойчивыми к действию моющих и дезинфицирующих средств.
            
            В номере должна быть комфортная для проживания домашней собаки температура в диапазоне от 16 до 25 °С. Выход из номера должен осуществляться только через дверь.`
        },
        5: {
            img: img7_1popap,
            head: `Размеры помещения`,
            text: `Номер должен иметь высоту стандартного жилого помещения 2.4 м. площадь не менее 2.5 м2; в помещении с номерами для собак мелкого размера должно быть окно не менее 0,2 м2. Номер должен иметь внутри антивандальную отделку и дверь с надежным замком.`
        },
        6: {
            img: img7_2popap,
            head: `Места выгула`,
            text: `Места выгула животных устанавливаются зоогостиницей или передержкой без согласования с владельцем животного. Выгул животных различных биологических видов должен осуществляться на разных выгульных площадках, оборудованных в соответствии с видовыми особенностями животных.`
        },
        7: {
            img: img7_2popap,
            head: `Индивидуальные особенности`,
            text: `Индивидуальные особенности (количество прогулок, необходимость индивидуального выгула или наличие возможности выгула совместно с другими животными того же вида) должны быть согласованы с владельцем животного (его представителем) при приеме животного в зоогостиницу или передержку.`
        },
        8: {
            img: img7_2popap,
            head: `Правила выгула`,
            text: `Правила организации выгула должны быть определены локальными актами зоогостиницы или передержки, соответствовать санитарно-гигиеническим правилам и обеспечивать безопасность выгуливаемых животных, персонала зоогостиницы или передержки и окружающих людей.`
        },
        9: {
            img: img7_2popap,
            head: `Условия выгула`,
            text: `Места для выгула животных должны быть надежно огорожены сплошным забором высотой не менее 2.5 м. Забор должен быть углублен в землю или иметь фундамент или «антипобеговое» устройство, чтобы не допустить подкопа и побега животного. Территория для выгула должна иметь ландшафт и покрытие, позволяющее безопасно выгуливать животных, а также осуществлять эффективную уборку выгула.`
        },
        10: {
            img: img7_2popap,
            head: `Местность выгула`,
            text: `Трава на территории выгула должна быть подстрижена. Покрытие должно быть безопасно для животных. Площадка для свободного выгула должна составлять не менее 1000 м2 для собак средних и крупных пород и не менее 100 м2 для собак мелких пород. В целях сохранности и безопасности животных их выгул должен осуществляться в сопровождении сотрудника зоогостиницы (передержки).`
        },
        11: {
            img: img7_2popap,
            head: `Раздельный выгул`,
            text: `Животных, проявляющих зооагрессию должны выгуливать отдельно от других животных. Выгул взрослых собак следует осуществлять не реже трех раз в день. Индивидуальный режим выгула для щенков и пожилых собак следует устанавливать в зависимости от индивидуальных особенностей животных по согласованию с владельцем животного (его представителем).`
        },
        12: {
            img: img7_2popap,
            head: `Уборка площадок для выгула`,
            text: `Площадки для выгула должны убирать ежедневно. Продукты жизнедеятельности животных должны утилизировать в соответствии с ветеринарным и санитарным законодательством. Выгул животных следует осуществлять только на территории гостиницы. Выход за территорию зоогостиницы для оказания дополнительных услуг следует осуществлять по дополнительным соглашениям и разрешениям, внесенным в условия договоров.`
        },
        13: {
            img: img7_2popap,
            head: `Договор с владельцом собаки`,
            text: `Место, необходимость и условия выгула животного, содержащегося в передержке, как и возможность его контакта с другими животными, а также совместное размещение и совместный выгул животных следует согласовывать с владельцем (доверенным лицом) и оформлять договором или письменным согласием.`
        },
        14: {
            img: img7_2popap,
            head: `Особые условия`,
            text: `Уборку мест содержания животных следует проводить не реже одного раза в день. В местах содержания животных должно быть сухо, чисто, у собак мелких пород вовремя должна проводиться смена одноразовых пеленок. Миски и кормушки следует мыть после каждого приема пищи. Поилки следует мыть при каждой смене порции воды, не реже двух раз в день.`
        },
        15: {
            img: img7_2popap,
            head: `Чистота и комфорт`,
            text: `По окончании пребывания животного следует проводить уборку места его содержания с дезинсекцией и дезинфекцией. На территории зоогостиницы при наличии специальных отдельных помещений допускается оказание дополнительных услуг, таких как дрессировка, груминг и др. Использование животных для любых, не согласованных договором мероприятий запрещается.`
        },
        16: {
            img: img7_2popap,
            head: `Требования к содержанию`,
            text: `Требования к индивидуальным предметам содержания животных: в средствах размещения непродуктивных животных разрешается использование индивидуальных предметов, в том числе аксессуаров, амуниции и гигиенических товаров. К индивидуальным предметам относят: кормушки, поилки, миски, игрушки, лежаки, подстилки, ошейники, шлейки, намордники, сумки-переноски и иные предметы, используемые для организации проживания животного. Они могут быть предоставлены владельцем (доверенным лицом) или зоогостиницей (передержкой).`
        },
        17: {
            img: img7_2popap,
            head: `Тепловая и химическая обработка`,
            text: `По окончании пребывания животного привезенные с ним индивидуальные предметы возвращают владельцу, а предметы, предоставленные зоогостиницей, следует подвергать тепловой и химической дезинфекции и дезинсекции.`
        }
    }

    render() {
        const {popapActive, activeFlex, points} = this.state;
        let pointsLength = activeFlex === 1 ? 5 : 17;

        return (
            <div className="step7">
                <TextBlock
                    text={`Как бы мы ни были привязаны к своей собаке, иногда возникают ситуации, когда нам необходимо куда-то поехать и нет возможности взять ее с собой. 

                        Сегодня найти зоогостиницу обычно не представляет сложности. В 2016 году был утвержден <b>ГОСТ на услуги для непродуктивных животных</b> и на его требования можно ориентироваться, как на некий стандарт.`}
                />
                <InfoBlock
                    text={
                        `Ознакомьтесь с существующими вариантами, нажав на активные элементы.`
                    }
                />
                <div className="step_content">
                    <div className="flex">
                        {this.navList.map(item => {
                            return (
                                <div
                                    key={item.index}
                                    className={`flex_el ${activeFlex === 0 ? 'disabled ' : ''}` + (activeFlex === item.index && 'active')}
                                    onClick={() => {
                                        this.changeFlex(item.index)
                                    }}
                                >
                                    {item.text}
                                </div>
                            )})
                        }
                    </div>
                    {activeFlex !== 0 &&
                    <div className={'imgDiv ' + (activeFlex !== 1 ? `imgDiv--${activeFlex}` : '')}>
                        {this.contentActive[activeFlex].text &&
                        <TextBlock
                            text={this.contentActive[activeFlex].text}
                        />
                        }
                        {this.contentActive[activeFlex].content ? this.contentActive[activeFlex].content : ""}
                        {this.contentActive[activeFlex].img || this.contentActive[activeFlex].points ?
                            <div className="info">
                                {this.contentActive[activeFlex].img &&
                                <img src={this.contentActive[activeFlex].img} alt="" className='infoImg'/>
                                }
                                {this.contentActive[activeFlex].points &&
                                <div className="infoPoints">
                                    {this.contentActive[activeFlex].points.map(item => {
                                        return (
                                            <div
                                                key={item}
                                                className={'point ' + (points.indexOf(item) >= 0 && 'active')}
                                                onClick={() => {
                                                    this.changePoint(item)
                                                }
                                                }>
                                            </div>
                                        )
                                    })}
                                </div>
                                }
                            </div> : ""}
                    </div>
                    }
                </div>

                {popapActive !== 0 &&
                <div className={'infoPopap'}>
                    <div className="infoPopap_wrp">
                        <div className="popap_close" onClick={() => {
                            this.changePoint(0)
                        }}/>
                        <div className="image">
                            <div className="imageBg">
                                <img src={this.popapWrp[popapActive].img} alt=""/>
                            </div>
                        </div>
                        <div className="scroll">
                            <div className="head"
                                 dangerouslySetInnerHTML={{__html: this.popapWrp[popapActive].head}}/>
                            <TextBlock
                                text={this.popapWrp[popapActive].text}
                                fontSize={16}
                            />
                            <button className="btn btn_blue" onClick={() => {
                                this.changePoint(popapActive < pointsLength ? popapActive + 1 : 0)
                            }}>Продолжить</button>
                        </div>
                    </div>
                </div>
                }

            </div>
        )
    }
}
