import React from 'react'

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step1">
                <div className="head">план урока</div>

                <div className="text">
                    В этом уроке мы рассмотрим видеокурс из цикла «Основы дрессировки» и узнаем, как обучить собаку выдержке.
                </div>

                <div className="lessonSteps">
                    <div className="lessonSteps_el">
                        1 Этап
                    </div>
                    <div className="lessonSteps_el">
                        2 Этап
                    </div>
                    <div className="lessonSteps_el">
                        Тест
                    </div>
                    <div className="lessonSteps_el">
                        Разбираем ошибки
                    </div>
                    <div className="lessonSteps_el">
                        Итоги урока
                    </div>
                </div>

            </div>
        )
    }
}
