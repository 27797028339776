import React from 'react'

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step1">
                <div className="head">план урока</div>

                <div className="text">
                    Вашему вниманию предлагается цикл уроков, предназначенный для широкого круга людей, интересующихся собаководством - от планирующих завести собаку - до тех, у кого она уже есть.
                    <br/>
                    <br/>
                    Собака требует внимания к здоровью. Сейчас, к сожалению, далеко не все собаки могут похвастаться богатырским здоровьем, кроме того, у многих  собак встречаются специфические проблемы со здоровьем, связанные с особенностями экстерьера: проблемы с позвоночником у растянутых и коротконогих собак, проблемы с суставами у гигантских пород, проблемы с дыханием у короткомордых собак.
                    <br/>
                    <br/>
                    Но даже абсолютно здоровая собака так же уязвима, как и любое живое существо,<b> ей требуется регулярное выполнение профилактических мероприятий и внимание к здоровью.</b>
                </div>

                <div className="lessonSteps">
                    <div className="lessonSteps_el">
                        Как заботиться о здоровье собаки?
                    </div>
                    <div className="lessonSteps_el">
                        Врожденные особенности собаки
                    </div>
                    <div className="lessonSteps_el">
                        Факторы, влияющие на здоровье
                    </div>
                    <div className="lessonSteps_el">
                        Профилактические мероприятия и выбор ветеринарного врача
                    </div>
                    <div className="lessonSteps_el">
                        Самостоятельный осмотр
                    </div>
                    <div className="lessonSteps_el">
                        Оценка упитанности собак
                    </div>
                </div>

            </div>
        )
    }
}
