import React from 'react'

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step1">
                <div className="head">план урока</div>

                <div className="text">
                    В этом уроке мы рассмотрим видеокурс из цикла «Основы дрессировки» и узнаем, как обучить собаку связкам команд «Сидеть», «Лежать», «Стоять».
                </div>

                <div className="lessonSteps">
                    <div className="lessonSteps_el">
                        Связка команд «Сидеть» – «Стоять»
                    </div>
                    <div className="lessonSteps_el">
                        Связка команд «Сидеть» – «Лежать»
                    </div>
                    <div className="lessonSteps_el">
                        Связка команд «Стоять» – «Лежать»
                    </div>
                    <div className="lessonSteps_el">
                        Разбираем ошибки
                    </div>
                    <div className="lessonSteps_el">
                        Итоги урока
                    </div>
                    <div className="lessonSteps_el">
                        Выполнение команд «сидеть», «лежать», «стоять»
                    </div>
                    <div className="lessonSteps_el">
                        Тест
                    </div>
                </div>

            </div>
        )
    }
}
