import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import img1 from "../../../image/lessons/lesson6/step11-1.png";
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";


export default class step11 extends React.Component {
    state = {
    };

    render() {
        return (
            <div className="step11 step35">
                <TextBlock
                    fonSize={16}
                    text={`У собак, как и у людей, ожирение принято связывать с угрозой здоровью, и в большинстве случаев оно действительно представляет такую угрозу — от элементарных неудобств до тяжелых патологических состояний, сопряженных с риском для жизни.
`}
                />
                <div className="flex">
                    <div className="flex_el"><div>сокращение<br/> продолжительности жизни</div></div>
                    <div className="flex_el"><div>заболевания органов мочевыделительной системы</div></div>
                    <div className="flex_el"><div>заболевания<br/> сердечно сосудистой системы</div></div>
                    <div className="flex_el"><div>патология репродуктивной системы</div></div>
                    <div className="flex_el"><div>расстройства пищеварения</div></div>
                    <div className="flex_el"><div>заболевания суставов</div></div>
                    <div className="flex_el"><div>нарушение работы респираторной системы</div></div>
                    <div className="flex_el"><div>сахарный диабет</div></div>
                    <div className="flex_el"><div>снижение иммунитета</div></div>
                    <div className="flex_el"><div>онкологические заболевания</div></div>
                    <div className="flex_el"><div>нарушение терморегуляции</div></div>
                    <div className="flex_el"><div>повышенный риск<br/> при хирургических вмешательствах</div></div>
                    <div className="flex_el"><div>дерматологические заболевания</div></div>
                    <img src={img1} alt="" className='mainImg'/>
                </div>
                <InfoBlock text={`Рассмотрите схему. На ней показаны риски, появляющиеся у животных в связи с ожирением.`}/>
            </div>
        )
    }
}