import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step1">
                <TextBlock
                    text={`Промышленные корма удовлетворяют все потребности животных, так как они изготавливаются по точным расчетам и с использованием высококачественных ингредиентов. В этом уроке вы научитесь правильно интерпретировать информацию о свойствах корма с упаковок, а также узнаете больше о составе кормов.`}
                />
                <div className="head">план урока</div>

                <div className="lessonSteps">
                    <div className="lessonSteps_el">Информация, содержащаяся на упаковках готовых кормов для животных</div>
                    <div className="lessonSteps_el">Ингредиенты, используемые в промышленных кормах</div>
                    <div className="lessonSteps_el">Интерпретация сырьевого состава корма</div>
                    <div className="lessonSteps_el">Итоги урока</div>
                    <div className="lessonSteps_el">Тест</div>
                </div>

            </div>
        )
    }
}
