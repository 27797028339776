import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson36/step16-1.png'
import img1xs from '../../../image/lessons/lesson36/step16-1xs.png'


export default class step16 extends React.Component {

    render() {
        return (
            <div className="step16">
                <TextBlock
                    text={`Как различаются корма разных классов? Чем <b>выше класс корма,</b> тем <b>выше его диетические свойства.</b>
                    
                    <b>Диетические свойства</b> — это содержание основных питательных веществ в корме. Если посмотреть на пирамиду, можно заметить, что чем выше класс корма, тем больше в его составе белков и жиров, а также выше энергетическая ценность (Ккал).
                    
                    Таким образом, можно сделать вывод, что чем выше класс корма, тем <b>больше</b> он содержит <b>питательных веществ.</b>`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt=""/>
                </picture>
            </div>
        )
    }
}
