import React from 'react'
import Test from "../../Test";

export default class step4 extends React.Component {
    state = {
    };


    render() {
        return (
            <div className="step4">
                <Test lesson={this.props.lesson} stepId={this.props.stepId} showNextStep={this.props.showNextStep} showPrevStep={this.props.showPrevStep}/>
            </div>
        )
    }
}
