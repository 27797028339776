import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step2">
                <InfoBlock text={`Ознакомьтесь с содержанием видео ролика.`}/>
                <VideoBlock
                    src={'https://www.youtube.com/embed/kIwiRhhGcZc'}
                    title={'https://www.youtube.com/watch?v=kIwiRhhGcZc'}
                />
            </div>
        )
    }
}
