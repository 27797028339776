import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson4/step18-1.png'
import img2 from '../../../image/lessons/lesson4/step18-2.png'
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";


export default class step6 extends React.Component {
    state = {
        pointActive: 0,
        points: [],
    };

    changePoint = pointActive => {
        this.setState({pointActive}, () => {
            this.checkPoint(pointActive)
        })
    }

    checkPoint = (point) => {
        const {points} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        console.log(points)
        if (points.length === 5) {
            this.props.showNextBtn()
        }
    }

    popapWrp = {
        1: {
            head: 'Для чего выводились?',
            text: `Гончие – очень специфические собаки, по-прежнему остающиеся верными единственному предназначению – охоте.

Гончие, как и многие другие охотничьи породы, веками выводились в соответствии с одной работой – гоном зверя.

Вне «поля» эти собаки жили на псарнях, предоставленные сами себе, и таким их свойствам, как удобство в качестве «компаньона» или «собаки для дома» веками не придавалось никакого значения.`
        },
        2: {
            head: 'Характер',
            text: `Эти собаки могут обладать очень самостоятельным нравом и стремлением к лидерству (закрепленным веками группового содержания).

Кроме того, такое важное качество гончих как «вязкость» - способность часами преследовать конкретную добычу, требует большого упрямства и концентрации, оборотной стороной которых является почти полная «закрытость» прорабатывающей след собаки от любых внешних раздражителей, в том числе – команд хозяина. 

Поэтому даже современные заводские породы гончих – весьма неудобные собаки-компаньоны.`
        },
        3: {
            head: 'Особенности внешности',
            text: `Размер – от среднего до крупного. 
    
Шерсть большинства пород ухода не требует.`
        },
        4: {
            head: 'Типичные представители',
            text: `Гончие - не самые удобные собаки-компаньоны (упрямы и излищне самостоятельны), чаще всего наши любители заводят биглей и бассетов. 

Из относящихся к этой же группе, но не являющихся типичными гончими (по характеру это скорее универсальная фермерская собака) известен родезийский риджбек.`
        },
    }


    render() {
        const {pointActive, points} = this.state;
        return (
            <div className="step14">
                <TextBlock
                    text={`<span class="text_subheader">Гончие</span>`}
                />
                <InfoBlock
                    text={`Ознакомьтесь с каждой группой, нажав на ключевые элементы.`}
                />
                <div className="flex">
                    <img src={img1} alt="" className='mainImg'/>
                    <div className="flex_el"><span className={points.indexOf(1) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(1)}}>Для чего выводились?</span></div>
                    <div className="flex_el"><span className={points.indexOf(2) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(2)}}>Характер</span></div>
                    <div className="flex_el"><span className={points.indexOf(3) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(3)}}>Особенности внешности</span></div>
                    <div className="flex_el"><span className={points.indexOf(4) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(4)}}>Типичные представители</span></div>
                </div>

                {pointActive !== 0 &&
                <PopupInStep
                    closePopup={() => this.changePoint(0)}
                    popupImg={img2}
                    popupHead={this.popapWrp[pointActive].head}
                    popupText={this.popapWrp[pointActive].text}
                    changePoint={() => this.changePoint(pointActive < 4 ? pointActive + 1 : 0)}
                />
                }
            </div>
        )
    }
}
