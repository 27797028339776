import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson36/step18-1.png'

export default class step18 extends React.Component {


    render() {
        return (
            <div className="step18">
                <TextBlock
                    text={`Третий критерий, который отличает корма для домашних животных — <b>их специализация,</b> то есть наличие внутри ассортимента разнообразных рецептур для различных групп животных.
                    
                    <b>Чем выше класс корма тем выше специализация под конкретную группу животных,</b> например:`}
                />
                <div className="flex">
                    {/*<div className="flex_el">*/}
                    {/*    <ul>*/}
                    {/*        <li>корм супер-премиум класса имеет ещё большую специализацию, он учитывает возраст собаки, её образ жизни, особенности пищеварения, а также может учитывать породные и другие особенности;</li>*/}
                    {/*        <li>корм премиум класса PEDIGREE® предлагает корм с учётом возраста животного и его образа жизни (корм для щенков, взрослых собак от 1 года до 7 лет, для собак старше 7 лет и для стерилизованных собак);</li>*/}
                    {/*        <li>корм эконом класса CHAPPI® — это корм для взрослых собак без учёта образа жизни животного и других особенностей.</li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                    <div className="flex_el">
                        <img className="flex_img" src={img1} alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}
