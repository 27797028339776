import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'

import img1 from '../../../image/lessons/lesson41/step30-1.png'
import img2 from '../../../image/lessons/lesson41/step30-2.png'
import img3 from '../../../image/lessons/lesson41/step30-3.png'
import img4 from '../../../image/lessons/lesson41/step30-4.png'
import img5 from '../../../image/lessons/lesson41/step30-5.png'
import img6 from '../../../image/lessons/lesson41/step30-6.png'
import img7 from '../../../image/lessons/lesson41/step30-7.png'
import img8 from '../../../image/lessons/lesson41/step30-8.png'
import img9 from '../../../image/lessons/lesson41/step30-9.png'
import img10 from '../../../image/lessons/lesson41/step30-10.png'
import img11 from '../../../image/lessons/lesson41/step30-11.png'
import img12 from '../../../image/lessons/lesson41/step30-12.png'
import img13 from '../../../image/lessons/lesson41/step30-13.png'
import img14 from '../../../image/lessons/lesson41/step30-14.png'
import img1_popup from '../../../image/lessons/lesson41/step30-popup1.png'
import img2_popup from '../../../image/lessons/lesson41/step30-popup2.png'
import img3_popup from '../../../image/lessons/lesson41/step30-popup3.png'
import img4_popup from '../../../image/lessons/lesson41/step30-popup4.png'
import img5_popup from '../../../image/lessons/lesson41/step30-popup5.png'
import img6_popup from '../../../image/lessons/lesson41/step30-popup6.png'
import img7_popup from '../../../image/lessons/lesson41/step30-popup7.png'


export default class step30 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        points: [0],
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }

    clickPoint = pointActive => {
        const {points, windowWidth} = this.state
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        if (windowWidth < 768) {
            document.body.scrollIntoView( true)
            document.body.style.overflow = "hidden"
        }

        if (pointActive > 7) {
            this.closePoint()
            this.setState({pointActive: 0, points})
        } else {
            this.setState({pointActive, points})
        }

        if (points.length === 7) {
            this.props.showNextBtn();
        }
    }

    closePoint = () => {
        document.body.style.overflow = ""
        this.setState({pointActive: 0})
    }

    infoData = {
        1: {
            head: `Первый прикорм* с курицей для щенков всех пород с 3 недель`,
            img: img7,
            imgPopup: img1_popup,
            className: `first `,
        },
        2: {
            head: `Для щенков всех пород с курицей от 2 месяцев`,
            img: img8,
            imgPopup: img2_popup,
            className: `second `,
        },
        3: {
            head: `Для взрослых собак миниатюрных пород с говядиной от 12 месяцев`,
            img: img9,
            imgPopup: img3_popup,
            className: `third `,
        },
        4: {
            head: `Для взрослых собак маленьких пород c говядиной от 12 месяцев`,
            img: img10,
            imgPopup: img4_popup,
            className: `fourth `,
        },
        5: {
            head: `Для взрослых собак всех пород с говядиной от 12 месяцев`,
            img: img11,
            imgPopup: img5_popup,
            className: `fifth `,
        },
        6: {
            head: `Для взрослых собак крупных пород корм с говядиной от 18 месяцев`,
            img: img12,
            imgPopup: img6_popup,
            className: `sixth `,
        },
        7: {
            head: `Для щенков крупных пород, полнорационный корм с 6 месяцев с курицей`,
            img: img14,
            imgPopup: img7_popup,
            className: `seventh `,
        },
    }

    render() {
        const {pointActive} = this.state
        return (
            <div className="step30">
                <InfoBlock
                    text={`Нажмите на изображения, чтобы познакомиться с сухими рационами PEDIGREE®`}
                />

                <div className="head">
                    Сухие рационы
                </div>
                <div className="flex">
                    <div className="flex_el left">
                        <div className="flex_el_head">
                            Для щенков
                        </div>
                        <div className="flex_el_box">
                            <div className="flex_el_img-box">
                                <img src={img1} alt="" className="flex_el_img" onClick={() => {this.clickPoint(1)}}/>
                            </div>
                            <div className="flex_el_img-box">
                                <img src={img2} alt="" className="flex_el_img" onClick={() => {this.clickPoint(2)}}/>
                            </div>
                            {/*<div className="flex_el_img-box">*/}
                            {/*    <img src={img13} alt="" className="flex_el_img" onClick={() => {this.clickPoint(7)}}/>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="flex_el right">
                        <div className="flex_el_head">
                            Для взрослых собак
                        </div>
                        <div className="flex_el_box">
                            <div className="flex_el_img-box">
                                <img src={img3} alt="" className="flex_el_img" onClick={() => {this.clickPoint(3)}}/>
                            </div>
                            <div className="flex_el_img-box">
                                <img src={img4} alt="" className="flex_el_img" onClick={() => {this.clickPoint(4)}}/>
                            </div>
                            <div className="flex_el_img-box">
                                <img src={img5} alt="" className="flex_el_img" onClick={() => {this.clickPoint(5)}}/>
                            </div>
                            <div className="flex_el_img-box">
                                <img src={img6} alt="" className="flex_el_img" onClick={() => {this.clickPoint(6)}}/>
                            </div>
                        </div>
                    </div>
                    {Object.keys(this.infoData).map(item => {
                        return (
                            <div key={item} className={'info ' + (this.infoData[item].className) + (pointActive === Number(item) && 'active')}>
                                <div className="info_box">
                                    <div className="info_close" onClick={this.closePoint}/>
                                    <div className="info_color">
                                        <img src={this.infoData[item].imgPopup} alt="" className="info_color_img"/>
                                    </div>
                                    <div className="info_head">
                                        {this.infoData[item].head}
                                    </div>
                                    <img src={this.infoData[item].img} alt="" className="imgCenter"/>
                                    {pointActive === 1 &&
                                    <div className="info_note">
                                        *Доступен только для профессионального использования заводчиками собак
                                    </div>
                                    }
                                    <button
                                        className="btn btn_blue"
                                        onClick={() => {this.clickPoint(pointActive + 1)}}
                                    >
                                        продолжить
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}
