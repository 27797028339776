import React from 'react'
import img1 from '../../../image/lessons/lesson5/step18-1.png'


export default class step6 extends React.Component {


    render() {
        return (
            <div className="step18">
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_head">ДЛЯ ПИТОМЦЕВ</div>
                        <div className="flex_wrp">
                            <ul>
                                <li>Меньшая калорийность. Профилактика набора
                                    лишнего веса.</li>
                                <li>Легко разгрызать и глотать. Это особенно важно для котят и щенков, а также для старых животных.</li>
                                <li>Высокое содержание мясных ингредиентов.</li>
                                <li>Уменьшает концентрацию мочи за счет высокого содержания жидкости, что способствует профилактике мочекаменной болезни. Актуально для кошек и мелких собак.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_head">ДЛЯ ХОЗЯЕВ</div>
                        <div className="flex_wrp">
                            <ul>
                                <li>Большой выбор вкусов и текстур дает возможность разнообразить рацион питомца.</li>
                                <li>Высокие вкусовые качества. Легко угодить привередливым питомцам.</li>
                                <li>Закрытый пауч не нужно хранить
                                    в холодильнике.</li>
                                <li>Паучи предназначены для разового кормления.</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <img src={img1} alt="" className="imgCenter"/>
            </div>
        )
    }
}