import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from "../../../image/lessons/lesson40/step7-1.png";
import img2 from "../../../image/lessons/lesson40/step7-2.png";
import img3 from "../../../image/lessons/lesson40/step7-3.png";
import img4 from "../../../image/lessons/lesson40/step7-4.png";


export default class step7 extends React.Component {

    render() {
        return (
            <div className="step7">
                <TextBlock
                    text={`В основе эффективности лежит сочетание <span class="text_red">механического действия и благоприятного воздействия активных компонентов.</span>`}
                />
                <div className="head">
                    Действие активных компонентов
                </div>
                <TextBlock
                    text={`Активные компоненты, триполифосфат натрия и сульфат цинка, способствуют сдерживанию роста налёта и предотвращают отложение зубного камня.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_el_img-box left">
                            <div className="flex_el_img-box_text">
                                Свободный кальций без триполифосфата натрия
                            </div>
                            <div className="flex_el_img-wrp">
                                <img src={img1} alt="" className="flex_el_img"/>
                            </div>
                        </div>
                        <div className="flex_el_img-box right">
                            <div className="flex_el_img-box_text">
                                Связанный кальций с триполифосфатом натрия
                            </div>
                            <div className="flex_el_img-wrp">
                                <img src={img2} alt="" className="flex_el_img"/>
                            </div>
                        </div>
                        <div className="flex_el_head">
                            ТРИПОЛИФОСФАТ НАТРИЯ
                        </div>
                        <div className="flex_el_text">
                            Связывает кальций, содержащийся в слюне. В результате, <b>связанный кальций не откладывается</b> на поверхности зубов в виде зубного камня.
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_el_img-box left">
                            <div className="flex_el_img-box_text">
                                Зубной камень без сульфата цинка
                            </div>
                            <div className="flex_el_img-wrp">
                                <img src={img3} alt="" className="flex_el_img"/>
                            </div>
                        </div>
                        <div className="flex_el_img-box right">
                            <div className="flex_el_img-box_text">
                                Замедление образования зубного камня с мсульфатом цинка
                            </div>
                            <div className="flex_el_img-wrp">
                                <img src={img4} alt="" className="flex_el_img"/>
                            </div>
                        </div>
                        <div className="flex_el_head">
                            СУЛЬФАТ ЦИНКА
                        </div>
                        <div className="flex_el_text">
                            Замедляет формирование зубного камня за счет <b>сдерживания роста кристаллов, содержащих кальций.</b>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
