import React from 'react'

import Lesson1 from "./Lesson1";
import Lesson2 from "./Lesson2";
import Lesson3 from "./Lesson3";
import Lesson4 from "./Lesson4";
import Lesson5 from "./Lesson5";
import Lesson6 from "./Lesson6";
import Lesson7 from "./Lesson7";
import Lesson7Owner from "./Lesson7Owner";
import Lesson8 from "./Lesson8";
import Lesson9 from "./Lesson9";
import Lesson10 from "./Lesson10";
import Lesson12 from "./Lesson12";
import Lesson13 from "./Lesson13";
import Lesson25 from "./Lesson25";
import Lesson26 from "./Lesson26";
import Lesson27 from "./Lesson27";
import Lesson28 from "./Lesson28";
import Lesson29 from "./Lesson29";
import Lesson30 from "./Lesson30";
import Lesson31 from "./Lesson31";
import Lesson32 from "./Lesson32";
import Lesson33 from "./Lesson33";
import Lesson34 from "./Lesson34";
import Lesson35 from "./Lesson35";
import Lesson36 from "./Lesson36";
import Lesson38 from "./Lesson38";
import Lesson39 from "./Lesson39";
import Lesson40 from "./Lesson40";
import Lesson41 from "./Lesson41";

export default class lessons extends React.Component{

    componentDidMount() {
    }

    _renderLesson = () => {
        let lesson = this.props.location.state
        switch (lesson.attributes.url) {
            case '1' : {
                return <Lesson1 lesson={lesson}/>
            }
            case '2' : {
                return <Lesson2 lesson={lesson}/>
            }
            case '3' : {
                return <Lesson3 lesson={lesson}/>
            }
            case '4' : {
                return <Lesson4 lesson={lesson}/>
            }
            case '5' : {
                return <Lesson5 lesson={lesson}/>
            }
            case '6' : {
                return <Lesson6 lesson={lesson}/>
            }
            case '7' : {
                return <Lesson7 lesson={lesson}/>
            }
            case '8' : {
                return <Lesson8 lesson={lesson}/>
            }
            case '9' : {
                return <Lesson9 lesson={lesson}/>
            }
            case '10' : {
                return <Lesson10 lesson={lesson}/>
            }
            case '7_owner' : {
                return <Lesson7Owner lesson={lesson}/>
            }
            case '12' : {
                return <Lesson12 lesson={lesson}/>
            }
            case '13' : {
                return <Lesson13 lesson={lesson}/>
            }
            case '25' : {
                return <Lesson25 lesson={lesson}/>
            }
            case '26' : {
                return <Lesson26 lesson={lesson}/>
            }
            case '27' : {
                return <Lesson27 lesson={lesson}/>
            }
            case '28' : {
                return <Lesson28 lesson={lesson}/>
            }
            case '29' : {
                return <Lesson29 lesson={lesson}/>
            }
            case '30' : {
                return <Lesson30 lesson={lesson}/>
            }
            case '31' : {
                return <Lesson31 lesson={lesson}/>
            }
            case '32' : {
                return <Lesson32 lesson={lesson}/>
            }
            case '33' : {
                return <Lesson33 lesson={lesson}/>
            }
            case '34' : {
                return <Lesson34 lesson={lesson}/>
            }
            case '35' : {
                return <Lesson35 lesson={lesson}/>
            }
            case '36' : {
                return <Lesson36 lesson={lesson}/>
            }
            case '38' : {
                return <Lesson38 lesson={lesson}/>
            }
            case '39' : {
                return <Lesson39 lesson={lesson}/>
            }
            case '40' : {
                return <Lesson40 lesson={lesson}/>
            }
            case '41' : {
                return <Lesson41 lesson={lesson}/>
            }
            default : {
                return false
            }
        }
    }

    render() {
        return (
            this._renderLesson()
        )
    }
}
