import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step1">
                <div className="head">план урока</div>
                <TextBlock
                    text={`В этом уроке рассматриваются следующие вопросы:`}
                />

                <div className="lessonSteps">
                    <div className="lessonSteps_el">О бренде PEDIGREE®</div>
                    <div className="lessonSteps_el">Научная основа рационов PEDIGREE®</div>
                    <div className="lessonSteps_el">Ассортимент рационов PEDIGREE®</div>
                    <div className="lessonSteps_el">Тест</div>
                </div>
            </div>
        )
    }
}
