import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import img1 from '../../../image/lessons/lesson2/step5-1.png';
import img2 from '../../../image/lessons/lesson2/step5-2.png';


export default class step5 extends React.Component{

    state = {
        flexActive: 0,
        flexs: [],
    }

    changeFlex = flexActive => {
        const {flexs} = this.state
        if (flexs.indexOf(flexActive) === -1) {
            flexs.push(flexActive)
        }
        this.setState({flexActive, flexs})
        if (flexs.length === 2) {
            this.props.showNextBtn()
        }
    }

    closeFlex = () => {this.setState({flexActive: 0})}

    render() {
        const {flexs, flexActive} = this.state
        return (
            <div className="step5">
                <TextBlock
                    text={`Если нет возможности дать щенку двигаться свободно и не всегда хватает времени, можно обеспечить необходимую нагрузку повышением её интенсивности. Активно играйте, найдите возможность дать побегать на выгуле, но не переутомляйте щенка – это может быть вредно для его здоровья.
                Очень полезны и хорошо нагружают молодую собаку:
                <span className="text_blue"><b>- движение по глубокому снегу или мелкой воде (подходит для старшей группы);</b></span>
                <b>- плаванье (к сожалению, не всегда доступно и не все современные собаки идут в воду)</b>`}
                    hidden={flexActive > 0 ? 'hidden' : ''}
                />
                <InfoBlock
                    text={`Ознакомьтесь с каждой характеристикой, нажав на ключевые элементы.`}
                    hidden={flexActive > 0 ? ' hidden' : ''}
                />

                <div className="flex">
                    <div
                        className={'flex_closed ' + (flexActive === 0 ? 'hidden' : '')}
                        onClick={() => {this.closeFlex()}}
                    />
                    <div className={'flex_el ' + (flexActive === 2 && 'hidden')}>
                        <div className="flex_img" onClick={()=>{this.changeFlex(1)}}>
                            <img src={img1} alt=""/>
                        </div>
                        <div className={'wrp ' + (flexs.indexOf(1) >= 0 && 'show ') + (flexActive === 0 && 'unshow')}>
                            <TextBlock text={`Гуляя с щенком, держите его постоянно под контролем. Не предоставляйте его самому себе. Обязательно уделяйте часть времени на прогулке занятиям.`}/>
                        </div>
                    </div>
                    <div className={'flex_el ' + (flexActive === 1 && 'hidden')}>
                        <div className="flex_img" onClick={()=>{this.changeFlex(2)}}>
                            <img src={img2} alt=""/>
                        </div>
                        <div className={'wrp ' + (flexs.indexOf(2) >= 0 && 'show ') + (flexActive === 0 && 'unshow')}>
                            <TextBlock text={`Гуляя с щенком, меняйте маршруты. Постепенно приучайте его к многообразию окружающего мира: улицам, транспорту, городскому шуму. Это подготовит его к будущей взрослой жизни.`}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
