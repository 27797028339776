import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson34/step12-1.png'
import List from "../../../Components/LessonAndTestComponents/List";


export default class step12 extends React.Component {

    ListData = {
        1:
            [
                `декларация о соответствии;`,
                `сертификат качества c результатами анализов;`,
                `ветеринарное свидетельство;`
            ],
        2:
            [
                `визуальный контроль;`,
                `спектральная идентификация;`,
                `содержание макронутриентов и влажности;`,
                `исследование на бактериальные и микотоксины;`,
                `окислительные характеристики.`,
                `и другие исследования`
            ]
    };

    render() {
        return (
            <div className="step12">
                <TextBlock
                    text={`Входной контроль сырья осуществляется <b>в два этапа:</b> проверка сопроводительной документации и лабораторный контроль.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="attention">
                            Проверка документации
                        </div>
                        <div className="flex_wrp">
                            <h3 className="flex_head">
                                К обязательной сопроводительной документации относятся:
                            </h3>
                            <List ListData={this.ListData[1]}/>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="attention">
                            Лабораторный контроль
                        </div>
                        <div className="flex_wrp">
                            <h3 className="flex_head">
                                В лабораторный контроль входят следующие меры:
                            </h3>
                            <List ListData={this.ListData[2]}/>
                        </div>
                    </div>
                    <img className="img" src={img1} alt=""/>
                </div>
            </div>
        )
    }
}
