import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson34/step8-1.png'
import img2 from '../../../image/lessons/lesson34/step8-2.png'
import img3 from '../../../image/lessons/lesson34/step8-3.png'
import img4 from '../../../image/lessons/lesson34/step8-4.png'
import img5 from '../../../image/lessons/lesson34/step8-5.png'


export default class step8 extends React.Component {

    render() {
        return (
            <div className="step8">
                <TextBlock
                    text={`Давайте разберемся, какое сырье входит в состав готовых кормов.
                    
                    В сухих и влажных рационах используются различные сырьевые источники, которые делятся на категории в зависимости от содержания в них питательных веществ.`}
                />
                <div className="attention">
                    Сырьевые источники
                </div>
                <div className="flex flex_arrow">
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <img src={img5} alt=""/>
                            <div className="flex_img flex_img_mobile">
                                <img src={img1} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <img src={img5} alt=""/>
                            <div className="flex_img flex_img_mobile">
                                <img src={img2} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <img src={img5} alt=""/>
                            <div className="flex_img flex_img_mobile">
                                <img src={img3} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <img src={img5} alt=""/>
                            <div className="flex_img flex_img_mobile">
                                <img src={img4} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex_info">
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <p className="flex_head">
                                источники углеводов
                            </p>
                            <div className="flex_text">
                                рис, кукуруза, пшеница, ячмень
                            </div>
                            <div className="flex_img">
                                <img src={img1} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <p className="flex_head">
                                источники белков
                            </p>
                            <div className="flex_text">
                                курица, говядина, рыба, яйца
                            </div>
                            <div className="flex_img">
                                <img src={img2} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <p className="flex_head">
                                источники жиров
                            </p>
                            <div className="flex_text">
                                растительные масла, животные жиры
                            </div>
                            <div className="flex_img">
                                <img src={img3} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <p className="flex_head">
                                витамины и минералы
                            </p>
                            <div className="flex_text">
                                пищевые витаминно-минеральные премиксы, отдельные витамины
                            </div>
                            <div className="flex_img">
                                <img src={img4} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
