import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson4/step21-1-1.png'
import img2 from '../../../image/lessons/lesson4/step21-1-2.png'
import img3 from '../../../image/lessons/lesson4/step21-2.png'
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";


export default class step6 extends React.Component {
    state = {
        pointActive: 0,
        points: [],
    };

    changePoint = pointActive => {
        this.setState({pointActive}, () => {
            this.checkPoint(pointActive)
        })
    }

    checkPoint = (point) => {
        const {points} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        console.log(points)
        if (points.length === 5) {
            this.props.showNextBtn()
        }
    }

    popapWrp = {
        1: {
            head: 'Для чего выводились?',
            text: `Эти собачки на протяжении веков были сугубо домашними, в связи с чем наилучшим образом подходят в качестве комнатных животных.

Очень популярны и многочисленны, особенно в городских условиях. В то же время, не забывайте, что даже самая маленькая собака требует не меньшей ответственности и обязательного воспитания, как и большая.`
        },
        2: {
            head: 'Характер',
            text: `Очень живые, преданные и послушные, они пригодны для содержания как в тесной квартире, так и в загородном доме.

Некоторые из этих пород довольно нежные, но другие с удовольствием станут напарниками в различных видах спорта, не уступая более крупным собратьям. К примеру, пудели и папийоны входят в топ-10 самых обучаемых пород собак.`
        },
        3: {
            head: 'Особенности внешности',
            text: `Большинство собак в этой группе очень маленькие. Есть породы с шерстью от длинной до короткой, есть даже бесшерстные.

Все породы с длинной шерстью требуют тщательного ухода за шерстью и поддержания ее в чистоте, иначе их роскошная шуба превращается в нечто непрезентабельное. Это обязательно нужно учитывать, принимая решение о приобретении такой собаки.`
        },
        4: {
            head: 'Типичные представители',
            text: `В этой группе многие породы очень популярны: русский той, цветные болонки и мальтезе, гриффоны. Сейчас стало меньше некогда очень популярных пуделей, возможно, это связано с всплеском интереса к тем декоративным породам, которые появились у наших любителей только в конце 20-го века.

Следует иметь ввиду, что маленьких, хрупких собачек не следует покупать в подарок детям - это очень плохая идея.

Собака - не игрушка!

Ребенку следует заводить собаку покрепче и не раньше, чем он сможет к ней ответственно относиться и самостоятельно ухаживать.`
        },
    }


    render() {
        const {pointActive, points} = this.state;
        return (
            <div className="step17">
                <TextBlock
                    text={`<span class="text_subheader">Собаки-компаньоны и декоративные собаки</span>`}
                />
                <InfoBlock
                    text={`Ознакомьтесь с каждой группой, нажав на ключевые элементы.`}
                />
                <div className="flex">
                    <div className="flexImg">
                        <img src={img1} alt="" className=''/>
                        <img src={img2} alt="" className=''/>
                    </div>
                    <div className="flex_el"><span className={points.indexOf(1) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(1)}}>Для чего выводились?</span></div>
                    <div className="flex_el"><span className={points.indexOf(2) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(2)}}>Характер</span></div>
                    <div className="flex_el"><span className={points.indexOf(3) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(3)}}>Особенности внешности</span></div>
                    <div className="flex_el"><span className={points.indexOf(4) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(4)}}>Типичные представители</span></div>
                </div>

                {pointActive !== 0 &&
                <PopupInStep
                    closePopup={() => this.changePoint(0)}
                    popupImg={img3}
                    popupHead={this.popapWrp[pointActive].head}
                    popupText={this.popapWrp[pointActive].text}
                    changePoint={() => this.changePoint(pointActive < 4 ? pointActive + 1 : 0)}
                />
                }
            </div>
        )
    }
}
