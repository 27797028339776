import React from 'react'
import TextBlock from "../../../Components/LessonAndTestComponents/TextBlock";

import img1 from '../../../image/lessons/lesson41/step32-1.png'
import img2 from '../../../image/lessons/lesson41/step32-2.png'

export default class step32 extends React.Component {

    render() {
        return (
            <div className="step32">
                <TextBlock
                    text={`Содержание питательных веществ в корме обеспечивается разными сырьевыми источниками.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img className="flex_el_img" src={img1} alt=""/>
                        <div className="flex_el_text">
                            <b>PEDIGREE® для щенков всех пород</b> <span className="flex_el_green">с 3 недель</span> специально создан для перевода щенков с материнского молока на специальные рационы для щенков. При смешивании с теплой водой гранулы быстро впитывают воду и становятся мягкими.
                        </div>
                    </div>
                    <div className="flex_el">
                        <img className="flex_el_img" src={img2} alt=""/>
                        <div className="flex_el_text">
                            <b>PEDIGREE® для щенков всех пород</b> <span className="flex_el_blue">с 2 месяцев</span> является высококалорийным и хорошо усвояемым рационом, созданным с учетом потребностей щенков в период наиболее интенсивного роста.
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
