import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img2_1 from '../../../image/lessons/lesson7/step2-1.png'
import img2_2 from '../../../image/lessons/lesson7/step2-2.png'
import img2_3 from '../../../image/lessons/lesson7/step2-3.png'
import img2_4 from '../../../image/lessons/lesson7/step2-4.png'
import img2_5 from '../../../image/lessons/lesson7/step2-5.jpg'
import img2_6 from '../../../image/lessons/lesson7/step2-6.png'
import img2_1popap from '../../../image/lessons/lesson7/step2-1popap.png'
import img2_2popap from '../../../image/lessons/lesson7/step2-2popap.png'
import img2_3popap from '../../../image/lessons/lesson7/step2-3popap.png'
import img2_4popap from '../../../image/lessons/lesson7/step2-4popap.png'
import img2_5popap from '../../../image/lessons/lesson7/step2-5popap.png'
import img2_6popap from '../../../image/lessons/lesson7/step2-6popap.png'


export default class step2 extends React.Component {
    state = {
        popapActive: 0,
        popapShow: false,
        activeFlex: 0,
        points: []
    };

    changeFlex = activeFlex => {
        this.setState({activeFlex})
    }

    showPopap = popapActive => {
        const {popapShow, points} = this.state;
        if (!popapShow) {
            if (points.indexOf(popapActive) === -1) {
                points.push(popapActive)
            }
            this.setState({
                popapActive,
                popapShow: !popapShow,
                points,
            })
        } else {
            this.setState({
                popapActive: 0,
                popapShow: !popapShow
            })
        }
        if (points.length === 28) {
            this.props.showNextBtn();
        }
    }

    navList = [
        {
            "index": 1,
            "text": "Дома, в квартире"
        },
        {
            "index": 2,
            "text": "На улице в городе"
        },
        {
            "index": 3,
            "text": "За городом"
        },
        {
            "index": 4,
            "text": "На выгуле"
        },
        {
            "index": 5,
            "text": "В транспорте"
        },
        {
            "index": 6,
            "text": "На кинологическом мероприятии"
        }
    ]

    contentActive = {
        1: {
            text: `<span class="text_dark-blue"><b>Поведение собаки в доме не должно доставлять неудобств хозяину.</b></span>`,
            img: img2_1,
            points: [1, 2, 3, 4, 5, 6, 7]
        },
        2: {
            text: `Даже если вам повезло жить в шаговой доступности от оборудованного, удобного и достаточно просторного выгула или безлюдного места, где собака может вволю порезвиться, ни одна живущая в городе собака <span class="text_dark-blue"><b>не может прожить жизнь не бывая на людных улицах, во дворах, у дорог и прочих местах, предполагающих шум, оживленное движение, наличие людей и животных.</b></span>

                Поэтому определенные хорошие манеры и подготовка к такой жизни ей совершенно необходимы.`,
            img: img2_2,
            points: [9, 10, 11, 12, 13]
        },
        3: {
            text: `Жизнь в загородном доме имеет свои преимущества как для собаки, так и для ее владельца. Во многом требования к содержанию собак за городом ниже, чем к собаке в городе. Но и там они присутствуют.`,
            img: img2_3,
            points: [14, 15, 16, 17, 18]
        },
        4: {
            text: false,
            img: img2_4,
            points: [19, 20, 21, 22, 23]
        },
        5: {
            text: false,
            img: img2_5,
            points: [24, 25, 26, 27, 28]
        },
        6: {
            text: `Обычно участию в кинологическом мероприятии предшествует достаточно длительная подготовка.
                <span class="text_dark-blue"><b>На кинологические мероприятия собака должна приходить подготовленной.</b></span>`,
            img: img2_6,
            points: [29]
        }
    }


    popapWrp = {
        0: {img: '', head: '', text: ''},
        1: {
            img: img2_1popap,
            head: `Поведение дома, в квартире`,
            text: `<span class="text_brown"><b>Собаке не следует:</b></span>
                - загрязнять жилье продуктами жизнедеятельности
                
                <span class="text_blue"><b>Исключение:</b></span>
                - маленькие собачки; 
                - старые и тяжело больные животные, которые не могут соблюдать чистоту по объективным причинам.`
        },
        2: {
            img: img2_1popap,
            head: `Поведение дома, в квартире`,
            text: `<span class="text_brown"><b>Собаке не должна:</b></span>
                - рвать и грызть что попало.`
        },
        3: {
            img: img2_1popap,
            head: `Поведение дома, в квартире`,
            text: `<span class="text_brown"><b>Воспитанная собака не должна:</b></span>
                - воровать еду со стола и из мест, где она хранится, и, желательно, вообще не попрошайничать у стола. Собаки-попрошайки, привыкшие получать со стола куски, чаще и таскают со стола и труднее от этого отучаются.`
        },
        4: {
            img: img2_1popap,
            head: `Поведение дома, в квартире`,
            text: `<span class="text_brown"><b>Собака должна:</b></span>
                - уметь оставаться одна при необходимости, не досаждая соседям воем и лаем и не портя имущество хозяев от скуки.`
        },
        5: {
            img: img2_1popap,
            head: `Поведение дома, в квартире`,
            text: `- Собаку следует обучить выполнять команды хозяина.`
        },
        6: {
            img: img2_1popap,
            head: `Поведение дома, в квартире`,
            text: `- Собаке следует четко определить правила жизни в доме. Для этого владельцу необходимо быть последовательным. Например, если вы позволяете собаке спать с вами в одной кровати, нельзя ругать ее за то, что она туда запрыгивает в неподходящее время - она попросту не понимает, когда можно, а когда - нельзя. То же применимо и к остальным правилам поведения в доме.`
        },
        7: {
            img: img2_1popap,
            head: `Поведение дома, в квартире`,
            text: `<span class="text_brown"><b>Собака не должна:</b></span>
                - своим поведением не давать владельцу вести социальную жизнь.
                
                Либо ее нужно смолоду приучить к гостям и научить себя хорошо вести с посторонними, либо, если у вас недоверчивая, сторожевая собака, загодя приучить к «месту», где ее можно временно изолировать. И она должна там спокойно отдыхать, не беспокоя окружающих.`
        },
        9: {
            img: img2_2popap,
            head: `Поведение на улице в городе`,
            text: `<span class="text_blue"><b>Собаку нужно:</b></span>

                - приучить к минимально необходимому комплекту амуниции: ошейнику, поводку и наморднику. 
                
                - научить спокойно ходить рядом, не грызть поводок и не рваться с него (и прекращать рывки, если что-то ее сильно возбудило, по команде).`
        },
        10: {
            img: img2_2popap,
            head: `Поведение на улице в городе`,
            text: `"Общественное место" начинается с лестничной клетки, лифта, подъезда. Собаку следует приучить не пачкать эти места, не шуметь и вести себя спокойно. Нервных и злобных собак, во избежание неожиданностей, выводить на прогулку и заводить в подъезд лучше в наморднике, к которому собака должна быть заранее приучена - даже короткий поводок не является гарантией от покуса в стесненных условиях при неожиданной встрече с посторонним.`
        },
        11: {
            img: img2_2popap,
            head: `Поведение на улице в городе`,
            text: `<span class="text_blue"><b>Собаку нужно:</b></span>

                - приучить спокойно реагировать на городской шум, транспортные средства, прохожих, других собак, кошек и представителей городской фауны.`
        },
        12: {
            img: img2_2popap,
            head: `Поведение на улице в городе`,
            text: `Для передвижения на общественном транспорте собаку следует приучить к таким поездкам. В зависимости от вида транспорта и размера собаки, транспортировка может осуществляться как в боксе/переноске, так и просто на поводке и в наморднике. В любом случае, нахождение в транспортном средстве может явиться стрессом для собаки, поэтому не стоит планировать долгих поездок сразу - сначала необходимо познакомить собаку с необычной обстановкой.`
        },
        13: {
            img: img2_2popap,
            head: `Поведение на улице в городе`,
            text: `Для посещения объектов городской инфраструктуры (кафе, магазинов и пр., не снабженных запрещающей табличкой) собака должна быть приучена к скоплению посторонних, обучена спокойной реакции на внимание (в разумных пределах) с их стороны и навыкам послушания. К минимуму таких навыков относятся команды «сидеть», «лежать», «рядом», а также прекращение нежелательных действий по команде.`
        },
        14: {
            img: img2_3popap,
            head: `Поведение за городом`,
            text: `<span class="text_brown"><b>Собака не может</b></span> всю жизнь провести на замкнутом пространстве приусадебного участка, с ней нужно гулять, а если владелец ведет активный образ жизни, возможно, ей предстоят поездки.

                Поэтому ее, как и городскую собаку, нужно приучить к транспорту и городской среде (для этого молодую собаку можно повозить туда на «экскурсии»).`
        },
        15: {
            img: img2_3popap,
            head: `Поведение за городом`,
            text: `<span class="text_blue"><b>Собаку нужно:</b></span>
                
                - приучить спокойно реагировать на скот и другую домашнюю живность, иначе ее охотничий инстинкт может ввергнуть владельца в нешуточные неприятности.`
        },
        16: {
            img: img2_3popap,
            head: `Поведение за городом`,
            text: `<span class="text_blue"><b>Собаку нужно:</b></span>
                
                - приучить спокойно ночевать во дворе в оборудованном вольере и оставаться одной, не досаждая соседям воем.`
        },
        17: {
            img: img2_3popap,
            head: `Поведение за городом`,
            text: `Часто собаку требуется учить вести себя на участке: 
                - не копать грядки, не носиться по цветам и т.д.
                
                Это можно сделать, как установив ограды, так и на этапе приучения, держа ее под присмотром, корректируя ее поведение похвалой и порицанием.`
        },
        18: {
            img: img2_3popap,
            head: `Поведение за городом`,
            text: `«Сельскую» собаку, как и городскую, нужно научить хорошо вести себя на прогулке вне территории и обучить необходимым навыкам послушания.`
        },
        19: {
            img: img2_4popap,
            head: `Поведение на выгуле`,
            text: `Выгул или дрессировочная площадка - прекрасное место для занятий с собакой на улице. Но для работающего человека подчас трудно выбрать такое время, когда там никого нет.
                
                На достаточно просторном выгуле можно отойти позаниматься
                в сторонке, но учтите - молодая или общительная собака будет сильно отвлекаться. Занятия с «отвлечением» полезны на определенном этапе дрессировки, но первоначальную выработку навыка нужно отрабатывать так, чтобы собака как можно меньше отвлекалась.`
        },
        20: {
            img: img2_4popap,
            head: `Поведение на выгуле`,
            text: `У всех собак разные характеры. Одни дружат, другие ссорятся. Если вы хотите, чтобы ваша собака играла с собратьями без поводка, заранее приучите ее к общению с ними.
                
               Это не всегда возможно со взрослой, агрессивной собакой - ее уже трудно «переделать». В этом случае  нужно ограничить контакты тем кругом, с которым она не конфликтует или обратиться к опытному дрессировщику, осуществляющему коррекцию поведения.`
        },
        21: {
            img: img2_4popap,
            head: `Поведение на выгуле`,
            text: `Не допускайте конфликтов и тем более не провоцируйте, отпуская собаку тогда, когда конфликт очень вероятен.`,
        },
        22: {
            img: img2_4popap,
            head: `Поведение на выгуле`,
            text: `<span class="text_blue"><b>Прежде чем отпускать собаку, научите ее послушанию.</b></span>
                
                Собаку, которая не подходит по команде, не обучена прекращению нежелательных действий и не отучена подбирать с земли, отпускать можно только в закрытом выгуле во время занятий послушанием, когда вы отрабатываете навык с помощью лакомства или мотивационным предметом.`
        },
        23: {
            img: img2_4popap,
            head: `Поведение на выгуле`,
            text: `Собака, даже недоверчивая к чужим людям, довольно быстро приучается лояльно относиться к владельцам других собак на выгуле, но все равно будьте внимательны - когда они возбуждены или при возникновении конфликтных ситуации, возможны покусы.
                
                <span class="text_blue"><b>Будьте внимательны и постоянно следите за собакой.</b></span>`
        },
        24: {
            img: img2_5popap,
            head: `Поведение в транспорте`,
            text: `В личном транспорте собака, свободно перемещающаяся по салону, может стать причиной дтп или может получить травму при нештатной ситуации, например, при экстренном торможении.
                
                Собака должна находиться в переноске или в специальном гамаке (или используются специальная амуниция, позволяющая пристегнуть собаку, ограничив ее передвижение).`
        },
        25: {
            img: img2_5popap,
            head: `Поведение в транспорте`,
            text: `Собак, которые боятся транспортных средств или которых укачивает, не тащите силком, приучайте постепенно лакомством, игрушками, короткими «тренировочными» поездками. Можно проконсультироваться касательно укачивания и препаратов против него с вашим ветеринарным врачом.`
        },
        26: {
            img: img2_5popap,
            head: `Поведение в транспорте`,
            text: `В общественном транспорте внимательно изучите правила перевозки соответствующим видом транспорта (или транспортной компании) и неукоснительно им следуйте. 
                
                Во многих видах общественного транспорта обязательным условием перевозки является нахождение собаки в наморднике и на коротком поводке.`
        },
        27: {
            img: img2_5popap,
            head: `Поведение в транспорте`,
            text: `Перевозить в общественном транспорте можно собаку, приученную спокойно относиться к посторонним людям.`
        },
        28: {
            img: img2_5popap,
            head: `Поведение в транспорте`,
            text: `Если вам предстоят дальние путешествия с собакой, практически обязательным условием их осуществления станет приучение собаки к транспортному боксу.`
        },
        // 29: {
        //     img: img2_6popap,
        //     head: `Поведение на кинологических мероприятиях`,
        //     text: `К спортивно-зрелищным – <span class="text_blue"><b>дрессировка с непременным послушанием.</b></span>`
        // },
        29: {
            img: img2_6popap,
            head: `Поведение на кинологических мероприятиях`,
            text: `К выставкам, выводкам и племсмотрам необходимо провести ринговую подготовку – приучение собаки к определённым действиям в ринге, где близко находятся незнакомые собаки, к манипуляциям судьи и т.п.`
        }
    }

    render() {
        const {popapShow, popapActive, activeFlex, points} = this.state;
        return (
            <div className="step2">
                <TextBlock
                    text={`Говорят, что собака - отражение хозяина. И в этом есть доля истины.
                        Можно приобрести очень неплохую, по ее собственному потенциалу, собаку, можно хорошо ее содержать и кормить, правильно за ней ухаживать, но чтобы она действительно была хорошей собакой – <b>ее непременно потребуется обучать и воспитывать.</b>`}
                />
                <InfoBlock
                    text={
                        `Ознакомьтесь с существующими вариантами, нажав на активные элементы.`
                    }
                />
                <div className="step_content">
                    <div className="flex">
                        {this.navList.map(item => {
                            return (
                                <div
                                    key={item.index}
                                    className={'flex_el ' + (activeFlex === item.index && 'active')}
                                    onClick={() => {
                                        this.changeFlex(item.index)
                                    }}
                                >{item.text}</div>
                            )})
                        }
                    </div>
                    {activeFlex !== 0 &&
                        <div className={'imgDiv ' + (activeFlex !== 1 ? `imgDiv--${activeFlex}` : '')}>
                            {this.contentActive[activeFlex].text &&
                            <TextBlock
                                text={this.contentActive[activeFlex].text}
                            />
                            }
                            <div className="info">
                                {this.contentActive[activeFlex].img &&
                                    <img src={this.contentActive[activeFlex].img} alt="" className='infoImg'/>
                                }
                                {this.contentActive[activeFlex].points &&
                                    <div className="infoPoints">
                                        {this.contentActive[activeFlex].points.map(item => {
                                            return (
                                                <div
                                                    key={item}
                                                    className={'point ' + (points.indexOf(item) >= 0 && 'active')}
                                                    onClick={() => {
                                                        this.showPopap(item)
                                                    }
                                                    }>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>

                {popapShow &&
                <div className={'infoPopap'}>
                    <div className="infoPopap_wrp">
                        <div className="popap_close" onClick={() => {
                            this.showPopap(0)
                        }}/>
                        <div className="image">
                            <div className="imageBg">
                                <img src={this.popapWrp[popapActive].img} alt=""/>
                            </div>
                        </div>
                        <div className="scroll">
                            <div className="head"
                                 dangerouslySetInnerHTML={{__html: this.popapWrp[popapActive].head}}/>
                            <TextBlock
                                text={this.popapWrp[popapActive].text}
                                fontSize={16}
                            />
                            <button className="btn btn_blue" onClick={() => {
                                this.showPopap(0)
                            }}>Продолжить</button>
                        </div>
                    </div>
                </div>
                }

            </div>
        )
    }
}