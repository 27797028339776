import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson33/step10-1.png'


export default class step6 extends React.Component {

    render() {
        return (
            <div className="step10">
                <TextBlock
                    text={`При кормлении беременной собаки нужно помнить несколько правил, чтобы не навредить ее здоровью.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <div className="flex_count">1</div>
                            <TextBlock
                                text={`Нельзя перекармливать собаку <b>на ранней стадии беременности.</b> Это может привести к образованию лишнего веса.`}
                            />
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <div className="flex_count">2</div>
                            <TextBlock
                                text={`Собаке <b>не нужны дополнительные витамины</b> и минералы — это вызовет дисбаланс. Все необходимые микронутриенты <b>уже содержатся</b> в корме.`}
                            />
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <div className="flex_count">3</div>
                            <TextBlock
                                text={`За неделю до родов собака должна есть <b>примерно на 60% больше</b> ее стандартной суточной нормы.`}
                            />
                        </div>
                    </div>
                    <img src={img1} alt=""/>
                </div>
            </div>
        )
    }
}
