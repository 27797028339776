import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import img1 from "../../../image/lessons/lesson6/step8-1.png";


export default class step8 extends React.Component {
    state = {
    };

    render() {
        return (
            <div className="step8 step32">
                <TextBlock
                    fonSize={16}
                    text={`Эти статистические данные показывают распределение собак по весу среди общей популяции в России.`}
                />
                <div className="blockImg">
                    <img src={img1} alt="" className='mainImg'/>
                </div>
                <TextBlock
                    fonSize={16}
                    text={`Далее вы узнаете, что такое нормальная кондиция, и о том, что она - отображение отличного состояния здоровья собаки. А также мы расскажем вам про упитанность и про проблему ожирения и истощения у собак.`}
                />
            </div>
        )
    }
}
