import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import SwipeBlock from '../../../Components/LessonAndTestComponents/SwipeBlock'
import img1 from '../../../image/lessons/lesson1/step6-3.png';
import Slider from "react-slick";


export default class step6 extends React.Component {
    state = {
        slideShow: 0
    }

    changeSlide = (next) => {
        if (next === 9) {
            this.props.showNextBtn();
        }
        this.setState({
            slideShow: next
        })
    }

    nextSlide = (number) => {
        return number
    }

    slider = {
        1: {
            head: `1. Шерстный покров.`,
            text: `Шерсть должна быть чистой и густой, соответствовать предусмотренному стандарту. 

                У гладкошерстных пород недопустимы излишне мягкая, курчавая или волнистая шерсть, пушистые хвосты и уши, являющиеся косвенными признаками будущей длинношерстности. 
                
                Длинношерстные породы, наоборот, должны быть по возможности «одетыми», часто длинношерстные щенки в детстве имеют шерсть неравномерной длины. 
                
                Щенок не должен неприятно пахнуть и иметь расчесы или прыщики (говорящие о проблемах с кожей или эктопаразитах и энтопаразитах). `
        },
        2: {
            head: `2. Окрас и отметины.`,
            text: `Должны соответствовать у породистой собаки требованиям конкретного стандарта.

                Насыщенные окрасы предпочтительнее, поскольку хорошая пигментация ценится. Кроме того, блеклые окрасы после смены шерсти часто становятся еще более невыразительными.`
        },
        3: {
            head: `3. Хвост.`,
            text: `Длина и форма должны соответствовать стандарту. У всех пород (кроме некоторых) недопустимы залом и сросшиеся позвонки. 

                Для обнаружения рекомендуется обхватить пальцами основание хвоста и провести ими к кончику, ощупывая позвонки. Делать это следует с большой тщательностью.
                
                В сомнительных случаях иногда следует предложить сделать щенку рентгеновский снимок. Важное значение имеет постав хвоста - он должен соответствовать тому, что описано в стандарте породы. Например, высокий постав хвоста характерен многим терьерам и лайкам, а низкий постав - овчаркам.`
        },
        4: {
            head: `4. Грудь.`,
            text: `Мелкая, не доходящая до локтей грудь редко становится лучше с возрастом. Ширина груди должна соответствовать ширине постава передних лап.

                Узкогрудые щенки редко набирают необходимую мощь и ширину с возрастом. Одновременно с шириной груди обращают внимание на локти, которые у большинства пород должны быть хорошо прижаты к грудной клетке собаки, находиться в параллельных плоскостях, и не должны быть "свободными".`
        },
        5: {
            head: `5. Форма.`,
            text: `Линия верха, длина поясницы и глубина паха должны соответствовать стандарту породы и находиться в гармонии друг с другом. 

                Нарушение пропорций между этими параметрами свидетельствует об анатомических дефектах наследственного характера. В процессе неравномерного роста пропорции могут меняться, но 6-ти недельный щенок должен обладать цельным гармоничным строением.`
        },
        6: {
            head: `6. Постав и углы конечностей.`,
            text: `Прямые, без углов, конечности никогда не становятся лучше с возрастом. 

                Недопустимы: коровий и бочкообразный постав, хотя разобраться в конечностях маленького щенка не всегда просто. 

                Передние конечности должны быть прямые, без размета и не чрезмерно косолапые.

                Недопустимы: мягкая пясть, распущенная лапа, искривленные конечности с признаками рахита, смещенные внутрь шишки роста.`
        },
        7: {
            head: `7. Глаза.`,
            text: `У большинства пород, стандарт которых оговаривает темные глаза, пороком являются светло-голубые и зеленые глаза, свидетельствующие, что они будут светлыми и у взрослой собаки.

                Глаза щенка в возрасте 6-8 недель должны быть темно-синие или темно-карие, тогда в последующем они будут темные.

                Обратите внимание на форму и размер глаз. Какое у них выражение? Это интуитивный, но необходимый момент осмотра.`
        },
        8: {
            head: `8. Уши.`,
            text: `Форма, толщина, длина и положение ушей. Эти признаки имеют большое значение в совокупности с общими пропорциями головы собаки.`
        },
        9: {
            head: `9. Зубы.`,
            text: `Для всех пород недопустим недокус. 

                   Перекус у некоторых пород допустим (например, бульдоги), но имейте в виду - у собак перекус может усугубляться с возрастом, поэтому является вредным признаком для пород, у которых перекус с отходом не предусмотрен стандартом. Также следует помнить, что окончательно прикус у собак формируется только после смены зубов, и хороший прикус у маленького щенка - не гарантия хорошего прикуса у взрослой собаки.`
        },
        10: {
            head: `10. Нос и морда.`,
            text: `Про «сухой и мокрый нос» - мокрый и холодный нос бывает у собаки, когда она активна. Теплый и сухой нос у отдыхающей собаки не является признаком заболевания.

                Обращаем внимание на пигментацию мочки носа, форму и ширину ноздрей. Глубина морды и форма губ должны быть в соответствии с требованиями стандарта.

                У щенков всех без исключения пород, включая бульдогов, передние зубы должны быть прикрыты сомкнутыми губами и не видны при закрытой пасти.`
        },
    }

    render() {
        let settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            beforeChange: (current, next) => {
                this.changeSlide(next)
            },
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        arrows: false,
                        dots: true
                    }
                },
            ]
        };
        return (
            <div className="step6">
                <InfoBlock text={`Нажмите на ключевые элементы, чтобы больше узнать о них.`}/>
                <SwipeBlock text={`Ознакомьтесь с каждой группой нажав на ключевые элементы.<br>Используйте свайп для перемещения между элементами.`}/>

                <div className="flex">
                    <div className="slider">
                        <Slider ref={slickSlider => (this.slickSlider = slickSlider)} {...settings} >
                            {Object.values(this.slider).map(item => {
                                return (
                                    <div
                                        key={item.text}
                                        className="slider_el"
                                    >

                                        <div className="head">{item.head}</div>
                                        <TextBlock text={item.text} fontSize={16}/>
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                    <div className="img">
                        {Object.keys(this.slider).map(item => {
                            return (
                                <div
                                    key={item}
                                    className={"dots " + (item - 1 === this.state.slideShow && 'active')}
                                    onClick={() => this.slickSlider.slickGoTo(item - 1)}
                                >
                                    {item}
                                </div>
                            )
                        })}
                        <img src={img1} alt=""/>
                    </div>
                </div>
            </div>
        )
    }

}
