import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson36/step15-1.png'
import img1xs from '../../../image/lessons/lesson36/step15-1xs.png'


export default class step15 extends React.Component {


    render() {
        return (
            <div className="step15">
                <TextBlock
                    text={`Вы видите пирамиду классов готовых кормов. Различают <b>5 классов</b> кормов:`}
                />
                <div className="flex">
                    <div className="flex_el flex_el--economy">
                        эконом
                    </div>
                    <div className="flex_el flex_el--super">
                        мейн-стрим
                    </div>
                    <div className="flex_el flex_el--premium">
                        премиум
                    </div>
                    <div className="flex_el flex_el--super">
                        супер премиум
                    </div>
                    <div className="flex_el flex_el--super">
                        ультра премиум
                    </div>
                </div>
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img src={img1} alt=""/>
                </picture>
            </div>
        )
    }
}
