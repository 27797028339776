import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson4/step22-1.png'
import img2 from '../../../image/lessons/lesson4/step22-2.png'
import PopupInStep from "../../../Components/LessonAndTestComponents/PopupInStep";


export default class step6 extends React.Component {
    state = {
        pointActive: 0,
        points: [],
    };

    changePoint = pointActive => {
        this.setState({pointActive}, () => {
            this.checkPoint(pointActive)
        })
    }

    checkPoint = (point) => {
        const {points} = this.state
        if (points.indexOf(point) === -1) {
            points.push(point)
        }
        console.log(points)
        if (points.length === 5) {
            this.props.showNextBtn()
        }
    }

    popapWrp = {
        1: {
            head: 'Для чего выводились?',
            text: `Охотники. Самые аристократические, самые «кровные», самые почитавшиеся в прошлом охотничьи собаки, элита, «голубая кровь» охотничьего собаководства.

Это – самые быстрые собаки на Земле, что отражено в английском названии этих собак: «windhounds» - «собаки ветра». Породы этой группы предназначены для поимки быстрого зверя, это действительно дети простора, ветра и бешеной гонки.

Некоторые борзые настолько азартны, что в погоне за добычей могут разбиться насмерть об оказавшееся на пути дерево.

С борзыми можно заниматься очень красивым и азартным видом спорта – собачьими бегами (преследование искусственной приманки, имитирующей зайца, по специальной трассе).`
        },
        2: {
            head: 'Характер',
            text: `Своенравные, с большим чувством собственного достоинства, но вместе с тем необычайно интеллигентные и импозантные собаки. Не сторожа.

Требуют продолжительных прогулок и внимания к физической кондиции. Требуют мягкого и тактичного воспитания.`
        },
        3: {
            head: 'Особенности внешности',
            text: `Размер – от малого до гигантского. В группе есть породы со всеми типами шерстного покрова.`
        },
        4: {
            head: 'Типичные представители',
            text: `Очень успешные шоу-собаки, русская псовая борзая является официальном символом отечественной кинологии и ее изображение служит эмблемой Российской кинологической федерации (РКФ) – самой крупной кинологической организации в России, представляющей нашу страну в Международной кинологической федерации (FCI).`
        },
    }


    render() {
        const {pointActive, points} = this.state;
        return (
            <div className="step18">
                <TextBlock
                    text={`<span class="text_subheader">Борзые</span>`}
                />
                <InfoBlock
                    text={`Ознакомьтесь с каждой группой, нажав на ключевые элементы.`}
                />
                <div className="flex">
                    <img src={img1} alt="" className='mainImg'/>
                    <div className="flex_el"><span className={points.indexOf(1) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(1)}}>Для чего выводились?</span></div>
                    <div className="flex_el"><span className={points.indexOf(2) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(2)}}>Характер</span></div>
                    <div className="flex_el"><span className={points.indexOf(3) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(3)}}>Особенности внешности</span></div>
                    <div className="flex_el"><span className={points.indexOf(4) >= 0 ? 'active' : ''} onClick={() => {this.changePoint(4)}}>Типичные представители</span></div>
                </div>

                {pointActive !== 0 &&
                <PopupInStep
                    closePopup={() => this.changePoint(0)}
                    popupImg={img2}
                    popupHead={this.popapWrp[pointActive].head}
                    popupText={this.popapWrp[pointActive].text}
                    changePoint={() => this.changePoint(pointActive < 4 ? pointActive + 1 : 0)}
                />
                }
            </div>
        )
    }
}
