import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson40/step3-1.png';
import img2 from '../../../image/lessons/lesson40/step3-2.png';


export default class step6 extends React.Component {

    render() {
        return (
            <div className="step23">
                <TextBlock
                    text={`<b>Собаки подвержены следующим заболеваниям ротовой полости</b>`}
                />
                <ul>
                    <li>Болезни пародонта (пародонтопатии)</li>
                    <li>Врожденные патологии</li>
                    <li>Болезни пульпы (пульпит)</li>
                    <li>Заболевания, вызванные травмой</li>
                    <li>Деструктивные поражения зубов</li>
                    <li>Новообразования ротовой полости</li>
                </ul>
                <div className="statistics">
                    <div className="statistics_text">
                        У 4 из 5 собак в возрасте старше 3 лет наблюдаются признаки заболеваний зубов и десен
                    </div>
                    <div className="statistics_dogs">
                        <img src={img1} alt="" className="statistics_img"/>
                        <img src={img1} alt="" className="statistics_img"/>
                        <img src={img1} alt="" className="statistics_img"/>
                        <img src={img1} alt="" className="statistics_img"/>
                        <img src={img2} alt="" className="statistics_img"/>
                    </div>
                </div>
            </div>
        )
    }
}
