import React from 'react'
import InfoBlock from "../../../Components/LessonAndTestComponents/InfoBlock";
import VideoBlock from "../../../Components/LessonAndTestComponents/VideoBlock";

export default class step5 extends React.Component {
    state = {
    };


    render() {
        return (
            <div className="step5">
                <InfoBlock text={`Ознакомьтесь с содержанием видео ролика.`}/>
                <VideoBlock
                    src={'https://www.youtube.com/embed/K5azq2lt-9U'}
                    title={'https://www.youtube.com/watch?v=K5azq2lt-9U'}
                />
            </div>
        )
    }
}
