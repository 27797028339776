import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import img1 from '../../../image/lessons/lesson5/step28-1.png'
import img2 from '../../../image/lessons/lesson5/step28-2.png'
import img3 from '../../../image/lessons/lesson5/step28-3.png'
import img4 from '../../../image/lessons/lesson5/step28-4.png'


export default class step6 extends React.Component {


    render() {
        return (
            <div className="step28">
                <TextBlock
                    text={`Использование лакомств важно для укрепления связи между животным и его владельцем, а также для решения таких функциональных задач как, например, чистка зубов.

Для сохранения здоровья питомца нужно ответственно подходить к выбору угощений. Перебор лакомств может привести к проблемам с весом. Существуют специализированные лакомства для животных, которые положительно влияют на здоровье. `}
                />

                <div className="flex">
                    <div className="flex_el red">
                        <img src={img1} alt="" className="icon"/>
                        <img src={img3} alt="" className="text"/>
                    </div>
                    {/*<div className="flex_el ">*/}
                    {/*    <img src={img2} alt="" className="icon"/>*/}
                    {/*    <img src={img4} alt="" className="text"/>*/}
                    {/*</div>*/}
                </div>
            </div>
        )
    }
}