import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson5/step41-1.png'
import img2 from '../../../image/lessons/lesson5/step41-2.png'


export default class step6 extends React.Component {


    render() {
        return (
            <div className="step41">
                <TextBlock text={`Маленькие породы собак подвержены риску развития заболевания мочевыделительных путей, так как их моча является концентрированной. Чтобы снизить уровень риска, необходимо соблюдать водный баланс.`}/>
                <div className="flex">
                    <img src={img1} alt="" className="left"/>
                    <div className="right">
                        <TextBlock text={`Чем больше объем мочи, тем ниже ее концентрация.

                        Соответственно, чем больше воды собака употребляет, тем больше объем мочи и тем ниже ее концентрация.`}/>
                        <img src={img2} alt="" className='img'/>
                    </div>
                </div>
            </div>
        )
    }
}