import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import img1 from '../../../image/lessons/lesson3/step11-1.png'
import img2 from '../../../image/lessons/lesson3/step11-2.png'


export default class step11 extends React.Component {

    render() {
        return (
            <div className="step11">
                <TextBlock
                    fontSize={16}
                    text={`Уборка – это механическое и/или химическое воздействие, позволяющее удалить органическое вещество (грязь, остатки корма, фекалии, рвоту и так далее).`}
                />

                <div className="img">
                    <div className="blueBlock">УБОРКА</div>
                    <div className="circle">
                        <div className="circle_el">
                            <img src={img1} alt=""/>
                            Сухая уборка
                        </div>
                        <div className="circle_el">
                            <img src={img2} alt=""/>
                            Влажная уборка
                        </div>
                    </div>
                </div>

                <div className="flex">
                    <div className="flex_el">
                        Уборка не приставших к поверхности органических веществ (фекалии, стружка, остатки корма, пыль).
                    </div>
                    <div className="flex_el">
                        Уборка приставших к поверхности загрязнений, биопленка и т.д.
                    </div>
                </div>

                <div className="ul">
                    <div className="liHead">Пять компонентов уборки</div>
                    <div className="li">
                        <div className="number">1</div>
                        <div className="text">
                            <span className="textBlue"><b>Моющее средство:</b></span> позволяет отделить и растворить органическое вещество, прилипшее к поверхности, в частности биопленку.
                        </div>
                    </div>
                    <div className="li">
                        <div className="number">2</div>
                        <div className="text">
                            <span className="textBlue"><b>Горячая вода:</b></span> для большей эффективности моющего средства.
                        </div>
                    </div>
                    <div className="li">
                        <div className="number">3</div>
                        <div className="text">
                            <span className="textBlue">Инструмент для уборки:</span> для удаления объемных загрязнений (метла, лопата)
                            и для устранения приставшей грязи (щетка).
                        </div>
                    </div>
                    <div className="li">
                        <div className="number">4</div>
                        <div className="text">
                            <span className="textBlue"><b>Механическое воздействие:</b></span> позволяет отскрести наиболее стойкие загрязнения
                            и уничтожить биопленку.
                        </div>
                    </div>
                    <div className="li">
                        <div className="number">5</div>
                        <div className="text">
                            <span className="textBlue"><b>Ополаскивание:</b></span> по окончании уборки необходимо обязательно смыть следы загрязнений и остатки моющего средства путем обильного ополаскивания очищаемых поверхностей чистой водой!  Если промывание выполняется недостаточно тщательно, возникает опасность химической реакции между моющим и дезинфицирующим средством – последнее может утратить активность.
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}