import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson39/step2-1.png'
import img2 from '../../../image/lessons/lesson39/step2-2.png'
import img3 from '../../../image/lessons/lesson39/step2-3.png'
import img4 from '../../../image/lessons/lesson39/step2-4.png'


export default class step2 extends React.Component {
    state = {
    };

    render() {
        return (
            <div className="step2">
                <TextBlock
                    text={`Различают <b>четыре основные</b> функциональные потребности животных.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <h3 className="flex_el_head">
                            Здоровое пищеварение
                        </h3>
                        <div className="flex_el_img-box">
                            <img className="flex_el_img" src={img1} alt=""/>
                        </div>
                        <p className="flex_el_text">
                            Переваривание и усвоение пищи, отсутствие повышенного газообразования и проблем со стулом.
                        </p>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_el_head">
                            Здоровье кожи и шерсти
                        </h3>
                        <div className="flex_el_img-box">
                            <img className="flex_el_img" src={img2} alt=""/>
                        </div>
                        <p className="flex_el_text">
                            Здоровый блеск и гладкость шерсти, упругость кожи, отсутствие раздражений и перхоти.
                        </p>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_el_head">
                            Хороший иммунитет
                        </h3>
                        <div className="flex_el_img-box">
                            <img className="flex_el_img" src={img3} alt=""/>
                        </div>
                        <p className="flex_el_text">
                            Стойкость к негативным факторам окружающей среды, защита от болезней.
                        </p>
                    </div>
                    <div className="flex_el">
                        <h3 className="flex_el_head">
                            Здоровые зубы
                        </h3>
                        <div className="flex_el_img-box">
                            <img className="flex_el_img" src={img4} alt=""/>
                        </div>
                        <p className="flex_el_text">
                            Отсутствие зубного камня и налета, здоровые зубы и десны.
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
