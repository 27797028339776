import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson35/step3-1.png';


export default class step3 extends React.Component {
    state = {
    };

    render() {
        return (
            <div className="step3">
                <TextBlock
                    text={`На задней стороне упаковки расположена более детальная информация о продукте.`}
                />
                <div className="flex">
                    <div className="flex_el left">
                        <div className="flex_el_item">
                            Описание продукта
                            <span className="flex_el_number">1</span>
                        </div>
                        <div className="flex_el_item">
                            Ключевые преимущества продукта
                            <span className="flex_el_number">2</span>
                        </div>
                        <div className="flex_el_item">
                            Расчет рекомендованной порции
                            <span className="flex_el_number">3</span>
                        </div>
                        <div className="flex_el_item">
                            Функциональные особенности корма
                            <span className="flex_el_number">4</span>
                        </div>
                        <div className="flex_el_item">
                            Сырьевой состав
                            <span className="flex_el_number">5</span>
                        </div>
                        <div className="flex_el_item">
                            Питательный состав
                            <span className="flex_el_number">6</span>
                        </div>
                        <div className="flex_el_item">
                            Энергетическая ценность продукта
                            <span className="flex_el_number">7</span>
                        </div>
                        <div className="flex_el_item">
                            Производитель и место изготовления
                            <span className="flex_el_number">8</span>
                        </div>
                    </div>
                    <div className="flex_el right">
                        <img className="img" src={img1} alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}
