import React from 'react'

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step1">
                <div className="head">план урока</div>

                <div className="text">
                    Навык хождения в наморднике очень важен для городской собаки, поэтому старайтесь приучить собаку к наморднику с раннего возраста. Намордник должен быть подобран таким образом, чтобы собака могла в нем свободно дышать, имея возможность высунуть язык.
                </div>

                <div className="lessonSteps">
                    <div className="lessonSteps_el">
                        1 этап. Лакомство в наморднике
                    </div>
                    <div className="lessonSteps_el">
                        2 этап. Увеличение времени нахождения в наморднике
                    </div>
                    <div className="lessonSteps_el">
                        3 этап. Застегивание намордника на собаке
                    </div>
                    <div className="lessonSteps_el">
                        Ошибки приучения к наморднику
                    </div>
                </div>

            </div>
        )
    }
}
