import React from 'react'

import LessonPlan from "../../Components/LessonAndTestComponents/LessonPlan";
import MainHeader from "../../Components/LessonAndTestComponents/MainHeader";
import TextBlock from "../../Components/LessonAndTestComponents/TextBlock";
import InfoBlock from "../../Components/LessonAndTestComponents/InfoBlock";
import AnswersBlock from "../../Components/LessonAndTestComponents/AnswersBlock";
import AnswersDnd from "../../Components/LessonAndTestComponents/AnswersDnd";
import QuestionCorrect from "../../Components/LessonAndTestComponents/QuestionCorrect";
import MainButton from "../../Components/LessonAndTestComponents/MainButton";
import PopapAfterTest from './popapAfterTest'

import lessImg from '../../image/lessons/lessImg.png';

import Api from '../../Service/Api'


export default class Test extends React.Component {
    Api = new Api();

    state = {
        MainHeader_header: 'Тест: урок 2', // Первая линия заголовка шага
        MainHeader_subheader: 'Как выбрать щенка', // Вторая линия заголовка шага
        MainHeader_class: 'bigHeader noBB', // Большой или маленький Заголовок
        typeMainBtn: 'check answer', // тип кнопок (take a lesson - Пройти урок, '' - стандартно медлу щагами)
        questionName: '', // Название вопроса
        questionCount: '', // Количество вопросов
        answerCorrect: 0, // Ответов верных
        count: 1, //Номер вопроса
        countNextQuestion: 1, //Номер следующего вопроса для тестов внутри шагов
        qId: '',
        question: '',
        answers: {},
        compareAnswer: {},
        showQuestionCorrect: false,
        questionCorrect: '',
        headCorrect: '',
        textCorrect: '',
        answerActive: [],
        nextQuestionName: '',
        nextQuestion: '',
        nextAnswers: '',
        nextTextCorrect: '',
        nextqId: '',
        showPopap: false,
        disabled: false,
        multi_answer: '',
        arrayStepsPlan: [], //массив шагов плана урока (lessonStepsActive)
    }

    // componentWillMount() {
    //     let array = sessionStorage.getItem('arrayStepsPlan').split("").filter(function(f) { return f !== ',' })
    //     let arrayStepsPlan = []
    //     array.map(el => {arrayStepsPlan.push(+el)})
    //     this.setState({arrayStepsPlan: arrayStepsPlan})
    // }

    componentDidMount() {
        let lesson = this.props.location !== undefined ? this.props.location.state : this.props.lesson
        this.setState({
            MainHeader_header: lesson.MainHeader_header,
            MainHeader_subheader: lesson.MainHeader_subheader
        })
        if(this.props.stepId){
            this.Api.getTestStepForLessonStep(lesson.attributes.id, this.props.stepId)
                .then((res) => {
                    if (res.status === 200) {
                        this.setState({
                            qId: res.question.attributes.id,
                            questionName: res.question.attributes.title,
                            question: res.question.attributes.description,
                            questionCount: res.totalSteps,
                            answers: res.question.answers,
                            textCorrect: res.question.attributes.caption,
                            answerCorrect: res.totalCorrect,
                            count: parseInt(res.question.attributes.title.match(/\d+/), 10)
                        })
                    }
                })
        }else{
            this.Api.startTest(lesson.attributes.id)
                .then((res) => {
                    if (res.status === 200) {
                        this.setState({
                            qId: res.question.attributes.id,
                            questionName: res.question.attributes.title,
                            question: res.question.attributes.description,
                            questionCount: res.totalSteps,
                            answers: res.question.answers,
                            textCorrect: res.question.attributes.caption,
                            multi_answer: res.question.attributes.multi_answer
                        })
                    }
                })
        }
    }

    checkAnswer = () => {
        const {qId, answerActive} = this.state
        this.setState({disabled: true})
        let lesson = this.props.location !== undefined ? this.props.location.state : this.props.lesson;
        let answers = [];
        // eslint-disable-next-line
        answerActive.map(item => {
            answers.push(item)
        })
        if(answers.length) {
            if (this.props.stepId) {
                answers = answers.sort();
                //Тест внутри шага
                this.Api.getTestStepForLessonStepCheck(lesson.attributes.id, this.props.stepId, qId, answers)
                    .then((res) => {
                        if (res.status === 200) {
                            this.setState({disabled: false})
                            if (res.question) {
                                this.setState({
                                    compareAnswer: res.compare.answers,
                                    questionCorrect: res.compare.correct === 1 ? 'correct' : 'inCorrect',
                                    typeMainBtn: 'next question',
                                    showQuestionCorrect: true,
                                    headCorrect: res.compare.correct === 1 ? 'все верно!' : 'К сожалению, ваш ответ неверен.',
                                    nextQuestionName: res.question.attributes.title,
                                    nextQuestion: res.question.attributes.description,
                                    nextAnswers: res.question.answers,
                                    nextqId: res.question.attributes.id,
                                    answerCorrect: res.totalCorrect,
                                    nextTextCorrect: res.question.attributes.caption,
                                    countNextQuestion: res.question.attributes.title
                                })
                                console.log(111, res.totalCorrect)
                            } else {
                                this.setState({
                                    compareAnswer: res.compare.answers,
                                    questionCorrect: res.compare.correct === 1 ? 'correct' : 'inCorrect',
                                    typeMainBtn: 'continue lesson',
                                    showQuestionCorrect: true,
                                    headCorrect: res.compare.correct === 1 ? 'все верно!' : 'К сожалению, ваш ответ неверен.',
                                    answerCorrect: res.totalCorrect,
                                    // textCorrect: res.question.attributes.caption,

                                })
                                console.log(222, res.totalCorrect)
                            }

                        }
                    })
            } else {
                //Тест после урока
                this.Api.checkAnswer(lesson.attributes.id, qId, answers)
                    .then((res) => {
                        if (res.status === 200) {
                            this.setState({disabled: false})
                            if (res.question) {
                                this.setState({
                                    compareAnswer: res.compare.answers,
                                    questionCorrect: res.compare.correct === 1 ? 'correct' : 'inCorrect',
                                    typeMainBtn: 'next question',
                                    showQuestionCorrect: true,
                                    headCorrect: res.compare.correct === 1 ? 'все верно!' : 'К сожалению, ваш ответ неверен.',
                                    nextQuestionName: res.question.attributes.title,
                                    nextQuestion: res.question.attributes.description,
                                    nextAnswers: res.question.answers,
                                    nextqId: res.question.attributes.id,
                                    answerCorrect: this.state.answerCorrect + res.compare.correct,
                                    nextTextCorrect: res.question.attributes.caption,
                                })
                            } else {
                                this.setState({
                                    compareAnswer: res.compare.answers,
                                    questionCorrect: res.compare.correct === 1 ? 'correct' : 'inCorrect',
                                    typeMainBtn: 'end test',
                                    showQuestionCorrect: true,
                                    headCorrect: res.compare.correct === 1 ? 'все верно!' : 'К сожалению, ваш ответ неверен.',
                                    answerCorrect: this.state.answerCorrect + res.compare.correct,
                                    // textCorrect: res.question.attributes.caption,

                                })
                            }

                        }
                    })
            }
        }else{
            this.setState({disabled: false})
        }

    }

    nextQuestion = () => {
        this.setState({disabled: true})
        const {nextQuestionName, nextAnswers, nextQuestion, nextqId, nextTextCorrect} = this.state
        if (nextQuestionName === '' && nextAnswers === '' && nextQuestion === '' && nextqId === '' && nextTextCorrect === '') {
            this.setState({
                showPopap: true
            })
        } else {
            this.setState({
                compareAnswer: {},
                questionCorrect: '',
                typeMainBtn: 'check answer',
                showQuestionCorrect: false,
                headCorrect: '',
                questionName: nextQuestionName,
                question: nextQuestion,
                answers: nextAnswers,
                qId: nextqId,
                textCorrect: nextTextCorrect,
                nextQuestionName: '',
                nextQuestion: '',
                nextAnswers: '',
                nextqId: '',
                nextTextCorrect: '',
                answerActive: [],
                count: this.props.stepId ? parseInt(this.state.countNextQuestion.match(/\d+/), 10) : this.state.count + 1,
                disabled: false
            })
        }
    }


    setAnswer = id => {
        const {answerActive} = this.state;
        answerActive.indexOf(id) > -1 ?
            delete answerActive[answerActive.indexOf(id)]
            :
            answerActive.push(id)
        this.setState({answerActive})
    }

    sendAnswer = answerActive => {
        this.setState({answerActive})
    }

    render() {
        const {
            MainHeader_header,
            MainHeader_subheader,
            MainHeader_class,
            answerCorrect,
            questionCount,
            questionName,
            count,
            question,
            answers,
            compareAnswer,
            typeMainBtn,
            questionCorrect,
            headCorrect,
            textCorrect,
            showQuestionCorrect,
            showPopap,
            disabled,
            multi_answer
        } = this.state;
        let lesson = this.props.location !== undefined ? this.props.location.state : this.props.lesson
        return (
            <div>
                <div className={"content_test " + (this.props.stepId && 'content_test_step')}>
                    <div className="test">
                        {/* Основной заголовок шага */}
                        <MainHeader
                            header={MainHeader_header}
                            subheader={MainHeader_subheader}
                            headerClass={MainHeader_class}
                            questionName={questionName}
                            count={count}
                            answerCorrect={answerCorrect}
                            questionCount={questionCount}

                        />
                        <InfoBlock
                            text={`Выберите вариант ответа и нажмите на него`}
                            hidden={showQuestionCorrect ? ' hidden' : ''}
                        />
                        <MainHeader
                            headerClass="MainHeader_mobile"
                            questionName={questionName}
                            count={count}
                            answerCorrect={answerCorrect}
                            questionCount={questionCount}
                            testHeadClass="testHeadMobile"

                        />
                        {/* Текст вопроса */}
                        <TextBlock text={question}/>
                        {/* Варианты ответов */}
                        {multi_answer === 2
                            ?
                            <AnswersDnd answers={answers} compareAnswers={compareAnswer} setAnswer={this.setAnswer} sendAnswer={this.sendAnswer}/>
                            :
                            <AnswersBlock answers={answers} compareAnswers={compareAnswer} setAnswer={this.setAnswer}/>}
                        {/*<AnswersBlock answers={answers} compareAnswers={compareAnswer} setAnswer={this.setAnswer}/>*/}
                        {/* Верный или нет ответ */}
                        {showQuestionCorrect &&
                        <QuestionCorrect
                            type={questionCorrect} // correct - верный, inCorrect - не выерный
                            head={headCorrect} // Заголовок блока
                            text={textCorrect} // Текст блока
                        />
                        }

                        <MainButton
                            type={typeMainBtn}
                            disabled={disabled}
                            checkAnswer={this.checkAnswer}
                            nextQuestion={this.nextQuestion}
                            showPrevBtn={this.props.stepId !== undefined}
                            showNextStep={this.props.showNextStep}
                            showPrevStep={this.props.showPrevStep}

                        />
                    </div>
                </div>
                {/* Вывод Оглавление урока */}
                <LessonPlan
                    lessonNumeral={this.props.location !== undefined ? parseInt(lesson.MainHeader_header.match(/\d+/), 10) : parseInt(lesson.attributes.name.match(/\d+/), 10)} /*{parseInt(lesson.MainHeader_header.match(/\d+/), 10)}*/
                    lessonImg={lessImg} // Картинка урока
                    lessonName={MainHeader_subheader} // Название урока
                    lessonSteps={lesson.plan !== undefined ? lesson.plan : lesson.lessonPlan} // Шаги урока
                    lessonStepsActive={lesson.plan !== undefined ? lesson.plan.length - 1 : lesson.lessonPlan.length - 1} // Активный шаг урока
                    onClick={this.setStateBtnSteps}
                    arrayStepsPlan={this.state.arrayStepsPlan}
                />
                {showPopap &&
                <PopapAfterTest
                    history={this.props.history}
                />
                }
            </div>


        )
    }
}
