import React from 'react'
import './style.sass'


export default class Cookies extends React.Component{
    render() {
        return (
            <div className="Cookies">
                <div className="Cookies__container">
                <div className="Cookies_text">

                    Мы используем файлы cookie для персонализации контента и рекламы, предоставления функций социальных сетей и анализа нашего трафика. Вы можете использовать настройки файлов cookie, чтобы изменить свои предпочтения. <br/>
                    <a href="/cookies.pdf" className="Cookies_link" target="_blank" rel="noopener noreferrer">Узнайте больше о нашей политике в отношении файлов cookie</a>
                </div>
                <a className="Cookies_links" href="#">
                    Настройки
                    файлов cookie
                </a>
                <div className="Cookies_buttons">
                    <div className="Cookies_box">
                        <button
                            type="button"
                            className="btn btn_cookies-white"
                            onClick={this.props.cookiesYes}
                        >
                            Принять
                        </button>
                        <button
                            type="button"
                            className="btn btn_cookies-trans"
                            onClick={this.props.closeCookiesPopup}
                        >
                            Отклонить
                        </button>
                    </div>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={this.props.closeCookiesPopup}
                    />
                </div>
                </div>
            </div>
        )
    }
}
