import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

import img1 from '../../../image/lessons/lesson39/step6-1.png';
import img1xs from '../../../image/lessons/lesson39/step6-1xs.png';


export default class step6 extends React.Component {

    render() {
        return (
            <div className="step6">
                <TextBlock
                    text={`Хороший иммунитет нужен животным для борьбы с инфекционными заболеваниями. Изучите, какие <b>факторы ослабляют иммунитет,</b> и что нужно организму для борьбы с этим.`}
                />
                <picture>
                    <source srcSet={img1xs} media="(max-width: 767px)"/>
                    <img className="img" src={img1} alt=""/>
                </picture>
                <TextBlock
                    text={`На рисунке показаны факторы, негативно влияющие на клетки.
                    
                    В результате воздействия этих факторов образуются <b>свободные радикалы,</b> разрушающие клетку. Для защиты клетки необходимы <b>антиоксиданты.</b>`}
                />
            </div>
        )
    }
}
