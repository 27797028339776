import React from 'react'
import './style.sass'


export default class Expert extends React.Component{
    render() {
        return (
            <div className="Expert">
                {this.props.preview &&
                <div className="Expert_text">
                    {this.props.preview}
                </div>
                }
                <button className="btn btn_blue" onClick={() => {this.props.showPopupExpert()}}>
                    <span className={'Expert_button_text'}>{this.props.expertName}</span>
                </button>
                <div className="Expert_info">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.8435 0.000856297C5.21788 0.0791116 -0.077399 5.53089 0.000856297 12.1565C0.0791116 18.7821 5.5309 24.0774 12.1565 23.9991C18.7821 23.9209 24.0774 18.4691 23.9991 11.8435C23.9209 5.21787 18.4691 -0.077399 11.8435 0.000856297ZM13.1738 3.99187C14.3998 3.99187 14.765 4.69617 14.765 5.50481C14.765 6.52213 13.9564 7.46119 12.5739 7.46119C11.4261 7.46119 10.8783 6.88732 10.9044 5.92217C10.8783 5.11353 11.5566 3.99187 13.1738 3.99187ZM10.0436 19.5125C9.20889 19.5125 8.60894 19.0169 9.18281 16.7736L10.148 12.8347C10.3045 12.2087 10.3306 11.9478 10.148 11.9478C9.88711 11.9478 8.81762 12.3913 8.16549 12.8086L7.74813 12.1304C9.78277 10.4349 12.1043 9.44366 13.0956 9.44366C13.9303 9.44366 14.0607 10.4349 13.6434 11.9478L12.5478 16.0954C12.3652 16.8257 12.4434 17.0866 12.626 17.0866C12.8869 17.0866 13.6955 16.7736 14.5042 16.1475L14.9737 16.7736C13.0173 18.7299 10.8783 19.5125 10.0436 19.5125Z" fill="#99BFDE"/>
                    </svg>
                    <span className="Expert_info_text">{this.props.expertInfo}</span>
                </div>
            </div>
        )
    }
}
