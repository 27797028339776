import React from 'react'
import StartPage from '../Page/Entrance/StartPage'
import Admin from '../Page/Entrance/Admin'
import Owner from '../Page/Entrance/Registration'
import Auth from '../Page/Entrance/Auth'
import Forgot from '../Page/Entrance/Forgot'
import Dostupnost from '../Page/dostupnost'
import CoocieForm from '../Page/CoocieForm'
// import ResetPassword from '../Page/Entrance/ResetPassword'
import { Switch, Route } from 'react-router-dom'


export default function Entrance(props) {
    return(
        <entrance>
            <Switch>
                <Route exact path='/startPage' component={StartPage}/>
                <Route exact path='/startPage/dostupnost' component={Dostupnost}/>
                <Route exact path='/startPage/coociesform' component={CoocieForm}/>
                <Route exact path='/startPage/admin' children={<Admin changeType={props.changeType}/>}/>
                <Route exact path='/startPage/registration' children={<Owner changeType={props.changeType}/>}/>
                <Route exact path='/startPage/auth' children={<Auth changeType={props.changeType}/>}/>
                <Route exact path='/startPage/forgot' children={<Forgot changeType={props.changeType}/>}/>
                {/*<Route exact path='/startPage/reset-password' children={<ResetPassword changeType={props.changeType}/>}/>*/}
            </Switch>
        </entrance>
    )
}
