import React from 'react'
import './style.sass'
import img1 from "../../image/CertificateModal-1.png";

const CertificateModal = () => {

    const saveShow = () => {
        localStorage.setItem('CERTMODAL', 'true');
        document.location.href = "/certificate";
    }

    return (
        <div className="certificate-modal" onClick={() => {saveShow()}}>
            <div className="certificate-modal__content">
                <div
                    className="modal_closed"
                    onClick={() => {this.props.closePopap()}}
                />
                <div className="certificate-modal__content__container">
                    <div className="certificate-modal__content__title">
                        Поздравляем!
                    </div>
                    <div className="certificate-modal__content__desc">
                        Вы набрали 80% <br/>
                        правильных ответов
                    </div>
                    <div className="certificate-modal__content__info">
                        и прекрасно овладели материалом курса. С&nbsp;радостью вручаем вам сертификат!
                    </div>
                    <div className="certificate-modal__content__preview">
                        <img src={img1} alt=""/>
                    </div>
                    <button onClick={() => saveShow()} className="certificate-modal__content__btn">
                        СКАЧАТЬ СЕРТИФИКАТ
                    </button>
                </div>
            </div>
        </div>
    )
}
export default CertificateModal