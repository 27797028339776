import React from 'react'
import './style.sass'
import Slider from "react-slick";
import SwipeBlock from '../SwipeBlock';
import List from "../List";

export default class PopupInStep extends React.Component {

    componentDidMount() {
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
    }

    componentWillUnmount() {
        document.body.style.overflow = "";
    }

    stopClosePopup = (e) => {
        e.stopPropagation()
    }

    render() {
        let settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        dots: true,
                        arrows: false,
                    }
                },
            ]
        };
        return (
            <div className="PopupInStep" onClick={() => {this.props.closePopup()}}>
                <div className="popup" onClick={(e) => {this.stopClosePopup(e)}}>
                    <div
                        className="popup_closed"
                        onClick={() => {this.props.closePopup()}}
                    />
                    {this.props.popupImg &&
                    <div className="popup_imgHead">
                        <picture>
                            <source media="(max-width: 767px)" srcSet={this.props.popupImgMobile}/>
                            <img className="popup_imgHeadBg" src={this.props.popupImg} alt=""/>
                        </picture>
                    </div>
                    }
                    <div className="popup_content">
                        <div
                            className="popup_head"
                            dangerouslySetInnerHTML={{ __html: this.props.popupHead }}
                        />
                        {this.props.popupSubhead &&
                        <div
                            className="popup_subHead"
                            dangerouslySetInnerHTML={{ __html: this.props.popupSubhead }}
                        />
                        }
                        <div className="popup_text" dangerouslySetInnerHTML={{ __html: this.props.popupText }}/>
                        {this.props.popupImg2 &&
                        <img className="popup_imgContent" src={this.props.popupImg2} alt=""/>
                        }
                        {this.props.popupText2 &&
                        <div className="popup_text popup_text2" dangerouslySetInnerHTML={{ __html: this.props.popupText2 }}/>
                        }
                        {this.props.ListData &&
                        <List ListData={this.props.ListData}/>
                        }
                        {this.props.popupImg3 &&
                        <img className="popup_imgContent" src={this.props.popupImg3} alt=""/>
                        }
                        {this.props.slider &&
                        <Slider {...settings} className={'slider'}>
                            {Object.values(this.props.slider).map((slide, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="slide"
                                        dangerouslySetInnerHTML={{__html: slide}}
                                    />
                                )
                            })}
                        </Slider>
                        }
                        {this.props.slider &&
                            <SwipeBlock
                                text={this.props.swipeText ? this.props.swipeText : 'Используйте свайп для перемещения по шагам.'}
                            />
                        }
                        <button
                            className="btn btn_blue"
                            onClick={() => {this.props.changePoint()}}
                        >
                            {this.props.popupBtnText ? this.props.popupBtnText : 'продолжить'}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
