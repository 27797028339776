import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import Popup from "../../../Components/Popup";

import img1 from '../../../image/lessons/lesson41/step8-1.png';
import img2 from '../../../image/lessons/lesson41/step8-2.png';
import img3 from '../../../image/lessons/lesson41/step8-3.png';


export default class step8 extends React.Component {

    state = {
        windowWidth: window.innerWidth,
        pointActive: 0,
        points: [0],
        showPopup: false,
    };

    //Отлов события ресайз
    handlerResize = () => {
        this.setState({windowWidth: window.innerWidth})
    }

    componentDidMount() {
        window.addEventListener('resize', this.handlerResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handlerResize);
    }


    clickPoint = pointActive => {
        const {points, windowWidth} = this.state
        if (points.indexOf(pointActive) === -1) {
            points.push(pointActive)
        }

        if (windowWidth < 767) {

            if (pointActive > 3) {
                this.setState({
                    pointActive: 0,
                    showPopup: false,
                })
            } else {
                this.setState({
                    pointActive: pointActive,
                    showPopup: true,
                    points: points
                })
            }

        } else {
            this.setState({pointActive, points})
        }

        if (points.length === 4) {
            this.props.showNextBtn();
        }
    }

    closePopup = () => {
        this.setState({showPopup: false})
    }

    infoData = {
        1: {
            img: img1,
            list: [
                `Куриная мука`,
                `Мясная мука`,
                `Жир животный`,
            ],
        },
        2: {
            img: img1,
            list: [
                `Кукуруза`,
                `Пшеница`,
                `Рис`,
                `Подсолнечное масло`,
            ],
        },
        3: {
            img: img1,
            list: [
                `Пивные дрожжи`,
                `Витамины`,
                `Минералы`,
            ],
        },
    }

    render() {
        const {pointActive, showPopup} = this.state
        return (
            <div className="step8">
                <InfoBlock
                    text={`Нажмите на активные элементы, чтобы узнать больше о пищеварении собак`}
                />
                <TextBlock
                    text={`Рационы PEDIGREE® cодержат высокоусвояемые ингредиенты:`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <img src={img1} alt="" className={'flex_el_img ' + (pointActive === 1 && 'active')} onClick={() => {this.clickPoint(1)}}/>
                        <div className={'flex_el_block ' + (pointActive === 1 && 'active')}>
                            <ul>
                                {this.infoData[1].list.map((item, index) => {
                                    return (
                                        <li key={index}>{item}</li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="flex_el">
                        <img src={img2} alt="" className={'flex_el_img ' + (pointActive === 2 && 'active')} onClick={() => {this.clickPoint(2)}}/>
                        <div className={'flex_el_block ' + (pointActive === 2 && 'active')}>
                            <ul>
                                {this.infoData[2].list.map((item, index) => {
                                    return (
                                        <li key={index}>{item}</li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="flex_el">
                        <img src={img3} alt="" className={'flex_el_img ' + (pointActive === 3 && 'active')} onClick={() => {this.clickPoint(3)}}/>
                        <div className={'flex_el_block ' + (pointActive === 3 && 'active')}>
                            <ul>
                                {this.infoData[3].list.map((item, index) => {
                                    return (
                                        <li key={index}>{item}</li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="statistic">
                    <div className="statistic_el left">
                        В рационах PEDIGREE®
                    </div>
                    <div className="statistic_el right">
                        <ul>
                            <li>Усвоение сухого вещества – не ниже 80%</li>
                            <li>Усвоение белка – не ниже 82%</li>
                        </ul>
                    </div>
                </div>
                {showPopup &&
                <Popup
                    closePopup={this.closePopup}
                >
                    <img src={this.infoData[pointActive].img} alt="" className="Popup_img"/>


                    <ul>
                        {this.infoData[pointActive].list.map((item, index) => {
                            return (
                                <li key={index}>{item}</li>
                            )
                        })}
                    </ul>
                    <button
                        className="btn btn_blue"
                        onClick={() => {this.clickPoint(pointActive + 1)}}
                    >
                        продолжить
                    </button>
                </Popup>
                }
            </div>
        )
    }
}
