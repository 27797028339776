import React from 'react'
import './style.sass'
import Element from './element.js'
import Newbie from '../../image/GuruPanel/01-Newbie.png'
import Specialist from '../../image/GuruPanel/02-Specialist.png'
import Professional from '../../image/GuruPanel/03-Professional.png'
import Master from '../../image/GuruPanel/04-Master.png'
import Weisedog from '../../image/GuruPanel/05-Weisedog.png'
import Smarty from '../../image/GuruPanel/06-Smarty.png'
import Guru from '../../image/GuruPanel/07-Guru.png'
import Api from "../../Service/Api";
import Slider from "react-slick";

export default class GuruPanel extends React.Component {
    Api = new Api();

    _renderEl = () => {
        const {user} = this.props;
        let elements = {
            1: {
                img: Newbie,
                name: 'новичок',
            },
            2: {
                img: Specialist,
                name: 'специалист',
            },
            3: {
                img: Professional,
                name: 'профессионал',
            },
            4: {
                img: Master,
                name: 'мастер',
            },
            5: {
                img: Weisedog,
                name: 'знаток',
            },
            6: {
                img: Smarty,
                name: 'мудрец',
            },
            7: {
                img: Guru,
                name: 'гуру',
            }
        }

        return Object.values(elements).map((el, index) => {
            return (
                <Element key={index} active={user !== undefined && user.rank >= index} img={el.img} name={el.name}/>
            )
        })

    }

    render() {
        const {user} = this.props
        let settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 7,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 6,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 420,
                    settings: {
                        slidesToShow: 1,
                        initialSlide: user !== undefined ? parseInt(user.rank + 1) : 1,
                        centerMode: true
                    }
                },
            ]
        };
        return (
            <div className="guru">
                <Slider {...settings}>
                    {this._renderEl()}
                </Slider>
            </div>
        )
    }
}
