import React from 'react'

import img1 from '../../../image/lessons/lesson5/step27-1.png'


export default class step6 extends React.Component {


    render() {
        return (
            <div className="step27">
                <div className="flex">
                    <div className="ul">
                        <div className="li">
                            <div className="number">1</div>
                            <div className="text">
                                Рассчитать <b>суточный объем</b> рациона, <b>количество дней</b>, на которые хватит одной упаковки, а также <b>стоимость</b> одной порции можно с помощью специальных формул.
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">2</div>
                            <div className="text">
                                Чтобы поддерживать здоровый вес питомца, нужно кормить его полноценным и сбалансированным кормом, удовлетворяющим его <b>энергетическую
                                потребность</b>.
                            </div>
                        </div>
                        <div className="li">
                            <div className="number">3</div>
                            <div className="text">
                                Размер порции рассчитывается, исходя из <b>желаемого веса</b> питомца и <b>энергетической
                                ценности корма</b>.
                            </div>
                        </div>
                    </div>
                    <img src={img1} alt=""/>
                </div>
            </div>
        )
    }
}