import React from 'react'
import InfoBlock from '../../../Components/LessonAndTestComponents/InfoBlock';
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';
import ArrowBack from '../../../Components/LessonAndTestComponents/ArrowBack';

import img1 from "../../../image/lessons/lesson7Owner/step2-1.png";
import img1xs from "../../../image/lessons/lesson7Owner/step2-1xs.png";
import img2 from "../../../image/lessons/lesson7Owner/step2-2.png";
import img2xs from "../../../image/lessons/lesson7Owner/step2-2xs.png";
import img3 from "../../../image/lessons/lesson7Owner/step2-3.png";
import img3xs from "../../../image/lessons/lesson7Owner/step2-3xs.png";




export default class step2 extends React.Component {
    state = {
        activeFlex: 0,
        flexs: [],
        flexHidden: false,
    };

    changeFlex = activeFlex => {
        const {flexs} = this.state
        this.setState({activeFlex, flexHidden: true})
        if (flexs.indexOf(activeFlex) === -1) {
            flexs.push(activeFlex)
        }
        if (flexs.length === 3) {
            this.props.showNextBtn();
        }
    }

    closeFlexClick = () => {this.setState({flexHidden: false, activeFlex: 0})}

    click_flex = {
        1: {
            text1: `Человек одомашнил собаку несколько тысяч лет назад. И тысячи лет прекрасно обходился до появления инструкторов и кинологов. `,
            text2: `Любой человек вполне способен выработать у любой психически здоровой собаки базовые навыки послушания (для чего-то «специального» уже может потребоваться инструктор), нужно только поинтересоваться, как это делается, и победить свою лень, <b>заниматься систематически</b>`,
            img: img1,
            imgXs: img1xs,
            header: `Ежедневно и систематически`
        },
        2: {
            text1: ``,
            text2: `Как было написано выше - <b>условные рефлексы у всех собак вырабатываются по общим правилам</b>. 

                    Поэтому инструктор в первую очередь должен быть хорошим специалистом. Если у него есть большой опыт работы с собаками вашей породы - это хорошо, но совершенно необязательно.`,
            img: img2,
            imgXs: img2xs,
            header: `Инструктор для собаки`
        },
        3: {
            text1: `Все собаки учатся достаточно быстро. Буквально после нескольких упражнений по выработке навыка собака прекрасно понимает, что ей предлагается сделать и может это исполнить.`,
            text2: `А может и не исполнить, если будет занятие поинтереснее. Поэтому для закрепления навыка (выработки стабильного послушания) с собакой нужно заниматься систематически и при различных отвлекающих факторах (другие люди, собаки, птицы и т.д.) для того, чтобы собака смогла продемонстрировать нужный навык в любой обстановке.`,
            img: img3,
            imgXs: img3xs,
            header: `Уникальные методики обучения`
        }
    }


    render() {
        const {activeFlex, flexHidden} = this.state
        return (
            <div className="step2">
                <TextBlock
                    text={`О дрессировке собак написано много книг, статей в интернете, есть видеоуроки. Но, несмотря на это, именно сейчас на этот счет существует несколько заблуждений:`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />
                <InfoBlock
                    text={`Ознакомьтесь с распространёнными мифами, нажав на активные элементы.`}
                    hidden={flexHidden === true ? ' hidden' : ''}
                />
                <div className={'flex ' + (flexHidden === true && 'hidden')}>
                    <div className={'flex_el ' + (activeFlex === 1 && 'flex_el_active')} onClick={()=>{this.changeFlex(1)}}>
                        Обучать мою собаку должен только специальный человек, я не могу это делать самостоятельно.
                    </div>
                    <div className={'flex_el ' + (activeFlex === 2 && 'flex_el_active')} onClick={()=>{this.changeFlex(2)}}>
                        Моей собаке нужен кинолог-породник.
                        Только породник знает,
                        как с ней заниматься.
                    </div>
                    <div className={'flex_el ' + (activeFlex === 3 && 'flex_el_active')} onClick={()=>{this.changeFlex(3)}}>
                        Есть интенсивные
                        и уникальные методики, позволяющие обучать
                        собаку очень быстро.
                    </div>
                </div>
                {activeFlex !== 0 &&
                    <div className={'flex_click active_' + activeFlex}>
                        <ArrowBack
                            returnMenu={this.closeFlexClick}
                        />
                        <div className="imgPhone">
                            <img className="imgPhoneBg" src={this.click_flex[activeFlex].imgXs} alt=""/>
                            <div className="header">
                                {this.click_flex[activeFlex].header}
                            </div>
                        </div>
                        {this.click_flex[activeFlex].text1 &&
                            <TextBlock
                                text={this.click_flex[activeFlex].text1}
                            />
                        }
                        <div className="flex_click_flex">
                            <TextBlock
                                text={this.click_flex[activeFlex].text2}
                            />
                            <img className="img" src={this.click_flex[activeFlex].img} alt=""/>
                        </div>
                    </div>
                }
            </div>
        )
    }
}
