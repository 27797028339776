import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson39/step5-1.png';
import img2 from '../../../image/lessons/lesson39/step5-2.svg';
import img3 from '../../../image/lessons/lesson39/step5-3.svg';
import img4 from '../../../image/lessons/lesson39/step5-4.svg';


export default class step5 extends React.Component{

    render() {
        return (
            <div className="step5">
                <TextBlock
                    text={`Чтобы поддерживать кожу и шерсть в хорошем состоянии, животным необходимо получать из рациона достаточное количество таких веществ, как <b>линолевая кислота и цинк</b>. Ищите эти компоненты на упаковке, чтобы быть уверенным, что корм способствует здоровью кожи и шерсти.`}
                />
                <div className="digestion">
                    Здоровье кожи и шерсти
                </div>
                <div className="block">
                    <div className="block_el">
                        <span className="block_el_arrow">
                            <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.29289 18.7071C7.68342 19.0976 8.31658 19.0976 8.70711 18.7071L15.0711 12.3431C15.4616 11.9526 15.4616 11.3195 15.0711 10.9289C14.6805 10.5384 14.0474 10.5384 13.6569 10.9289L8 16.5858L2.34315 10.9289C1.95262 10.5384 1.31946 10.5384 0.928932 10.9289C0.538407 11.3195 0.538407 11.9526 0.928932 12.3431L7.29289 18.7071ZM7 -4.3711e-08L7 18L9 18L9 4.3711e-08L7 -4.3711e-08Z" fill="#005EAD"/>
                            </svg>
                        </span>
                        <h3 className="block_el_head">
                            Линолевая кислота
                        </h3>
                        <ul>
                            <li>Является компонентом клеточных мембран, способствует насыщению клеток кислородом.</li>
                            <li>Защищает кожу от излишнего испарения влаги и препятствует ломкости и сухости волоса.</li>
                        </ul>
                        <div className="block_el_box">
                            <span className="block_el_icon">
                                <img src={img2} alt="" className="img_icon"/>
                            </span>
                        </div>
                        <span className="block_el_plus">
                            +
                        </span>
                    </div>
                    <div className="block_el">
                        <span className="block_el_arrow">
                            <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.29289 18.7071C7.68342 19.0976 8.31658 19.0976 8.70711 18.7071L15.0711 12.3431C15.4616 11.9526 15.4616 11.3195 15.0711 10.9289C14.6805 10.5384 14.0474 10.5384 13.6569 10.9289L8 16.5858L2.34315 10.9289C1.95262 10.5384 1.31946 10.5384 0.928932 10.9289C0.538407 11.3195 0.538407 11.9526 0.928932 12.3431L7.29289 18.7071ZM7 -4.3711e-08L7 18L9 18L9 4.3711e-08L7 -4.3711e-08Z" fill="#005EAD"/>
                            </svg>
                        </span>
                        <h3 className="block_el_head">
                            Цинк
                        </h3>
                        <ul>
                            <li>Участвует в синтезе коллагена — основного белка кожи.</li>
                            <li>Регулирует процесс формирования волоса.</li>
                            <li>Стимулирует обменные процессы в коже.</li>
                        </ul>
                        <div className="block_el_box">
                            <span className="block_el_icon">
                                <img src={img3} alt="" className="img_icon"/>
                            </span>
                        </div>
                    </div>
                    <div className="block_el">
                        <span className="block_el_arrow right">
                            <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.29289 18.7071C7.68342 19.0976 8.31658 19.0976 8.70711 18.7071L15.0711 12.3431C15.4616 11.9526 15.4616 11.3195 15.0711 10.9289C14.6805 10.5384 14.0474 10.5384 13.6569 10.9289L8 16.5858L2.34315 10.9289C1.95262 10.5384 1.31946 10.5384 0.928932 10.9289C0.538407 11.3195 0.538407 11.9526 0.928932 12.3431L7.29289 18.7071ZM7 -4.3711e-08L7 18L9 18L9 4.3711e-08L7 -4.3711e-08Z" fill="#005EAD"/>
                            </svg>
                        </span>
                        <span className="block_el_arrow left">
                            <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.29289 18.7071C7.68342 19.0976 8.31658 19.0976 8.70711 18.7071L15.0711 12.3431C15.4616 11.9526 15.4616 11.3195 15.0711 10.9289C14.6805 10.5384 14.0474 10.5384 13.6569 10.9289L8 16.5858L2.34315 10.9289C1.95262 10.5384 1.31946 10.5384 0.928932 10.9289C0.538407 11.3195 0.538407 11.9526 0.928932 12.3431L7.29289 18.7071ZM7 -4.3711e-08L7 18L9 18L9 4.3711e-08L7 -4.3711e-08Z" fill="#005EAD"/>
                            </svg>
                        </span>
                        <ul>
                            <li>Выраженный блеск и насыщенный цвет шерсти.</li>
                            <li>Снижение количества перхоти.</li>
                        </ul>
                        <div className="block_el_box">
                            <span className="block_el_icon">
                                <img src={img4} alt="" className="img_icon"/>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="img-box">
                    <img src={img1} alt="" className="img"/>
                </div>
            </div>
        )
    }
}
