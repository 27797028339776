import React from 'react'
import img1 from '../../../image/lessons/lesson3/step6.png';


export default class step6 extends React.Component {
    state = {}


    render() {
        return (
            <div className="step6">
                <div className="text">
                    Необходимо отделить <span className="textBlue">«чистые»</span> сектора от <span
                    className="textBlue">«грязных»</span>, а затем провести разбивку внутри каждого сектора,
                    исходя из индивидуальных факторов риска. Деление внутри сектора зависит от степени уязвимости
                    каждого животного, то есть в первую очередь от его физиологического статуса. <span
                    className="textBlue">В каждом секторе должно
                                        быть свое оборудование для уборки и дезинфекции!</span> Перемещение по питомнику должно осуществляться
                    согласно принципу движения только вперед.
                </div>
                <img src={img1} alt="" className={'img'}/>
            </div>
        )
    }

}