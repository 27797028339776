import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step1">
                <TextBlock
                    text={`Производители готовых кормов предоставляют покупателю большие возможности выбора различных кормов для домашних животных. Чтобы разобраться в разнообразии кормов, необходимо понимать, как они классифицируются по разным критериям.`}
                />
                <div className="head">план урока</div>

                <div className="text">
                    В этом уроке вы узнаете об основных различиях между кормами и особенностях их выбора.
                </div>
                <div className="text">
                    Вы изучите:
                </div>

                <div className="lessonSteps">
                    <div className="lessonSteps_el">По каким критериям классифицируются готовые корма для домашних животных</div>
                    <div className="lessonSteps_el">Какие бывают классы кормов, и чем они отличаются</div>
                    <div className="lessonSteps_el">Почему важно подбирать корм, учитывая возрастные и физиологические особенности питомца</div>
                    <div className="lessonSteps_el">Итоги урока</div>
                    <div className="lessonSteps_el">Тест</div>
                </div>

            </div>
        )
    }
}
