import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import img2 from "../../../image/lessons/lesson6/step7-2.png";
import img2xl from "../../../image/lessons/lesson6/step7-2xl.png";
import img3 from "../../../image/lessons/lesson6/step7-3.png";
import img3xl from "../../../image/lessons/lesson6/step7-3xl.png";
import img4 from "../../../image/lessons/lesson6/step7-4.png";
import img4xl from "../../../image/lessons/lesson6/step7-4xl.png";
import img5 from "../../../image/lessons/lesson6/step7-5.png";
import img5xl from "../../../image/lessons/lesson6/step7-5xl.png";
import img6 from "../../../image/lessons/lesson6/step7-6.png";
import img6xl from "../../../image/lessons/lesson6/step7-6xl.png";
import img7 from "../../../image/lessons/lesson6/step7-7.png";
import img7xl from "../../../image/lessons/lesson6/step7-7xl.png";
import img8 from "../../../image/lessons/lesson6/step7-8.png";
import img8xl from "../../../image/lessons/lesson6/step7-8xl.png";
import img9 from "../../../image/lessons/lesson6/step7-9.png";


export default class step7 extends React.Component {
    state = {
        showPopap: false,
        popupActive: 0
    };

    openPopup = (numberPopup) => {
        this.setState({
            showPopap: true,
            popupActive: numberPopup
        })
    }

    closePopup = () => {
        this.setState( {
            showPopap: false,
        })
    }

    popupWrp = {
        1: img2xl,
        2: img3xl,
        3: img4xl,
        4: img5xl,
        5: img6xl,
        6: img7xl,
        7: img8xl
    }

    render() {
        const {showPopap, popupActive} = this.state;
        return (
            <div className="step7">
                <TextBlock
                    fonSize={16}
                    text={`Чтобы забота о здоровье собаки была комфортнее, стоит обращаться за помощью к дневнику здоровья.`}
                />
                <div className="recommend">
                    <h4 className="recommend__title">
                        Рекомендации для владельца щенка
                        {/*<span>*/}
                        {/*    <img src={img9} alt="Логотип Pedigree"/>*/}
                        {/*</span>*/}
                    </h4>
                    <div className="recommend__box">
                        <div className="recommend__list">
                            <div className="recommend__item" onClick={() => {this.openPopup(1)}}>
                                <img src={img2} alt=""/>
                            </div>
                            <div className="recommend__item" onClick={() => {this.openPopup(2)}}>
                                <img src={img3} alt=""/>
                            </div>
                            <div className="recommend__item" onClick={() => {this.openPopup(3)}}>
                                <img src={img4} alt=""/>
                            </div>
                        </div>
                        <div className="recommend__list">
                            <div className="recommend__item" onClick={() => {this.openPopup(4)}}>
                                <img src={img5} alt=""/>
                            </div>
                            <div className="recommend__item" onClick={() => {this.openPopup(5)}}>
                                <img src={img6} alt=""/>
                            </div>
                            <div className="recommend__item" onClick={() => {this.openPopup(6)}}>
                                <img src={img7} alt=""/>
                            </div>
                            <div className="recommend__item" onClick={() => {this.openPopup(7)}}>
                                <img src={img8} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>

                {showPopap &&
                <div className={'infoPopap'}>
                    <div className="infoPopap_wrp infoPopap_wrp_img">
                        <div className="popap_close" onClick={() => {
                            this.closePopup()
                        }}/>
                        <img src={this.popupWrp[popupActive]} alt=""/>
                        {/*<button className="btn btn_blue" onClick={() => {
                            this.changePoint(pointActive < 6 ? pointActive + 1 : 0)
                        }}>Далее</button>*/}
                    </div>
                </div>
                }

            </div>
        )
    }
}
