import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock';

export default class step5 extends React.Component {

    cardText = {
        1: {
            text: `<span class="text_dark-blue"><b>Кличка</b></span> – очень важный маркер для собаки.`
        },
        2: {
            text: `Собака не знает своей клички изначально, <span class="text_dark-blue"><b>к кличке нужно приучить.</b></span>`
        },
        3: {
            text: `<span class="text_dark-blue"><b>Кличка должна быть короткой и четкой</b></span> для удобства произношения.`
        },
        4: {
            text: `<span class="text_dark-blue"><b>Работайте короткими сессиями по 5-10 повторений за раз,</b></span> не забывайте хвалить собаку во время обучения!`
        },
        5: {
            text: `Впоследствии <span class="text_dark-blue"><b>кличку можем произносить перед любой командой,</b></span> чтобы привлечь внимание собаки.`
        },
    }

    render() {
        return (
            <div className="step5">
                <div className="flex">
                    {Object.keys(this.cardText).map(item => {
                        return (
                            <div
                                key={item}
                                className="flex_el"
                            >
                                <div className="flex_card">
                                    <div className="flex_results">
                                        {'вывод ' + item}
                                    </div>
                                    <div className="flex_wrp">
                                        <TextBlock
                                            text={this.cardText[item].text}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>



        )
    }
}
