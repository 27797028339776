import React from 'react'

export default class step1 extends React.Component {
    render() {
        return (
            <div className="step1">
                <div className="head">план урока</div>

                <div className="text">
                    Вашему вниманию предлагается цикл уроков, предназначенный для широкого круга людей,
                    интересующихся собаководством — от планирующих завести собаку — до тех, у кого она уже есть.
                    <br/>
                    <br/>
                    Не секрет, что собака — это прежде всего ответственность. И человек, выбирая питомца, должен
                    хорошо себе представлять тот круг вопросов, которые неизбежно возникнут.
                    <br/>
                    <br/>
                    Цель этого урока — очертить этот круг и по возможности предложить ответы.
                    В этом уроке рассматриваются следующие вопросы:
                </div>

                <div className="lessonSteps">
                    <div className="lessonSteps_el">
                        О чём надо знать будущему владельцу собаки?
                    </div>
                    <div className="lessonSteps_el">
                        Как выбрать собаку?
                    </div>
                </div>

            </div>
        )
    }
}