import React from 'react'
import './style.sass'

export default class ModalAfterStep extends React.Component {

    componentDidMount() {
        document.body.scrollIntoView( true);
        document.body.style.overflow = "hidden";
    }

    componentWillUnmount() {
        document.body.style.overflow = "";
    }

    stopClosePopap = (e) => {
        e.stopPropagation()
    }

    render() {
        return (
            <div className="ModalAfterStep" onClick={() => {this.props.closePopap()}}>
                <div className={'modal ' + this.props.className} onClick={(e) => {this.stopClosePopap(e)}}>
                    <div
                        className="modal_closed"
                        onClick={() => {this.props.closePopap()}}
                    />
                    <div className="modal_img">
                        <img src={this.props.modalImg} alt=""/>
                    </div>
                    <div className={'modal_head'} dangerouslySetInnerHTML={{ __html: this.props.modalHead }}/>
                    <div className="modal_content">
                        <div className={'modal_text'} dangerouslySetInnerHTML={{ __html: this.props.modalText }}/>
                        {this.props.modalImg2 &&
                        <img src={this.props.modalImg2} alt="" className="imgCenter img2"/>
                        }
                        {this.props.modalText2 &&
                        <div className={'modal_text'} dangerouslySetInnerHTML={{__html: this.props.modalText2}}/>
                        }
                    </div>
                    <div className="modal_btn">
                        <button
                            className="btn btn_blue"
                            onClick={() => {this.props.endLesson ? this.props.showPopapAfterLesson() : this.props.showNextStep()}}
                        >
                            {this.props.modalBtnText ? this.props.modalBtnText : 'продолжить'}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}
