import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'
import img1 from '../../../image/lessons/lesson33/step28-1.jpg'


export default class step6 extends React.Component {


    render() {
        return (
            <div className="step28">
                <TextBlock
                    text={`Как мы видим, оптимальный рацион для собаки зависит <b>от стадии жизни,</b> на котором она находится.
                    
                    Самая <b>высокая</b> потребность в питательных веществах приходится на период лактации. В этот период собака может есть <b>в 4 раза больше</b> ее стандартной нормы.`}
                />

                <div className="flex">
                    <div className="flex_el red">
                        <TextBlock
                            text={`Щенки начинают процесс отлучения от грудного вскармливания тогда, когда они начинают проявлять интерес к еде своей матери и пить воду из ее миски. Они нуждаются <b>в большом количестве приемов пищи в течение дня,</b> чтобы получать необходимые для роста питательные вещества в достаточном количестве. Собаки <b>разных размеров</b> растут в различном темпе и взрослеют в течение разного времени. Это также необходимо учитывать при планировании рациона щенка.`}
                        />
                    </div>
                    <div className="flex_el ">
                        <img src={img1} alt="" className="img"/>
                    </div>
                </div>
                <TextBlock
                    text={`Питание взрослой собаки должно быть <b>сбалансированным</b> и поддерживать ее состояние здоровья. Пожилым собакам нужен особый уход и <b>легко перевариваемая</b> пища.`}
                />
            </div>
        )
    }
}
