import React from 'react'
import TextBlock from '../../../Components/LessonAndTestComponents/TextBlock'

import img1 from '../../../image/lessons/lesson35/step5-1.png';


export default class step5 extends React.Component{

    render() {
        return (
            <div className="step5">
                <TextBlock
                    text={`Международные стандарты устанавливают единые правила, по которым должен оформляться состав корма. Ознакомьтесь с самыми значимыми из них.`}
                />
                <div className="flex">
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <div className="flex_count">1</div>
                            <TextBlock
                                text={`Ингредиенты перечисляют в порядке <b>уменьшения массовой доли</b> в момент изготовления продукта.
                                
                                Например, если на первом месте стоит курица, а на втором — злаки, то это означает, что курицы в этой рецептуре больше, чем злаков.`}
                            />
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <div className="flex_count">2</div>
                            <TextBlock
                                text={`В перечислении ингредиентов применяют <b>названия категорий,</b> к которым они принадлежат.
                                
                                Вместо перечисления отдельных мясных компонентов <b>может быть использована фраза «мясные ингредиенты».</b>`}
                            />
                        </div>
                    </div>
                    <div className="flex_el">
                        <div className="flex_wrp">
                            <div className="flex_count">3</div>
                            <TextBlock
                                text={`Ингредиент с массовой долей <b>менее 2%</b> может не указываться в перечне ингредиентов.`}
                            />
                        </div>
                    </div>
                </div>
                <img className="img" src={img1} alt=""/>
            </div>
        )
    }
}
